import React from "react";
import postscribe from "postscribe";

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount=()=>{
      postscribe('#verify-form', "<script type='text/javascript'> var w7fzzwg1mfw6t6; (function(d, t) { var s = d.createElement(t), options = { 'userName':'geoffbanks', 'formHash':'w7fzzwg1mfw6t6', 'autoResize':true, 'height':'596', 'async':true, 'host':'wufoo.com', 'header':'show', 'ssl':true }; s.src = ('https:' == d.location.protocol ?'https://':'http://') + 'secure.wufoo.com/scripts/embed/form.js'; s.onload = s.onreadystatechange = function() { var rs = this.readyState; if (rs) if (rs != 'complete') if (rs != 'loaded') return; try { w7fzzwg1mfw6t6 = new WufooForm(); w7fzzwg1mfw6t6.initialize(options); w7fzzwg1mfw6t6.display(); } catch (e) { } }; var scr = d.getElementsByTagName(t)[0], par = scr.parentNode; par.insertBefore(s, scr); })(document, 'script'); </script>")
  }

  render() {
    return (
      <div id='verify-form'>
        <div id="wufoo-w7fzzwg1mfw6t6">
          Fill out my
          <a href="https://geoffbanks.wufoo.com/forms/w7fzzwg1mfw6t6">
            online form
          </a>
          .
        </div>
      </div>
    );
  }
}
export default Verify;
