//import './css/style.css'
// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./css/style.css";
import "./css/resCarousel.css";
import "./css/custom.css";
import { Provider } from "react-redux";
import store from "./store";

import { BrowserRouter as Router } from "react-router-dom";

import { Provider as UnstatedProvider } from "unstated";
// import UNSTATED from 'unstated-debug';

import * as utility from "./js/utility";

import Header from "./Component/Header";
import Routes from "./routes";


// UNSTATED.logStateChanges = false;

const rootEl = document.getElementById("root");
utility.AutoLogout();
//utility.CheckInPlaySocket();

if (module.hot) {
  module.hot.accept();
}
ReactDOM.render(
  <Provider store={store}>
    <UnstatedProvider>
      <Router>
        <div>
          <Header />
          {/* returns list of all routes */}
          <Routes />
        </div>
      </Router>
    </UnstatedProvider>
  </Provider>,
  rootEl
);
