import React from 'react';


class Maintanence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {           
        }
    }





    render() {
       return (<div>
           <div className="maintanence_page">
                <img src="/images/geoff-down.png"/>
            </div>
           </div>)
    }
}

export default Maintanence;