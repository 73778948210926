import React from "react";
import RacesList from "./RacesList";
import * as $ from "jquery";
import _ from "lodash";
import subscribe from "unstated-subscribe-hoc";
import {BetSlipContainer} from "../Containers/BetSlipContainer";
import {PreferenceContainer} from "../Containers/PreferenceContainer";
import Tooltip from "./Shared/Tooltip";

let bogArrBanner = [];
var gotResponse;
var liveData;

class NextRace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            liveRacesEvent: {Races: []},
            timeLeftFormatted: "",
            timeLeft: "",
            showFullHide: true,
            limit: 4,
            sportCode: "",
            eventData: [],
            bogBanner: false,
        };
        this.LoadLiveData = this.LoadLiveData.bind(this);
        this.ParseAndSetTime = this.ParseAndSetTime.bind(this);
    }

    componentDidMount() {
        const _this = this;
        const params = new URLSearchParams(window.location.search);
        var sportcode = params.get("sportcode");
        // if (window.location.pathname == '/') {
        //     sportcode = 'H,h,o';
        // }
        if (window.location.pathname == "/") {
            sportcode = "H,h";
        }
        if (sportcode == "g,q") {
            this.showFull();
        }
        //console.log("sportcode------------" + sportcode);
        this.setState({sportCode: sportcode});
        // _this.LoadLiveData(sportcode);
        //THIS CODE LOADS LIVE RACES IN THE HEADER
        // this.keepLoading = setInterval(function() {
        //   _this.LoadLiveData(sportcode);
        // }, 5000);
        this.runCountDown = setInterval(function () {
            _this.ParseAndSetTime(_this.state.timeLeft);
        }, 1000);
    }

    componentWillReceiveProps(newProps) {

        liveData = newProps.mainState;
        var limit = newProps.limit;
        if (limit != undefined) {
            this.setState({limit: limit, showFullHide: false});
        }

        this.setState({liveData: liveData});
        this.LoadLiveData("");
        // this.ParseAndSetTime(this.state.timeLeft);
    }

    LoadLiveData(sportcode) {
        if (sportcode == null) {
            sportcode = "H,h";
        }
        const _this = this;
        gotResponse = false;

        var liveraces = this.state.liveData;
        if (_.isEmpty(liveraces)) return;
        gotResponse = true;
        liveData = [];

        if (typeof liveraces != "undefined") {
            var liveData = liveraces;
            if (liveData.Events) {
                // liveData.Events[0].ToolTip = liveData.BestOdds;
                liveData.Name = liveData.MeetingName;
                liveData.Races = liveData.Events;
                liveData.TimeLeft = liveData.TimeText;
                if (liveData.BestOdds) {
                    liveData.PriceGuarantee = "1";
                }
                liveData.Races.map((data, i) => {
                    data.SelName = data.DogName;
                });
            }
            if (liveData.Races !== undefined && liveData.Races.length !== 0) {
                var eventFile = liveData.Races[0].EventFile;
                if (liveData.Races[0].EventFile === undefined) {
                    eventFile = liveData.Races[0].eventfile;
                }
                var eventVar = {
                    ef: eventFile,
                    eg: liveData.Races[0].EventGroup,
                    e: liveData.Races[0].Events,
                };
            }

            debugger;


            // console.log(liveData.Races[0].ToolTip, "tooltip1");
            // console.log(liveData.Races[0].ToolTipBoardPrice, "tooltip2");
            let _bogBanner = false;
            // ALL THE CODE BELOW IS FOR BOG BANNER, TURNED OFF FOR NOW
            // if (liveData.Races[0].ToolTip || liveData.Races[0].ToolTipBoardPrice) {
            //   // if ((window.location.pathname == '/') && (liveData.Races[0].ToolTip == "Best odds guaranteed" || liveData.Races[0].ToolTipBoardPrice == "Best odds guaranteed")) {
            //   //previous starts if ((liveData.Races[0].ToolTip.toLowerCase() == "best odds guaranteed" || liveData.Races[0].ToolTipBoardPrice.toLowerCase() == "best odds guaranteed")) {
            //   //     //    if ( (liveData.Races[0].ToolTip == "BEST ODDS GUARANTEED" || liveData.Races[0].ToolTipBoardPrice == "BEST ODDS GUARANTEED")) {
            //   //     _this.setState({ bogBanner: true });
            //   // }perviuos ends

            //   if (liveData.Races != undefined) {
            //     if (
            //       (typeof liveData.Races[0].ToolTip !== "undefined" &&
            //         liveData.Races[0].ToolTip.toLowerCase() ===
            //           "best odds guaranteed") ||
            //       (typeof liveData.Races[0].ToolTipBoardPrice !== "undefined" &&
            //         liveData.Races[0].ToolTipBoardPrice === "best odds guaranteed")
            //     ) {
            //       //    if ( (liveData.Races[0].ToolTip == "BEST ODDS GUARANTEED" || liveData.Races[0].ToolTipBoardPrice == "BEST ODDS GUARANTEED")) {
            //       _bogBanner = true;
            //     }
            //   } else if (liveData.Events != undefined) {
            //     if (
            //       (typeof liveData.Events[0].ToolTip !== "undefined" &&
            //         liveData.Events[0].ToolTip.toLowerCase() ===
            //           "best odds guaranteed") ||
            //       (typeof liveData.Events[0].ToolTipBoardPrice !== "undefined" &&
            //         liveData.Events[0].ToolTipBoardPrice === "best odds guaranteed")
            //     ) {
            //       //    if ( (liveData.Races[0].ToolTip == "BEST ODDS GUARANTEED" || liveData.Races[0].ToolTipBoardPrice == "BEST ODDS GUARANTEED")) {
            //       _bogBanner = true;
            //     }
            //   }
            //   var bogSportidMatch = JSON.parse(
            //     localStorage.getItem("bog_sportid_match")
            //   );
            //   _.find(bogSportidMatch, function (o) {
            //     if (o.ItemId === liveData.Races[0].EventId) {
            //       console.log(o.ItemId, "ItemId1");
            //       console.log(liveData.Races[0].EventId, "ItemId2");
            //       _bogBanner = false;
            //     }
            //     return o.ItemId === liveData.Races[0].EventId;
            //   });
            // }

            // liveData.Races = _.sortBy(liveData.Races, function (event) {
            //   return event.PriceLink1 === "NR" || event.Price1 === "NR" ? 1 : 0;
            // });
            // if (liveData.BestOdds != undefined) {
            //   _bogBanner= false;
            // }
            // ALL THE CODE BELOW IS FOR BOG BANNER, TURNED OFF FOR NOW /// ENDS HERE

            this.setState({eventData: eventVar, bogBanner: _bogBanner});
        } else {
            return;
        }
        if (liveData.Tooltip != "") {
            delete liveData.Tooltip;
        }
        //console.log("liveData----", liveData);

        //SORT RUNNER - SET NON RUNNER (NR) AT BOTTOM
        liveData.Races = _.sortBy(liveData.Races, function (event) {
            return event.PriceLink1 === "NR" || event.Price1 === "NR" ? 1 : 0;
        });
        _this.setState({liveRacesEvent: liveData});
        this.ParseAndSetTime(liveData.TimeLeft);

        // this.highlightBog(liveData);
    }

    highlightBog(data) {
      const tooltip = data.Races[0].Tooltip;
      if (typeof tooltip != "undefined") {
            if (tooltip.toLowerCase() === "best odds guaranteed")
                $(".price").addClass("bestOddPrice");
        }
    }

    ParseAndSetTime(time) {
        const that = this;
        if (typeof time != "undefined" && time.indexOf(":") > 0) {
            var dd = time.split(":");
            if (dd.length == 3) {
                var hour = dd[0];
                var min = dd[1];
                var sec = parseInt(dd[2]) - 1;

                if (sec <= 0) {
                    min = min - 1;
                    sec = 0;
                }
                if (min <= 0 && sec == 0) {
                    hour = hour - 1;
                    min = 0;
                }
                if (mm < 10) {
                    that.setState({
                        timeLeft: hour + ":0" + min + ":" + sec,
                        timeLeftFormatted: that.state.timeLeft,
                    });
                } else {
                    that.setState({
                        timeLeft: hour + ":" + min + ":" + sec,
                        timeLeftFormatted: that.state.timeLeft,
                    });
                }
            } else {
                var hh = dd[0];
                var mm = parseInt(dd[1]) - 1;

                if (mm <= 0) {
                    hh = hh - 1;
                    mm = 0;
                }
                if (mm < 10) that.setState({timeLeft: hh + ":0" + mm});
                else that.setState({timeLeft: hh + ":" + mm});

                that.setState({
                    timeLeftFormatted: that.state.timeLeft,
                });
            }
        } else that.setState({timeLeftFormatted: time});
    }

    showFull() {
        // /this.limit =100;
        this.setState({limit: 100, showFullHide: false});
        if (
            this.state.liveRacesEvent.Races[0] &&
            (this.state.liveRacesEvent.Races[0].ToolTip ||
                this.state.liveRacesEvent.Races[0].ToolTipBoardPrice)
        ) {
            // $(".price").removeClass("bestOddPrice");
            // setTimeout(() => {
            //   $(".price").addClass("bestOddPrice");
            // }, 100);
        }
    }

    closeBogBanner() {
        this.GetAllBOGBetsFromLocalStorage();

        bogArrBanner.push({
            ItemId: this.state.liveRacesEvent.Races[0].EventId,
        });
        localStorage.setItem("bog_sportid_match", JSON.stringify(bogArrBanner));

        this.setState({bogBanner: false});
    }

    bog_redirect() {
        //this.props.history.push("/promotions/BOG");
        window.location.href = "/promotions/BOG";
    }

    GetAllBOGBetsFromLocalStorage() {
        var data = localStorage.getItem("bog_sportid_match");

        if (typeof data == "undefined" || data == null) {
            bogArrBanner = [];
            return bogArrBanner;
        } else {
            bogArrBanner = JSON.parse(data);
            return bogArrBanner;
        }
    }

    render() {
        if (this.state.liveRacesEvent.length != 0) {
            return (
                <div className="show-events" id="loaderContainer">
                    {this.state.bogBanner == true ? (
                        <div className="bog flipInX animated">
                            <div className="bog-label-outer">
                                {" "}
                                <img
                                    src="/images/cross.svg"
                                    className="cross_bg"
                                    onClick={() => this.closeBogBanner()}
                                />
                                <div
                                    className="col-md-12 bog-label"
                                    onClick={() => this.bog_redirect()}
                                >
                                    <label>BOG</label>

                                    <div className="bog-text">
                                        <h2>Best Odds Guaranteed </h2>

                                        <p className="bog-label-left">
                                            Whenever you see the sign, if you take an early price and
                                            Starting Price (SP) is bigger, we will pay out at the
                                            bigger odds (Singles only)!
                                        </p>
                                        <p className="bog-label-right">
                                            <img src="/images/BOG.png"/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="show-events-inner hideRaceTimeMenu">
                        {/* <div className="next-race">NEXT RACES</div> */}
                        <div className="show-events-heading">
                            <Tooltip/>
                            <h3
                                data-tip={
                                    this.state.liveRacesEvent.Races[0]
                                        ? this.state.liveRacesEvent.Races[0].EventId
                                        : ""
                                }
                            >
                                <img
                                    src="/images/9-dots.png"
                                    data-toggle="collapse"
                                    data-target="#otherRaces"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                />
                                {this.state.liveRacesEvent.Name}{" "}
                                {this.state.liveRacesEvent.Time}
                                {this.state.liveRacesEvent.Races[0] ? (
                                    this.state.liveRacesEvent.Races[0].ToolTip ||
                                    this.state.liveRacesEvent.Races[0].ToolTipBoardPrice ? (
                                        <span>
                      {" "}
                                            {/* - {this.state.liveRacesEvent.Races[0].ToolTip}{" "} */}
                                            {this.state.liveRacesEvent.Races[0].ToolTipBoardPrice}{" "}
                    </span>
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    ""
                                )}
                                {/* <span >{this.state.liveRacesEvent.Time} , {this.state.liveRacesEvent.Info1}{this.state.liveRacesEvent.Name}
                                </span> */}
                            </h3>
                        </div>
                        {/* <div className="collapse" id="otherRaces" >
                            <RacesList sportCode={this.state.sportCode} eventData={this.state.eventData} />
                        </div > */}

                        <RacesList
                            sportCode={this.state.sportCode}
                            eventData={this.state.eventData}
                        />

                        <div>
                            <div className="time-remaining" title="AB">
                                <div className="col-md-6">
                                    <h3 className="event-name">
                                        {this.state.liveRacesEvent.Info1}
                                    </h3>
                                </div>
                                <div className="col-md-3 text-right">
                                    <label>{this.state.liveRacesEvent.PType}</label>
                                </div>
                                <div className="col-md-3 text-center">
                                    <label style={{width: 100 + "%", textAlign: "center", color: "red",}}>
                                        {/* Hide clock icon when no time  */}
                                        {this.state.timeLeftFormatted ?
                                            <i className="fa fa-clock-o" aria-hidden="true"></i> : ""
                                        }
                                        {this.state.timeLeftFormatted}
                                    </label>
                                    {/* <label>TIME LEFT
                                        <span>05</span>
                                        <em>:</em>
                                        <span>45</span>
                                        <em>:</em>
                                        <span>27</span>
                                    </label> */}
                                </div>
                            </div>
                            <div>
                                {this.state.liveRacesEvent.Races.map(function (race, i) {
                                    if (i < this.state.limit) {
                                        return (
                                            <div className="list" key={i}>
                                                <div className="col-md-6">
                                                    <label>
                                                        {race.Image ? (
                                                            <img
                                                                src={
                                                                    "https://v0.geoff-banks.com/images/Dogs/" +
                                                                    race.Image
                                                                }
                                                                alt="image"
                                                                height="20px "
                                                                style={{marginRight: 10 + "px"}}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {race.SelName}
                                                        <br/>
                                                    </label>
                                                </div>
                                                {/* <div className="col-md-3">
                          <label className="jockey-name">
                            {race.JockeyName}{" "}
                          </label>
                        </div> */}

                                                <div className="col-md-3 text-right">
                                                    {race.price != "" &&
                                                    race.Price1 !== "NR" &&
                                                    race.gprice ? (
                                                        <span
                                                            className={
                                                                race.Tooltip.toLowerCase().trim() ==
                                                                "best odds guaranteed"
                                                                    ? "cursor-pointer price bestOddPrice"
                                                                    : "cursor-pointer price"
                                                            }
                                                            onClick={(e) =>
                                                                this.props.Store.AddBet(
                                                                    e,
                                                                    race,
                                                                    race,
                                                                    race.selection
                                                                )
                                                            }
                                                        >
                              {this.props.PreferenceStore.ConvertPrice(
                                  race.price
                              )}{" "}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className="col-md-3 text-right">
                                                    {race.Price1 != "NR" ? (
                                                        <span
                                                            className="cursor-pointer "
                                                            onClick={(e) => this.props.Store.AddSpBet(race)}
                                                        >
                              {" "}
                                                            SP
                            </span>
                                                    ) : (
                                                        <span>{race.Price1}</span>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                }, this)}
                                {this.state.liveRacesEvent.PlText ? (
                                    <div className="list">
                                        <div className="col-md-12">
                                            <label>
                                                <i>{this.state.liveRacesEvent.PlText}</i>
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        {this.state.showFullHide ? (
                            <div
                                className="view-full-race text-center"
                                onClick={(e) => this.showFull()}
                            >
                                <label>
                                    View full Race{" "}
                                    <i className="glyphicon glyphicon-chevron-right"></i>
                                </label>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                // <div className="panel-body section-outer ">
                //     <div className="nextRace">
                //         Next Races
                //           </div>
                //     <table className="table table-striped">
                //         <thead>
                //             <tr className="goodwood">

                //                 <th>  <b>{this.state.liveRacesEvent.Name} {this.state.liveRacesEvent.Time}

                //                     {
                //                         this.state.liveRacesEvent.Info1 ?
                //                             <span> , {this.state.liveRacesEvent.Info1}<br /></span>
                //                             : ''
                //                     }  </b>
                //                     {
                //                         this.state.liveRacesEvent.PriceGuarantee == '1' ?
                //                             <em>(BEST ODDS GUARANTEED)</em> : ''
                //                     } </th>
                //                 <th></th>
                //                 <th>
                //                     <label className="early-price-heading">  {this.state.liveRacesEvent.PType}
                //                     </label>
                //                 </th>
                //                 <th><label className="early-price-heading" > {this.state.timeLeftFormatted}</label> </th>
                //             </tr>
                //         </thead>

                //         <tbody>
                //             {
                //                 this.state.races.map(function (race, i) {
                //                     return (
                //                         <tr key={i}>
                //                             <td>
                //                                 {race.Image ?
                //                                     <img src={'https://v0.geoff-banks.com/images/Dogs/' + race.Image} alt="image" height="20px " style={{ marginRight: 10 + 'px' }} />
                //                                     : ''
                //                                 }

                //                                 {race.SelName} </td>
                //                             <td>{race.JockeyName}  </td>

                //                             {race.price != '' && race.Price1 !== 'NR' && race.gprice ?
                //                                 <td className="center"><label className="early-price " onClick={(e) => BetSlip_v1_Provider.AddBet(e, race, race, race.selection)}> {race.price}</label></td>
                //                                 :
                //                                 <td className="center"><label className="early-price">{race.Price1}</label></td>
                //                             }
                //                             {race.Price1 === 'NR' ?
                //                                 <td></td>
                //                                 :
                //                                 <td className="center"><label className="early-price" onClick={(e) => BetSlip_v1_Provider.addSpBet(race)}> SP</label></td>
                //                             }
                //                         </tr>
                //                     )
                //                 }, this)
                //             }
                //         </tbody>

                //     </table>
                //     <div className="meetingPl">
                //         {this.state.liveRacesEvent.PlText}
                //     </div>
                // </div>
            );
        } else {
            return <div></div>;
        }
    }
}

export default subscribe(NextRace, {
    Store: BetSlipContainer,
    PreferenceStore: PreferenceContainer,
});
