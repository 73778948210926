import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import Home from "./Views/Home";
import Login from "./Views/Login";
import Affiliate from "./Views/Affiliate";

import Races from "./Views/Races";
import SingleEvent from "./Views/Single-Event";
import SingleEventResult from "./Views/Single-Event-Result";

import Sports from "./Views/Sports";
import ShowEvents from "./Views/Show-Events";
import ExpiredEvent from "./Views/ExpiredEvent";

import AntePost from "./Views/AntePost";
import SportEvents from "./Views/SportEvents";
import AntePostEvents from "./Views/AntePostEvents";
import BetSlip from "./Views/BetSlip";
import PlaceBet from "./Views/PlaceBet";

// ****Promotion*** //
import Promotions from "./Views/Promotions";
import PromotionPages from "./Views/PromotionPages";

// ****Account*** //
import Transactions from "./Views/Account/Transactions";
import ChangePassword from "./Views/Account/ChangePassword";
import MyBets from "./Views/Account/MyBets";
import Deposit from "./Views/Account/Deposit";
import PaydooDeposit from "./Views/Account/Paydoo-deposit";
import Withdrawal from "./Views/Account/Withdrawal";
import PaypalWithdrawal from "./Views/Account/PaypalWithdrawal";
import ReverseWithdrawals from "./Views/Account/reverse-withdrawals";
import ManualDeposite from "./Views/Account/Manual-deposite";
import ManualDeposit2 from "./Views/Account/ManualDeposit2";
import ReactivateAccount from "./Views/Account/Reactivate-account";
import DepositLimit from "./Views/Account/Deposit-limit";
import ResponsibleGambling from "./Views/Account/Responsible-gambling";

import Signup from "./Views/Account/Signup";
import SignupV1 from "./Views/Account/Signup-v1";

import ForgotPassword from "./Views/Account/ForgotPassword";
import ShowPage from "./Views/Show-Pages";
import UserDetails from "./Views/Account/UserDetails";
import MyProfile from "./Views/Account/MyProfile";
import UpdateMyProfile from "./Views/Account/UpdateMyProfile";
import PaypalDeposit from "./Views/Account/PaypalDeposit";
import Maintanence from "./Views/Maintanence";
import PaymentGatway from "./Views/Account/PaymentGatway";
import Verify from "./Views/Verify";
import SignupForm from "./Views/Account/sign-up";
import AffiliateV2 from "./Views/AffiliateV2";
import { GetFormsData } from "./js/api-service";
import IFrameForm from "./Views/IFrameForm";

const Routes = () => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    FetchRoutes();
  }, []);

  const FetchRoutes = async () => {
    try {
      let result = await GetFormsData();
      if (result && result.status === 200) {
        console.log("paths wolf", result.data.data);
        setRoutes(result.data.data);
      } else {
        throw new Error(result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/sign-up" component={Signup} />
        <Route exact path="/sign-up-v1" component={SignupV1} />
        <Route
          exact
          path={["/sign-up-v2", "/sign-up-v3"]}
          component={SignupForm}
        />

        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/user-details" component={UserDetails} />
        <Route exact path="/my-profile" component={MyProfile} />
        <Route exact path="/update-my-profile" component={UpdateMyProfile} />

        <Route path="/Affiliate" component={Affiliate} />
        <Route path="/sign-up-affiliate" component={AffiliateV2} />

        <Route path="/transactions" component={Transactions} />
        <Route path="/deposit" component={Deposit} />
        <Route path="/withdrawals" component={Withdrawal} />
        <Route path="/paypal-withdrawal" component={PaypalWithdrawal} />
        <Route path="/paypal-deposit" component={PaypalDeposit} />
        <Route path="/payment-gatway" component={PaymentGatway} />
        <Route path="/paydoo-deposit" component={PaydooDeposit} />

        <Route path="/reactivate-account" component={ReactivateAccount} />
        <Route path="/manual-deposit" component={ManualDeposite} />
        <Route path="/manual_deposit_v1" component={ManualDeposit2} />
        <Route path="/reverse-withdrawals" component={ReverseWithdrawals} />
        <Route path="/Responsible-gambling" component={ResponsibleGambling} />
        <Route path="/Deposit-limit" component={DepositLimit} />
        <Route path="/show-page/:pageName" component={ShowPage} />
        <Route path="/change-password" component={ChangePassword} />

        <Route exact path="/promotions" component={Promotions} />
        <Route path="/promotions/:pageName" component={PromotionPages} />

        <Route path="/my-bets" component={MyBets} />
        <Route exact path="/bet-slip" component={BetSlip} />
        <Route exact path="/place-bet" component={PlaceBet} />
        <Route exact path="/sports" component={Races} />
        <Route path="/sports/:name" component={Sports} />
        <Route path="/show-events" component={ShowEvents} />
        <Route path="/single-event" component={SingleEvent} />
        <Route path="/single-event-result" component={SingleEventResult} />
        <Route path="/myfavourites" component={ExpiredEvent} />
        <Route exact path="/antepost" component={AntePost} />
        <Route exact path="/sportEvents" component={SportEvents} />
        <Route exact path="/antePostEvents" component={AntePostEvents} />
        <Route exact path="/maintanence" component={Maintanence} />
        <Route exact path="/verify" component={Verify} />

        {/* IFrame Routes */}
        {
            routes.map((r)=>(<Route exact path={r.slug} render={(props)=>(<IFrameForm data={{ url: r.url, title: r.name, height: r.height }} {...props} />)} />))
        }
        {/* - */}
      </Switch>
    </>
  );
};

export default Routes;
