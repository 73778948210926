import React from "react";

import Select from "react-select";
import * as Api from "../../js/api-service";
import * as Utility from "../../js/utility";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import Version from "../../Component/Shared/version";
import AddressAutoComplete from "../../Component/AddressAutoComplete"

// import * as Shared from "./Shared/Index";

// import { getaddressByZipcode } from '../../apis/address'
// import { PostDataWithHeader, PostDataWithBody, GetData } from '../../apis/request'
// import { googleSheetExport } from '../../apis/googleSheet'
// import { GOOGLE_CREDENTIAL_URLS, API_BASE_URL } from "../../util/constants";
// import axios from 'axios';
// import swal from 'sweetalert';

// import Loader from "../common/Loader";

import _ from "lodash";
import { MoonLoader } from "react-spinners";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const mobRegEx = RegExp("^([0|+[0-9]{1,5})?([0-9]{10})$");

const passRegEx = RegExp("^[_A-z0-9]*((-|\s)*[_A-z0-9])*$")
// const passRegEx = RegExp("/^[A-Za-z0-9 ]+$/")
// ^[a-zA-Z0-9]{4,10}$


class SignupV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      depositLimit: "",
      zipCode: "",
      firstLineAddress: "",
      secondLineAddress: "",
      thirdLineAddress: "",
      town: "",
      country: "",
      postCode: "",
      isAddressFieldVisible: false,
      showAddressdrp: false,
      addressList: [],
      address: "",
      isInvalidCode: false,
      isZipCodeRequired: false,
      firstName: "",
      lastName: "",
      personalDetailFileName: "",
      verificationFileName: "",
      bankDetailFileName: "",
      isTermCondCheck: true,
      email: "",
      username: "",
      password: "",
      promocode: "",
      isOcassional: true,
      isSubmit: false,
      showPassword: false,
      date: "",
      month: "",
      year: "",
      ShowLoader: false,
      securityQuestion: " ",
      securityAnswer: "",
      verificationFileBase64: "",
      personalDetailBase64: "",
      bankDetailBase64: "",
      mobileNumber: "",
      countryCode: "",
      checkNum: true,
      QuestionList: [
        { label: "Select Security Question", value: " " },
        {
          label: "What is your mother's maiden name?",
          value: "What is your mother's maiden name?",
        },
        {
          label: "Which phone number do you remember most from your childhood?",
          value: "Which phone number do you remember most from your childhood?",
        },
        {
          label: "What was your favorite place to visit as a child?",
          value: "What was your favorite place to visit as a child?",
        },
        {
          label: "Who is your favorite actor,musician, or artist?",
          value: "Who is your favorite actor,musician, or artist?",
        },
        {
          label: "What is the name of your best friend from childhood?",
          value: "What is the name of your best friend from childhood?",
        },
      ],
      errors: {
        firstName: "First Name is required",
        lastName: "Last Name is required",
        email: "Email is required",
        username: "Username is required",        
        verificationFileName: "Personal Verification file is required",
        personalDetailFileName: "",
        bankDetailFileName:"",
        password: "Password is required",
        date: "Date is required",
        month: "Month is required",
        year: "Year is required",
        isTermCondCheck: "",
        securityAnswer: "",
        mobileNumber: "Mobile Number is invalid",
        depositLimit: "Deposit Limit is required",
        firstLineAddress: "Address is required",
        country: "Country is required",
        town: "Town is required"
      },
    };
  }
  componentWillMount() {
    //     swal({title:"",
    //     text:"Your registration for Geoff Banks Online has been successfull." ,
    //  timer:"3000"});
  }
  componentDidMount() { }

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    if (!this.state.isAddressFieldVisible && !this.state.showAddressdrp) {
      // Utility.ShowNotification("Please fill the address field!");
      this.setState({
        isAddressFieldVisible: !this.state
          .isAddressFieldVisible,
      })
      valid = false;
    }
    return valid;
  };


  handleChange = (event) => {
    var d = new Date();
    var year = d.getFullYear();
    // event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "firstName":
        this.state.errors.firstName =
          value.length == 0 ? "First Name is required" : "";
        break;
      case "lastName":
        this.state.errors.lastName =
          value.length == 0 ? "Last Name is required" : "";
        break;
      case "email":
        this.state.errors.email =
          value.length == 0
            ? "Email is required"
            : validEmailRegex.test(value)
              ? ""
              : "Email is not valid!";
        break;
      case "username":
        this.state.errors.username =
          value.length == 0 ? "Username is required" 
          : !passRegEx.test(value)
            ? "No special character allowed "
            :"";
        break;
      case "date":
        this.state.errors.date =
          value.length == 0
            ? "Date is required"
            : Number(value) <= 31
              ? ""
              : "Enter valid date";
        break;
      case "month":
        this.state.errors.month =
          value.length == 0
            ? "Month is required"
            : Number(value) <= 12
              ? ""
              : "Enter valid month";
        break;
      case "year":
        this.state.errors.year =
          value.length == 0
            ? "Year is required"
            : Number(value) > 1930 && Number(value) < year
              ? ""
              : "Enter valid Year between 1930 and " + year;
        break;
      case "password":
        this.state.errors.password =
          value.length == 0
            ? "Password  is required"
            : !passRegEx.test(value)
                ? "No special character allowed "
                : (value.length < 8 || value.length > 12)
                  ? "Password must be min 8 character and max 12 characters" 
                  // : (value.length > 12)
                  // ? "Password must be 12 characters long!"        
                  : "";
        break;
      case "verificationFileName":
        this.state.errors.verificationFileName =
          value.length == 0 ? "Personal Verification file is required" : "";
        break;
      // case "personalDetailFileName":
      //   this.state.errors.personalDetailFileName =
      //     value.length == 0 ? "Address Verification file is required" : "";
      //   break;
      // case "bankDetailFileName":
      //   this.state.errors.bankDetailFileName =
      //     value.length == 0 ? "Bank Statement file is required" : "";
      //   break;
      case "mobileNumber":
        this.state.errors.mobileNumber =
          value.length == 0
            ? "Mobile Number is required"
            : value.length < 10
              ? "Enter valid Mobile Number"
              : !mobRegEx.test(this.state.mobileNumber)
                ? "Please include country code in mobile number, ex: +44"
                : "";
        break;
      case "depositLimit":
        this.state.errors.depositLimit =
          value.length == 0 ? "Deposit Limit is required" 
          : value > Utility.getMaxDepositLimit()
          ? "Deposit limit exceeded. Deposit limit should be below £"+Utility.getMaxDepositLimit()
          :"";
        break;
      case "firstLineAddress":
        this.state.errors.firstLineAddress =
          value.length == 0 ? "Address is required" : "";
        break;
      case "country":
        this.state.errors.country =
          value.length == 0 ? "Country is required" : "";
        break;
      case "town":
        this.state.errors.town =
          value.length == 0 ? "Town is required" : "";
        break;
      case "securityAnswer":
        this.state.errors.securityAnswer =
          this.state.securityQuestion.trim() == ""
            ? ""
            : value.length == 0
              ? "Please provide security answer for selected question "
              : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value }, () => {
      console.log(errors);
    });
  };

  onChangeValueHandler = (val) => {
   this.setState({zipCode :val})
   this.findMyAddress()
  }

  onAddressChange = (value) => {
    // if (value.value == -1) {

    // }
    // else {
    if (value.value != -1) {
      var address = _.split(value.value, ",");
      var thirdLineAddress =
        typeof address[2] === "undefined" ? "" : address[2] + ",";
      thirdLineAddress =
        thirdLineAddress + typeof address[3] === "undefined"
          ? ""
          : address[3] + ",";
      thirdLineAddress =
        thirdLineAddress + typeof address[4] === "undefined"
          ? ""
          : address[4] + ",";

      this.handleChange({ target: { value: address[0]||"", name: "firstLineAddress" } })
      this.handleChange({ target: { value: address[5]||"", name: "town" } })
      this.handleChange({ target: { value: address[6]||"", name: "country" } })

        this.setState({
          // firstLineAddress: typeof address[0] === "undefined" ? "" : address[0],
          secondLineAddress: address[1]||"",
          thirdLineAddress: thirdLineAddress,
          // town: address[5],
          // country: address[6],
          isAddressFieldVisible: true,
          postCode: this.state.zipCode,
        });
    }
  };

  findMyAddress = (event) => {
    // var code = event.keyCode || event.which;
    var zipCode = this.state.zipCode;
    if (zipCode == "") {
      this.setState({ isZipCodeRequired: true });
      return false;
    }
    this.setState({
      showAddressdrp: false,
      isAddressFieldVisible: false,
      isZipCodeRequired: false,
    });
    // if (true) { //13 is the enter keycode
    //Do stuff in here
    Api.getaddressByZipcode(this.state.zipCode).then((result) => {
      if (result != null) {
        if (result.addresses != undefined) {
          var drpLoc = [];
          drpLoc.push({ value: "-1", label: "Select Address" });
          for (var i = 0; i < result.addresses.length; i++) {
            drpLoc.push({
              value: result.addresses[i],
              label: result.addresses[i],
            });
          }
          this.setState({
            addressList: drpLoc,
            showAddressdrp: true,
            isAddressFieldVisible: false,
            isInvalidCode: false,
          });
        } else {
          this.setState({
            showAddressdrp: false,
            isAddressFieldVisible: true,
            isInvalidCode: true,
          });
        }
      }
    });
    // }
  };

  onVerificationFileChange = (event) => {
    var verFile = event.target.files[0];
    
    if (verFile == undefined) {
      return;
    }

    //ADD DATETIME IN FILE NAME
    let verFilename = verFile.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}$1`);

    let name = event.target.name;
    let value = event.target.files[0].name;

    let errors = this.state.errors;

    switch (name) {
      case "verificationFileName":
        this.state.errors.verificationFileName =
          value.length == 0 ? "Personal Verification file is required" : "";
        break;
      default:
        break;
    }
    var self = this;
    this.setState({ errors, [name]: value }, () => {
      console.log(errors);
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(verFile);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: verFile.name,
          type: verFile.type,
          size: Math.round(verFile.size / 1000) + " kB",
          base64: reader.result,
          file: verFile,
        };

        self.setState({ 
          verificationFileBase64: fileInfo.base64 ,
          verificationFileName:verFilename
        });
      };
    });
  };
  onPersonalDetailFileChange = (event) => {
    var perFile = event.target.files[0];
    if (perFile == undefined) {
      return;
    }
    //ADD DATETIME IN FILE NAME
    let perFileName = perFile.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}$1`);
    
    let name = event.target.name;
    let value = event.target.files[0].name;
    let errors = this.state.errors;

    // switch (name) {
    //   case "personalDetailFileName":
    //     this.state.errors.personalDetailFileName =
    //       value.length == 0 ? "Address Verification file is required" : "";
    //     break;
    //   default:
    //     break;
    // }
    var self = this;
    this.setState({ errors, [name]: value }, () => {
      console.log(errors);
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(perFile);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: perFile.name,
          type: perFile.type,
          size: Math.round(perFile.size / 1000) + " kB",
          base64: reader.result,
          file: perFile,
        };
        self.setState({ personalDetailBase64: fileInfo.base64 ,
          personalDetailFileName:perFileName});
      };
    });
    // this.setState({ personalDetailFileName: perFile.name })
  };

  onBankDetailFileChange = (event) => {
    var bankFile = event.target.files[0];

    if (bankFile == undefined) {
      return;
    }
    //ADD DATETIME IN FILE NAME
    let bankFileName = bankFile.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}$1`);

    let name = event.target.name;
    let value = event.target.files[0].name;
    
    // switch (name) {
    //   case "bankDetailFileName":
    //     this.state.errors.bankDetailFileName =
    //       value.length == 0 ? "Bank Statment file is required" : "";
    //     break;
    //   default:
    //     break;
    // }

    var self = this;
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(bankFile);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        name: bankFile.name,
        type: bankFile.type,
        size: Math.round(bankFile.size / 1000) + " kB",
        base64: reader.result,
        file: bankFile,
      };
      self.setState({ bankDetailBase64: fileInfo.base64, bankDetailFileName: bankFileName });
    };
  };
  onTermConditionChange = (event) => {
    let name = "isTermCondCheck";
    //  let value=event.target.files[0].name;
    let errors = this.state.errors;
    switch (name) {
      case "isTermCondCheck":
        this.state.errors.isTermCondCheck =
          this.state.isTermCondCheck == true
            ? "Please Check term and condition"
            : "";
        break;
      default:
        break;
    }
    this.setState(
      { errors, isTermCondCheck: !this.state.isTermCondCheck },
      () => {
        console.log(errors);
      }
    );

    // this.setState({ isTermCondCheck: !this.state.isTermCondCheck })
  };
  onRegistrationClick = (e) => {
    // console.log(this.state, "register state")
    this.setState({ isSubmit: true });
    if (this.validateForm(this.state.errors)) {
      //FIXES BEFORE WE SUBMIT
      // if (!this.state.isAddressFieldVisible && !this.state.showAddressdrp) {
      //   Utility.ShowNotification("Please fill the address field!");
      //     this.setState({
      //       isAddressFieldVisible: !this.state
      //         .isAddressFieldVisible,
      //     })
      //   return ;
      // }
      if (this.state.verificationFileName.trim() == this.state.personalDetailFileName.trim() ) {
        Utility.CustomTimeNoty("The same verification document cannot be uploaded more than once.", 3000);
        return;
      }
      if (this.state.date && this.state.month && this.state.year && this.state.errors.year.length == 0 && Utility._calculateAge(this.state.year + "-" + this.state.month + "-" + this.state.date) < 18 &&
        this.state.isSubmit) {
        return;
      }
      let mobileNo = this.state.mobileNumber;
      if (mobileNo.length == 12) mobileNo = "+" + mobileNo;
      let FormModel = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        firstLineAddress: this.state.firstLineAddress,
        secondLineAddress: this.state.secondLineAddress,
        thirdLineAddress: this.state.thirdLineAddress,
        town: this.state.town,
        country: this.state.country,
        postCode: this.state.zipCode,
        password: this.state.password,
        email: this.state.email,
        username: this.state.username,
        // verificationFileName:  `${this.state.verificationFileName}-${Date.now()}`,
        // personalDetailFileName: `${this.state.personalDetailFileName}-${Date.now()}`,
        // bankDetailFileName: `${this.state.bankDetailFileName}-${Date.now()}`,
        verificationFileName:  this.state.verificationFileName,
        personalDetailFileName: this.state.personalDetailFileName,
        bankDetailFileName: this.state.bankDetailFileName,
        promocode: this.state.promocode,
        isOcassional: this.state.isOcassional ? "True" : "False",
        date: this.state.date + "/" + this.state.month + "/" + this.state.year,
        mobileNumber: mobileNo,
        securityQuestion: this.state.securityQuestion,
        securityAnswer: this.state.securityAnswer,
        personalDetailBase64: this.state.personalDetailBase64,
        verificationFileBase64: this.state.verificationFileBase64,
        bankDetailBase64: this.state.bankDetailBase64,
        depositLimit: this.state.depositLimit
      };
      console.log(FormModel, "FORM MODEL")

      // if (
      //   this.state.countryCode.length > 10 ||
      //   this.state.countryCode.length < 10
      // ) {
      //   this.setState({ checkNum: true });
      //   return;
      // } else {
      //   this.setState({ checkNum: false });
      // }

      Utility.ShowLoading("Loader");


      var that = this;
      Api.PostRegDataWithBody(
        Api.Reg_API_BASE_URL_V1 + "SendRegistration",
        FormModel
      ).then((result) => {
        console.log(result, "ABC");
        Utility.HideLoading("Loader");
        if (result.status == 200) {
          Utility.ShowNotification(
            "Your registration for Geoff Banks Online has been successfull."
          );
          if(window.dataLayer && typeof window.dataLayer==='object'){
            window.dataLayer.push({'event':'Signup'})
          }
          //if affiliateToken present in Localstoarge then save affiliateToken and userid
          if(Utility.getLocalStorage('affiliateToken')!=null && Utility.getLocalStorage('affiliateToken')!=""){
            var affiliateUserData= {
              userId: result.data.username,
              trackingCode : Utility.getLocalStorage('affiliateToken'),
            }
            Api.LogSignup(affiliateUserData, (res) => {
              if(res.status == 200){
                  console.log("Successfull",res)
              } else{
                  console.log("Error in LogSignup")
              } 
            });
          }

          Api.PostRegDataWithBody(
            Api.Reg_API_BASE_URL_V1 + "SendRegistrationEmail",
            FormModel
          ).then((res) => {
            console.log(res);

            let form = new FormData();
            form.append(
              "mobileNumber",
              this.state.mobileNumber.includes("+")
                ? this.state.mobileNumber.split("+")[1]
                : this.state.mobileNumber
            );
            Api.SendSMS(form);

            Utility.HideLoading("Loader");
            setTimeout(function () {
              that.props.history.push("/show-page/RegistrationReceived");
            }, 2500);
          });

          

          // //if btag present in cookie then save username,email and btag in Database 
          // if(Utility.GetCookie('btag')!=null && Utility.GetCookie('btag')!=""){
          //   var affiliateUserData= {
          //     username: result.data.username,
          //     email: result.data.email,
          //     btag: Utility.GetCookie('btag'),
          //   }
          //   Api.SaveAffiliateUser(affiliateUserData, (res) => {
          //     console.log(res);      
          //   });
          // }

        } else {
          Utility.HideLoading("Loader");

          Utility.ShowNotification("Something went wrong");
        }
      });
    } else {
      console.error("Invalid Form");
      Utility.HideLoading("Loader");
      Utility.ShowNotification("Please check you supplied all requested details");
    }
  };

  onSecurityQuestChange = (e) => {
    var errors = this.state.errors;
    if (e.target.value == "") {
      this.state.errors.securityAnswer = "";
      this.setState({
        securityQuestion: e.target.value,
        securityAnswer: "",
        errors,
      });
    } else {
      this.state.errors.securityAnswer =
        "Please provide security answer for selected question";
      this.setState({ securityQuestion: e.target.value, errors });
    }
  };
  handleOcassionalCheck = (e) => {
    this.setState({ isOcassional: !this.state.isOcassional });
  };

  handleMobileNumber = (mobileNumber, country) => {
    let checkCode = mobileNumber.split(country.dialCode)[1];
    // console.log(checkCode, "CHECk");
    this.setState({ mobileNumber, countryCode: checkCode });

    if (
      typeof checkCode != "undefined" &&
      (checkCode.length > 10 || checkCode.length < 10)
    ) {
      this.setState({ checkNum: true });
    } else {
      this.setState({ checkNum: false });
    }
  };

  RenderAgeErr() {
    return this.state.date && this.state.month && this.state.year && this.state.errors.year.length == 0 && Utility._calculateAge(this.state.year + "-" + this.state.month + "-" + this.state.date) < 18 &&
      this.state.isSubmit && (
        <span className="error">
          Age must be 18 or above.
      </span>
      )
  }
  render() {
    // const { errors } = this.state;
    // if (this.state.isLoader) {
    //     return <Loader></Loader>;
    // }
    return (
      // <Shared.Loader Show={this.state.isLoader} />
      <div>
        <div className="sign-up-v1">
          <div className="row1">
            <div className="headerLogo1">
              <div className="gb-logo1">
                <a href="/">
                  <img src="/images/logo.svg" alt="Geoff Banks"></img>
                </a>
              </div>
            </div>

            <div className="go-back">
              <a href="/">
                <img
                  src="/images/left-arrow.png"
                  alt="go back"
                  className="back"
                ></img>
              </a>
            </div>
          </div>

          <div className="sign-up-v1 user-detail changePassword">
            <div className="container mb20">
              <div className="col-sm-12 col-xs-12 form-head">
                <div className="arrow-steps clearfix">
                  <div className="step current">
                    <span> Step 1: Sign Up</span>{" "}
                  </div>
                  {/* <div className="step"> <span> Step 2 : Add Funds and Play</span> </div> */}
                </div>
              </div>
              {/* {this.state.ShowLoader == false ? ( */}

              <div className="col-sm-12 col-xs-12 newSignupform">
                <div className="row" id="Loader">
                  <div className="  col-xs-12 form-head-mobile">
                    <h6>Step 1: Sign Up</h6>
                  </div>
                  <div className="col-lg-6  col-md-6 col-xs-12 form-box">
                    <h3 className="pseudo_border">
                      <span>Your Details</span>
                    </h3>
                    <div className="form-group">
                      <div className="form-group input-block">
                        <label for="inputFirstName">First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          value={this.state.firstName}
                          onChange={(e) => this.handleChange(e)}
                          id="inputFirstName"
                          placeholder=" First Name"
                        />
                        {this.state.errors.firstName.length > 0 &&
                          this.state.isSubmit && (
                            <span className="help-block error">
                              {this.state.errors.firstName}
                            </span>
                          )}
                      </div>
                      <div className="form-group input-block">
                        <label for="inputLastName">Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          value={this.state.lastName}
                          onChange={(e) => this.handleChange(e)}
                          id="inputLastName"
                          placeholder=" Last Name"
                        />
                        {this.state.errors.lastName.length > 0 &&
                          this.state.isSubmit && (
                            <span className="error">
                              {this.state.errors.lastName}
                            </span>
                          )}
                      </div>
                    </div>

                    <div className="dateSingleRow form-row">
                      <div className="form-group input-block">
                        <label for="inputDate">Date</label>
                        <input
                          type="text"
                          name="date"
                          maxLength="2"
                          value={this.state.date}
                          onChange={(e) => this.handleChange(e)}
                          className="form-control date1col"
                          id="inputDate"
                          placeholder="DD"
                        />
                        {this.state.errors.date.length > 0 &&
                          this.state.isSubmit && (
                            <span className="error">
                              {this.state.errors.date}
                            </span>
                          )}
                      </div>
                      <div className="form-group input-block">
                        <label for="inputMonth">Month</label>
                        <input
                          type="number"
                          maxLength="2"
                          name="month"
                          value={this.state.month}
                          onChange={(e) => this.handleChange(e)}
                          className="form-control inputMonth date2col"
                          id="inputMonth"
                          placeholder="MM"
                        />
                        {this.state.errors.month.length > 0 &&
                          this.state.isSubmit && (
                            <span className="error">
                              {this.state.errors.month}
                            </span>
                          )}
                      </div>
                      <div className="form-group input-block last">
                        <label for="inputYear">Year</label>
                        <div className="spl-num"> 18+ </div>
                        <input
                          type="number"
                          maxLength="4"
                          name="year"
                          value={this.state.year}
                          onChange={(e) => this.handleChange(e)}
                          className="form-control date3col"
                          id="inputYear"
                          placeholder="YYYY"
                        />
                        {this.state.errors.year.length > 0 &&
                          this.state.isSubmit && (
                            <span className="error">
                              {this.state.errors.year}
                            </span>
                          )}
                      </div>
                      <div className="text-center">
                        {this.RenderAgeErr()}
                      </div>
                      <div className="col-md-12">
                        <small id="emailHelp" className="form-text text-muted">
                          You must be over 18 to bet with us. If we can't verify
                          your age electronically, we will require documments to
                          verify your age.
                        </small>
                      </div>
                    </div>

                      <div className="form-group address-block">
                        <label for="inputZipCode">Postal code Or Address</label>
                        <AddressAutoComplete 
                          placeholder="Type your Post Code"
                          onChangeValue={this.onChangeValueHandler} 
                        />
                         <a
                          href=""
                          title="Search your address by entering Post Code"
                          className="question"
                        >
                          ?
                        </a>
                        {this.state.isZipCodeRequired && (
                          <span className="error text-danger">
                            ZipCode is required{" "}
                          </span>
                        )}
                        {this.state.isInvalidCode && (
                          <span className="error text-danger">
                            Please recheck your postcode, it seems to be
                            incorrect{" "}
                          </span>
                        )}
                      </div>


                    <div className="form-group">
                      {/* <div className="form-inline input-block">
                        <label for="inputZipCode">Postal code Or Address</label>
                        <input
                          type="text"
                          className="form-control  spl-width-icon"
                          id="inputZip"
                          onChange={(e) =>
                            this.setState({
                              zipCode: e.target.value,
                              address: e.target.value,
                            })
                          }
                          value={this.state.zipCode}
                          placeholder="Type your Post Code"
                        />        
                        <button
                          className="btn btn-primary findBtn"
                          onClick={this.findMyAddress.bind(this)}
                        >
                          {" "}
                          Find{" "}
                        </button>
                        <a
                          href=""
                          title="Search your address by entering Post Code"
                          className="question"
                        >
                          ?
                        </a>
                        {this.state.isZipCodeRequired && (
                          <span className="error text-danger">
                            ZipCode is required{" "}
                          </span>
                        )}
                        {this.state.isInvalidCode && (
                          <span className="error text-danger">
                            Please recheck your postcode, it seems to be
                            incorrect{" "}
                          </span>
                        )}
                      </div> */}



                      {/* <div className="form-group">
                                <label for="inputZip"> Address</label>
                                <input type="text" className="form-control" id="inputZip" placeholder=" Zip Code" />
                            </div> */}
                      <div className="col-md-12 text-right nopadding">
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              isAddressFieldVisible: !this.state
                                .isAddressFieldVisible,
                            })
                          }
                        >
                          Enter Address Manually
                        </a>
                      </div>
                    </div>

                    {this.state.showAddressdrp ? (
                      <div className="form-group">
                        <Select
                          options={this.state.addressList}
                          defaultValue={this.state.addressList[0]}
                          // value ={this.state.selectedCurrency}
                          onChange={(value) => this.onAddressChange(value)}
                          required={!this.state.isAddressFieldVisible}
                          autoFocus={true}
                          openMenuOnFocus={true}
                        />
                      </div>
                    ) : ""}
                    {this.state.isAddressFieldVisible ? (
                      <div>
                        <div className="form-group input-block">
                          <label>First Address Line</label>
                          <input
                            id="firstLineAddress"
                            name="firstLineAddress"
                            className="form-control"
                            onChange={(e) => this.handleChange(e)
                            }
                          value={this.state.firstLineAddress}
                          type="text"
                            required={this.state.isAddressFieldVisible}
                          />
                          {this.state.firstLineAddress.length == 0 &&
                            this.state.isSubmit && (
                              <span className="error">
                                {this.state.errors.firstLineAddress}
                              </span>
                            )}
                        </div>
                        <div className="form-group input-block">
                          <label>Second Address Line</label>
                          <input
                            id="secondLineAddress"
                            name="secondLineAddress"
                            className="form-control"
                            onChange={
                              (e) => this.handleChange(e)
                            }
                            value={this.state.secondLineAddress}
                            type="text"
                          />
                        </div>
                        <div className="form-group input-block">
                          <label>Third Address Line</label>
                          <input
                            id="thirdLineAddress"
                            name="thirdLineAddress"
                            className="form-control"
                            onChange={
                              (e) => this.handleChange(e)
                            }
                            value={this.state.thirdLineAddress}
                            type="text"
                          />
                        </div>
                        <div className="form-group input-block">
                          <label>Town</label>
                          <input
                            id="town"
                            name="town"
                            value={this.state.town}
                            onChange={(e) => this.handleChange(e)
                            }
                            className="form-control"
                            type="text"
                            required={this.state.isAddressFieldVisible}
                          />
                          {this.state.town.length == 0 &&
                            this.state.isSubmit && (
                              <span className="error">
                                {this.state.errors.town}
                              </span>
                            )}
                        </div>
                        <div className="form-group input-block">
                          <label>Country</label>
                          <input
                            id="country"
                            name="country"
                            value={this.state.country}
                            onChange={(e) => this.handleChange(e)
                            }
                            className="form-control"
                            type="text"
                            required={this.state.isAddressFieldVisible}
                          />
                          {this.state.country.length == 0 &&
                            this.state.isSubmit && (
                              <span className="error">
                                {this.state.errors.country}
                              </span>
                            )}
                        </div>
                        <div className="form-group input-block">
                          <label>Postcode</label>
                          <input
                            value={this.state.postCode}
                            onChange={(e) =>
                              this.setState({ postCode: e.target.value })
                            }
                            className="form-control"
                            type="text"
                            required={this.state.isAddressFieldVisible}
                          />
                        </div>
                      </div>
                    ) : ""}
                    <div className=" input-block">
                      <label for="inputMobile">Mobile Number including country code (Ex: +44 888 888 8888)</label>
                      {/* <PhoneInput
                        id="inputMobile"
                        name="mobileNumber"
                        className="form-control"
                        country={"gb"}
                        value={this.state.mobileNumber}
                        onChange={(mobileNumber, country) => {
                          this.handleMobileNumber(mobileNumber, country);
                        }}
                        placeholder="Mobile Number"
                        autoFormat='true'
                        inputProps={{
                          id:"inputMobile",
                          name: 'mobileNumber',
                          required: true,
                          autoFocus: true
                        }}
                      /> */}
                      <input
                        type="number"
                        name="mobileNumber"
                        onChange={(e) => this.handleChange(e)}
                        className="form-control"
                        id="inputMobile"
                        placeholder="Mobile Number"
                        required
                      />
                      {this.state.errors.mobileNumber.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.mobileNumber}
                          </span>
                        )}
                      {/* {this.state.checkNum && this.state.isSubmit ? (
                        <span className="error">Mobile Number is invalid!</span>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div className=" input-block">
                      <label for="inputMobile">Daily Deposit Limit</label>
                      <input
                        type="number"
                        name="depositLimit"
                        onChange={(e) => this.handleChange(e)}
                        className="form-control"
                        id="inputMobile"
                        placeholder="Daily Deposit Limit"
                        min="10" 
                        //max="10000"
                      />
                       {this.state.errors.depositLimit.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.depositLimit}
                          </span>
                        )}
                      <span></span>
                      <div className="deposit-msg">
                        
                        <small id="emailHelp" className="form-text text-muted">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        Deposit limits over £2000 daily will need to be accompanied with a recent bank statement
                        </small>
                      </div>
                    </div>
                  </div>


                  <div className="col-xs-12 form-head-mobile">
                    <h6>Step 2 : Add Funds and Play</h6>
                  </div>
                  <div className="col-lg-6 col-md-6 col-xs-12 form-box">
                    <h3 className="pseudo_border">
                      <span>Your Geoff Banks Online account</span>
                    </h3>
                    <div className="input-block">
                      <label for="exampleInputEmail1">
                        Photo ID*
                      </label>
                      <input
                        type="file"
                        name="verificationFileName"
                        className="form-control"
                        onChange={(e) => this.onVerificationFileChange(e)}
                      />
                      {this.state.errors.verificationFileName.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.verificationFileName}
                          </span>
                        )}
                    </div>
                    <div className=" input-block">
                      <label htmlFor="exampleInputEmail1">
                        Address Verification (Optional)
                      </label>
                      <input
                        type="file"
                        name="personalDetailFileName"
                        className="form-control"
                        placeholder="Enter Verification"
                        onChange={(e) => this.onPersonalDetailFileChange(e)}
                      />
                      {this.state.errors.personalDetailFileName.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.personalDetailFileName}
                          </span>
                        )}
                    </div>
                    {/* <div className="input-block">
                      <label for="exampleInputEmail1">
                        Bank Statement
                      </label>
                      <input
                        type="file"
                        name="bankDetailFileName"
                        className="form-control"
                        onChange={(e) => this.onBankDetailFileChange(e)}
                      />
                       {this.state.errors.bankDetailFileName.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.bankDetailFileName}
                          </span>
                        )}
                    </div> */}
                    <div className=" input-block">
                      <label for="exampleInputEmail1">Preferred Username (No special character allowed)</label>
                      <input
                        type="text"
                        value={this.state.username}
                        name="username"
                        minLength={"4"}
                        maxLength={"8"}
                        onChange={(e) => this.handleChange(e)}
                        className="form-control"
                        placeholder="Enter Username"
                      />
                      {this.state.errors.username.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.username}
                          </span>
                        )}
                    </div>
                    <div className=" input-block">
                      <label for="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        value={this.state.email}
                        name="email"
                        onChange={(e) => this.handleChange(e)}
                        className="form-control"
                        placeholder="Enter Personal detail"
                      />
                      {this.state.errors.email.length > 0 &&
                        this.state.isSubmit && (
                          <span className="error">
                            {this.state.errors.email}
                          </span>
                        )}
                    </div>
                    <div className="input-block">
                      <label for="inputPassword2">Preferred password- min 12 characters (alpha and numeric only)</label>
                      <div className="form-inline">
                        <input
                          type={!this.state.showPassword ? "password" : ""}
                          value={this.state.password}
                          name="password"
                          onChange={(e) => this.handleChange(e)}
                          className="form-control spl-width"
                          id="inputPassword2"
                          placeholder="Password"
                        />
                        <button
                          type="submit"
                          className="btn  btn-secondary"
                          onClick={(e) =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                        >
                          Show
                        </button>
                        {this.state.errors.password.length > 0 &&
                          this.state.isSubmit && (
                            <span className="error">
                              {this.state.errors.password}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className=" input-block">
                      <label for="securityQ">Security Question</label>
                      <select
                        id="securityQ"
                        onChange={(e) => this.onSecurityQuestChange(e)}
                        className="form-control"
                      >
                        {this.state.QuestionList.map((value) => (
                          <option value={value.value}>{value.label}</option>
                        ))}
                        ;
                      </select>
                      {/* <Select  className="form-control" options={this.state.QuestionList}
                                    defaultValue={this.state.QuestionList[0]}
                                    onChange={(value) => this.onSecurityQuestChange(value)} /> */}
                    </div>
                    <div className=" input-block">
                      <label for="securityAnswer">Security Answer</label>
                      <input
                        type="text"
                        disabled={!this.state.securityQuestion.trim()}
                        name="securityAnswer"
                        onChange={(e) => this.handleChange(e)}
                        className="form-control"
                        id="securityAnswer"
                        placeholder="Security Answer"
                      />
                      {this.state.errors.securityAnswer.length > 0 &&
                        this.state.isSubmit &&
                        this.state.securityQuestion.trim().length > 0 && (
                          <span className="error">
                            {this.state.errors.securityAnswer}
                          </span>
                        )}
                    </div>
                    <div className=" input-block">
                      <label for="promocode"> Promo Code </label>
                      <input
                        type="text"
                        name="promoCode"
                        className="form-control"
                        value={this.state.promocode}
                        onChange={(e) =>
                          this.setState({ promocode: e.target.value })
                        }
                        id="promocode"
                        placeholder="Promocode"
                      />
                      <div className="apply">
                        <span className=" checkmark-promo"></span>
                      </div>
                    </div>

                    <div className=" form-end">
                      <h6>Please tick below it:</h6>
                      <div className="form-check input-block">
                        <label
                          className="form-check-label checkbox-container"
                          for="gridCheck"
                        >
                          {/* <small>
                            I am happy to receive occasional{" "}
                            <a href="/show-page/privacy">
                              marketing communications and exciting offers
                            </a>{" "}
                            from Geoff Banks Online
                          </small> */}
                           <small>
                            I am happy to receive{" "}
                            <a href="/show-page/privacy">
                            targeted marketing 
                            </a>{" "}
                            only from Geoff Banks Online and Geoff Banks Casino sites. We do not share data with 3rd parties.
                          </small>
                          <input
                            type="checkbox"
                            checked={this.state.isOcassional ? "checked" : ""}
                          />
                          <span
                            className="checkmark"
                            onClick={(e) => this.handleOcassionalCheck(e)}
                          ></span>
                        </label>
                      </div>
                      <div className="form-check input-block">
                        <label
                          className="form-check-label checkbox-container"
                          htmlFor="gridCheck"
                        >
                          <small>
                            I agree to the{" "}
                            <a href="/show-page/termsandconditions">
                              terms and conditions
                            </a>
                          </small>

                          <input
                            type="checkbox"
                            checked={
                              this.state.isTermCondCheck ? "checked" : ""
                            }
                          />
                          <span
                            className="checkmark"
                            name="isTermCondCheck"
                            onClick={(e) => this.onTermConditionChange(e)}
                          ></span>
                        </label>
                        {this.state.errors.isTermCondCheck.length > 0 &&
                          this.state.isSubmit && (
                            <span className="error">
                              {this.state.errors.isTermCondCheck}
                            </span>
                          )}
                      </div>
                      <br />
                      <button
                        type="submit"
                        onClick={(e) => this.onRegistrationClick(e)}
                        className="fadeIn fourth submit btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formVersion">
                <Version />
              </div>
              {/* ):null} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SignupV2;
