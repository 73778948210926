import React from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
//import SwiperContainer from '../Component/SwiperContainer';
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getMeetingDetail } from "../js/api-service";
import * as BetSlip_v1_Provider from "../js/bet-slip";
import subscribe from "unstated-subscribe-hoc";
import { BetSlipContainer } from "../Containers/BetSlipContainer";
import * as Utility from "../js/utility";

import {
  getOldFormattedBetPayload,
  convertOldFormattedBet,
  betTransferStore
} from '../Component/BetSlip/public-api';

const addBetExternal = (bet) => {
    betTransferStore.addBet(
        convertOldFormattedBet(
            getOldFormattedBetPayload(bet)
        )
    );
};

class SportEvents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      meetingDetail: [],
      events: [],
      results: [],
      isResult: "",
    };
  }

  componentDidMount() {
    const _this = this;
    const params = new URLSearchParams(_this.props.location.search);
    const eventfile = params.get("eventfile");
    const EventGroup = params.get("EventGroup");
    const Events = params.get("Events");
    getMeetingDetail(eventfile, EventGroup, Events, function (mettingRespose) {
      _this.setState({ meetingDetail: mettingRespose });

      if (mettingRespose.Status === "Result") {
        _this.setState({ isResult: true });
      } else {
        _this.setState({ isResult: false });
        _this.setState({ events: mettingRespose.Events });
      }
    });
  }

  render() {
    var content = (
      <div>
        <div className="content-section">
          <LeftContainer />
          <div className="content-section-center">
            <div className="panel-body section-outer heading-accordion">
              <table className="table table-striped">
                <thead>
                  <tr className="goodwood">
                    <th>
                      {" "}
                      <b>
                        {this.state.meetingDetail.MeetingName} ,{" "}
                        {this.state.meetingDetail.EventName}{" "}
                      </b>{" "}
                    </th>
                    <th></th>
                    <th>
                      <label className="early-price-heading">
                        {" "}
                        {this.state.meetingDetail.PType}
                        {/* <i className="fa fa-caret-up" aria-hidden="true"></i> */}
                      </label>
                    </th>
                    <th>
                      <label className="early-price-heading">
                        {" "}
                        Time Left {this.state.meetingDetail.TimeText}{" "}
                        {this.state.isResult}
                      </label>{" "}
                    </th>
                  </tr>
                </thead>

                {this.state.isResult == false ? (
                  <tbody>
                    {this.state.events.map(function (event, i) {
                      return (
                        <tr key={i}>
                          <td>{event.SelName} </td>
                          <td>{event.Jockey} </td>
                          {event.price != "" &&
                          event.PriceLink1 !== "NR" &&
                          event.gprice ? (
                            <td className="center">
                              <label
                                className="early-price"
                                onClick={(e) =>
                                  this.props.Store.AddBet(
                                    e,
                                    event,
                                    event,
                                    event.selection
                                  )
                                }
                              >
                                {Utility.ConvertPrice(event.price)}
                              </label>
                            </td>
                          ) : (
                            <td className="center">
                              <label className="early-price">
                                {event.PriceLink1}
                              </label>
                            </td>
                          )}
                          {event.PriceLink1 === "NR" ? (
                            <td className="center"></td>
                          ) : (
                            <td className="center">
                              <label
                                className="early-price"
                                onClick={(e) =>
                                  this.props.Store.AddSpBet(event)
                                }
                              >
                                {" "}
                                SP
                              </label>
                            </td>
                          )}
                        </tr>
                      );
                    }, this)}
                  </tbody>
                ) : (
                  <tbody>
                    {this.state.meetingDetail.Results.map(function (result, j) {
                      return (
                        <tr key={j}>
                          <td>{result.ResultPlace}</td>
                          <td>{result.ResultText}</td>
                          <td className="center">
                            <label> {result.Odds}</label>
                          </td>
                          <td></td>
                        </tr>
                      );
                    }, this)}
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {this.state.meetingDetail.Results1.map(function (
                      result1,
                      j
                    ) {
                      return (
                        <tr key={j}>
                          <td>{result1.Name}</td>
                          <td>
                            <label> {result1.Odds}</label>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    },
                    this)}
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Fav</td>
                      <td>{this.state.meetingDetail.Fav}</td>
                      <td className="center">
                        <label> {this.state.meetingDetail.FavPrice}</label>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                )}
              </table>
              <div className="meetingPl">{this.state.meetingDetail.Pl}</div>
            </div>
          </div>
          <RightContainer />
          <Footer />
        </div>
      </div>
    );
    return <div>{content}</div>;
  }
}

export default subscribe(SportEvents, { Store: BetSlipContainer });
