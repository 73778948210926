import React from 'react'
import { compose } from 'redux';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button } from "react-bootstrap";
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as Api from '../../js/api-service';
import * as utility from '../../js/utility';

class MyBets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    componentDidMount() {
    }


    render() {
        var content = (
        <div>
        <div className="my-account">

        <div className="container">

                <AccountSidebar />
                <div className="account-right-container">
                <div className="my-bet-section">
                    <h3>My Bet</h3>

                    <div className="bet-form">
                        <select className="all-bets">
                            <option value="volvo">All Bets</option>
                            <option value="saab">All Bets</option>
                            <option value="mercedes">All Bets</option>
                            <option value="audi">All Bets</option>
                        </select>

                        <select className="last-days">
                            <option value="volvo">Last 30 days</option>
                            <option value="volvo">Last 30 days</option>
                            <option value="volvo">Last 30 days</option>
                            <option value="volvo">Last 30 days</option>
                        </select>

                        <select className="year">
                            <option value="year">2019</option>
                            <option value="year">2019</option>
                            <option value="year">2019</option>
                            <option value="year">2019</option>
                        </select>

                        <a href="#">GO !</a>

                        <input type="text" placeholder="Bet Slip Id"/>

                        <span className="button">
                            <a href="#">Search</a>
                            <a className="print" href="#">Print</a>
                        </span>

                    </div>

                    <div className="bet-error">
                        <i className="fa fa-info-circle"></i> No bets were found for the selected period.
                    </div>

                </div>



            </div>

          </div>
        </div>

        <Footer />
      </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    if (state.form.wizard !== undefined) {
        // console.log(state.form.wizard.values);
        return state.form.wizard.values;
    }
    return {};
};

export default compose(connect(mapStateToProps), reduxForm({ form: 'wizard', destroyOnUnmount: false, forceUnregisterOnUnmount: true }))(MyBets);