// import React from "react";
// import "../css/main.css";
// import BetPlacedModal from "../Component/BetPlacedModal";
// import MessageModal from "../Component/MessageModal";
// //import InsufficientModal from "../Component/InsufficientModal";
// //import StakeExceededModal from "../Component/StakeExceededModal";
// import PriceChangeModal from "../Component/PriceChangeModal";
// import PriceChangedModal from "../Component/Modals/PriceChangedModal";
// import * as $ from "jquery";
// import _ from "lodash";
// import * as Api from "../js/api-service";
// import * as betSlip from "../js/bet-slip";
// import * as utility from "../js/utility";
// import subscribe from "unstated-subscribe-hoc";
// import {BetSlipContainer} from "../Containers/BetSlipContainer";
// import {AccountContainer} from "../Containers/AccountContainer";
// import {PreferenceContainer} from "../Containers/PreferenceContainer";
// import betcruncher from "betcruncher";
// import {Button} from "react-bootstrap";

// var potentialReturn = [];
// var betData;
// var gotResponse = true;
// var data = [];

// class BetSlipContent extends React.Component {
//     constructor(props) {
//         super(props);

//         var slip = {
//             type: "double",
//             stake: 1,
//             eachWay: false
//         };

//         var selections = [
//             {
//                 odds: "8/11",
//                 terms: "1/4",
//                 position: 1
//             },
//             {
//                 odds: "15/2",
//                 terms: "1/4",
//                 position: 1
//             }
//         ];
//         var slip1 = {
//             type: "single",
//             stake: 1,
//             eachWay: false
//         };

//         var selections1 = [
//             {
//                 odds: "8/11",
//                 terms: "1/4",
//                 position: 1
//             }
//             // { odds: "12/5", terms: "1/4", position: 1 }
//         ];

//         //console.log(betcruncher(slip1, selections1), "pot1");
//         //console.log(betcruncher(slip, selections), "pot1");

//         this.state = {
//             errorMessage: "",
//             headerTitle: "",
//             slipData: [],
//             betSlip: [],
//             wagers: [],
//             slipEmpty: "",
//             custom: false,
//             placeBetButton: false,
//             loginData: "",
//             Alert: false,
//             show: false,
//             placeBetData: [],
//             wagerValue: "",
//             Message: [],
//             selectionId: "",
//             totalStake: 0,
//             priceChangedBetsData: [],

//             bets: [],
//             wagersData: [],
//             totalBalance: "",
//             betDetail: "",
//             potentialReturn: "",
//             insufficientFunds: "hide",
//             stakeExceeded: "hide",
//             doublesValues: []
//         };

//         this.timeOutVar = "";
//         this.boxValue = "";
//         this.inputValue = "";
//         this.potentialReturn = "";
//         this.calculator = false;
//         this.tick = "";
//         this.dot = true;
//         this.add_value = true;
//         this.toggle2 = "";
//         this.hasContent = false;
//         this.balanceInfo = "";
//         this.removeAll = "";
//         this.a = 0;
//         this.b = "";
//         this.c = "";
//         this.d = "";
//         this.CP = "";
//         this.str = "";
//         this.res = "";
//         this.noncustom = true;
//         this.inp1 = false;
//         this.titleNames = "";
//         this.matchesData = "";
//         this.eventsData = "";
//         this.length = "";
//         this.showDiv = false;
//         this.placeBetResult = "";
//         this.wageId = "";
//         //placed bet detail
//         this.betData = "";
//         this.AccountBalance = "";
//         utility;
//         this.totalAmount = "";

//         this.RemoveBet = this.RemoveBet.bind(this);
//         this.checkbox = this.checkbox.bind(this);
//         this.closeMsgModal = this.closeMsgModal.bind(this);
//         this.closeInSufficientMsgModal = this.closeInSufficientMsgModal.bind(this);
//         this.acceptInSufficientMsgModal = this.acceptInSufficientMsgModal.bind(
//             this
//         );
//         this.closePriceChangeModal = this.closePriceChangeModal.bind(this);
//         this.closeStakeExceededMsgModal = this.closeStakeExceededMsgModal.bind(
//             this
//         );
//         this.acceptChange = this.acceptChange.bind(this);
//         this.declineChange = this.declineChange.bind(this);
//         this.LiveOddsBetslip = this.LiveOddsBetslip.bind(this);
//     }

//     componentWillReceiveProps(nextProps) {
//         //console.log(nextProps);
//         betData = nextProps;
//     }

//     componentDidMount() {
//         var that = this;
//         // setInterval(()=>{ that.LiveOddsBetslip(); },10000) window.LiveOddsBetslip =
//         // this.LiveOddsBetslip;
//         that.GetCompleteBasketData();

//         this.authorization();

//         //CHECK IF BETSLIP HAS EXPIRED
//         this.IsBetSlipExpired();

//         utility.getSetting(setting => {
//             //console.log("setting:", setting);
//             if (setting.live_betslip_enabled) {
//                 setInterval(function () {
//                     if (gotResponse) {
//                         that.LiveOddsBetslip();
//                     }
//                 }, 1000);
//             }
//         });
//     }

//     handleChange = event => {
//         this.inputValue = event.target.value;
//         this.boxValue = event.target;
//         //console.log(this.boxValue, "rahul");
//         const ctrl = $("#" + event.target.id);
//         let targetCtrl = $("#" + ctrl.attr("data-pr-target-ctrl"));
//         targetCtrl.text("");

//         if (this.inputValue !== "") {
//             let bet_type = ctrl.attr("bet-type");
//             let stake = ctrl.attr("stake");
//             bet_type = bet_type.toLowerCase();
//             let a = parseInt(this.inputValue);
//             let b = parseInt(stake);
//             stake = a * b;
//             let result = "";
//             if (bet_type === "singles") {
//                 //console.log(stake);
//                 //console.log(typeof this.inputValue, typeof stake);
//                 result = this.calculatePotentialReturn(stake, "single", 1);
//             } else if (bet_type === "doubles") {
//                 result = this.calculatePotentialReturn(stake, "double", 2);
//             } else if (bet_type === "trebles") {
//                 result = this.calculatePotentialReturn(stake, "treble", 3);
//             }

//             if (result > 0) {
//                 targetCtrl.text("Potential Return: £" + result);
//             }
//             //console.log(potentialReturn, "PotentialReturn");
//         } else {
//             //console.log(this.inputValue, "rahul", "handle");
//         }
//         //this.setState({ wagerValue: event.target.value });
//     };

//     calculatePotentialReturn(stake, bet_type, loopRun) {
//         //console.log("working", "pot");
//         let bets = betData.Store.state.BetSlip; // taking out bets
//         const sel = [];
//         const slip = {
//             type: "single",
//             stake: stake,
//             eachWay: false
//         };
//         if (this.tick == true) {
//             slip.eachWay = true;
//         }
//         slip.type = bet_type;
//         for (let i = 0; i < loopRun; i++) {
//             if (bets[i].Price != undefined) {
//                 let price = bets[i].Price.trim(); // " 12-2"
//                 //console.log(price);
//                 let a = price.split("-");
//                 let odds = a[0] + "/" + a[1]; // looping through all bets
//                 let obj = {
//                     odds: odds,
//                     terms: "1/4",
//                     position: 1
//                 };
//                 sel.push(obj);
//             } else {
//                 return;
//             }
//         }

//         let data = betcruncher(slip, sel);
//         return data.returns;
//         //console.log(bets, "bet");
//     }

//     IsBetSlipExpired() {
//         if (betSlip.GetBetsCount() <= 0) return;
//         const val = utility.GetCookie("FreshBetSlip");
//         //CHECK IF EXPIRED SINCE LAST TIME BETSLIP WAS EMPTY
//         if (typeof val == "undefined" || val == null) {
//             this.ForgetEverything();
//             utility.ShowNotification("BetSlip Expired!");
//             return true;
//         }
//     }

//     LiveOddsBetslip() {
//         const that = this;
//         if (!this.props.Store.state.SlipEmpty) {
//             let previousBetslip = this.props.Store.state.SlipData.Bets;
//             let basketId = this.props.Store.state.SlipData.BasketId;
//             //console.log(basketId, previousBetslip);
//             gotResponse = false;
//             Api.GetBasketForLiveOdds(basketId, cb => {
//                 // /cb.basket[0].EventSelections.homeodds = '2-2'
//                 //console.log("GetBasketForLiveOdds-", cb);
//                 gotResponse = true;
//                 if (cb.basket != null) {
//                     cb.basket.forEach((v, i) => {
//                         //console.log(v.EventSelections.homeodds, v.betprice, v);
//                         //console.log(v.EventSelections.homeodds, v.EventSelections.drawodds, v.EventSelections.awayodds);
//                         //console.log(' v.betprice', v.betprice);
//                         var hasChanged = false;
//                         if (v.betprice.toUpperCase() !== "EVENS") {
//                             if (v.selection === "0") {
//                                 hasChanged = v.betprice !== v.EventSelections.homeodds;
//                             } else if (v.selection === "1") {
//                                 hasChanged = v.betprice !== v.EventSelections.drawodds;
//                             } else if (v.selection === "2") {
//                                 hasChanged = v.betprice !== v.EventSelections.awayodds;
//                             }
//                         }
//                         if (hasChanged) {
//                             console.log(
//                                 `Wallah! Price has changed! New price is ${v.EventSelections.homeodds}`,
//                                 v.eventId
//                             );
//                             // that.RemoveBet(v.basketitemid, 'a');
//                             that.props.Store.UpdateInBetSlip(v);
//                         } else {
//                             console.log("match", v);
//                         }
//                     });
//                 }
//             });
//         }
//     }

//     GetCompleteBasketData() {
//         this.props.Store.UpdateBetSlip();
//         return;
//         console.log("GetCompleteBasketData called");
//         var that = this;
//         // utility.ShowAddBetLoader("addingBetLoader");
//         betSlip.GetBetSlipData(data => {
//             that.slipData = data;
//             this.setState({potentialReturn: ""});
//             this.props.Store.setState({SlipData: this.slipData});

//             if (that.slipData.Bets != undefined) {
//                 if (that.slipData.Bets.length > 0) {
//                     that.props.Store.setState({
//                         BetSlip: this.slipData.Bets,
//                         SlipEmpty: false
//                     });
//                     // utility.HideAddBetLoader("addingBetLoader");
//                     setTimeout(() => {
//                         console.log("Focus to first textbox set");
//                         var elem = document.getElementsByClassName("wager-input")[0];
//                         console.log(elem);
//                         if (typeof elem != "undefined") {
//                             elem.focus();
//                             elem.click();
//                         }
//                     }, 1000);
//                 } else {
//                     that.props.Store.setState({SlipEmpty: true});
//                 }
//             } else {
//                 that.props.Store.setState({BetSlip: [], SlipEmpty: true});
//                 utility.HideAddBetLoader("addingBetLoader");
//             }
//             if (that.slipData.Wagers != undefined) {
//                 if (that.slipData.Wagers.length > 0) {
//                     that.props.Store.setState({Wagers: this.slipData.Wagers});
//                 }
//             } else {
//                 that.props.Store.setState({Wagers: []});
//             }
//             console.log("----", this.slipData);
//         });
//         console.log("Slip State from Store", that.props.Store.state);
//         utility.HideAddBetLoader("addingBetLoader");
//     }

//     authorization() {
//         if (localStorage.getItem("CustInfo") == null || undefined) {
//             this.login = true;
//             this.setState({placeBetButton: false});
//             this.balanceInfo = false;
//         } else {
//             this.login = false;
//             this.setState({placeBetButton: true});
//             this.balanceInfo = true;
//             this.setState({
//                 loginData: JSON.parse(localStorage.getItem("CustInfo"))
//             });
//             //console.log("++++++++++++" + this.state.loginData);
//         }
//         if (localStorage.getItem("BetSlipCache") == null) {
//             this.setState({slipEmpty: true});
//             this.removeAll = false;
//             this.hasContent = false;
//         } else {
//             this.setState({slipEmpty: false});
//             this.removeAll = true;
//         }
//     }

//     RemoveBet(itemId, idx) {
//         const removeBOG = JSON.parse(localStorage.getItem("LocalStorageBogArr"));

//         _.remove(removeBOG, function (e) {
//             //$("#" + e.ItemId).remove();
//             return e.ItemId == itemId;
//         });

//         localStorage.setItem("LocalStorageBogArr", JSON.stringify(removeBOG));

//         console.log(removeBOG, "removeBOG");
//         this.setState({potentialReturn: ""});
//         this.props.Store.RemoveBet(
//             itemId,
//             data => {
//                 console.log("Removed");
//                 var BetCount = parseInt(betSlip.GetBetsCount()) - 1;
//                 betSlip.SaveBetsCount(BetCount);
//             },
//             err => {
//                 console.log("err", err);
//             }
//         );
//     }

//     checkbox(check, wage) {
//         let val = $("#inpColor0").val();
//         let lines = wage.NumberOfLines;
//         let stake = parseInt(val) * parseInt(lines);
//         if (check.checked) {
//             console.log("checked", wage);
//             console.log(check, wage, "harshiv");
//             this.tick = true;

//             let a = this.calculatePotentialReturn(stake, "single", 1);
//             console.log(a, "harshiv");
//             $("#potential-return-val0").text("Potential Return: £" + a);

//             //    let a =  this.calculatePotentialReturn(); console.log(a,'harshiv')
//             // this.wageId = wage.
//         } else {
//             console.log("unchecked");
//             this.tick = false;
//             let a = this.calculatePotentialReturn(stake, "single", 1);
//             console.log(a, "harshiv");
//             $("#potential-return-val0").text("Potential Return: £" + a);
//         }
//     }

//     SingleInput(single) {
//         console.log(single, "single");
//         this.inputValue = single;
//         this.boxValue = single;
//         console.log(this.inputValue.value, "inputValue");
//         this.calculator = false;
//         this.dot = true;
//     }

//     add(text) {
//         // 5+ to 200+
//         console.log(this.boxValue, "rahul");
//         let stake = $("#" + this.boxValue.id).attr("stake");
//         let bet_type = $("#" + this.boxValue.id)
//             .attr("bet-type")
//             .toLowerCase();
//         let targetCtrl = $("#" + this.boxValue.id).attr("data-pr-target-ctrl");
//         var result;
//         console.log(this.boxValue.value, stake, "text");

//         if (this.boxValue == undefined) {
//         } else {
//             this.calculator = true;
//             var res = parseInt(text.trim("+"));

//             if (this.boxValue.value == "") {
//                 this.boxValue.value = 0 + res;
//                 stake = parseInt(stake) * parseInt(this.boxValue.value);
//                 if (bet_type == "singles") {
//                     result = this.calculatePotentialReturn(stake, "single", 1);
//                     $("#" + targetCtrl).text("Potential Return: £" + result);
//                 } else if (bet_type == "doubles") {
//                     result = this.calculatePotentialReturn(stake, "double", 2);
//                     $("#" + targetCtrl).text("Potential Return: £" + result);
//                 }
//             } else {
//                 console.log(this.boxValue.value, res, "elsepart");

//                 let a = parseInt(this.boxValue.value) + res;
//                 console.log(a, "value");
//                 this.boxValue.value = a;

//                 stake = parseInt(stake) * parseInt(this.boxValue.value);
//                 if (bet_type == "singles") {
//                     result = this.calculatePotentialReturn(stake, "single", 1);
//                     $("#" + targetCtrl).text("Potential Return: £" + result);
//                 } else if (bet_type == "doubles") {
//                     result = this.calculatePotentialReturn(stake, "double", 2);
//                     $("#" + targetCtrl).text("Potential Return: £" + result);
//                 }
//             }
//         }
//     }

//     LoadWagers() {
//         var finalWagers = [];
//         $(".wager-input").each((index, wager) => {
//             var wid = wager.dataset.wagerid;
//             if (wager.value != "" || parseInt(wager.value) == 0) {
//                 //ONLY ADD WAGER TO BET IF VALUE ENTERED BY USER
//                 var w = _.find(this.props.Store.state.SlipData.Wagers, {id: wid});
//                 var chBox = $("#ew_" + wid);
//                 if (chBox) {
//                     var checked = chBox.prop("checked");
//                     if (checked) {
//                         w["EW_" + wid] = 1;
//                     }
//                 }
//                 w["WagerType_" + wid] = wager.value; //JUST SET BOTH FOR THE SAKE OF SANITY
//                 w["autoWager"] = wager.value;
//                 finalWagers.push(w);
//             }
//         });
//         console.log(finalWagers);
//         return finalWagers;
//     }

//     removeAllButton() {
//         this.props.Store.RemoveAll(data => {
//             localStorage.removeItem("BetSlipCache");
//             localStorage.removeItem("BasketId");
//             localStorage.removeItem("BasketCountId");
//             localStorage.removeItem("BetCount");
//             betSlip.SaveBetsCount(0);
//             this.authorization();
//         });
//     }

//     PlaceBet() {
//         $("#place_bet").attr("disabled", true);
//         utility.ShowBetPlacingLoader();

//         //let body = 'UserName=' + localStorage["username"] + '&Password=' + localStorage["password"];
//         let bodyOption =
//             "UserName=" +
//             localStorage["username"] +
//             "&Password=" +
//             localStorage["password"];

//         if (
//             this.props.PreferenceStore.state.Preferences &&
//             this.props.PreferenceStore.state.Preferences.account_status != undefined
//         ) {
//             if (
//                 this.props.PreferenceStore.state.Preferences.account_status ==
//                 "NoBetting"
//             ) {
//                 console.log("outttttt");
//                 var msg = {
//                     Title: "Notice",
//                     Body:
//                         "You are not allowed to place bets. Please contact our support team at " +
//                         "<a href='mailto:support@geoff-banks.com'>support@geoff-banks.com</a> for more details."
//                 };
//                 $("#place_bet").attr("disabled", false);
//                 utility.HideBetPlacingLoader();
//                 this.setState({Message: msg, showMsgModal: true});
//                 return;
//             }
//         }
//         this.tick = false;
//         var that = this;

//         Api.Login(bodyOption, data => {
//             if (data.Status === "1") {
//                 var wagers = that.LoadWagers();

//                 localStorage.setItem("CustInfo", JSON.stringify(data));
//                 if (wagers.length <= 0) {
//                     $("#place_bet").attr("disabled", false);
//                     utility.HideBetPlacingLoader();
//                     alert(
//                         "Can't place empty bet!, Please enter a wager value to proceed."
//                     );
//                     return; //MUST NOT PROCEED WITH EMPTY WAGERS
//                 }

//                 let id = wagers[0].id;

//                 let proposed_stake = wagers[0]["WagerType_" + id];

//                 var Available_Funds_With_Currency = data.funddetail.AvailableFunds;

//                 var available_funds = Available_Funds_With_Currency.substring(
//                     1,
//                     Available_Funds_With_Currency.length
//                 ).replace(",", "");

//                 if (parseFloat(wagers[0].autoWager) > parseFloat(available_funds)) {
//                     var customInsufficientPopUpHtml = "";
//                     var msg = {
//                         Title: "Insufficient Funds In Account",
//                         Body: customInsufficientPopUpHtml
//                     };
//                     $("#place_bet").attr("disabled", false);
//                     utility.HideBetPlacingLoader();
//                     that.setState({
//                         Message: msg,
//                         insufficientFunds: "show",
//                         stakeExceeded: "hide"
//                     });
//                 } else {
//                     //utility.ShowBetPlacingLoader();
//                     betSlip.PlaceBet(wagers, data => {
//                         utility.HideBetPlacingLoader();
//                         if (!data.Body) {
//                             var items = _.filter(data.Wagers, function (item) {
//                                 return (
//                                     item != "" &&
//                                     typeof item.ResultMessage != "undefined" &&
//                                     item.ResultMessage.length > 0 &&
//                                     item.ResultMessage.toLowerCase().indexOf("not placed") >= 0
//                                 );
//                             });
//                             var msg = "";
//                             var hasMessages = items.length > 0;
//                             if (hasMessages) {
//                                 msg = items[0].ResultMessage;
//                             }
//                             if (
//                                 typeof data.Message != "undefined" &&
//                                 data.Message.length > 0
//                             ) {
//                                 msg = data.Message;
//                             }

//                             //***********Price Change Popup********************//

//                             // var localData = utility.GetAllBetsFromLocalStorage();

//                             // console.log("localData----", localData);
//                             // var PriceChangedBets = []
//                             // localData.forEach((element, index) => {
//                             //     var bet = '';
//                             //     if (element.hasPriceChange) {
//                             //         bet = _.find(that.props.Store.state.SlipData.Bets, function (bet) { return bet.ItemId == element.basketItemId; });
//                             //         if (bet != undefined && bet != '') {
//                             //             PriceChangedBets.push(bet);
//                             //         };
//                             //     }
//                             // });
//                             // console.log("PriceChangedBets----", PriceChangedBets);
//                             // if (PriceChangedBets.length > 0) {
//                             //     PriceChangedBets.forEach((bet, index) => {
//                             //         console.log("Name---", bet.Detail + " : " + bet.Price)
//                             //     })

//                             //     var msg = {
//                             //         Title: "PRICE CHANGE",
//                             //         //Body:"<div>The odds , markets or availability of your bet has changed.</div><br>",
//                             //         Body: "<h4> The odds , markets or availability of your bet has changed.</h4>" +
//                             //             "<strong>" +
//                             //             PriceChangedBets.forEach((bet, index) => {
//                             //                 bet.Detail + " : " + bet.Price
//                             //             }) +
//                             //             " </strong>  <h5> Please Accept the new price </h5>"
//                             //     }
//                             //     this.setState({ Msg: msg, showPriceChangeModal: true, PriceChangedBets: PriceChangedBets });
//                             //     // PriceChangedBets.forEach((bet, index) => {
//                             //     //     var msg = {
//                             //     //         Title: "PRICE CHANGE",
//                             //     //         Body:"<div>The odds , markets or availability of your bet has changed.</div><br>",
//                             //     //         // Body: "<h4> The odds , markets or availability of your bet has changed.</h4>" +
//                             //     //         //     "<strong>" +
//                             //     //         //     data.Bets[index].Detail +
//                             //     //         //     " : " +
//                             //     //         //     data.Bets[index].Price +
//                             //     //         //     " </strong>  <h5> Please Accept the new price </h5>"
//                             //     //     }
//                             //     //     this.setState({ Msg: msg, showPriceChangeModal: true, BetId: bet.ItemId, PriceChangedBets:PriceChangedBets });

//                             //     // });

//                             // }

//                             // return;

//                             if (typeof msg != "undefined" && msg.length > 0) {
//                                 //alert(msg);
//                                 console.log("msg---", msg);

//                                 var a = utility.GenerateMessage(msg);
//                                 console.log(a, "a.............");

//                                 if (a == "Bet not placed. The price has changed now.") {
//                                     var priceChangedBets = _.filter(data.Bets, function (
//                                         element,
//                                         index
//                                     ) {
//                                         return (
//                                             element.Price !=
//                                             that.props.Store.state.SlipData.Bets[index].Price
//                                         );
//                                     });
//                                     console.log("priceChangedBets---", priceChangedBets);

//                                     if (priceChangedBets != undefined) {
//                                         this.setState({
//                                             showPriceChangedModal: true,
//                                             priceChangedBetsData: priceChangedBets
//                                         });
//                                     }

//                                     // that.props.Store.state.SlipData.Bets.forEach((element, index) => {
//                                     //   if (element.Price != data.Bets[index].Price) {
//                                     //     var msg = {
//                                     //       Title: "PRICE CHANGE",
//                                     //       // Body:"<div>The odds , markets or availability of your bet has changed.</div><br>",
//                                     //       Body:
//                                     //         "<h4> The odds for your selection has changed.</h4>" +
//                                     //         "<strong> " +
//                                     //         data.Bets[index].Detail +
//                                     //         " now " +
//                                     //         data.Bets[index].Price +
//                                     //         " </strong>  <h5> Press Accept to take the new price and place bet.</h5>"
//                                     //       // Body: "<h4> The odds , markets or availability of your bet has changed.</h4>" +
//                                     //       //     "<strong>" +
//                                     //       //     data.Bets[index].Detail +
//                                     //       //     " : " +
//                                     //       //     data.Bets[index].Price +
//                                     //       //     " </strong>  <h5> Please Accept the new price </h5>"
//                                     //     };
//                                     //     this.setState({
//                                     //       Msg: msg,
//                                     //       showPriceChangeModal: true,
//                                     //       BetId: data.Bets[index].ItemId,
//                                     //       selectionId: data.Bets[index].ItemId
//                                     //     });
//                                     //   }
//                                     // });
//                                     $("#place_bet").attr("disabled", false);
//                                     console.log("if a");
//                                 } else {
//                                     msg = msg.replace(/[&\/\\#,+()$~%'":*?<>{}�-]/g, "");
//                                     console.log(msg, "msgggg");
//                                     const regex = /Liability Exceeded on [a-zA-Z]+\.  -> The maximum stake available is �[0-9\.]+/gm;
//                                     var re = /The maximum stake available is (\d+(\.\d{1,2}))/g;
//                                     const regex_maxstake = /is[^\d]((.|,)\d+)+/gm;
//                                     let MaxStake = regex_maxstake.exec(msg);
//                                     console.log(MaxStake, "MaxStakeMaxStake");
//                                     //let MaxStake_Value = MaxStake[0].replace("is ", "").trim();
//                                     let MaxStake_Value = 0.0;
//                                     if (MaxStake != null) {
//                                         MaxStake_Value = MaxStake[0].replace("is ", "").trim();
//                                     }

//                                     let m;
//                                     m = re.exec(msg);
//                                     let formattedAlert = [];

//                                     console.log("before while");

//                                     var results = [];
//                                     var doublesValues1 = [];

//                                     data.Wagers.forEach((match1, groupIndex) => {
//                                         var ResultMessage = match1.ResultMessage;
//                                         if (typeof ResultMessage != "undefined") {
//                                             var resultMsg = ResultMessage.replace(/[&\/\\#,+()$~%'":*?<>{}�-]/g, "");
//                                             console.log("resultMsg--->>>", resultMsg)
//                                             var reng = /Should you wish to place more than the permitted stake offered (\d+(\.\d{1,2}))/g;
//                                             var messageArray = reng.exec(resultMsg) //GET STAKE VALUE FROM KNOCKBACK MESSAGE
//                                             var WagerTypeNameFull = match1.WagerTypeNameFull;
//                                             if (messageArray != null && messageArray != undefined && messageArray[1] != undefined) {
//                                                 //IF THERE IS STAKE EXCEED ERROR MESSAGE
//                                                 doublesValues1.push(messageArray[1]);
//                                                 results.push(
//                                                     '<i class="fa fa-exclamation-triangle cl-red" aria-hidden="true"></i> ' +
//                                                     WagerTypeNameFull.replace("�", "£") +
//                                                     " : <br/> " +
//                                                     ResultMessage.replace("�", "£").replace(
//                                                         "(£" + messageArray[1] + ")",
//                                                         "<b>(£" + messageArray[1] + ")</b>"
//                                                     ) +
//                                                     "<br/><br/>"
//                                                 );
//                                             } else {
//                                                 //IF THERE IS NO STAKE EXCEED ERROR MESSAGE
//                                                 doublesValues1.push(0);
//                                                 results.push(
//                                                     WagerTypeNameFull.replace("�", "£") +
//                                                     " : <br/> " +
//                                                     ResultMessage.replace("�", "£") +
//                                                     "<br/><br/>"
//                                                 );
//                                             }

//                                             //m = re.exec(msg);

//                                             // var WagerTypeNameFull = match1.WagerTypeNameFull;

//                                             // if (typeof ResultMessage != "undefined") {
//                                             //   doublesValues1.push(m[1]);
//                                             //   results.push(
//                                             //     WagerTypeNameFull.replace("�", "£") +
//                                             //       " : <br/> " +
//                                             //       ResultMessage.replace("�", "£").replace(
//                                             //         "(£" + m[1] + ")",
//                                             //         "<b>(£" + m[1] + ")</b>"
//                                             //       ) +
//                                             //       "<br/><br/>"
//                                             //   );
//                                             // }
//                                         } else {
//                                             doublesValues1.push(0);
//                                         }
//                                     });

//                                     if (results.length >= 1) {
//                                         // this.setState({

//                                         // });
//                                         // console.log(results,"results");
//                                         // console.log(doublesValues1,"doublesValues1");

//                                         // this.Multiple_Stake = true;
//                                         // this.Multiple_Stake_Price = results;
//                                         // this.doublesValues = doublesValues1
//                                         //}

//                                         // //this.utility.showAlert("Notice", msg);


//                                         var customStakeExceededPopUpHtml =
//                                             '<div><p style="text-align:left;"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>The Available stake for your wager has been exceeded.</p>' +
//                                             '<p style="text-align:justify;">' +
//                                             results +
//                                             "</p>" +
//                                             "</div>";

//                                         customStakeExceededPopUpHtml = customStakeExceededPopUpHtml.replace(/,/g, '')

//                                         var msg = {
//                                             //Title: "Notice",
//                                             Title: "Stake Exceeded",
//                                             Body: customStakeExceededPopUpHtml
//                                         };
//                                         $("#place_bet").attr("disabled", false);
//                                         //this.setState({ Message: msg, showMsgModal: true });
//                                         // this.setState({ Message: msg, showStakeExceededMsgModal: true, totalStake: MaxStake_Value,insufficientFunds:"hide",stakeExceeded:"show"  });
//                                         this.setState({
//                                             Message: msg,
//                                             totalStake: MaxStake_Value,
//                                             insufficientFunds: "hide",
//                                             stakeExceeded: "show",
//                                             doublesValues: doublesValues1
//                                         });
//                                     }
//                                     // console.log(msg, "knockback");

//                                     // while ((m = regex.exec(msg)) !== null) {
//                                     //   console.log("while");
//                                     //   // This is necessary to avoid infinite loops with zero-width matches
//                                     //   if (m.index === regex.lastIndex) {
//                                     //     regex.lastIndex++;
//                                     //   }

//                                     //   // The result can be accessed through the `m`-variable.
//                                     //   m.forEach((match, groupIndex) => {
//                                     //     console.log(`Found match, group ${groupIndex}: ${match}`);
//                                     //     // let msg = match.replace(/[&\/\\#,+()$~%'":*?<>{}�-]/g, '');
//                                     //     formattedAlert.push(msg);
//                                     //     console.log(formattedAlert, "formatted");
//                                     //     //this.utility.showAlert("Notice", msg);
//                                     //     var msg = {
//                                     //       //Title: "Notice",
//                                     //       Title: "Stake Exceeded",
//                                     //       Body: msg
//                                     //     };

//                                     //     this.setState({ Message: msg, totalStake: MaxStake_Value, insufficientFunds: "hide", stakeExceeded: "show" });
//                                     //     //this.setState({ Message: msg, showMsgModal: true });

//                                     //     console.log(msg, "knockback");
//                                     //   });
//                                     // }
//                                 }
//                                 $("#place_bet").attr("disabled", false);
//                                 return;
//                             } else {
//                                 console.log("data----", data);
//                                 that.setState({placeBetData: data});
//                                 that.betData = data;
//                                 that.LoadBetData();
//                                 that.infos();
//                                 this.setState({modalShow: true});

//                                 var bogplacedata = data;
//                                 if (bogplacedata.Bets.length > 0) {
//                                     bogplacedata.Bets.forEach((bet, index) => {
//                                         var result_isBog = this.IsBog(bet.ItemId);

//                                         if (result_isBog.toLowerCase().indexOf("hide") > 0) {
//                                             bet.IsBOG = false;
//                                             console.log(
//                                                 bogplacedata.Wagers[index],
//                                                 "potentialreturn"
//                                             );
//                                             if (bogplacedata.Wagers[index] != undefined) {
//                                                 bet.PotentialReturn = bogplacedata.Wagers[
//                                                     index
//                                                     ].PotentialReturn.replace("�", "");
//                                             }
//                                         } else {
//                                             bet.IsBOG = true;
//                                             if (bogplacedata.Wagers[index] != undefined) {
//                                                 bet.PotentialReturn = bogplacedata.Wagers[
//                                                     index
//                                                     ].PotentialReturn.replace("�", "");
//                                             }
//                                         }
//                                     });

//                                     utility.SaveBetSlipHistory(bogplacedata, responseresult => {
//                                         console.log(
//                                             responseresult.data.bet_info,
//                                             typeof responseresult.data.bet_info,
//                                             JSON.parse(responseresult.data.bet_info),
//                                             "bogplacedata"
//                                         );
//                                     });
//                                 }

//                                 this.timeOutVar = setTimeout(() => {
//                                     that.modalClose();
//                                 }, 10000);
//                                 // this.props.data.history.push({    pathname: '/place-bet',  state: { data:
//                                 // data } });
//                             }
//                         } else {
//                             var customInsufficientPopUpHtml =
//                                 "<div><span>Proposed Stake : £" +
//                                 proposed_stake +
//                                 "</span>" +
//                                 "<span> Available Funds : " +
//                                 available_funds +
//                                 "</span>" +
//                                 "</div>";
//                             var msg = {
//                                 //Title: "Wager Not Accepted",
//                                 Title: "Insufficient Funds In Account",
//                                 Body: customInsufficientPopUpHtml
//                                 //Body: data.Body
//                             };
//                             $("#place_bet").attr("disabled", false);
//                             //this.setState({ Message: msg, showInSufficientMsgModal: true,insufficientFunds:"show",stakeExceeded:"hide" });
//                             this.setState({
//                                 Message: msg,
//                                 insufficientFunds: "show",
//                                 stakeExceeded: "hide"
//                             });
//                             //this.utility.showAlert("Wager Not Accepted", data.Body);
//                         }
//                     }, (err) => {
//                         console.log("PLACE BET ERROR : ", err);
//                         var msg = {
//                             Title: "Error",
//                             Body:
//                                 "An error occured. Please retry later or contact customer service if problem persist. "
//                         };
//                         $("#place_bet").attr("disabled", false);
//                         utility.HideBetPlacingLoader();
//                         this.setState({Message: msg, showMsgModal: true});
//                     });
//                 }
//             }
//         });
//     }

//     closeStakeExceededMsgModal() {
//         //this.setState({ showStakeExceededMsgModal: false });
//         this.setState({stakeExceeded: "hide"});
//     }

//     closeInSufficientMsgModal() {
//         //this.setState({ showInSufficientMsgModal: false });
//         this.setState({insufficientFunds: "hide"});
//     }

//     acceptInSufficientMsgModal() {
//         console.log("totalStake11", this.state.totalStake);
//         let findnoofbettextboxes = this.props.Store.state.Wagers.length;
//         let Available_Funds_With_Currency = utility.GetCustInfoFromLocalStorage()
//             .funddetail.AvailableFunds;
//         let Available_Funds = Available_Funds_With_Currency.substring(
//             1,
//             Available_Funds_With_Currency.length
//         ).replace(",", "");
//         let perTextBoxBetValue = 0;
//         if (this.state.totalStake > 0) {
//             perTextBoxBetValue = this.state.totalStake / findnoofbettextboxes;
//         } else {
//             console.log("Available_Funds11", Available_Funds);
//             console.log("findnoofbettextboxes11", findnoofbettextboxes);
//             perTextBoxBetValue = Available_Funds / findnoofbettextboxes;
//         }

//         if (findnoofbettextboxes === 1) {
//             $("#inpColor0").val(parseFloat(perTextBoxBetValue).toFixed(2));
//             this.closeInSufficientMsgModal();
//             if (this.state.totalStake > 0) {
//                 //$('#place_bet').click();
//             }
//             this.closeStakeExceededMsgModal();
//         } else if (findnoofbettextboxes > 1) {
//             // this.props.Store.state.Wagers.map(function (wage, i) {
//             //   console.log(wage, "wagerssss");
//             //   var totalvalue = parseFloat(perTextBoxBetValue).toFixed(2);
//             //   var NumberOfLines = parseFloat(wage.NumberOfLines);
//             //   var results = (totalvalue / NumberOfLines);

//             //   $('#inpColor' + i).val(parseFloat(totalvalue).toFixed(2));

//             // }, this)

//             setTimeout(() => {
//                 this.props.Store.state.Wagers.map(function (wage, i) {
//                     console.log("should be this--", this.state.doublesValues[i]);
//                     var value = parseFloat(this.state.doublesValues[i]).toFixed(2);
//                     $("#inpColor" + i).val(value);
//                     if (this.state.doublesValues[i] == 0) {
//                         $("#inpColor" + i).prop("disabled", true);
//                     }
//                 }, this);
//             }, 1000);

//             this.closeInSufficientMsgModal();
//             this.closeStakeExceededMsgModal();
//         } else {
//             return;
//         }
//     }

//     closeMsgModal() {
//         this.setState({showMsgModal: false});
//     }

//     closePriceChangeModal() {
//         this.setState({showPriceChangeModal: false});
//     }

//     acceptChange() {
//         console.log("accepppttttt");
//         this.GetCompleteBasketData();
//         this.closePriceChangeModal();
//         $("#place_bet").click();
//     }

//     declineChange(id) {
//         console.log("decccllliinnneee", id);
//         if (id != "" && id != undefined) {
//             this.RemoveBet(id);
//         }
//         // this.state.PriceChangedBets.forEach((bet, index) => {
//         //   this.RemoveBet(bet.ItemId);
//         // });
//         this.closePriceChangeModal();
//     }

//     rejectbet = id => {
//         console.log("reject bet id", id);
//         if (id != "" && id != undefined) {
//             this.RemoveBet(id);
//             var updatedata = _.filter(this.state.priceChangedBetsData, function (bet) {
//                 return bet.ItemId != id;
//             });

//             if (updatedata != undefined) {
//                 console.log("length--", updatedata.length);
//                 if (updatedata.length == 0) {
//                     this.closePriceChangedModal();
//                 }

//                 this.setState({
//                     priceChangedBetsData: updatedata
//                 });
//             }
//             console.log("updatedata---", updatedata);

//             //this.RemoveBet(id);
//         }
//     };

//     acceptAllNewPrice = () => {
//         console.log("accepppttttt");
//         this.GetCompleteBasketData();
//         this.closePriceChangedModal();
//         $("#place_bet").click();
//     };

//     declineAllNewPrice = () => {
//         this.state.priceChangedBetsData.forEach((bet, index) => {
//             this.RemoveBet(bet.ItemId);
//         });
//         this.closePriceChangedModal();
//     };

//     closePriceChangedModal = () => {
//         this.setState({showPriceChangedModal: false});
//     };

//     //++++++++ PLACE BET FUCTIONS++++++++
//     LoadBetData() {
//         // THIS BLOCK HELPS TESTING BY SAVING BETDATA IN LOCALSTORAGE AND KEEP LOADING
//         // THIS VIEW
//         if (
//             typeof this.betData == "undefined" ||
//             this.betData == null ||
//             this.betData == ""
//         ) {
//             this.betData = JSON.parse(localStorage.getItem("PostBetData"));
//         } else {
//             localStorage.setItem("PostBetData", JSON.stringify(this.betData));
//         }
//         this.setState({betDetail: this.betData, bets: this.betData.Bets});

//         this.FixBetData();
//         console.log(this.betData, "PlaceBet_Data");
//     }

//     FixBetData = () => {
//         var goodWagers = [];
//         if (this.betData != null) {
//             console.log("this.betData.Wagers--", this.betData.Wagers);
//             this.betData.Wagers.forEach(wager => {
//                 //REMOVE EMPTY WAGERS WITH NO ERROR/RESULT
//                 if (
//                     typeof wager.ResultMessage != "undefined" &&
//                     typeof wager.BetDetailAmt != "undefined"
//                 ) {
//                     // if (typeof wager.BetDetailAmt == "undefined")   wager.BetDetailAmt = "0";
//                     // //FIX MISSING AMOUNTS TO 0
//                     wager.ResultMessage =
//                         typeof wager.ResultMessage != "undefined"
//                             ? this.FixMessage(wager.ResultMessage)
//                             : "";
//                     if (
//                         wager.ResultMessage.toLowerCase().indexOf("not placed") >= 0 ||
//                         wager.ResultMessage.toLowerCase().indexOf("sorry") >= 0
//                     ) {
//                         wager.ResultClass = "error";
//                         wager.BetDetailAmt = "0";
//                     } else {
//                         wager.ResultClass = "success";
//                         wager.BetDetailAmt = wager.BetDetailAmt.replace("�", "");
//                     }
//                     goodWagers.push(wager);
//                 }
//             });
//             this.betData.Wagers = goodWagers;
//             this.setState({Wagers: this.betData.Wagers});
//         }
//     };

//     FixMessage(msg) {
//         return msg
//             .replace("()", "")
//             .replace("�", "")
//             .replace("Potential", "<br>Potential")
//             .replace("  ", " ");
//     }

//     infos() {
//         if (
//             this.betData.AccountBalance != undefined &&
//             this.betData.BetTypeSum != undefined
//         ) {
//             this.AccountBalance = this.betData.AccountBalance.substring(
//                 7,
//                 this.betData.AccountBalance.length
//             );
//             this.setState({accountBalance: this.AccountBalance});
//             this.totalAmount = this.betData.BetTypeSum.substring(1, 5);
//             this.setState({totalBalance: this.totalAmount});
//         }
//     }

//     home() {
//         this.ForgetEverything();
//     }

//     ForgetEverything() {
//         this.props.Store.RemoveAll(data => {
//             localStorage.removeItem("BetSlipCache");
//             localStorage.removeItem("BasketId");
//             localStorage.removeItem("BasketCountId");
//             localStorage.removeItem("BetCount");
//             localStorage.removeItem("BetSlipBox"); //remove betslip box
//             betSlip.SaveBetsCount(0);
//             this.authorization();
//         });
//         betSlip.ResetBasket();
//         localStorage.setItem("PostBetData", null);
//         utility.UpdateUserData(cb => {
//             this.props.AccountStore.CustomInfo();
//         });
//     }

//     modalClose = () => {
//         //this.setState({potentialReturn:''})
//         clearTimeout(this.timeOutVar);
//         var that = this;
//         this.home();
//         this.props.Store.RemoveAll(() => {
//             that.setState({modalShow: false});
//             //window.location.reload();
//         });
//     };
//     IsBog = itemid => {
//         console.log("--in--", itemid);
//         const localStorageBOG = JSON.parse(
//             localStorage.getItem("LocalStorageBogArr")
//         );
//         if (localStorageBOG != null) {
//             const items = _.filter(localStorageBOG, function (v) {
//                 return v.ItemId === itemid;
//             });
//             console.log("item--", items);
//             if (items !== undefined && items.length > 0) {
//                 return "bog_span flipInX animated";
//             } else {
//                 return "bog_span flipInX animated hide";
//             }
//         } else {
//             return "bog_span flipInX animated hide";
//         }
//     };

//     goToDeposit() {
//         window.location.href = "/deposit";
//     }

//     render() {
//         return (
//             <div className="fixed-betslip" id="fixed-betslip">
//                 {/* Insufficient PopUp Details */}

//                 <div className={this.state.insufficientFunds + " insufficient_funds"}>
//                     <div>
//                         <h3>
//                             {" "}
//                             {this.state.Message.Title}{" "}
//                             <i
//                                 className="fa fa-times hand"
//                                 onClick={() => this.closeInSufficientMsgModal()}
//                             />
//                         </h3>
//                         <div className="insufficient_funds_inner_betslip">
//                             {/* <div className="msgBody">gsgsagsag
//               </div> */}
//                             <label>
//                                 {/* Press Accept to place wager with funds available, or press deposit to add more funds. */}
//                                 You have insufficient Funds! To Deposit Funds Click{" "}
//                                 <a
//                                     href="javascript:;"
//                                     className="change_color"
//                                     onClick={this.goToDeposit}
//                                 >
//                                     Here
//                                 </a>
//                             </label>
//                         </div>
//                         <div className="insufficient_funds_buttons_betslip">
//                             {/* <Button onClick={this.acceptInSufficientMsgModal} className="closeBtn">Accept</Button>
//               <Button onClick={this.goToDeposit} className="closeBtn">Deposit</Button> */}
//                             <Button
//                                 onClick={this.closeInSufficientMsgModal}
//                                 className="closeBtn"
//                             >
//                                 Back to Betslip
//                             </Button>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Stake Exceeded PopUp Details */}

//                 <div
//                     className={
//                         this.state.stakeExceeded + " insufficient_funds stake_exceeded"
//                     }
//                 >
//                     <h3>
//                         {" "}
//                         {this.state.Message.Title}{" "}
//                         <i
//                             className="fa fa-times hand"
//                             onClick={() => this.closeStakeExceededMsgModal()}
//                         />
//                     </h3>
//                     <div className="insufficient_funds_inner">
//                         <div
//                             className="msgBody"
//                             dangerouslySetInnerHTML={{__html: this.state.Message.Body}}
//                         ></div>
//                         <label>Press Accept to take the New Stake and Place Bet.</label>
//                     </div>
//                     <div className="insufficient_funds_buttons">
//                         <Button
//                             onClick={this.acceptInSufficientMsgModal}
//                             className="closeBtn"
//                         >
//                             Accept
//                         </Button>
//                         <Button
//                             onClick={this.closeStakeExceededMsgModal}
//                             className="closeBtn"
//                         >
//                             Decline
//                         </Button>
//                     </div>
//                 </div>

//                 <div className="right-sidebar-inner row">
//                     <div className="col-md-4 border-right first active">
//                         <h2>BET SLIP</h2>
//                     </div>
//                     <div className="col-md-6 border-right">
//                         <a
//                             href="javascript:void(0)"
//                             onClick={this.props.Store.RemoveAll}
//                             className="remove-all-bets"
//                         >
//                             <i className="fa fa-trash-o" aria-hidden="true"/>
//                             &nbsp;Remove All
//                         </a>
//                         &nbsp;&nbsp;
//                         <a
//                             href="javascript:void(0)"
//                             onClick={this.LiveOddsBetslip}
//                             className="hiddenLiveOddsBetslip"
//                         >
//                             &nbsp;&nbsp;
//                         </a>
//                     </div>
//                     <div className="col-md-2">
//                         <h3>
//                             <i className="fa fa-question-circle"/>
//                         </h3>
//                     </div>
//                 </div>

//                 <div className="multi-bets">
//                     <div className="multi-bets-arrow"/>
//                     {this.props.Store.state.SlipEmpty ? (
//                         <div className="emptySlip">
//                             No bet has been selected. To select a bet, please click on the
//                             respective result.
//                         </div>
//                     ) : (
//                         <div id="addingBetLoader">
//                             <div id="betSlipLoaderContainer">
//                                 {this.props.Store.state.BetSlip.map(function (bet, i) {
//                                     return (
//                                         <div
//                                             className="multi-bets-inner "
//                                             key={i}
//                                             data-itemid={bet.ItemId}
//                                         >
//                                             <div className="row">
//                                                 <div className="multi-bet-text text-right">
//                                                     <div className="col-md-8">
//                                                         <h3>{bet.Name}</h3>
//                                                         <em>
//                                                             {bet.Detail}@{" "}
//                                                             {this.props.PreferenceStore.ConvertPrice(
//                                                                 bet.BetPrice
//                                                             )}
//                                                             {bet.NewPC}
//                                                         </em>
//                                                     </div>
//                                                     <div className="col-md-4 multi-bet-right">
//                                                         <h3>
//                                                             {this.props.PreferenceStore.ConvertPrice(
//                                                                 bet.BetPrice
//                                                             )}
//                                                             <a onClick={() => this.RemoveBet(bet.ItemId, i)}>
//                                                                 <i className="fa fa-times"/>
//                                                             </a>
//                                                         </h3>
//                                                         <span
//                                                             id={bet.ItemId}
//                                                             className={this.IsBog(bet.ItemId)}
//                                                         >
//                               BOG
//                             </span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     );
//                                 }, this)}

//                                 <div className="wager-type">
//                                     <div className="wager-type-outer">
//                                         <div className="row">
//                                             <div className="col-md-7">
//                                                 <h4>WAGER TYPE</h4>
//                                             </div>
//                                             <div className="col-md-5 text-right">
//                                                 <h5>(WIN ONLY)</h5>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="wager-inner">
//                                         {this.props.Store.state.Wagers.map(function (wage, i) {
//                                             return (
//                                                 <div key={i}>
//                                                     <div className="row wager-inner-row">
//                                                         <div className="col-md-5">
//                                                             <h3>{wage.WagerTypeName}</h3>
//                                                             <label>
//                                                                 ({wage.NoLines}
//                                                                 Lines)
//                                                             </label>
//                                                         </div>
//                                                         <div className="col-md-3">
//                                                             {wage.EW === "" ? (
//                                                                 <div>
//                                                                     <input
//                                                                         type="checkbox"
//                                                                         onChange={e =>
//                                                                             this.checkbox(e.target, wage)
//                                                                         }
//                                                                         id={"ew_" + wage.id}
//                                                                     />
//                                                                     <span>EACH WAY</span>
//                                                                 </div>
//                                                             ) : (
//                                                                 <span>WIN ONLY</span>
//                                                             )}
//                                                         </div>
//                                                         <div
//                                                             className="col-md-4"
//                                                             id={this.state.potentialReturn + " test"}
//                                                         >
//                                                             <input
//                                                                 data-pr-target-ctrl={"potential-return-val" + i}
//                                                                 stake={wage.NumberOfLines}
//                                                                 bet-type={wage.WagerTypeName}
//                                                                 type="number"
//                                                                 id={"inpColor" + i}
//                                                                 data-wagerid={wage.id}
//                                                                 className="textBox wager-input"
//                                                                 onClick={e => this.SingleInput(e.target)}
//                                                                 onChange={this.handleChange}
//                                                             />
//                                                         </div>
//                                                     </div>
//                                                     <div className="row potential-return-container">
//                                                         <div className="col-md-12">
//                                                             <div
//                                                                 className="potential"
//                                                                 id={"potential-return-val" + i}
//                                                             >
//                                                                 <div id={i} className="potential"/>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             );
//                                         }, this)}
//                                     </div>
//                                 </div>

//                                 <div className="stake">
//                                     {this.props.Store.state.ShowPlaceBetButton ? (
//                                         <button
//                                             className="place_bet"
//                                             id="place_bet"
//                                             onClick={() => this.PlaceBet()}
//                                         >
//                                             PLACE BET
//                                         </button>
//                                     ) : (
//                                         <button
//                                             className="betslip-login-prompt"
//                                             onClick={utility.ShowLoginBox}
//                                         >
//                                             Please Login/Signup to Place the Bet
//                                         </button>
//                                     )}
//                                     {this.props.Store.state.ShowPlaceBetButton ? (
//                                         <div className="button-note">Just press once</div>
//                                     ) : (
//                                         ""
//                                     )}
//                                     <p>
//                                         Use staking ladder to input your amount. Click more than
//                                         once to add to your stake!
//                                     </p>
//                                     <a onClick={e => this.add(e.target.innerText)}>5+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>10+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>15+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>50+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>100+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>125+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>150+</a>
//                                     <a onClick={e => this.add(e.target.innerText)}>200+</a>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//                 <BetPlacedModal
//                     show={this.state.modalShow}
//                     onHide={this.modalClose}
//                     placebetdata={this.betData}
//                     bets={this.betData.Bets}
//                     wagers={this.state.betDetail.Wagers}
//                     totalbalance={this.state.totalBalance}
//                     betdetail={this.state.betDetail} //potentialReturn = {this.state.potentialReturn}
//                 />

//                 <MessageModal
//                     onAccept={this.onAccept}
//                     show={this.state.showMsgModal}
//                     onHide={this.closeMsgModal}
//                     message={this.state.Message}
//                 />

//                 {/* <InsufficientModal
//           onAccept={this.acceptInSufficientMsgModal}
//           show={this.state.showInSufficientMsgModal}
//           message={this.state.Message}
//           onHide={this.closeInSufficientMsgModal}
//         />
//         <StakeExceededModal
//           onAccept={this.acceptInSufficientMsgModal}
//           show={this.state.showStakeExceededMsgModal}
//           onHide={this.closeStakeExceededMsgModal}
//           message={this.state.Message}
//         /> */}
//                 <PriceChangeModal
//                     show={this.state.showPriceChangeModal}
//                     onHide={this.closePriceChangeModal}
//                     msg={this.state.Msg}
//                     acceptchange={() => this.acceptChange()}
//                     declinechange={() => this.declineChange(this.state.selectionId)}
//                 />

//                 <PriceChangedModal
//                     show={this.state.showPriceChangedModal}
//                     onHide={this.closePriceChangedModal}
//                     pricechangedbetsdata={this.state.priceChangedBetsData}
//                     rejectbet={id => this.rejectbet(id)}
//                     acceptall={() => this.acceptAllNewPrice()}
//                     rejectall={() => this.declineAllNewPrice()}
//                 />
//             </div>
//         );
//     }
// }


import subscribe from "unstated-subscribe-hoc";
import { BetSlipContainer } from "../Containers/BetSlipContainer";
import { AccountContainer } from "../Containers/AccountContainer";
import { PreferenceContainer } from "../Containers/PreferenceContainer";
import { BetSlipPanel } from './BetSlip/public-api';
import * as utility from "../js/utility";
import React from 'react';
import { authApiService } from "./BetSlip/public-api";

const BetSlipContent = (props) => {
    const goToDeposit = () => {
        window.location.href = '/deposit';
    };

    const logout = () => {
        utility.Logout();
        utility.GotoHomepage();

        // Additional auth for internal BetSlip module API
        authApiService.logout();
    };

    return (
        <BetSlipPanel
            {...props}
            showNotification={utility.ShowNotification}
            showLoginBox={utility.ShowLoginBox}
            IsCustomerLoggedIn={utility.IsCustomerLoggedIn}
            oddsFormat={props.externalPreferenceStore.state.OddFormat}
            goToDeposit={goToDeposit}
            externalLogout={logout}
        />
    )
};

export default subscribe(BetSlipContent, {
    externalStore: BetSlipContainer,
    externalAccountStore: AccountContainer,
    externalPreferenceStore: PreferenceContainer
});
