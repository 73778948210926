import React from 'react'
//import {Link} from 'react-router-dom';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LeftContainer from '../Component/LeftContainer';
import RightContainer from '../Component/RightContainer';
import * as Utility from '../js/utility';
import { FavouriteContainer } from '../Containers/FavouriteContainer';
import subscribe from 'unstated-subscribe-hoc'
class ExpiredEvent extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        var that = this;

        if (that.props.location != undefined &&
            that.props.location.state != undefined &&
            that.props.location.state.Eventpath != undefined) {
                that.props.FavouriteStore.GetFavoriteByLink(that.props.location.state.Eventpath,(val,data) => {

                    if (val && data != undefined) {

                        this.props.FavouriteStore.RemoveFromFavourite(data.id, cb => {

                            that.RedirectToHomePage();
                        })
                    
                }else{
                    that.RedirectToHomePage();
                }
            })
        } else {
            that.RedirectToHomePage();
        }
    }

    RedirectToHomePage=()=>{
        setTimeout(function () {
            Utility.GotoHomepage();
       }, 5000);
    }

    render() {
        var content = (
            <div>
                <div className="homepage">
                    <div className="container">
                        <div className="content-section-inner">

                            <LeftContainer />
                            <div className="center-container">

                                <div className="sports">
                                    <div className="headerContainer">
                                        <div className="headerTitle myfavorite-banner">
                                            My favourites
                                        </div>
                                    </div>
                                    <div className="EmptyEvents">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        This event is expired now. Redirecting you to homepage.
                                    </div>
                                </div>
                            </div>
                            <RightContainer />
                        </div>
                    </div>
                </div>
                <Footer/>
            </div >
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

export default subscribe(ExpiredEvent, {
    FavouriteStore: FavouriteContainer
});