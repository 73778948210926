import React from "react";
import subscribe from "unstated-subscribe-hoc";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import TranDet from "../../Component/TransactionDetails";
import TranSummary from "./TranSummary";
import TransactionBlock from "./TransectionBlock";
import TranTypeBox from "./TranTypeBox";
import * as Api from "../../js/api-service";
import * as utility from "../../js/utility";
import * as betSlip from "../../js/bet-slip";
import * as Shared from "../../Component/Shared/Index";
import { AccountContainer } from "../../Containers/AccountContainer";
import moment from "moment";
import _ from "lodash";
import $ from "jquery";

var transData = "";
// var refno = "";
var ij = 0;

class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginData: "",
      funddetail: "",
      transaction: 1,
      duration: 1,
      statement: [],
      hasContent: false,
      ShowLoader: false,

      WinDetails: "",
      Summary: [],
      visible: false,
      transData: [],
      IsEnablelive_transaction_bog: "",
      BetSlip: [],
      BetSearchResult: [],
      earn: 0,
      compactView: localStorage.getItem('compact-view') || '0',
    };
    this.showDetail = this.showDetail.bind(this);
  }

  componentDidMount() {
    utility.UpdateIsEnableInPlay();
    var arr = [];
    let CustInfo = JSON.parse(localStorage.getItem("CustInfo"));
    //let user_name = CustInfo.Cust_Ref;

    //console.log(moment().format("YYYY-MM-DD HH:mm:ss"), "now2");

    this.setState({
      IsEnablelive_transaction_bog: utility.IsEnablelive_transaction_bog(),
    });

    var fund = JSON.parse(localStorage.getItem("authorization"));
    if (fund != undefined) {
      //console.log(fund.funddetail);
      this.setState({ funddetail: fund.funddetail });
      this.viewStatement(this.state.transaction, this.state.duration);
    } else {
      this.props.history.push("/");
      utility.ShowNotification(
        "Please Sign in to your account to access this page!"
      );
    }
  }

  handleChange(e, key) {
    //console.log("innnnnn", e.target.value);
    if (key === "transaction") {
      this.setState({ transaction: e.target.value });
    } else {
      this.setState({ duration: e.target.value });
    }
    //console.log(this.state);
  }

  IsAllowedToCheckTransactions = () => {
    const key = "LastTransactionsChecked";
    const lastTime = localStorage.getItem(key);
    let allowed = false;
    if (lastTime == undefined || lastTime == null) allowed = true;
    else {
      const diff = (new Date() - new Date(lastTime)) / 1000;
      if (diff > 30) allowed = true;
    }
    if (allowed === true) localStorage.setItem(key, new Date());
    return allowed;
  };

  viewStatement(trans, dur) {
    //CHECK THRESHOLD LIMITS
    if (!this.IsAllowedToCheckTransactions()) {
      utility.ShowNotification(
        "You're using the feature too fast. Please try after 30 seconds."
      );
      return;
    }
    let CustInfo = JSON.parse(localStorage.getItem("CustInfo"));
    let user_name = CustInfo.Cust_Ref;
    const arr = [];

    let start_date = "";
    const end_date = moment().format("YYYY-MM-DD HH:mm:ss");
    switch (dur) {
      case 1:
        start_date = moment()
          .subtract(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 2: //            2 Days
        start_date = moment().subtract(2, "days").format("YYYY-MM-DD HH:mm:ss");
        break;
      case 3: //          5 Days
        start_date = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
        break;
      case 4: ///        7 Days
        start_date = moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss");
        break;
      case 5: //     14 Days
        start_date = moment()
          .subtract(14, "days")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 6: //   30 Days
        start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 7: // 2 Months
        start_date = moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 8: // 3 Months
        start_date = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 9: //6 Months
        start_date = moment()
          .subtract(6, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 10: //9 Months
        start_date = moment()
          .subtract(9, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
      case 11: //12 Months
        start_date = moment()
          .subtract(12, "months")
          .format("YYYY-MM-DD HH:mm:ss");
        break;
    }

    //console.log(start_date,end_date,"teststestes");
    Api.betSlipTicketInfoAll(user_name, start_date, end_date, (result) => {
      {
        result.data.map(function (transaction, i) {
          arr.push(JSON.parse(transaction.bet_info));
        });
      }

      this.setState({ BetSearchResult: arr });
    });

    // let CustInfo = JSON.parse(localStorage.getItem("CustInfo"));
    // let user_name = CustInfo.Cust_Ref;
    let transFltType = trans;
    if (trans === 9 || trans === 10 || trans === 11 || trans === 15) {
      transFltType = 1; // get all transactions
    }
    this.setState({ hasContent: false, ShowLoader: true });
    //utility.ShowAddBetLoader("addingBetLoader");
    betSlip.getStatements(transFltType, dur, (data) => {
      data.Transactions.forEach((e, index) => {
        const d = e.TransactionSummary.Date.split(" ");
        const sportCode = e.TransactionSummary.WagerInfo.SportCode;
        if (sportCode) {
          const sportInfo = Api.GetSportInfo(sportCode);
          if (sportInfo) {
            e.TransactionSummary.WagerInfo["SportImage"] = sportInfo.Image;
          }
          // else{     e.TransactionSummary.WagerInfo["SportImage"] = sportInfo.Image; }
        }
        e.TransactionSummary["date"] = d[0];
        // if (e.TransactionSummary.CreditAmount <= 0) {
        // e.TransactionSummary["HasCredit"] = false;
        // e.TransactionSummary["TranLabel"] = "Loss";
        // e.TransactionSummary["TranAmount"] = e.TransactionSummary.DebitAmount; } else
        // {     e.TransactionSummary["HasCredit"] = true;     if
        // (e.TransactionSummary.CreditAmount > e.TransactionSummary.DebitAmount) {
        //    e.TransactionSummary["HasProfit"] = true;
        // e.TransactionSummary["TranAmount"] = e.TransactionSummary.CreditAmount -
        // e.TransactionSummary.DebitAmount;     } else {
        // e.TransactionSummary["HasProfit"] = false;     } }

        // Api.betSlipTicketInfo(e.TransactionSummary.RefNo, betres => {
        //     var isBogCheck = JSON.parse(betres.data[0].bet_info).Bets;

        //     let foundObject = _.find(isBogCheck, function (e) {
        //         console.log("ee_inside", e.IsBOG);
        //         if (e.IsBOG === true) {
        //             data.Transactions[index].TransactionSummary.IsBOG = true;
        //         }
        //         else {
        //             data.Transactions[index].TransactionSummary.IsBOG = false;
        //         }

        //     });
        // })
      });
      //this.data = data;

      //utility.HideAddBetLoader("addingBetLoader");

      // if (trans == 11) {
      //     var items = _.filter(data.Transactions, function (item) {
      //         return (item.MainTransaction.Type == trans);
      //     });
      //     console.log("items------",items)
      //     data.Transactions = items;
      // }
      let statementData = data;
      const FilteredData = utility.FilterData(trans, statementData); //filter all transaction based on debit,credit,reversal withdrawal
      if (FilteredData != undefined) {
        statementData = FilteredData;
      }
      var that = this;
      setTimeout(function () {
        // var RefNos = $("#addingBetLoader .sections .wager-info .showDetail a")
        //     .map(function () {
        //         return this.id;
        //     })
        //     .get()
        //     .join();
        // console.log(RefNos, "RefNos");

        //Api.betSlipTicketInfoAll(user_name,betres => {

        // var isBogCheck1 = betres.data;
        that.state.BetSearchResult.forEach((e, index) => {
          const bets = e.Bets || [];

          const TicketNo = e.Wagers;

          bets.forEach((e1, i) => {
            if (
              TicketNo &&
              typeof TicketNo === 'object' &&
              typeof TicketNo[0] !== 'undefined' &&
              typeof TicketNo[0].TicketNumber != "undefined" &&
              TicketNo[0].BetDetailType.toUpperCase() === "SINGLES"
            ) {
              const addcll = "ref-" + TicketNo[0].TicketNumber;
              // alert(addcll);
              if (e1.IsBOG === true) {
                const resa = `.${addcll}`;
                $(resa).removeClass("hide").addClass("show");
              }
            }
          });
        });

        //})
      }, 1500);

      //this.setState({ statement: statementData, hasContent: true, ShowLoader: false });
      // var _this = this;
      // setTimeout(function () {
      this.setState({
        statement: statementData,
        hasContent: true,
        ShowLoader: false,
      });
      // }, 2000);
    });
  }

  openWagerInfo(d) {
    this.props.history.push({
      pathname: "/wager-info",
      state: {
        data: d,
      },
    });
  }

  logout() {
    this.props.history.push("/login");
    utility.Logout();
  }

  lostPassword() {
    this.props.history.push("/forgot-password");
  }

  showDetail(trans) {
    this.setState({ transData: trans.TransactionSummary });
    const refNo = trans.TransactionSummary.RefNo;
    const officeNo = trans.MainTransaction.OfficeNo;
    this.setState({ visible: true });
    this.GetDetails(officeNo, refNo);

    // console.log(trans); Api.getWagerInfo(trans.TransactionSummary.RefNo, res => {
    //     console.log("res------", res);     this.wagerData = res; this.myData();
    // });
  }

  hideDetail = () => {
    this.setState({ visible: false });
  };

  // GetDetails(officeNo, refNo) {
  //     const _this = this;
  //     Api.getWagerInfo(officeNo, refNo, res => {
  //         console.log("wager info:", res);
  //         _this.setState({
  //             WinDetails: res
  //                 .Wagers
  //                 .shift()
  //                 .WagerText,
  //             Summary: res.Wagers
  //         })

  //     });
  // }

  GetBOGDetails(officeNo, refNo, CreditAmount, DebitAmount, Odds_, IsOpens) {
    if (Odds_ != null)
      Api.GetWagerInfoFromMainServer(officeNo, refNo, (res) => {
        const final_Result = res.Wagers;
        const winValues = res.Wagers[0].WagerText;
        let winValue = 1;
        if (winValues != undefined) {
          winValue = winValues.replace("&euro;", "");
        }
        _.filter(this.state.BetSearchResult, function (o) {
          if (o.Wagers.length > 0) {
            if (o.Wagers[0].TicketNumber == refNo) {
              const isBogCheck = o.Bets;
              let extraEarn = "";

              final_Result.forEach((e1, i) => {
                let oddRes;
                if (i == 0) return;
                e1.IsBOG = isBogCheck[i - 1].IsBOG;
                e1.PotentialReturn = isBogCheck[i - 1].PotentialReturn;
                Odds_ = e1.Odds;
                const addcll = `ref_${refNo}`;
                const resa = "." + addcll;

                let earnedBOG = 0;
                let Odds = Odds_.split("-");

                let OddsEP = "";
                let PotentialReturn = 0;

                if (Odds.length == 2) {
                  OddsEP = Odds_;
                  PotentialReturn =
                    parseFloat(Odds[0] / Odds[1]) * parseFloat(winValue) +
                    parseFloat(winValue);
                } else if (Odds.length == 3) {
                  oddRes = Odds_.split("EP");
                  //console.log("inside_ep", oddRes);
                  OddsEP = oddRes[0];
                  Odds = OddsEP.split("-");
                  PotentialReturn =
                    parseFloat(Odds[0] / Odds[1]) * parseFloat(winValue) +
                    parseFloat(winValue);
                } else if (Odds.length == 1) {
                  oddRes = Odds_.split("EP");
                  OddsEP = oddRes[0];
                  PotentialReturn = e1.PotentialReturn;
                }

                earnedBOG =
                  parseFloat(CreditAmount) - parseFloat(PotentialReturn);
                if (
                  earnedBOG.toFixed(2) != 0.0 &&
                  IsOpens != true &&
                  e1.IsBOG == true
                ) {
                  // console.log('after fixing', earnedBOG.toFixed(2))
                  // this.setState({
                  //     ...this.state, earn: earnedBOG
                  // })
                  // if (earnedBOG > 0) {
                  extraEarn +=
                    '<div class="col-md-6 bog_strip_inner"><span class="bog_span show transaction flipInX animated">BOG</span><span class="extraEarn">You won £' +
                    earnedBOG.toFixed(2).toString().replace("-", "") +
                    " extra with our best price guarantee.</span></div>";
                  $(resa).html(extraEarn);
                  // }
                }
              });
            }
          }
          //return o.name == 'john';
        });

        // Api.betSlipTicketInfo(refNo, betres => {

        //     var isBogCheck = JSON.parse(betres.data[0].bet_info).Bets;

        //     var extraEarn = "";
        //     final_Result.forEach((e1, i) => {

        //         if (i == 0) return;
        //         // if (refNo == "649663") {
        //         //     console.log(IsOpens, "e1111111111111111111");
        //         // }
        //         e1.IsBOG = isBogCheck[i - 1].IsBOG;
        //         e1.PotentialReturn = isBogCheck[i - 1].PotentialReturn;

        //         Odds_ = e1.Odds;
        //         var addcll = "ref_" + refNo;
        //         var resa = "." + addcll;

        //         var earnedBOG = 0;
        //         var Odds = (Odds_).split('-');

        //         var OddsEP = "";
        //         var PotentialReturn = 0;

        //         if (Odds.length == 2) {
        //             OddsEP = Odds_;
        //             PotentialReturn = (parseFloat(Odds[0] / Odds[1]) * parseFloat(winValue)) + parseFloat(winValue);
        //         }
        //         else if (Odds.length == 3) {
        //             var oddRes = (Odds_).split('EP');
        //             //console.log("inside_ep", oddRes);
        //             OddsEP = oddRes[0];
        //             Odds = (OddsEP).split('-');
        //             PotentialReturn = (parseFloat(Odds[0] / Odds[1]) * parseFloat(winValue)) + parseFloat(winValue);
        //         }
        //         else if (Odds.length == 1) {
        //             var oddRes = (Odds_).split('EP');
        //             OddsEP = oddRes[0];
        //             PotentialReturn = e1.PotentialReturn;
        //         }

        //         earnedBOG = parseFloat(CreditAmount) - (parseFloat(PotentialReturn))
        //         console.log(earnedBOG,"step2");
        //         if (earnedBOG.toFixed(2) != 0 && IsOpens != true && e1.IsBOG == true)

        //             extraEarn += '<div class="col-md-6 bog_strip_inner"><span class="bog_span show transaction flipInX animated">BOG</span><span class="extraEarn">You won £' + earnedBOG.toFixed(2).toString().replace('-', '') + ' extra with our best price guarantee.</span></div>';
        //         $(resa).html(extraEarn);
        //     })

        // });
      });
  }

  GetDetails(officeNo, refNo) {
    const _this = this;

    Api.getWagerInfo(officeNo, refNo, (res) => {
      var final_Result = res.Wagers;
      Api.betSlipTicketInfo(refNo, (betres) => {
        var isBogCheck = JSON.parse(betres.data[0].bet_info).Bets;
        final_Result.forEach((bet, index) => {
          if (index != 0) {
            bet.IsBOG = isBogCheck[index - 1].IsBOG;
            bet.PotentialReturn = isBogCheck[index - 1].PotentialReturn;
          }
        });
      });
      setTimeout(function () {
        _this.setState({
          WinDetails: res.Wagers.shift().WagerText,
          //Summary: res.Wagers
          Summary: final_Result,
        });
      }, 800);
    });
  }

  toggleCompactView = () => {
    let checked = localStorage.getItem('compact-view')
    checked = checked == '1' ? '0' : '1'
    localStorage.setItem('compact-view', checked)
    this.setState(
      {
        ...this.state,
        compactView: checked,
      });
  };

  render() {
    const newValue = this.state.WinDetails.replace("&euro;", "");
    const content = (
      <div>
        {/*  /> */}
        <div className="my-account">
          <div className="container">
            <AccountSidebar />
            <div className="account-right-container">
              <div className="transaction">
                <div className="acount-inner">
                  <h3>Account Overview</h3>
                </div>

                <div className="balance-outer">
                  <div className="col-md-4">
                    <div className="balance-left">
                      <img src="/images/Account_Balance_Wallet_Icon_2.png" />
                      <div className="balance-inner">
                        <label>Current Balance</label>
                        {/* <span>{this.state.funddetail.Balance}</span> */}
                        {this.props.AccountStore.state.CustomInfo.funddetail !=
                          undefined ? (
                          <span>
                            {
                              this.props.AccountStore.state.CustomInfo
                                .funddetail.Balance
                            }
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="balance-left credit">
                      <img src="/images/Credit_Card_Multiple_Icon_1.png" />
                      <div className="balance-inner">
                        <label>Available Credit</label>
                        {/* <span>{this.state.funddetail.AvailableFunds}</span> */}
                        {this.props.AccountStore.state.CustomInfo.funddetail !=
                          undefined ? (
                          <span>
                            {
                              this.props.AccountStore.state.CustomInfo
                                .funddetail.AvailableFunds
                            }
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="balance-right"></div>
                  </div>

                  <div className="col-md-8 text-center">
                    <h2>Download the Geoff Banks App for Android and IOS</h2>
                    <p>
                      Make sure you have downloaded the latest version of the
                      Geoff Banks mobile app. The App has been improved based on
                      customer feedback and is the best way to bet with Geoff
                      Banks on the move. By downloading the Geoff Banks App you
                      will also be eligible for App only price boosts and
                      specials as well as being able to access the same markets
                      and specials as on our desktop site. Download the Geoff
                      Banks App from the Google Play Store or the Apple App
                      Store and log in with your usual account details.
                    </p>
                    <div className="text-center store-links">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks"
                        title="Android App"
                      >
                        <img src="/images/play-store-btn.png" />
                      </a>
                      <a
                        href="https://itunes.apple.com/gb/app/geoff-banks-betting/id881898186?mt=8"
                        title="IOS App"
                      >
                        <img src="/images/appstore-btn.png" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="trasaction-content">
                  <div className="transaction-heading">
                    <h3>Transactions</h3>
                  </div>

                  <div className="transaction-form">
                    <select
                      className="all-bets trans"
                      onChange={(e) => this.handleChange(e, "transaction")}
                    >
                      <option value="1">All Transactions</option>
                      <option value="2">All Bets</option>
                      <option value="3">Unsettle/Pending Bets</option>
                      <option value="11">Deposits</option>
                      <option value="9">Withdrawals</option>
                      <option value="10">Reversal Withdrawals</option>
                      <option value="15">
                        Deposits, Withdrawals, Reversals
                      </option>
                    </select>

                    <select
                      className="year trans"
                      onChange={(e) => this.handleChange(e, "duration")}
                    >
                      <option value="1">Last 24 Hours</option>
                      <option value="2">Last 2 Days</option>
                      <option value="3">Last 5 Days</option>
                      <option value="4">Last 7 Days</option>
                      <option value="5">Last 14 Days</option>
                      <option value="6">Last 30 Days</option>
                      <option value="7">Last 2 Months</option>
                      <option value="8">Last 3 Months</option>
                      <option value="9">Last 6 Months</option>
                      <option value="10">Last 9 Months</option>
                      <option value="11">Last 12 Months</option>
                    </select>
                    <a
                      onClick={(e) =>
                        this.viewStatement(
                          this.state.transaction,
                          this.state.duration
                        )
                      }
                    >
                      GO!
                    </a>
                    {/* Compact View */}
                    <div className="toggleVideoBtn compact-view">
                      <span>
                        <label className="enableStream">Compact View</label>
                        <button
                          type="button"
                          className={
                            this.state.compactView == '0' ? "btn btn-xs btn-toggle"
                              : "btn btn-xs btn-toggle active"
                          }
                          // data-toggle="button"
                          // aria-pressed={JSON.stringify(this.state.compactView)}
                          autocomplete="off"
                          onClick={(e) => this.toggleCompactView()}
                        >
                          <div className="handle"></div>
                        </button>
                      </span>
                    </div>
                    {/*  */}
                  </div>
                  <Shared.Loader Show={this.state.ShowLoader} />
                  <div id="addingBetLoader">
                    {this.state.hasContent ? (
                      <div>
                        {this.state.statement.Transactions.map(function (
                          transaction,
                          i
                        ) {
                          if (
                            transaction.TransactionSummary.RefNo != "" &&
                            transaction.TransactionSummary.RefNo != "0"
                          )
                            // if (transaction.TransactionSummary.RefNo == "642973") {
                            //     console.log(transaction.TransactionSummary, "transsummary");

                            //     console.log(transaction.TransactionSummary.WagerInfo, "transwagerinfo");
                            // }
                            return (
                              <div className="sections" key={i}>
                                {utility.TranHasDetails(transaction) ? (
                                  <div>
                                    <a
                                      onClick={(e) =>
                                        this.showDetail(transaction)
                                      }
                                      id={transaction.TransactionSummary.RefNo}
                                      className="section-clickable"
                                    >
                                      <div className="col-md-10">
                                        <TranSummary
                                          ShowDetail={this.showDetail}
                                          transaction={transaction}
                                        />
                                      </div>

                                      <div class="col-md-2 text-right">
                                        <TranTypeBox
                                          transaction={transaction}
                                        />
                                      </div>

                                      <div className="balance_inner">
                                        {this.state.compactView == '0' ? (
                                          <React.Fragment>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="balance_border">
                                                  <h3>
                                                    {
                                                      transaction
                                                        .TransactionSummary.date
                                                    }
                                                  </h3>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="balance_border">
                                                  <h3>
                                                    {transaction
                                                      .TransactionSummary
                                                      .DebitAmount != null &&
                                                      transaction
                                                        .TransactionSummary
                                                        .DebitAmount != 0 ? (
                                                      <span>
                                                        Stake: £{" "}
                                                        {
                                                          transaction
                                                            .TransactionSummary
                                                            .DebitAmount
                                                        }
                                                        <br />
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {transaction
                                                      .TransactionSummary
                                                      .HasProfit ? (
                                                      <span>
                                                        {utility.GetTranType(
                                                          transaction
                                                        )}
                                                        : £{" "}
                                                        {
                                                          transaction
                                                            .TransactionSummary
                                                            .CreditAmount
                                                        }
                                                        <br />
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div className="balance_border">
                                                  <h3>
                                                    <span>
                                                      Balance: £{" "}
                                                      {
                                                        transaction
                                                          .TransactionSummary
                                                          .Balance
                                                      }
                                                    </span>
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                            <TransactionBlock
                                              data={transaction.MainTransaction.Wagers}
                                            />
                                          </React.Fragment>
                                        ) : null}
                                      </div>

                                      {transaction.TransactionSummary
                                        .HasProfit &&
                                        this.state.IsEnablelive_transaction_bog ==
                                        "true" ? (
                                        <div
                                          className={
                                            "ref-" +
                                            transaction.MainTransaction.RefNo +
                                            " hide"
                                          }
                                        >
                                          {/* {this.GetBOGDetails(
                                            transaction.MainTransaction
                                              .OfficeNo,
                                            transaction.MainTransaction.RefNo,
                                            transaction.TransactionSummary
                                              .CreditAmount,
                                            transaction.TransactionSummary
                                              .DebitAmount,
                                            transaction.TransactionSummary
                                              .WagerInfo.Odds,
                                            transaction.TransactionSummary
                                              .IsOpen
                                          )} */}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {/* <div id={"bog-detail-"+""}> */}
                                      {/* <div className={"ref_" + transaction.MainTransaction.RefNo + " show"}></div> */}
                                      <div
                                        class={
                                          transaction.TransactionSummary
                                            .IsOpen &&
                                            transaction.TransactionSummary
                                              .HasProfit === true &&
                                            transaction.TransactionSummary
                                              .ResultAmount > 0 &&
                                            this.state.earn > 0
                                            ? "hide"
                                            : "extra-bog bog_strip"
                                        }
                                      >
                                        <span
                                          className={
                                            "ref-" +
                                            transaction.MainTransaction.RefNo +
                                            " hide"
                                          }
                                        >
                                          <span
                                            class={
                                              "ref_" +
                                              transaction.MainTransaction
                                                .RefNo +
                                              " bog_span show strip"
                                            }
                                          >
                                            {" "}
                                          </span>
                                        </span>
                                      </div>
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                        },
                          this)}
                            
                        {this.state.statement.Transactions.length <= 0 ? (
                          <div className="Not-Found">No Enteries Found ! </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TranDet
            data={this.state.transData}
            show={this.state.visible}
            hide={this.hideDetail}
            summary={this.state.Summary}
            newData={newValue}
          />
        </div>

        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default subscribe(Transactions, { AccountStore: AccountContainer });
