import React from 'react'
import { compose } from 'redux';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import SwiperContainer from '../Component/SwiperContainer';
import LeftContainer from '../Component/LeftContainer';
import RightContainer from '../Component/RightContainer';
import RacesList from "../Component/RacesList";
import * as Api from '../js/api-service';
import * as BetSlip_v1_Provider from '../js/bet-slip';
import * as Utility from '../js/utility';

class SingleEventResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Event: { Results: [], Results1: [] },
        }
    }


    componentDidMount() {
        
        var that = this;
        Utility.ShowDataLoader("loaderContainer");
        const params = new URLSearchParams(that.props.location.search);
        const ef = params.get('eventfile');
        const eg = params.get('EventGroup');
        const ev = params.get('Events');

        Api.GetSingleEvent(ef, eg, ev, event => {
            var eventData = [];
            if (typeof event != "undefined") {
                eventData = event;
            }
            Utility.HideDataLoader("loaderContainer");
            that.setState({ Event: eventData });
        })

    }

    render() {
        var content = (
            <div>
                <div className="homepage">
                    <div className="container">
                        <div className="content-section-inner">

                            <LeftContainer />
                            <div className="center-container">
                                <div className="race-card" id="loaderContainer" >
                                    <div className="race-card-info-header">
                                    <h3>
                                    <img
                                        src="/images/9-dots.png"
                                        data-toggle="collapse"
                                        data-target="#otherRaces"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                        className="dotsImg"
                                    />
                                    {/* <i className="fa fa-chevron-down"></i> */}
                                    {this.state.Event.MeetingName} , {this.state.Event.EventName}
                                    </h3>
                                    </div>
                                    <div className="show-events">
                                        <RacesList/>
                                    </div>
                                    <div className="race-card-result">
                                        <div className='race-participant-header'>
                                            <div className="col-md-4"><h3>Rank</h3></div>
                                            <div className="col-md-4"><h3>Runner</h3></div>
                                            <div className="col-md-4"><h3>{this.state.Event.PType}</h3></div>
                                        </div>



                                        <div className="race-card-body">
                                            {this.state.Event.Results.map(function (result, i) {
                                                return (
                                                    <div className="race-card-row result-row" key={i}>
                                                        <div className="col-md-4"><label>{result.ResultPlace}</label></div>
                                                        <div className="col-md-4">{result.ResultText}</div>
                                                        <div className="col-md-4">{result.Odds}</div>
                                                    </div>
                                                )
                                            }, this)
                                            }

                                            {this.state.Event.Results1.map(function (result1, j) {
                                                return (
                                                    <div className="race-card-row" key={j}>
                                                        <div className="col-md-4"><label>{result1.Name}</label></div>
                                                        <div className="col-md-4">{result1.Odds}</div>
                                                        <div className="col-md-4"></div>
                                                    </div>
                                                )
                                            }, this)
                                            }

                                            <div className="race-card-row" >
                                            <div className="col-md-4"><label>NRs</label></div>
                                                <div className="col-md-4">{this.state.Event.NRText}</div>
                                               
                                            </div>

                                            <div className="race-card-row" >
                                                <div className="col-md-4"><label>Favorite</label></div>
                                                <div className="col-md-4">{this.state.Event.Fav}</div>
                                            </div>

                                        </div>



                                    </div>
                                </div>
















                                <div className="show-events">
                                    {/* <div className="show-events-inner">

                                        <div className="show-events-heading">
                                            <h3>
                                                <i className="fa fa-chevron-down"></i>{this.state.Event.MeetingName}
                                                {this.state.Event.Events[0] && this.state.Event.Events[0].ToolTip ?
                                                    <span> - {this.state.Event.Events[0].ToolTip}</span> : ''
                                                }
                                            </h3>
                                        </div>

                                        <div>
                                            <div className="time-remaining">
                                                <div className="col-md-6">
                                                    <h3 className="event-name">{this.state.Event.EventName} - {this.state.Event.PlText}
                                                    </h3>
                                                </div>
                                                <div className="col-md-3 text-right">
                                                    <label>{this.state.Event.PType}</label>
                                                </div>
                                                <div className="col-md-3 text-right">
                                                </div>
                                            </div>

                                            {
                                                this.state.Event.Events.map(function (event, i) {
                                                    return (
                                                        <div className="list" key={i}>
                                                            <div className="col-md-3">
                                                                <label>{event.SelName}</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className='jockey-name'>{event.Jockey}</label>
                                                            </div>

                                                            <div className="col-md-3 text-right">
                                                                {event.price != "" && event.price ?
                                                                    <span className="cursor-pointer " onClick={(e) => BetSlip_v1_Provider.AddBet(e, event, event, event.selection)}> {event.price}</span>
                                                                    :
                                                                    ''
                                                                }
                                                            </div>

                                                            <div className="col-md-3 text-right">
                                                                {event.PriceLink1 != "NR" ?
                                                                    <span className="cursor-pointer " onClick={(e) => BetSlip_v1_Provider.addSpBet(event)}> SP</span>
                                                                    :
                                                                    <span>{event.PriceLink1}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }, this)
                                            }


                                        </div>

                                    </div> */}
                                </div>
                            </div>

                            <RightContainer />


                        </div>
                    </div>
                </div>

                <Footer />
            </div >
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}



export default SingleEventResult;