import React, { Component } from "react";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as Api from "../../js/api-service";
import * as utility from "../../js/utility";

import "./MyProfile.css";
import { AccountContainer } from "../../Containers/AccountContainer";
import subscribe from "unstated-subscribe-hoc";

class PaypalWithdrawal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowLoader: false,
      showSuccess: false,

      PaypalDetail: {
        amount: "",
        email: "",
        payerID: "",
        userId: "",
        requestStatus: "pending",
        addedOn: ""
      },
      funds: "",
      duplicateEmailExists: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    
    // utility.GotoHomepage(); // NO ACCESS TO THIS PAGE FOR NOW
    if (utility.checkAuth() != undefined) {
      utility.ShowLoading("Loader");

      // Api.GetUserDetailFromNewDB((res) => {
      Api.GetUserDetailFromDirectus((res) => {
        let userData = res["data"][0]
        Api.checkDuplicateEmail(userData.payer_email, (cb) => {
          var data = cb["data"];

          if (data.length == 0&&userData.payer_id!==null) {

            Api.checkDuplicatePayerID(userData.payer_id, cd => {

              let cdData = cd["data"];
              if (cdData.length == 0) {

                this.setState({
                  PaypalDetail: {
                    ...this.state.PaypalDetail,
                    email: userData.payer_email,
                    payerID: userData.payer_id,
                    userId: userData.username,
                    duplicateEmailExists: false,
                  },
                });
              } else {
                this.setState({ duplicateEmailExists: true })
              }

            });
          }
          else {
            if(userData.payer_id==null||userData.payer_id=="")return;
            this.setState({ duplicateEmailExists: true })
          }
        });
      });

      setTimeout(() => {
        let val =
          this.props.AccountStore.state.CustomInfo.funddetail != undefined
            ? this.props.AccountStore.state.CustomInfo.funddetail.AvailableFunds
            : "";

        let availableFunds = val != "" ? val.split("£")[1] : "00.00";
        if (availableFunds.includes(",")) {
          availableFunds = availableFunds.split(",");
          availableFunds = availableFunds[0] + availableFunds[1];
        } else {
          availableFunds = val.split("£")[1];
        }

        this.setState({ funds: parseFloat(availableFunds) });
      }, 3000);

      utility.HideLoading("Loader");
    } else {
      this.props.history.push("/");
      utility.ShowNotification(
        "Please Sign in to your account to access this page!"
      );
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    utility.ShowLoading("Loader");
    if (
      parseFloat(this.state.PaypalDetail.amount) <= 0 ||
      parseFloat(this.state.PaypalDetail.amount) > this.state.funds
    ) {
      utility.HideLoading("Loader");
      this.setState({ showSuccess: false });
      utility.ShowNotification("Amount Invalid!");
    } else {


      Api.saveWithdrawalReq(this.state.PaypalDetail, (ab) => {

        this.setState({ showSuccess: true });

        let obj={
          RequestID: ab[0],
          UserId: this.state.PaypalDetail.userId,
          Amount: this.state.PaypalDetail.amount,
          Status:  this.state.PaypalDetail.requestStatus,
          Date: new Date().toDateString()
        }
        utility.LoadWithfdrawalTemplate(obj, (template) => {
          Api.SendEmail("", utility.config.email, "", template);
          utility.HideLoading("Loader");
          this.setState({ showSuccess: true });
      });
        setTimeout(() => {
          this.setState({
            showSuccess: true,
            PaypalDetail: { ...this.state.PaypalDetail, amount: "" },
          });
        }, 3000);

      });


      // Api.GetPaypalData("http://localhost:4000/CreatePayout",this.state.PaypalDetail, (ab) => {

      // Api.GetPaypalData(
      //   Api.Reg_API_BASE_URL_V1 +
      //   "CreatePayout",
      //   this.state.PaypalDetail,
      //   (res) => {
      //     utility.HideLoading("Loader");

      //     if (res.status == "200") {
      //       this.setState({ showSuccess: true });
      //       setTimeout(() => {
      //         this.setState({
      //           showSuccess: false,
      //           PaypalDetail: { ...this.state.PaypalDetail, amount: "" },
      //         });
      //       }, 3000);
      //     } else {
      //       utility.ShowNotification(res.details);
      //     }
      //   }
      // );
    }
  }

  handleChange = (event) => {
    this.setState({
      PaypalDetail: {
        ...this.state.PaypalDetail,
        [event.target.id]: event.target.value,
      },
    });
  };
  handleCancel() {
    window.location.href = "/";
  }

  render() {
    return (
      <div>
        <div className="my-account">
          <div className="container">
            <AccountSidebar />
            <div className="account-right-container">
              <div className="sign-up user-detail myProfile">
                <div className="acount-inner">
                  <h3>Withdraw Funds Using PayPal</h3>
                </div>

                <div className="user-details myProfileForm " id="Loader">
                  {/* <Shared.Loader  Show={this.state.UserDetail.ShowLoader}  /> */}

                  {this.state.showSuccess == true ? (
                    <div className="successMessage bgBlack">
                      <img src={"../images/checkCircle.png"} alt="" />
                  <p>We have received your Paypal withdrawal request - this will be checked and processed promptly and sent to your email address - {this.state.PaypalDetail.email}</p>
                      <p>
                      Many thanks for your continuing support for Geoff Banks Online
                      </p>
                      <span>
                        FEEL FREE TO GET IN TOUCH WITH OUR SUPPORT TEAM IN CASE
                        YOU NEED FURTHER HELP ON THIS.
                      </span>
                    </div>
                  ) : (
                    
                      <div>
                        {this.state.duplicateEmailExists ? (
                          <div className="noPaypalData">
                            <div className="imgBlock">
                              <img
                                src="/images/geoff-icon.png"
                                width="60px"
                                alt=""
                              />
                              <img
                                src="/images/forwardArrows.png"
                                width="30px"
                                alt=""
                              />
                              <img
                                src="/images/paypalLogo.png"
                                width="50px"
                                alt=""
                              />
                            </div>

                            <div>
                              <img
                                src="/images/warningIcon.png"
                                className="warningIcon"
                                width="50px"
                                alt=""
                              ></img>
                            </div>
                            <p className="m0">
                              Our records show the same email{" "}
                              {this.state.PaypalDetail.email} is linked with
                              multiple accounts.
                          </p>
                            <p className="m0">
                              Please get in touch with our support team
                          </p>
                            <span id="info" className="text-for-info">
                              Every account on Geoff must have a unique email to
                              use this feature.
                          </span>
                          </div>

                        )
                      : (

                        this.state.PaypalDetail.payerID===null||this.state.PaypalDetail.payerID===""||this.state.PaypalDetail.payerID===undefined?
                      
                        <div className="noPaypalData">
                          <div className="imgBlock">
                            <img
                              src="/images/geoff-icon.png"
                              width="60px"
                              alt=""
                            />
                            <img
                              src="/images/forwardArrows.png"
                              width="30px"
                              alt=""
                            />
                            <img
                              src="/images/paypalLogo.png"
                              width="50px"
                              alt=""
                            />
                          </div>

                          <div>
                            <img
                              src="/images/warningIcon.png"
                              className="warningIcon"
                              width="50px"
                              alt=""
                            ></img>
                          </div>
                          <p className="m0">
                          Please deposit funds to your account using PayPal before you can use the same for withdrawal. Please note, only the same PayPal account can be used for deposits and withdrawals.
                        </p>
                        </div>
                        
                        :
                            <form
                              className="my-profile-form"
                              id="paypal-form"
                              onSubmit={this.handleSubmit}
                            >
                              <div id="formContent">
                                <div className="row">
                                  {/* <div className="col-md-12">
                                <div className="input-block">
                                  <label className="paypalEmail">
                                    PayPal Email
                                  </label>
                                  <p>{this.state.PaypalDetail.email}</p>
                                </div>
                              </div> */}
                                  <div className="col-md-6">
                                    <div className="input-block">
                                      <label className="amountTitle">
                                        ENTER AMOUNT TO WITHDRAW
                                  </label>
                                      <br />
                                      <input
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        step={"0.01"}
                                        min={1.0}
                                        max={this.state.funds}
                                        className="fadeIn"
                                        placeholder="Enter Amount"
                                        value={this.state.PaypalDetail.amount}
                                        onChange={this.handleChange}
                                        required
                                      />

                                      {this.props.AccountStore.state.CustomInfo
                                        .funddetail != undefined ? (
                                          <div id="info" className="text-for-info">
                                            You can withdraw maximum{" "}
                                            {
                                              this.props.AccountStore.state.CustomInfo
                                                .funddetail.AvailableFunds
                                            }{" "}
                                            based on your current balance.
                                    </div>
                                        ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div className="row1 m0">
                                  <input
                                    type="submit"
                                    className="fadeIn fourth submit sub"
                                    value="Proceed"
                                    id="save"
                                  />

                                  <input
                                    type="reset"
                                    className="fadeIn fourth submit button cancel"
                                    value="Cancel"
                                    onClick={this.handleCancel}
                                  />
                                </div>
                              </div>
                            </form>

                          )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default subscribe(PaypalWithdrawal, { AccountStore: AccountContainer });
