import * as $ from "jquery";
import _ from "lodash";
// import {
//   UtilityProvider
// } from "./utility";
import * as UtilityProvider from "./utility";
import { BetSlipContainer } from "../Containers/BetSlipContainer";

var base_url = "https://api.geoff-banks.com/Geoff/";
export const BaseUrl = base_url;
export function GetUpdateSlip() {
  var val = localStorage["UpdateSlip"];
  if (typeof val == "undefined" || val == null) val = false;
  return val;
}

export function StopUpdateSlip() {
  localStorage["UpdateSlip"] = false;
}
export function SetUpdateSlip() {
  // BetSlipContainer.UpdateBetSlip();
  // UtilityProvider.SetUpdateSlip();
}

function GenerateBasketId() {
  //GERERATE NEW GUID and SAVE IN LOCALSTORAGE
  var basketId = UtilityProvider.GenerateGuid();
  localStorage.setItem("BasketId", basketId);
  return basketId;
}

export function GetBaseketId() {
  //GET BASKETID FROM LOCALSTORATE
  var basketId = localStorage.getItem("BasketId");
  if (typeof basketId == undefined || basketId == null)
    basketId = GenerateBasketId();
  return basketId;
}

function GetUsername() {
  var username = localStorage.getItem("username");
  return username ? username : "";
}

function GetPassword() {
  var password = localStorage.getItem("password");
  return password ? password : "";
}

export function getStatements(filterType, filterRange, callback) {
  var data;
  data = {
    username: GetUsername(),
    password: GetPassword(),
    selFltType: "1",
    selFltRng: "5",
    Submit2: "Submit"
  };
  // var settings = {
  //   "async": true,
  //   "crossDomain": true,
  //   "url": "http://v0.geoff-banks.com/cashier/statement-api.asp?selFltType=" + filterType + "&selFltRng=" + filterRange + "&cust_ref=" + GetUsername(),
  //   "method": "POST",
  //   "headers": {
  //     "Content-Type": "application/x-www-form-urlencoded"
  //   }
  // }

  // 
  let base_1 = `https://api.geoff-banks.com/Geoff/`
  let base_2 = `https://api2.geoffbanks.bet/api/Payments/`
  // 

  var settings = {
    async: true,
    crossDomain: true,
    url:
      `${UtilityProvider.isUseProxyTransactionAPI()?base_2:base_1}Getstatement_v2/` +
      GetUsername() +
      "/" +
      filterType +
      "/" +
      filterRange,
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };

  $.ajax(settings)
    .done(function(response) {
      callback(UtilityProvider.isUseProxyTransactionAPI()?response.data:response);
    })
    .fail(function(err) {
    })
    .always(function() {
    });
}

export function ResetBasket() {
  localStorage.setItem("BasketId", null);
}

export function GetBetsCount() {
  return UtilityProvider.GetBetsCount();
}

export function SaveBetsCount(count) {
  $(".mob-notify-bet").text(count);
  localStorage.setItem("BetCount", count);
}

export function GetBetSlipData(callback) {
  $.get(
    "https://v0.geoff-banks.com/events/bettingslip-api.asp?basketId=" +
      GetBaseketId(),
    data => {
      callback(data);
    }
  );
}

export function SetBetSlipExpiry(count) {
  if (count == 1) {
    //CHECK IF WE HAVE ONLY 1 BET IN THERE WE CAN SET EXPIRY WHEN IS FIRST BET
    //THIS LINE DECIDES WHEN TO EXPIRE BETSLIP
    UtilityProvider.SetCookieMins("FreshBetSlip", "1", 20);
  }
}

export function PlaceBet(wagers, callback,error) {
  var that = this;
  //USE THE CURRENT BASKET TO PLACE THE FINAL BET
  var username = GetUsername();
  var password = GetPassword();
  var data = _.merge.apply(null, [{}].concat(wagers));
  data["act"] = "bet";
  var settings = {
    async: true,
    crossDomain: true,
    url:
      "https://v0.geoff-banks.com/events/bettingslip-api.asp?username=" +
      username +
      "&password=" +
      password +
      "&basketId=" +
      GetBaseketId(),
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: data
  };

  $.ajax(settings).done(function(response) {
    callback(response);
    localStorage.removeItem("BetSlipBox");
    // We need to empty the basket and generate new GUID here
    // that.GenerateBasketId();
  }).fail(function(err) {
    error(err)
  });
}
