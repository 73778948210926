import React from 'react';
import './style.css';

export const BetSlipTopPanel = (props) => {
  const { isBetslipLoaded, isLoading, removeAll, betslip } = props;

  const renderRemoveAll = () => {
    if (isBetslipLoaded && !isLoading) {
      return (
        <button
          className="bet-remove-all"
          type="button"
          onClick={() => removeAll()}
        >
          <i className="fa fa-trash-o bet-remove-all-trash" aria-hidden="true" />
          Remove All
        </button>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="bet-top-actions">
        <div className="bet-title-section">
          <h3 className="betslip-title">Bet slip</h3>
        </div>
        {betslip.bets.length > 0 ? (
          <div className="bet-button-section">
            { renderRemoveAll() }
          </div>
        ) : null}
        <div className="bet-faq-section">
          <i className="fa fa-question-circle"/>
        </div>
      </div>
    </div>
  );
}