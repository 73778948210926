import React from "react";
import * as Api from "../js/api-service";
import * as Util from "../js/utility";
import InfoBar from "./Shared/InfoBar";
import _ from "lodash";

let isComplete = true;
let liveStreamInterval;

export default class VideoStream extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            streamSrc: "",
            streamLoc: "",
            SisStreamId: "",
            streamDesc: "",
            streamVenue: "",
            events: [],
            selectedEvent: 0,
            selectedRMGEvent: 0,
            toggleStream: true,
            selectedStream: Util.GetSelectedStream(),
            streamOptions: true,
            AtrEvents: [],
            RmgCurrentEvents: [],
            RmgAllEvents: "",
            RMGstreamSrc: "",
            displayEvent: true,
            singleHorseRaceRMG: [],
            showRMGDropdown: true,
            streamTypes: [
                {id: "TRP", name: "AtTheRaces"},
                {id: "SD365", name: "Racing UK"},
                {id: "SIS", name: "SIS Stream"},
            ],
            horseJSON: [],
            greyhoundJSON: [],
            SISAllEvents: [],
            SISCurrentEvents: [],
            selectedSISEvent: "",
            SISstreamSrc: "",
            showSISDropdown: true,
        };
    }

    componentWillReceiveProps(newProps) {
        if (!Util.IsCustomerLoggedIn()) return;
        this.LoadFromProps(newProps);
        if (newProps.sportcode == "g,q") {
            this.setState({
                streamTypes: [
                    {id: "TRP", name: "AtTheRaces"},
                    {id: "SIS", name: "SIS Stream"},
                ]
            })
        }
    }

    LoadFromProps(props) {
        this.getEvent();

        console.log(props.singleEvent, props, "SINGLE EVENTS");

        console.log("props.sportcod>>>>>",props.sportcode)

        let stream = props.sportcode !== undefined &&
         (props.sportcode.includes("g,q") ||
            props.sportcode.includes("vh")||
            props.sportcode.includes("$")
         ) ? "SIS" : Util.GetSelectedStream();


        if (
            props.singleEvent !== undefined &&
            props.singleEvent
        ) {
            if (props.eventName !== null && props.eventName.includes("SD365"))
                stream = "SIS";
            if (props.eventFile !== null && props.eventFile.includes("BFTRP"))
                stream = "TRP";
            if (props.eventFile !== null && props.eventFile.includes("HGB00"))
                stream = "SD365";
        }

        if(props.sportcode.includes("vh")||props.sportcode.includes("$")){
            stream = "SIS"  //Show SIS stream on virual horse or greypund page
        }

        this.setState({selectedStream: stream});
    }

    componentDidMount = () => {
        console.log("HI::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::")
        this.LoadFromProps(this.props);
        //if (this.props.sportcode === "vh" || this.props.sportcode === "$") return; //Show SIS stream for virtual races (https://nextpage.nifty.pm/l/Hf0ztyZT2aAaoL)

        this.enableStream();
        this.getJsonFile(); //ADDING RACE CONTENT IDs OF HORSE RACING GREYHOUND RACING


        if (Util.IsCustomerLoggedIn())
            this.playStream(); //FOR INSTANT LOAD ON MOUNT ONCE
        let that = this;
        liveStreamInterval = setInterval(() => {
            if (!Util.IsCustomerLoggedIn()) return;
            that.playStream();
        }, 2000);
    };

    //race-content.json page for content type Ids of horse and greyhound racing
    getJsonFile() {
        fetch(`/race-content.json`)
            .then((r) => r.json())
            .then((myJson) => {
                this.setState({
                    horseJSON: myJson.HorseRacing,
                    greyhoundJSON: myJson.GreyHound,
                }); //Setting json data of each race.
            });
    }

    enableStream() {
        let togVal = localStorage.getItem("toggleStream");
        if (togVal == null) togVal = this.state.toggleStream;

        let togValBoolean = togVal == "true" || togVal == true; //IN CASE YOU NEED TO CONVERT STRING TO BOOLEAN
        this.setState({
            toggleStream: togValBoolean,
            streamOptions: togValBoolean,
        });
        localStorage.setItem("toggleStream", togValBoolean);
        console.log(
            this.state.streamOptions,
            this.state.toggleStream,
            togValBoolean,
            togVal,
            "STREAM OPTS"
        );
    }

    getEventList() {
        isComplete = false;
        let that = this;
        if (this.state.selectedStream === "SD365") {
            Api.getRMGEventList((cb) => {
                console.log(cb, "RMG EVENTS VIDEO");
                isComplete = true;
                // let evt = cb.CurrentEvents;
                that.setState({
                    RmgAllEvents: cb.Events ? cb.Events : [], //Set empty array if undefined
                    RmgCurrentEvents: cb.CurrentEvents ? cb.CurrentEvents : [],  //Set empty array if undefined
                });
            });
        } else if (this.state.selectedStream === "TRP") {
            Api.getStreamEvents((cb) => {
                isComplete = true;
                console.log(cb, "ATR EVENTS VIDEO");

                let data = cb;
                let sportEvents = [];

                //CHECKING HERE FOR PAGE/EVENT BASED ON WHICH WE COMPARE THE CONTENT ID OF HORSE RACING AND GREYHOUND AND SET SPORT EVENTS.
                if (that.props.sportcode == "H,h") {
                    sportEvents = _.filter(data, (e) =>
                        _.some(that.state.horseJSON, (h) => {
                            if (parseInt(e.ContentTypeID) === parseInt(h)) return e;
                        })
                    );
                } else if (that.props.sportcode == "g,q") {
                    sportEvents = _.filter(data, (event) =>
                        _.some(that.state.greyhoundJSON, (h) => {
                            if (parseInt(event.ContentTypeID) === parseInt(h)) return event;
                        })
                    );
                }
                // if(sportEvents.length==0&&this.props.sportcode!==undefined) data=[];
                if (that.props.sportcode !== undefined) {
                    if (sportEvents.length > 0) data = sportEvents;
                    //RETURNING SPORTS EVENTS BASED ON SPORT AVAILABLE.
                    else data = [];
                }

                that.setState({AtrEvents: data});
            });
        } else if (that.state.selectedStream === "SIS") {
            Api.getSISEvents(this.props.sportcode,(cb) => {
                isComplete = true;
                let data = cb.CurrentEvents.length === 0 ? cb.Events : cb.CurrentEvents;
                let sportEvents = [];
                console.log(
                    data,
                    that.props.eventtime,
                    that.props.sportcode,
                    data,
                    "SIS EVENTS"
                );
                if (that.props.sportcode === "H,h") {
                    sportEvents = _.filter(data, (e) => {
                        if (e.sportName === "Horse Racing" || e.sportName === "Other")
                            return e;
                    });
                } else if (that.props.sportcode === "g,q") {
                    sportEvents = _.filter(data, (event) => {
                        if (event.sportName === "Greyhounds" || event.sportName === "Other")
                            return event;
                    });
                }
                if (that.props.sportcode !== undefined) {
                    if (sportEvents.length > 0) data = sportEvents;
                    else data = [];
                }
                that.setState({SISAllEvents: cb.Events, SISCurrentEvents: data});
            });
        }
    }

    componentWillUnmount() {
        clearInterval(liveStreamInterval);
    }

    playStream() {
        console.log(this.state.selectedStream, "STREAM NEAH")
        let sportcode = ""
        if (this.props.sportcode == undefined) {
            sportcode = ""
        } else {
            sportcode = this.props.sportcode
        }
        // console.log(this.props, sportcode, "Stream")
        if (localStorage.getItem("toggleStream") === "true") {
            this.getEventList();
            if (this.state.selectedStream === "TRP") {
                // console.log("Stream 1")
                this.getEvent();
            } else if (this.state.selectedStream === "SD365" && !sportcode.includes("g,q")) {
                // console.log("Stream 2")
                this.getRMGEvent();
            } else {
                // console.log("Stream 3")
                this.getSIS();
            }
        }
    }

    //RMG EVENTS FUNCTION
    async getRMGEvent() {
        let evt = await this.state.RmgCurrentEvents;

        //FOR SINGLE HORSE RACE EVENTS
        if (
            typeof this.props.locationname !== "undefined" &&
            typeof this.props.eventtime !== "undefined"
        ) {
            let singleHorseRace = _.filter(this.state.RmgAllEvents, (r) => {
                const rmgLocationName = r.location;
                const rmgEventTime = r.description.split(" ")[1];
                return (
                    this.props.locationname.toLowerCase() ===
                    rmgLocationName.toLowerCase() &&
                    this.props.eventtime === rmgEventTime &&
                    this.props.sportcode === "H,h"
                );
            });

            evt = singleHorseRace;
            this.setState({
                singleHorseRaceRMG: singleHorseRace,
                showRMGDropdown: false,
            });

            if (singleHorseRace.length <= 0) {
                return;
            }
        }

        if (evt.length <= 0) {
            if (this.state.RmgAllEvents.length === 0) {
                this.setState({selectedStream: "SD365"});
                return;
            } else {
                evt = await this.state.RmgAllEvents;
            }
        }

        console.log(evt, "RMG EVENT VIDEO");
        let selectedEvent = evt[0];
        console.log(
            this.state.RmgCurrentEvents,
            this.state.RmgAllEvents,
            "EVENT VIDEO"
        );

        if (this.state.selectedRMGEvent.length > 0) {
            selectedEvent = _.find(evt, (e) => {
                return e.id == parseInt(this.state.selectedRMGEvent);
            });
            if (typeof selectedEvent == "undefined") {
                this.setState({selectedRMGEvent: 0});
                selectedEvent = evt[0];
            }
        }

        if (typeof selectedEvent !== "undefined") {
            const data = {
                platform: "Desktop",
                eventID: selectedEvent.id,
                userID: Util.User().username,
            };
            Api.getRMGStream(data, (stream) => {
                const video_url = stream;

                console.log(video_url, "VIDEO URL RMG");
                this.setState({
                    RMGstreamSrc: video_url,
                });
            });
        }
    }

    getEvent() {
        // isComplete = false;

        // Api.getStreamEvents((cb) => {
        //   isComplete = true;
        //   console.log(cb, "ATR EVENTS VIDEO");

        //   this.setState({ events: cb, AtrEvents: cb });
        let cb = this.state.AtrEvents;
        console.log(cb, "STREAM ATR")
        if (cb.length === 0) {
            this.props.sportcode !== undefined && this.props.sportcode.includes("g,q") ?
                this.setState({selectedStream: "SIS"}) :
                this.setState({selectedStream: "SD365"});
            return;
        }
        let selectedEvent = cb[0];

        //CHECK IF USER HAS SELECTED A DIFFERENT VALUE
        if (this.state.selectedEvent.length > 0) {
            selectedEvent = _.find(cb, {ID: parseInt(this.state.selectedEvent)});
            if (typeof selectedEvent == "undefined") {
                this.setState({selectedEvent: 0});
                selectedEvent = cb[0];
            }
        }

        let val =
            `${Util.partnerCode}:${selectedEvent.ID}:L:${Util.User().username}:${Util.seedKey}`;
        let valMd5 = Util.GetMD5(val);

        const video_url =
            `https://bw197.attheraces.com/ps/player/default.aspx?partnercode=pkgc197&eventid=${selectedEvent.ID}&eventtype=live&userid=${Util.User().username}&key=${valMd5}`;

        this.setState({
            streamSrc: video_url,
            streamLoc: selectedEvent.Location,
            streamDesc: selectedEvent.Description,
            streamVenue: selectedEvent.VenueCode,
        });
        // });
    }

    async getSIS() {
        let evt = await this.state.SISCurrentEvents;
        if (evt.length <= 0 ) {
            if(this.props.sportcode == "vh" || this.props.sportcode =="$"){
                return
            }
            // evt= await this.state.SISAllEvents;
            this.setState({selectedStream: "TRP"});
            return;
        }
        // console.log(evt,"SIS EVENT");
        let selectedEvent = evt[0];
        // console.log(this.state.SISAllEvents,this.state.selectedSISEvent, "SIS EVENT");

        if (this.state.selectedSISEvent.length > 0) {
            selectedEvent = _.find(evt, (e) => {
                return e.streamId === this.state.selectedSISEvent;
            });
            if (selectedEvent === undefined) {
                this.setState({selectedSISEvent: ""});
                selectedEvent = evt[0];
            }
        }

        if (selectedEvent !== undefined) {
            const data = {
                streamId: selectedEvent.streamId,
                username: Util.User().username,
            };
            const prevVal = this.state.SisStreamId;

            Api.getSISStream(data, (stream) => {
                if (stream.error !== undefined && stream.error.length > 0) {
                    Util.ShowNotification(stream.error);
                }
                const video_url = stream.phenixEmbedUrl ? stream.phenixEmbedUrl : ''; //S
                console.log(video_url, "SIS STREAM URL");
                if (video_url === "") this.setState({selectedSISEvent: ""});
                if (prevVal !== stream.streamId) {
                    this.setState({
                        SISstreamSrc: video_url,
                        SisStreamId: stream.streamId
                    });
                }
            });
        }
    }

    switchEvent = (e) => {
        this.setState({selectedEvent: e.target.value});
    };

    switchRMGEvent = (e) => {
        this.setState({selectedRMGEvent: e.target.value});
    };

    switchSISEvent = (e) => {
        this.setState({selectedSISEvent: e.target.value});
    };

    switchStreaming = (e) => {
        this.setState({selectedStream: e.target.value});
        // if (this.props.singleEvent === undefined) {
        //   Util.SetSelectedStream(e.target.value);
        // }
    };

    toggleResult = (e) => {
        let togVal = localStorage.getItem("toggleStream");
        let togBool = togVal === "true";
        this.setState({toggleStream: !togBool, streamOptions: !togBool});
        localStorage.setItem("toggleStream", !togBool);
    };

    render() {
        // if (this.props.sportcode == "vh" || this.props.sportcode == "$") return ""; //Show SIS stream for virtual races (https://nextpage.nifty.pm/l/Hf0ztyZT2aAaoL)
        // else 
        if (!Util.IsCustomerLoggedIn() || !this.props.IsLogin)
            return (
                <InfoBar msg="Please login to your account to view live streams of games/races"/>
            );
        //NOT TO SHOW STREAM WHEN USER NOT LOGGED IN
        else if (
            this.state.AtrEvents.length <= 0 &&
            this.state.RmgCurrentEvents.length <= 0 &&
            this.state.RmgAllEvents.length <= 0 &&
            this.state.SISCurrentEvents.length <= 0 &&
            this.state.toggleStream
        )
            return (
                <InfoBar msg="Live event streaming will show here as soon as available."/>
            );

        return (
            <div>
                <div className="toggleVideoBtn">
                    {this.state.streamOptions  &&
                     (this.props.sportcode != "vh" ||
                     this.props.sportcode == "$")  ? (
                        <select
                            className="live-event-selector floatLeft"
                            onChange={this.switchStreaming}
                            value={this.state.selectedStream}
                        >
                            {this.state.streamTypes.map((stream) => {
                                return (
                                    <option key={stream.id} value={stream.id}>
                                        {stream.name}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        ""
                    )}

                    <label className="enableStream"> Show me the HD Stream </label>
                    <button
                        type="button"
                        className={
                            this.state.toggleStream == false
                                ? "btn btn-xs btn-toggle"
                                : "btn btn-xs btn-toggle active"
                        }
                        data-toggle="button"
                        aria-pressed="false"
                        autocomplete="off"
                        onClick={(e) => this.toggleResult()}
                    >
                        <div className="handle"></div>
                    </button>
                </div>

                {this.state.toggleStream == true ? (
                    <div className="live-stream-container">
                        {this.state.selectedStream == "TRP" ? (
                            <div>
                                {this.state.AtrEvents.length <= 0 ||
                                this.state.streamSrc.length <= 0 ? (
                                    <InfoBar msg="Live event streaming will show here as soon as available."/>
                                ) : (
                                    <div>
                                        <div id="loaderContainer">
                                            <iframe
                                                sandbox
                                                frameBorder="none"
                                                src={this.state.streamSrc}
                                                allow="autoplay"
                                                width="100%"
                                                height="400"
                                                className="border0"
                                            ></iframe>
                                        </div>
                                        <div class="video-stream-info animated flipInX">
                                            <div className="video-stream-info-left">
                                                <span className="live-pill animated pulse">LIVE</span>
                                                <span className="live-stream-location-name">
                          {" "}
                                                    {this.state.streamLoc}
                        </span>
                                            </div>
                                            <div className="video-stream-info-right">
                                                {this.state.AtrEvents.length > 1 ? (
                                                    <select
                                                        className="live-event-selector"
                                                        onChange={this.switchEvent}
                                                    >
                                                        {this.state.AtrEvents.map((evt, i) => {
                                                            return (
                                                                <option value={evt.ID}>
                                                                    {evt.Location}{" "}
                                                                    {Util.getATRTime(evt.StartDateTime)}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div class="geoff-icon-live-stream">
                                            <img src="images/logo.svg"></img>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : this.state.selectedStream == "SD365" ? (
                            <div className="rmgFrame">
                                {
                                    // this.state.RmgCurrentEvents.length <= 0 ||this.state.RmgAllEvents.length <= 0||
                                    this.state.RMGstreamSrc.length <= 0 ? (
                                        <InfoBar msg="Live event streaming will show here as soon as available."/>
                                    ) : (
                                        <div id="loaderContainer">
                                            {this.state.showRMGDropdown ? (
                                                <div className="rmgDropdown">
                                                    {this.state.RmgCurrentEvents.length > 1 ? (
                                                        <select
                                                            className="live-event-selector"
                                                            onChange={this.switchRMGEvent}
                                                        >
                                                            {this.state.RmgCurrentEvents.map((evt, i) => {
                                                                return (
                                                                    <option value={evt.id}>
                                                                        {evt.location}{" "}
                                                                        {Util.displayEventTime(evt.startDateTime)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) : null}

                                            <div>
                                                <iframe
                                                    id="RMGIframe"
                                                    sandbox
                                                    frameBorder="none"
                                                    src={this.state.RMGstreamSrc}
                                                    allow="autoplay"
                                                    width="600"
                                                    height="400"
                                                    className="border0"
                                                    scrolling="no"
                                                ></iframe>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <div className="rmgFrame">
                                {this.state.SISstreamSrc.length <= 0 ? (
                                    <InfoBar msg="Live event streaming will show here as soon as available."/>
                                ) : (
                                    <div id="loaderContainer">
                                        {this.state.showSISDropdown ? (
                                            <div className="rmgDropdown">
                                                {this.state.SISCurrentEvents.length > 1 ? (
                                                    <select
                                                        className="live-event-selector"
                                                        onChange={this.switchSISEvent}
                                                    >
                                                        {this.state.SISCurrentEvents.map((evt, i) => {
                                                            return (
                                                                <option value={evt.streamId}>
                                                                    {evt.competition == 'Live Races' || evt.competition == 'Buckingham' ?
                                                                        (evt.competition)
                                                                        :
                                                                        (evt.competition+" "+Util.displayEventTime(evt.startDate))
                                                                    }            
                                                                    {/* UK local time */}
                                                                    {/* {evt.competition}{" "}{Util.displayEventTime(evt.startDate)} */}
                                                                    {/* {Util.displayEventTime(evt.startDateUtc)} */}                                                                  
                                                                    
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : null}

                                        <div>
                                            <embed
                                                id="SISstream"
                                                key={this.state.SisStreamId}
                                                src={this.state.SISstreamSrc}
                                                allow="autoplay"
                                                width="600"
                                                height="400"
                                                className="border0"
                                                scrolling="no"
                                            ></embed>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}
