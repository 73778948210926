import { Subject } from 'rxjs';

const betSubject = new Subject();

const initialState = {
    bet: null,
};

let state = initialState;

export const betTransferStore = {
    init: () => betSubject.next(state),
    subscribe: (setState) => betSubject.subscribe(setState),
    addBet: (bet) => {
        state = {
            ...state,
            bet,
        };
        betSubject.next(state);
    },
    clearStore: () => {
        state = initialState;
        betSubject.next(state);
    },
    initialState,
};