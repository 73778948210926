import {
    Container
} from 'unstated'
import $ from 'jquery';
import * as Utility from "../js/utility";

var _ = require("lodash");

export class AccountContainer extends Container {
    state = {
        CustomInfo:[],
        Setting:[]
    };

    CustomInfo = () =>{
        this.setState({
            CustomInfo: Utility.GetCustomerInfo()
        });
    }

    GetSetting = () =>{
        Utility.getSetting(setting => {
            //console.log("setting",setting)
            this.setState({
                Setting: setting
            });            
        })        
    }



    
    

}