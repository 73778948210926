import React, { Component } from "react";
import { Alert, Modal } from "react-bootstrap";
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as Api from '../../js/api-service';
import * as utility from '../../js/utility';

// const passRegEx = RegExp("^[_A-z0-9]*((-|\s)*[_A-z0-9])*$")
const passRegEx = RegExp("^[_a-zA-Z0-9]*$")
export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Username: "",
            CurrentPassword: "",
            NewPassword: "",
            ConfirmPassword: "",
            Msg: '',
            isSubmit:false,
            errors: {
                NewPassword: "",
                ConfirmPassword: "",
            },
        };
    }
    componentDidMount() {
        

        // var fund = JSON.parse(localStorage.getItem('authorization'));
        // if (fund != undefined) {
        //     var cusInfo = utility.GetCustomerInfo();
        //     console.log("cusInfo---", cusInfo)
        //     if (cusInfo.Cust_Ref != undefined) {
        //         this.setState({Username: cusInfo.Cust_Ref})
        //     }
        // } else {
        //     this
        //         .props
        //         .history
        //         .push('/');
        // }

        if (utility.checkAuth() != undefined) {
            this.setState({Username:utility.getLocalStorage("username")})

    } else {
        this.props.history.push("/");
        utility.ShowNotification(
          "Please Sign in to your account to access this page!"
        );
      }

    }

    handleChange = event => {
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case "NewPassword":
            this.state.errors.NewPassword =
          value.length == 0
            ? "Password  is required"
            : !passRegEx.test(this.state.NewPassword)
                ? "No special character allowed "
                : (value.length < 8 || value.length > 12)
                  ? "Password must be min 8 character and max 12 characters"  
                  : "";
                  break
            case "ConfirmPassword":
                this.state.errors.ConfirmPassword =
                value.length == 0
                ? "Password  is required"
                : !passRegEx.test(this.state.ConfirmPassword)
                    ? "No special character allowed "
                    : (value.length < 8 || value.length > 12)
                        ? "Password must be min 8 character and max 12 characters"   
                        // : (this.state.ConfirmPassword !== this.state.NewPassword)
                        // ? "The new password and confirmed password do not match"   
                        : "";
                break;
            default:
            break;
        }

        this.setState({errors,
            [event.target.id]: event.target.value
        });

    }
    validateForm() {
        return this.state.Username.length > 0 && this.state.CurrentPassword.length > 0 && this.state.NewPassword.length && this.state.ConfirmPassword.length > 0;
    }
    validateField = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        return valid;
      };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isSubmit: true });

        var data = this.state;
        if(this.validateField(this.state.errors) ){
            utility.ShowLoading("Loader");
            Api.ChangePassword(data, resp => {
                this.setState({ Msg: resp.Message });
                utility.ShowNotification(resp.Message);
                if (resp.Message.toLowerCase().includes("updated")) {
                    localStorage["password"] = this.state.NewPassword;
                    this.setState({
                        Username: "",
                        CurrentPassword: "",
                        NewPassword: "",
                        ConfirmPassword: ""
                    })
                }
            }, err => {
            });
            utility.HideLoading("Loader");
        }
       
    }

    render() {
        return (
            <div>
                <div className="my-account">
                    <div className="container">
                        <AccountSidebar />
                        <div className="account-right-container">
                            <div className="sign-up changePassword">
                                <div className="acount-inner">
                                    <h3>Change Password</h3>
                                </div>

                                <div className="changePassForm" id="Loader">
                                    {/* {this.state.Msg
                                        ? <Alert bsStyle="success">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.Msg
                                                }}></div>
                                        </Alert>
                                        : ''
                                    } */}
                                    <form onSubmit={this.handleSubmit}>
                                        <div id="formContent">
                                            {/* <input
                                                type="text"
                                                id="Username"
                                                // readOnly
                                                className="fadeIn second"
                                                name="Username"
                                                placeholder="Username"
                                                value={this.state.Username}
                                                onChange={this.handleChange} /> */}

                                            <input
                                                type="password"
                                                id="CurrentPassword"
                                                className="fadeIn third"
                                                name="CurrentPassword"
                                                placeholder="Current Password"
                                                value={this.state.CurrentPassword}
                                                onChange={this.handleChange} />

                                            <input
                                                type="password"
                                                id="NewPassword"
                                                className="fadeIn third"
                                                name="NewPassword"
                                                placeholder="New Password"
                                                value={this.state.NewPassword}
                                                onChange={this.handleChange} />
                                                {this.state.errors.NewPassword.length > 0 &&
                                                    this.state.isSubmit && (
                                                        <span className="error">
                                                        {this.state.errors.NewPassword}
                                                        </span>
                                                )}


                                            <input
                                                type="password"
                                                id="ConfirmPassword"
                                                className="fadeIn third"
                                                name="ConfirmPassword"
                                                placeholder="Confirm Password"
                                                value={this.state.ConfirmPassword}
                                                onChange={this.handleChange} />

                                                {this.state.errors.ConfirmPassword.length > 0 &&
                                                    this.state.isSubmit && (
                                                        <span className="error">
                                                        {this.state.errors.ConfirmPassword}
                                                        </span>
                                                )}


                                            <div className="row1">
                                                <input
                                                    type="submit"
                                                    className="fadeIn fourth submit"
                                                    value="Submit"
                                                    disabled={!this.validateForm()} />
                                            </div>
                                            {/* <div id="info-password" className="text-for-info">Updating details feature under maintenance right now</div> */}
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        );
    }
}