import React from 'react'
import '../css/main.css'
import { compose } from 'redux';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Alert, Button } from "react-bootstrap";
import * as $ from "jquery";
import _ from "lodash";
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import SwiperContainer from '../Component/SwiperContainer';
import LeftContainer from '../Component/LeftContainer';
import RightContainer from '../Component/RightContainer';
import * as Api from '../js/api-service';
import * as betSlip from '../js/bet-slip';
import * as utility from '../js/utility';
import subscribe from 'unstated-subscribe-hoc'
import {BetSlipContainer} from '../Containers/BetSlipContainer';
class PlaceBet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            betDetail: '',
            bets: [],
            wagers: []
        }
        this.betData = '';
        this.AccountBalance = '';
        this.totalAmount = '';
    }

    componentDidMount() {
        this.LoadBetData();
        this.infos();

    }

    LoadBetData() {
        this.betData = this.props.location.state.data;

        // THIS BLOCK HELPS TESTING BY SAVING BETDATA IN LOCALSTORAGE AND KEEP LOADING
        // THIS VIEW
        if (typeof this.betData == "undefined" || this.betData == null || this.betData == '') {
            this.betData = JSON.parse(localStorage.getItem("PostBetData"));
            this.setState({ betDetail: this.betData, bets: this.betData.Bets });
        } else {
            localStorage.setItem("PostBetData", JSON.stringify(this.betData));
            this.setState({ betDetail: this.betData, bets: this.betData.Bets });
        }
        this.FixBetData();
    }

    FixBetData() {
        var goodWagers = [];
        if (this.betData != null) {
            this.betData.Wagers.forEach(wager => {
                //REMOVE EMPTY WAGERS WITH NO ERROR/RESULT
                if (typeof wager.ResultMessage != "undefined" && typeof wager.BetDetailAmt != "undefined") {
                    // if (typeof wager.BetDetailAmt == "undefined")   wager.BetDetailAmt = "0";
                    // //FIX MISSING AMOUNTS TO 0
                    wager.ResultMessage = (typeof wager.ResultMessage != "undefined")
                        ? this.FixMessage(wager.ResultMessage)
                        : "";
                    if (wager.ResultMessage.toLowerCase().indexOf("not placed") >= 0 || wager.ResultMessage.toLowerCase().indexOf("sorry") >= 0) {
                        wager.ResultClass = "error";
                        wager.BetDetailAmt = "0";
                    } else {
                        wager.ResultClass = "success";
                        wager.BetDetailAmt = wager
                            .BetDetailAmt
                            .replace("�", "");
                    }
                    goodWagers.push(wager);
                }
            });
            this.betData.Wagers = goodWagers;
            this.setState({ wagers: this.betData.Wagers })
        }
    }

    FixMessage(msg) {
        return msg.replace("()", "").replace("�", "").replace("Potential", "<br>Potential").replace("  ", " ");
    }

    infos() {
        if (this.betData.AccountBalance != undefined && this.betData.BetTypeSum != undefined) {
            this.AccountBalance = this.betData.AccountBalance.substring(7, this.betData.AccountBalance.length);
            this.setState({ accountBalance: this.AccountBalance })
            this.totalAmount = this.betData.BetTypeSum.substring(1, 5);
            this.setState({ totalBalance: this.totalAmount })
        }
    }

    home() {
        this.ForgetEverything();
        // need to work on this after close
        if (localStorage.getItem('view') != undefined) {
            window.location.href = localStorage.getItem('view');
        } else {
            this.props.history.push('/');
        }

    }

    ForgetEverything() {
        // betSlip.RemoveAll()
        //   .subscribe(res => {
        //     console.log(res, 'remove all')
        //   }, err => {
        //     console.log(err, 'err')
        //   }, () => {
        //     localStorage.removeItem('BetSlipCache');
        //     localStorage.removeItem('BasketId');
        //     localStorage.removeItem('BasketCountId');
        //     localStorage.removeItem('BetCount');
        //     betSlip.SaveBetsCount(0);
        //   });
        // betSlip.ResetBasket();
        // localStorage.setItem("PostBetData", null);;

        // utility.UpdateUserData(() => {});

        this.props.Store.RemoveAll(data => {
            localStorage.removeItem('BetSlipCache');
            localStorage.removeItem('BasketId');
            localStorage.removeItem('BasketCountId');
            localStorage.removeItem('BetCount');
            betSlip.SaveBetsCount(0);
        });
        betSlip.ResetBasket();
        localStorage.setItem("PostBetData", null);;
        utility.UpdateUserData(() => { });
    }

    render() {
        var content = (
            <div>

                <div className="content-section">
                    <LeftContainer />

                    <div className="content-section-center">

                        <div className="bets">
                            <b>You just placed bet for below matches</b>
                        </div>
                        {
                            this.state.bets.map(function (slip, i) {
                                return (
                                    <div className="row betsDetail" key={i}>
                                        <div className="col-sm-2">
                                            {i + 1}.
                                        </div>
                                        <div className="col-sm-10">
                                            {slip.WagerName} @ {slip.Price} {slip.NewPC}
                                        </div>
                                    </div>

                                )
                            }, this)
                        }

                        <div className="betAmountDetail">
                            <div className="betAmout" >
                                <b>Bet Amount Details</b>
                            </div>
                            {
                                this.state.wagers.map(function (wager, i) {
                                    return (
                                        <div className="row betsDetail" key={i}>
                                            <div className="col-sm-6">
                                                {wager.WagerTypeName}
                                                <span className="wagerType" >{wager.WType}</span>
                                                <br />
                                                <div dangerouslySetInnerHTML={{ __html: wager.ResultMessage }} className={'WagerResult ' + wager.ResultClass}></div>
                                            </div>
                                            <div className="col-sm-2"></div>
                                            <div className="col-sm-4">
                                                £{wager.BetDetailAmt}
                                            </div>
                                        </div>

                                    )
                                }, this)
                            }

                            <div className="row betsDetail">
                                <div className="col-sm-6">
                                    <b>Total Payment</b>
                                </div>
                                <div className="col-sm-2"></div>
                                <div className="col-sm-4">
                                    {this.state.totalBalance}
                                </div>
                            </div>
                            <div className="row betsDetail">
                                <div className="col-sm-6">
                                    <b>Current balance</b>
                                </div>
                                <div className="col-sm-2"></div>
                                <div className="col-sm-4">
                                    {this.state.betDetail.CustBalance}
                                </div>
                            </div>
                        </div>

                        <div className="closeMsg">
                            <Button bsStyle="info" onClick={(e) => this.home()}>Close Message</Button>
                        </div>

                    </div>
                    <RightContainer />

                    <Footer />

                </div> </div>
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

export default subscribe(PlaceBet, {Store: BetSlipContainer});
