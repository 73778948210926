import React, { useEffect, useRef, useState } from 'react';
import './style.css';

export const CustomModal = (props) => {
    const { handleClose } = props;

    const timerId = useRef(null);
    const countDownRef = useRef(10);
    const [countDown, setCountDown] = useState(10);

    useEffect(() => {
        // to fix saving partial countdown for the next dialog appearance
        if (timerId.current) {
            clearInterval(timerId.current);
        }

        timerId.current = setInterval(() => {
            if (countDownRef.current > 0) {
                countDownRef.current -= 1;
                setCountDown(countDownRef.current)
            }
        }, 1000);

        const cleanup = () => {
            if (timerId.current) {
                clearInterval(timerId.current);
            }
        };
        return cleanup;
    }, []);

    const closeModal = () => {
        handleClose();
    };
    
    return (
        <div> 
            <div className="bet-placed-backgound"></div>

            <div className="bet-placed-modal">
                <div className="bet-placed-modal-header">
                    <button
                        className="bet-placed-modal-close"
                        type="button"
                        onClick={closeModal}>
                        ×
                    </button>
                    <h4 className="bet-placed-modal-title">Bet Placed</h4>
                </div>

                <div className="bet-placed-modal-body">
                    { props.children }
                </div>

                <div className="bet-placed-modal-footer">
                    <p className="bet-placed-modal-footer-message">
                        This message will close in {countDown} second
                    </p>
                    <button
                        onClick={closeModal}
                        type="button"
                        className="bet-placed-modal-footer-button"
                    >
                        Close and continue
                    </button>
                </div>
            </div>
        </div>
    );
};