import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import * as $ from "jquery";
import _ from "lodash";
import * as Api from "../../js/api-service";
import * as Shared from "../../Component/Shared/Index";
import * as Utility from "../../js/utility";

//import { Link } from 'react-router-dom';

class MessageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showAlert: false,
      ShowLoader: false,
      modalTitle: "",
      userExists: false,
    };

    this.LoadFromProps = this.LoadFromProps.bind(this);
    this.close = this.close.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.LoadFromProps(newProps);
  }

  LoadFromProps(props) {
    this.setState({ modalTitle: props.name }, () => {
    });

    if (props.name === "30back") {
      this.checkUserClaim(Utility.User().username);
    }else{
      this.setState({userExists:false})
    }
  }

  componentDidMount = () => {
    var that = this;
    this.LoadFromProps(this.props);
  };

  validateForm = () => {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      !this.state.userExists
    );
  };

  checkUserClaim(username) {
    Api.get20backClaim(username, (res) => {
      var data = res.data;

      if (data.length != 0) {
        document.getElementById("claim-btn").disabled = true;
        $(".claimBtn").addClass("disabled");
        this.setState({ userExists: true });
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    $(".claimBtn").addClass("disabled");
    this.setState({ ShowLoader: true });
    var _this = this;
    let bodyOption = "UserName=" + _this.state.username + "&Password=" + _this.state.password;

    // Api.Login(bodyOption,(response) => {
    //     if (response.Status === "1") {
    //       _this.setState({ showAlert: false });
    //       _this.email(_this.state.username);
    //       //_this.props.onHide();
    //     } else if (response.Status === "0") {
    //       $(".claimBtn").removeClass("disabled");
    //       _this.setState({ showAlert: true, ShowLoader: false });
    //     }
    //   },
    //   (err) => {
    //   }
    // );

    Api.Login(bodyOption)
      .then((response) => {
        if (response.Status === "1") {
          _this.setState({ showAlert: false });
          _this.email(_this.state.username);
          //_this.props.onHide();
        } else if (response.Status === "0") {
          $(".claimBtn").removeClass("disabled");
          _this.setState({ showAlert: true, ShowLoader: false });
        }
      });

    // fetch("https://api.geoff-banks.com/Geoff/Login", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    //   },
    //   body: bodyOption,
    // })
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //     if (response.Status === "1") {
    //       _this.setState({ showAlert: false });
    //       _this.email(_this.state.username);
    //       //_this.props.onHide();
    //     } else if (response.Status === "0") {
    //       $(".claimBtn").removeClass("disabled");
    //       _this.setState({ showAlert: true, ShowLoader: false });
    //     }
    //     //_this.setState({ ShowLoader: false });
    //   })
    //   .catch(function (error) {
    //     console.log("err" + error);
    //   });
  };

  email = (username) => {
    var that = this;
    var Username = username;
    // var url = "https://www.geoffbanks.bet/email/geoff.php";
    var url = "https://www.v1.geoffbanks.bet/email/geoff.php?c=242";
    // var url = Utility.GetRoot() + 'email/geoff.php'
    var form = new FormData();
    form.append("username", Username);
    form.append("subject", this.state.modalTitle);

    if (this.state.modalTitle != "REG500") {
      var obj = {
        status: "published",
        username: Utility.getLowerCase(Username).trim(),
      };

      Api.postClaim(obj, (cb) => {
      });
    }
    else{
      this.setState({userExists:false})
    }
    var settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: "POST",
      headers: {},
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    };

    $.ajax(settings).done(function (response) {
      $(".claimBtn").removeClass("disabled");
      that.close();
      that.setState({ ShowLoader: false });
    });
  };

  close = () => {
    this.setState({
      username: "",
      password: "",
      showAlert: false,
      userExists: false,
    });

    this.props.onHide();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <Modal {...this.props} className="Reg-Modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* To claim your REG500 credit enter Username and Password */}
            To claim your {this.state.modalTitle} credit enter Username and
            Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showAlert == true ? (
            <Alert bsStyle="danger">
              You have entered an incorrect Username or Password. Please try
              again !
            </Alert>
          ) : (
            ""
          )}

          {this.state.userExists ? (
            <Alert bsStyle="danger">30back already claimed!</Alert>
          ) : null}

          <form onSubmit={this.handleSubmit}>
            <div id="formContent">
              <div className="InputField">
                <input
                  type="text"
                  id="username"
                  className="fadeIn second"
                  name="Username"
                  placeholder="Username"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
                <span className="envelopes">
                  <i className="fa fa-envelope"></i>
                </span>
              </div>

              <div className="InputField">
                <input
                  type="password"
                  id="password"
                  className="fadeIn third"
                  name="login"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <span className="lock">
                  <i className="fa fa-lock"></i>
                </span>
              </div>
              {/* <input
                                type="submit"
                                className="fadeIn fourth"
                                value="Login"
                                disabled={!this.validateForm()} /> */}
            </div>
          </form>
          <Shared.Loader Show={this.state.ShowLoader} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="claim-btn"
            onClick={this.handleSubmit}
            disabled={!this.validateForm()}
            className="closeBtn claimBtn"
          >
            Claim Credit
          </Button>
          <Button onClick={this.close} className="closeBtn">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MessageModal;
