import React from 'react';
import * as Api from '../js/api-service';
//import { Link } from 'react-router-dom';

class SwiperContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sports: [],
    }

  }

  componentDidMount() {

    this.setState({ sports: Api.GetSportsList() });
  }




  render() {
    return (
      <div className="resCarousel" data-items="2-4-5-6" data-interval="false" data-slide="1">
        <div className="resCarousel-inner">

          {
            this.state.sports.map(function (sport, i) {
            return (
              <div className="item" key={i}>
                <div className="tile resCarousel-images" >
                  <a href={sport.PathName}><img  src= {'/images/' + sport.Image} alt="sports" /></a>
                  <div className="res-detail">
                    <p>{sport.Name}</p>
                    {/* <b>40</b> */}
                  </div>

                </div>
              </div>)
          }, this)
          }

          {/* <div className="item">
          <div className="tile">
            <img src="/images/Baseball.png" alt="Baseball"/>

            <div className="res-detail">
              <p>Baseball</p>
              <b>1</b>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="tile">
            <img src="/images/Cricket.png" alt="Cricket"/>

            <div className="res-detail">
              <p>Cricket</p>
              <b>5</b>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="tile">
            <img src="/images/Football.png" alt="Football"/>

            <div className="res-detail">
              <p>Football</p>
              <b>6</b>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="tile">
            <img src="/images/Handball.png" alt="Handball"/>

            <div className="res-detail">
              <p>Handball</p>
              <b>1</b>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="tile">
            <img src="/images/Snooker.png" alt="Snooker"/>

            <div className="res-detail">
              <p>Snooker & Pool</p>
              <b>1</b>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="tile">
            <img src="/images/Tennis.png" alt="Tennis"/>

            <div className="res-detail">
              <p>Tennis</p>
              <b>22</b>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="tile">
            <img src="/images/volleyball.png" alt="Volleyball"/>

            <div className="res-detail">
              <p>Volleyball</p>
              <b>1</b>
            </div>
          </div>
        </div> */}

        </div>
        <button className='btn btn-default leftRs'> <span className="glyphicon glyphicon-menu-left"></span></button>
        <button className='btn btn-default rightRs'> <span className="glyphicon glyphicon-menu-right"></span></button>
      </div>

    )

  }
}
export default SwiperContainer;