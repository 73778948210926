import React from "react";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import * as Api from "../js/api-service";
import * as Utility from '../js/utility';

var data;
class ShowPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: {},
      data: {},
    };
  }

  componentDidMount() {
    
    Api.pageData(this.props.match.params.pageName, (cb) => {
      // let data = cb.data[0].content;
      // let title = cb.data[0].title
      // data = data.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')
      this.setState({ data: cb.data[0] });

    });

    // var id = 5;
    // Api.GetBanner(id, data => {
    //     console.log("bannn", data)
    //     this.setState({ banner: data })
    // }, err => {
    //     console.log("error", err)
    // })
  }

  render() {
    var content = (
      <div>
        <div className="homepage promo-pages">
          <div className="container">
            <div className="content-section-inner">
              <LeftContainer />
              <div className="right-content">
                {this.state.data.image != undefined ? (
                  <img src={this.state.data.image.data.full_url} width="100%" />
                ) : (
                  ""
                )}

                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: this.state.data.content }}
                ></div>
                {/* <div className="content">
                                    <ul>
                                        <li> To enter the offer you must state the Offer code ‘REG500’ when registering for a new Geoff Banks Account</li>
                                        <li>Geoff Banks will award up to 50 £10 real money bonuses for every £100 you generate in turnover, with each wager over 1/2 in odds (or cumulative) and each wager not exceeding £20 win or £10 each way. Only one wager per selection or event shall count towards your total.</li>
                                        <li>Each time you turn over £100 in qualifying wagers click the REG500 link on the homepage of the website and we will credit your account with your latest £10 real money bonus.</li>
                                        <li>After each successful real money bonus claim your turnover requirement will be reset to zero. It is therefore critical that you use the form below to claim your real money bonus in a timely matter. The reset of your turnover limit will only occur after you notify us.</li>
                                        <li>This offer cannot be used in conjunction with any other offers and real money bonuses shall not count towards our weekly rebate offer nor any other Geoff Banks promotions and offers.</li>
                                        <li>This offer is available to new Geoff Banks customers who have not held an account with Geoff Banks before and are residents of the UK ad meet all other registration requirements as stated in Geoff Banks Terms & Conditions</li>
                                        <li>Offer is limited to one per person, family, same or similar address, e-mail address, telephone number, same payment account (i.e. debit or credit card), mobile device (i.e. mobile or tablet), application download, shared computer (i.e. public library or workplace), IP address.</li>
                                        <li>Geoff Banks reserves the right to withdraw the offer from any customer and/or group of customers (i.e. those that may be linked through a referring website, messaging service, arbitrage alert service or betting system) at Geoff Banks' sole and absolute discretion.</li>
                                        <li>Geoff Banks reserves the right at its own discretion to vary, cancel, refuse or terminate the offer at any time without notice.</li>
                                        <li>  Geoff Banks Rules of Betting apply.</li>
                                    </ul>
                                </div>
                                <div className="foot">
                                    More information is available via support@geoff-banks.com
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default ShowPage;
