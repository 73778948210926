import React from 'react'
import '../css/main.css'
import { compose } from 'redux';
import * as $ from "jquery";
import _ from "lodash";
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LeftContainer from '../Component/LeftContainer';
import RightContainer from '../Component/RightContainer';
import BetSlipContent from '../Component/BetSlipContent';
import * as Api from '../js/api-service';
import * as betSlip from '../js/bet-slip';

class BetSlip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {
    }

    render() {
        var content = (
            <div>
                <div className="content-section">
                    <LeftContainer />

                    <div className="content-section-center">
                        <BetSlipContent externalData={this.props}/>

                        {/* {
                            this.state.slipEmpty ?
                                <div className="emptySlip">YOUR SLIP IS EMPTY </div>
                                :
                                <div>
                                    {
                                        this.state.placeBetButton ?
                                            <div className="balance">Account balance :{this.state.loginData.funddetail.Balance}</div> : ''
                                    }
                                    <div className="betSlip">BET SLIP</div>
                                    <div>
                                        <table className="table table-striped">
                                            <tbody>
                                                {this.state.betSlip.map(function (item, i) {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Detail} @ {item.BetPrice} {item.NewPC}</td>
                                                            <td><a onClick={() => this.RemoveBet(item.ItemId, i)}> <i className="fa fa-ban remove-bet"></i></a></td>
                                                        </tr>
                                                    )
                                                }, this)
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="closeMsg">
                                        <Button bsStyle="info" onClick={(e) => this.removeAllButton()}>REMOVE ALL</Button>
                                    </div>

                                    {this.state.wagers.length > 0 ?
                                        <div className="wagers">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="goodwood">
                                                        <th>  <b>WAGER TYPE </b>  </th>
                                                        <th> EACH WAY</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.wagers.map(function (wage, i) {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{wage.WagerTypeName}
                                                                    <br /> ( {wage.NoLines} LINES) </td>
                                                                {wage.EW === "" ?
                                                                    <td><input type="checkbox" onChange={(e) => this.checkbox(e.target, wage)} id="ew_{wage.id}" /> </td>
                                                                    :
                                                                    <td>WIN ONLY</td>
                                                                }
                                                                <td></td>
                                                                <td><input type='text' id={'inpColor' + i} data-wagerid={wage.id} readOnly className='textBox wager-input' onClick={(e) => this.SingleInput(e.target)} value="" /></td>
                                                            </tr>
                                                        )
                                                    }, this)
                                                    }
                                                </tbody>
                                            </table>
                                        </div> : ''
                                    }

                                    <div className="staking-container">
                                        {
                                            this.state.Alert == true ?
                                                <Alert bsStyle="danger">
                                                    Can't place empty bet!", "Please enter a wager value to proceed.
                                                </Alert>
                                                : ''
                                        }

                                        {this.state.placeBetButton ?
                                            <div className="bet-submit-container">
                                                <input name="Submit" type="button" id="submit-bets" value="PLACE BET" onClick={(e) => this.PlaceBet()} />
                                            </div>
                                            : ''
                                        }

                                        {this.state.custom ?
                                            <div className="staking-ladder" >
                                                <p style={{ color: 'white' }}>
                                                    Use staking ladder to input your amount. Click more than once to add to your stake!
                                                </p>
                                                <div className="ladderDiv">
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-sm-3 calAdd" onClick={(e) => this.add(e.target.innerText)}  >
                                                                5+
                                                            </div>
                                                            <div className="col-sm-3 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                10+
                                                            </div>
                                                            <div className="col-sm-3 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                25+
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-3 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                50+
                                                            </div>
                                                            <div className="col-sm-3 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                100+
                                                            </div>
                                                            <div className="col-sm-3 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                125+
                                                            </div>
                                                        </div>
                                                        <div className="row lastRow" >
                                                            <div className="col-sm-2 ">
                                                            </div>
                                                            <div className="col-sm-4 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                150+
                                                            </div>
                                                            <div className="col-sm-4 calAdd" onClick={(e) => this.add(e.target.innerText)} >
                                                                200+
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                        }

                                        <div className="num-pad-container">
                                            <div className="num-pad-inner">
                                                <div onClick={(e) => this.add(e.target.innerText)} className="calAdd col-sm-3">
                                                    5+
                                                </div>
                                                <div onClick={(e) => this.add(e.target.innerText)} className="calAdd col-sm-3">
                                                    10+
                                                </div>
                                                <div onClick={(e) => this.add(e.target.innerText)} className="calAdd col-sm-3">
                                                    25+
                                                </div>
                                                <div onClick={(e) => this.add(e.target.innerText)} className="calAdd col-sm-3">
                                                    50+
                                                </div>
                                            </div>
                                            <div className="num-pad-keypad">
                                                <div className="digits-container">
                                                    <div className="digits-left">
                                                        <div className="num-pad-inner">
                                                            <span className="pad-button" data-stack="7" onClick={(e) => this.addWageVal(e.target.innerText)}>7</span>
                                                            <span className="pad-button" data-stack="8" onClick={(e) => this.addWageVal(e.target.innerText)}>8</span>
                                                            <span className="pad-button" data-stack="9" onClick={(e) => this.addWageVal(e.target.innerText)}>9</span>
                                                        </div>
                                                        <div className="num-pad-inner">
                                                            <span className="pad-button" data-stack="4" onClick={(e) => this.addWageVal(e.target.innerText)}>4</span>
                                                            <span className="pad-button" data-stack="5" onClick={(e) => this.addWageVal(e.target.innerText)}>5</span>
                                                            <span className="pad-button" data-stack="6" onClick={(e) => this.addWageVal(e.target.innerText)}>6</span>
                                                        </div>
                                                        <div className="num-pad-inner">
                                                            <span className="pad-button" data-stack="1" onClick={(e) => this.addWageVal(e.target.innerText)}>1</span>
                                                            <span className="pad-button" data-stack="2" onClick={(e) => this.addWageVal(e.target.innerText)}>2</span>
                                                            <span className="pad-button" data-stack="3" onClick={(e) => this.addWageVal(e.target.innerText)}>3</span>
                                                        </div>
                                                        <div className="num-pad-inner">
                                                            <span className="pad-button pad-double-zero" data-stack="00" onClick={(e) => this.addWageVal(e.target.innerText)}>00</span>
                                                            <span className="pad-button" data-stack="0" onClick={(e) => this.addWageVal(e.target.innerText)}>0</span>
                                                            <span className="pad-button pad-dot" data-stack="." onClick={(e) => this.addWageVal(e.target.innerText)}>.</span>
                                                        </div>
                                                    </div>
                                                    <div className="digits-right" style={{ width: 25 + '%' }}>
                                                        <div className="pad-button pad-delete" onClick={(e) => this.Delete()} >Del</div>
                                                        <div className="pad-button pad-close" onClick={(e) => this.showCustomNumPad()}>Close</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                        } */}
                    </div>
                    <RightContainer />
                    <Footer />
                </div> </div>
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

export default BetSlip;