import React, {Component} from "react";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as Api from "../../js/api-service";
import * as utility from "../../js/utility";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";

let cardInitial={
  card_holder_name: "",
  card_holder_email:"",
  card_holder_phone: "",
  amount: "",
  card_number: "",
  card_expire_month: "",
  card_expire_year: "",
  account_number:"",
  card_security_code:"",
  card_holder_country_code: "GB",
  currency: "EUR",                
}
export default class PaymentGatway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usercards: [],
            selected_card: 'new',
            cardDetail: {
              ...cardInitial             
            },
            pattern: "****************",
            duplicateCardVal: "",
            showSuccess:false,
            disableUser:false,
            ShowVerificationDialog: false,
            acs_request_message: "",
            acs_url: "",
            transaction_id: ""
        };
    }

    FetchCard=async()=>{
      if(this.state.selected_card==='new'){
        this.setState({
          ...this.state,
          pattern: "****************",
          duplicateCardVal: "",
          disableUser: utility.User().username,
          cardDetail:{
            ...cardInitial,
            account_number: utility.User().username||''
          }
        })
        return
      }
      try{
        let result = await Api.UserCard(this.state.selected_card)
        console.log('result single card', result)
        if(result.data&&result.data.username){
          result = result.data
          this.setState({
            pattern: result.cardNumberLast4,
            duplicateCardVal: result.cardNumber,
            cardDetail: {
                card_holder_name: result.cardHolderName,
                card_holder_email:result.cardHolderEmail,
                card_holder_phone: result.cardHolderPhone,
                amount: 0,
                card_number: "",
                card_expire_month: result.expireMonth,
                card_expire_year: result.expireYear,
                account_number: result.username,
                card_security_code:"",
                card_holder_country_code: result.countryCode,
                currency: result.currency,                
            },
            disableUser:true
          });
        }
      }catch(err){
        console.log('error', err)
      }finally{}
    }

    FetchCards=async()=>{
      if(!utility.IsCustomerLoggedIn()) return
      try{
        let result = await Api.UserCards()
        console.log('result', result)
        if(result.data.items){
          this.setState({
            usercards: result.data.items
          })
        }else{
          throw result
        }
      }catch(err){
        console.log('error', err)
      }finally{}
    }

    HandlePaymentMethod=(evt)=>{
      let { value } = evt.target
      this.setState({ selected_card: value },()=>{this.FetchCard()})
    }

    componentDidMount() {
      // 
      this.FetchCards()
      // this.FetchCard()
      // 

let searchParam= new URLSearchParams(this.props.location.search||"")
// console.log('wolf', this.props.location.search, searchParam.get('status'))
if(searchParam.get('status')){
 
  if(searchParam.get('status') === 'true'){
    this.setState({showSuccess: true})
    setTimeout(() => {
      this.props.history.push("/");
    }, 5000);
  }

  utility.ShowNotification(searchParam.get('msg'));
}
//
        //if (utility.checkAuth() != undefined) {
        if(utility.IsCustomerLoggedIn()){
            //load the most recent one to prefill any info in form
            // Api.checkCardExists(utility.User().username, (cb) => {
            //   var data = cb.data[0]; //SORTED BY CREATED ON (DESCENDING). LATEST AT 0 INDEX
            //   if (cb.data.length != 0) {
            //     this.setState({
            //         pattern: utility
            //             .decrypt(data.card_number)
            //             .replace(/\d(?=\d{4})/g, "*"),
            //         duplicateCardVal: data.card_number,
            //         cardDetail: {
            //             card_holder_name: data.card_holder_name,
            //             card_holder_email:data.card_holder_email,
            //             card_holder_phone: data.card_holder_phone,
            //             amount: data.amount,
            //             card_number: "",
            //             card_expire_month: data.card_expire_month,
            //             card_expire_year: data.card_expire_year,
            //             account_number:utility.User().username,
            //             card_security_code:"",
            //             card_holder_country_code: "GB",
            //             currency: "EUR",                
            //         },
            //         disableUser:true
            //     });
            //   }else{
            //     this.setState({
            //       cardDetail: {
            //         ...this.state.cardDetail,
            //         account_number: utility.User().username,
            //       },
            //       disableUser:true
            //     });
            //   }
            // });
            
          this.setState({
            cardDetail: {
              ...this.state.cardDetail,
              account_number: utility.User().username,
            },
            disableUser:true
          });
            
          } else {
            console.log("Not logged in")
          }
       
    }

    handleChange = (event) => {
      if(event.target.id == "card_expire_month"){
        if(event.target.value.length>2 || parseInt(event.target.value) > 12){
          return
        }
      }
        this.setState({
            cardDetail: {
            ...this.state.cardDetail,
            [event.target.id]: event.target.value,
          },
        });
    };

    handleSubmit=(event)=>{
      console.log("innnnnnnn")
        event.preventDefault();
       
       
        utility.ShowLoading("Loader");

        if (this.state.cardDetail.card_number == "" && this.state.duplicateCardVal) {
          this.state.cardDetail.card_number = this.state.duplicateCardVal
          } else {
            console.log('wolf', this.state.cardDetail)
            if(!this.state.cardDetail.card_number||this.state.cardDetail.card_number.length<16){
              this.setState({ showSuccess: false });
              utility.HideLoading("Loader");
              utility.ShowNotification("Please fill in valid card number");
            return;
            }else{
              this.setState({
                cardDetail: {
                  ...this.state.cardDetail,
                  card_number: this.state.cardDetail.card_number,
                },
              });
            }
        }
        console.log("cardDetail>>>>",this.state.cardDetail)

        // var d = new Date();
        // // var month = (d.getMonth()+ 1);
        // var month = (d.getMonth());
        // var year = d.getFullYear();

        const expiryDate = new Date(this.state.cardDetail.card_expire_year + '-' + this.state.cardDetail.card_expire_month + '-01');
        if (expiryDate < new Date()) {
          this.setState({ showSuccess: false });
          utility.HideLoading("Loader");
          utility.ShowNotification("Expires on is invalid!");
          return;
        }


        // if ((this.state.cardDetail.card_expire_month <= month)||
        // (this.state.cardDetail.card_expire_month > 12) ||
        // (this.state.cardDetail.card_expire_year < year)) {
        //     this.setState({ showSuccess: false });
        //     utility.HideLoading("Loader");
        //     utility.ShowNotification("Expires on is invalid!");
        //     return;
        // }
       
        // if(!utility.checkAuth()){
        //   this.setState({ showSuccess: false });
        //   utility.HideLoading("Loader");
        //   utility.ShowNotification("Please login to continue");
        //   return;
        // }

        Api.PaymentCharge({...this.state.cardDetail, amount: (+this.state.cardDetail.amount * 100) + '', customer_id: utility.User().username},(res) => {
            if(res.status == 200 || res.status == 655){
              console.log(res, 'res')
                utility.HideLoading("Loader");
                utility.ShowNotification(res.message);  
                // this.setState({ showSuccess: true });  
                let cardDetail= this.state.cardDetail;
                let username = utility.User().username;
                //cardDetail.username = username != null ? username : "";
                // cardDetail.card_number = utility.encrypt(this.state.cardDetail.card_number)
                // delete cardDetail.card_security_code;//DO NOT SAVE CVV IN DIRECTUS
                // cardDetail.account_number=utility.encrypt(this.state.cardDetail.account_number)
                //cardDetail.account_number=this.state.cardDetail.account_number
                console.log("carddetail>>>>",cardDetail)
                // Api.saveCardDetail(cardDetail, (cb) => {
                //   console.log("Save card detail in directus")
                // });

                //
                console.log(res, "wolf")
                if(res.data && res.data.acs_request_message){
                  this.setState({
                    ShowVerificationDialog: true,
                    acs_request_message: res.data.acs_request_message,
                    acs_url: res.data.acs_url,
                    transaction_id: res.data.transaction_id
                  })
                }else{
                  // something went wrong
                }
                //
            } else{
                console.log(">err>>>>",res)
                if(res.message){
                    //this.setState({errorMsg:res.message })
                    utility.ShowNotification(res.message);
                }else if(res.responseJSON && res.responseJSON[""] && res.responseJSON[""][0]){
                    utility.ShowNotification(res.responseJSON[""][0])
                }else{
                    utility.ShowNotification("An error occurred .Please try again later")
                }
                utility.HideLoading("Loader");
            }
        });


    }

 

    render() {
        return (
            <div>
              <Modal show={this.state.ShowVerificationDialog} className="continue-to-payments animated faster slideInBottom">
              {/* test */}
                  <p>Please click below to complete the payment process</p>
                  <form action={this.state.acs_url} method="post">
                    <input type="hidden" name="PaReq" value={this.state.acs_request_message} />
                    <input type="hidden" name="TermUrl" value="https://api2.geoffbanks.bet/Payment/Process" />
                    <input type="hidden" name="MD" value={this.state.transaction_id} />
                    <input type="submit" value="Continue to 3DS verification"/>
                  </form>
                  <span
                  className="cancel"
                  onClick={()=>{
                    this.setState({
                      ShowVerificationDialog: false,
                      acs_request_message: "",
                      acs_url: "",
                      transaction_id: ""
                    })
                    utility.ShowNotification("Process Cancelled");
                  }
                  }
                    >Cancel</span>
              {/*  */}
              </Modal>
                <div className="my-account">
                    <div className="container">
                        <AccountSidebar/>
                        <div className="account-right-container">
                            <div className="sign-up user-detail changePassword">
                                <div className="acount-inner">
                                    <h3>Payment Gateway</h3>
                                </div>
                                {this.state.showSuccess?
                                    <div className="successMessage bgBlack">
                                        <img src={"../images/checkCircle.png"} alt="" />
                                        <p>Your request has been submitted.</p>
                                        <p>
                                        Many thanks for your continuing support for Geoff Banks
                                        Online
                                        </p>
                                        <span>
                                        FEEL FREE TO GET IN TOUCH WITH OUR SUPPORT TEAM IN CASE
                                        YOU NEED FURTHER HELP ON THIS.
                                        </span>
                                        <span className="redirect-mssg">You will be redirected to homepage in 5 seconds...</span>
                                    </div>
                                :
                                <div className="user-details changePassForm " id="Loader">
                                    {/* <Shared.Loader  Show={this.state.UserDetail.ShowLoader}  /> */}
                                    <div className="choose-card">
                                      <label>Pay using:</label>
                                      <select onChange={this.HandlePaymentMethod}>
                                        <option value={'new'} selected>Use New Card</option>
                                        {
                                          this.state.usercards.map((card)=>{
                                            return <option value={card.udid}>{card.cardNumber}</option>
                                          })
                                        }
                                      </select>
                                    </div>
                                    <form
                                        className="user-details-form"
                                        onSubmit={this.handleSubmit}
                                    >

                          <div id="manualForm">
                              <div className="fields1">
                                <div className="form-group amountField">
                                  <label>Username</label>
                                  <div>
                                    <InputMask
                                      className="maskField"
                                      // type="text"
                                      //mask="9999999999999999"
                                      // placeholder="**** **** **** ****"
                                      placeholder="Username"
                                      //maskChar={"*"}
                                      // alwaysShowMask={true}
                                      id="account_number"
                                      className="fadeIn "
                                      value={this.state.cardDetail.account_number}
                                      onChange={this.handleChange}
                                      autoComplete="new-password"
                                      readOnly={this.state.disableUser}
                                      required
                                    />
                                  </div>                                    
                                </div>
                           
                                <div className="form-group amountField">
                                  <label>Amount</label>
                                  <div>
                                    <input
                                      type="number"
                                      id="amount"
                                      name="amount"
                                      //step={"0.01"}
                                      //min={20}
                                      min={1}
                                      className="fadeIn"
                                      placeholder="Enter Amount"
                                      value={this.state.cardDetail.amount}
                                      onChange={this.handleChange}
                                      autoComplete="new-password"
                                      required
                                    />
                                  </div>
                                </div>


                          </div>

                        <div className="fields1">
                          <div className="form-group amountField">
                              <label>Card Holder Email</label>
                                <div>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        maxLength="50"
                                        id="card_holder_email"
                                        className="fadeIn"
                                        value={this.state.cardDetail.card_holder_email}
                                        onChange={this.handleChange}
                                        autoComplete="new-password"
                                        required
                                    />
                                </div>
                          </div>
                          <div className="form-group amountField">
                            <label>Card Holder Phone</label>
                            <div>
                              {/* <input
                                type="number"
                                placeholder="Phone Number"
                                id="card_holder_phone"
                                className="fadeIn"
                                pattern="[0-9]{10}"
                                value={this.state.cardDetail.card_holder_phone}
                                onChange={this.handleChange}
                                autoComplete="new-password"
                                required
                              /> */}
                              <InputMask
                              className="maskField"
                              mask="9999999999999"
                              maskChar={""}
                              // type="text"
                              placeholder="Phone Number"
                              id="card_holder_phone"
                              className="fadeIn "
                              value={this.state.cardDetail.card_holder_phone}
                              pattern={"^[0-9]{10,13}$"}
                              onChange={this.handleChange}
                              autoComplete="new-password"
                              required
                            />
                            </div>
                          </div>
                        </div>
                        <div className="fields1">
                          <div className="form-group cardnumField">
                            <label>Card Number</label>
                              <div>
                                <InputMask
                                  className="maskField"
                                  // type="text"
                                  mask="9999999999999999"
                                  // placeholder="**** **** **** ****"
                                  placeholder={this.state.pattern}
                                  maskChar={""}
                                  // alwaysShowMask={true}
                                  id="card_number"
                                  className="fadeIn "
                                  // pattern={"[0-9]{16}"}
                                  value={this.state.cardDetail.card_number}
                                  onChange={this.handleChange}
                                  onFocus={()=>{
                                    this.setState({
                                      pattern: " "
                                    })
                                  }}
                                  onBlur={()=>{
                                      this.setState({
                                        pattern: utility.IsCustomerLoggedIn() && this.state.duplicateCardVal ? this.state.duplicateCardVal
                                        .replace(/\d(?=\d{4})/g, "*") : "****************"
                                      })
                                  }}
                                  autoComplete="new-password"
                                />
                              </div>
                            </div>
                            <div className="form-group amountField">
                            <label>Card Holder Name</label>
                              <div>
                                  <input
                                      type="text"
                                      placeholder="Holder Name"
                                      maxLength="50"
                                      id="card_holder_name"
                                      className="fadeIn"
                                      value={this.state.cardDetail.card_holder_name}
                                      onChange={this.handleChange}
                                      autoComplete="new-password"
                                      required
                                  />
                              </div>    
                          </div>
                          </div>
                        <div className="fields1 card-fields">
                          <div className="form-group cardnumField card-cvv">
                            <label>CVV Number</label>
                            <div>
                            <InputMask
                              className="maskField"
                              mask="9999"
                              maskChar={""}
                              // type="text"
                              placeholder="Enter 3 digits"
                              id="card_security_code"
                              className="fadeIn "
                              value={this.state.cardDetail.card_security_code}
                              pattern={"^[0-9]{3,4}$"}
                              onChange={this.handleChange}
                              autoComplete="new-password"
                              required
                            />
                            </div>
                          </div>
                          
                          <div className="form-group cardnumField card-month">
                            <label>Card Expiry Month</label>
                            <div>
                            {/* <input
                                type="number"
                                max="12"
                                min="01"    
                                placeholder="Card Expire Month"
                                id="card_expire_month"
                                className="fadeIn"
                                value={this.state.cardDetail.card_expire_month}
                                onChange={this.handleChange}
                                autoComplete="new-password"
                                required
                              /> */}
                             <InputMask
                                  className="maskField"
                                  mask="99"
                                  maskChar={""}
                                  // type="text"
                                  //pattern={"^([1-9]|1[012])$"}
                                  pattern={"^[0-9]{2}$"}
                                  placeholder="MM"
                                  id="card_expire_month"
                                  className="fadeIn"
                                  value={this.state.cardDetail.card_expire_month}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                  required
                              />
                            </div>
                          </div>
                          <div className="form-group amountField card-year">
                            <label>Card Expiry Year</label>
                            <div>
                              <InputMask
                                  mask="9999"
                                  pattern={"[0-9]{4}"}
                                  maskChar={""}
                                  className="maskField"
                                  id="card_expire_year"
                                  placeholder="YYYY"
                                  className="fadeIn "
                                  value={this.state.cardDetail.card_expire_year}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                  required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row1">
                          <input
                            type="submit"
                            className="fadeIn fourth submit disabled"
                            value="Submit"
                          />
                          {/* <div className="msg light">Updating details feature under maintenance right now</div> */}
                        </div>
                      </div>
                                       
                                    </form>
                                </div>
                           
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}