import React from "react";
//import {Link} from 'react-router-dom';
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
import subscribe from "unstated-subscribe-hoc";
import {BetSlipContainer} from "../Containers/BetSlipContainer";
import {PreferenceContainer} from "../Containers/PreferenceContainer";
import Sports_EventList from "./Sports_EventList";
import CustomNotifications from "../Component/CustomNotifications";

var _ = require("lodash");
var sportName;
var EventName = "";
var autoReload;
var updatePrice

class ShowEvents extends React.Component {
    EventName;
    EventFile;
    EventGroup;

    constructor(props) {
        super(props);

        this.state = {
            Events1: [],
            Events: [],
            headerTitle: "",
            FullCardOptions: [],
            SportCode: "",
            ShowLoader: true,
            InPlayEvents: [],
            InPlayEventsUpper: [],
            eventName: "",
            showLoader: true
        };
    }

    componentDidMount() {
        
        // this.loadData((cb) => {});
        this.unlisten = this.props.history.listen((location, action) => {
            this.initialization();
        });

        this.initialization(true);
        const that = this;
        //UPDATE PRICE EVERY 5 SECONDS - https://nextpage.proofhub.com/bapplite/#app/todos/project-4734211540/list-147391440101/task-166653858275
        // updatePrice =  setInterval(() => {
        //     that.initialization(false);
        // }, 5000);

        //Update price every 5 Sec if RefreshOdds is Enable 
        updatePrice = setInterval(() => {
            //Get refreshOddsEnable value from localstorage
            if (Utility.isRefreshOddsEnable()) {
                that.initialization(false)
            } else {
            clearInterval(updatePrice);
            }
        }, 5000);

        Utility.LoadVirtualPlayer(false);
    }
    componentWillUnmount() {
        clearInterval(updatePrice);    
    }

    initialization(showLoader) {
        const that = this;
        if (showLoader)
            Utility.ShowDataLoader("loaderContainer");
        const params = new URLSearchParams(that.props.location.search);
        const eventName = params.get("eventName");
        this.EventName = eventName;
        const sportcode = params.get("sportcode");

        this.setState({eventName: eventName, SportCode: sportcode});
        const ef = params.get("eventfile");
        this.EventFile = ef;
        const eg = params.get("EventGroup");
        this.EventGroup = eg;
        // var ef = "e4sHIFT4";
        // var eg = "110";
        Api.GetSportsEvents(ef, eg, (events) => {
            if (typeof events != "undefined") {
                that.setState({Events1: events, showLoader: showLoader});
            }
            if (showLoader)
                Utility.HideDataLoader("loaderContainer");
        });
    }

    loadtoggleOnOff = () => {
        Api.getInPlayEvents((responseresult) => {
            this.setState({
                InPlayEvents: responseresult,
            });
        });
    };

    render() {
        const mystyle = {
            width: "100%",
            "margin-top": "10px",
            height: "450px",
        };
        const content = (
            <div>
                <div className="homepage">
                    <div className="container">
                        <div className="content-section-inner">
                            <LeftContainer/>
                            <div className="center-container">
                                {this.state.SportCode === "sb" ? (
                                    <div className="virtual">
                                        <div id="playerDiv1" style={mystyle}></div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="events-view" id="loaderContainer">
                                    {this.state.Events1[0] === undefined ? "" : (
                                        <CustomNotifications eventID={this.state.Events1[0].Events[0].EventId}/>
                                    )}
                                    <div className="events-view-heading">
                                        <div className="col-md-12">
                                            <div className="events-view-name">
                                                <h3>
                                                    <i className="fa fa-angle-down cursor"></i>
                                                    {this.state.eventName}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.Events1.map(function (events, i) {
                                            return events.Events[0].EventId === null ? (
                                                ""
                                            ) : (
                                                <div className="events-view-inner" key={i}>
                                                    <div className="event-sub-heading">
                                                        <div className="col-md-12">
                                                            <h4>{events.ElRespose}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="sections">
                                                        {events.Events.map(function (event, j) {
                                                            if (event.SelName != null && event.price != null)
                                                                return (
                                                                    <div
                                                                        className="sub-section cursor-pointer"
                                                                        key={j}
                                                                        onClick={(e) =>
                                                                            this.props.Store.AddBet(
                                                                                e,
                                                                                event,
                                                                                event,
                                                                                event.selection
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>{event.SelName}</span>
                                                                        <label>
                                                                            {this.props.PreferenceStore.ConvertPrice(
                                                                                event.price
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                );
                                                        }, this)}
                                                    </div>
                                                </div>
                                            );
                                        }, this)}
                                    </div>
                                </div>
                                <Sports_EventList
                                    SportName=""
                                    en={this.EventName}
                                    ef={this.EventFile}
                                    eg={this.EventGroup}
                                    showLoader={this.showLoader}
                                    props={this.props}
                                ></Sports_EventList>
                            </div>
                            <RightContainer/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
        return <div>{content}</div>;
    }
}

export default subscribe(ShowEvents, {
    Store: BetSlipContainer,
    PreferenceStore: PreferenceContainer,
});
