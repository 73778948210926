import React, { Component } from 'react';

export default class InfoBar extends Component {
    render() {
        return (
            <div className="InfoBar animated flipInX">
                <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;{this.props.msg}
            </div>
        );
    }
}