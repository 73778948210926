const ODDS_SELECTION = {
    HOME: 0,
    DRAW: 1,
    AWAY: 2,
};

const getSelectionPricesObj = (selectedBet) => {
    if (!selectedBet) {
        return null;
    }
    
    const {
        homeodds,
        drawodds,
        awayodds,
    
        firstodds,
        secondodds,
        thirdodds,
    
        homepricecode,
        drawpricecode,
        awaypricecode,
    
        home_gprice,
        draw_gprice,
        away_gprice,
    
        selection,    
    } = selectedBet;

    let result;

    if (selection === ODDS_SELECTION.DRAW) {
        result = {
            price: drawodds || secondodds,
            priceCode: drawpricecode,
            gPrice: draw_gprice,
        };
    } else if (selection === ODDS_SELECTION.AWAY) {
        result = {
            price: awayodds || thirdodds,
            priceCode: awaypricecode,
            gPrice: away_gprice,
        };
    } else {
        result = {
            price: homeodds || firstodds,
            priceCode: homepricecode,
            gPrice: home_gprice,
        };
    }

    return result;
};

const getProcessedPrice = (betPrice) => {
    return betPrice && betPrice.substring(0, 1);
};

const getAntepostEventsWithEventFile = (selectedBet) => {
    if (!selectedBet) {
        return null;
    }

    return {
        eventfile: selectedBet.eventfile,
        EventGroup: selectedBet.EventGroup,
        Events: selectedBet.Events,
        selection: selectedBet.selection,
        pricecode: selectedBet.pricecode,
        price: selectedBet.price,
        gpricecode: selectedBet.gpricecode,
        gprice: getProcessedPrice(selectedBet.gprice),
        coi: selectedBet.coi,
    };
};

const getAntepostEventsWithoutEventFile = (selectedBet) => {
    if (!selectedBet) {
        return null;
    }

    return {
        eventfile: selectedBet.EventFile,
        EventGroup: selectedBet.EventGroup,
        Events: selectedBet.Events,
        selection: selectedBet.selection,
        pricecode: selectedBet.pricecode,
        price: selectedBet.price,
        gpricecode: selectedBet.gpricecode,
        gprice: getProcessedPrice(selectedBet.gprice),
    };
};

const getGeneralEvents = (selectedBet) => {
    if (!selectedBet) {
        return null;
    }

    let {
        price,
        priceCode,
        gPrice
    } = getSelectionPricesObj(selectedBet);

    gPrice = gPrice || "0";

    const events = "0";

    return {
        eventfile: selectedBet.Code,
        EventGroup: selectedBet.eventGroup,
        Events: events,
        selection: selectedBet.selection,
        pricecode: priceCode,
        price: price,
        gpricecode: priceCode,
        gprice: gPrice,
    };
};

export const getOldFormattedBetPayload = (selectedBet) => {
    if (!selectedBet) {
        return null;
    }

    const {
        SelName,
        ALT,
        eventfile,
    } = selectedBet;

    if ((SelName || ALT) && eventfile) {
        return getAntepostEventsWithEventFile(selectedBet);
    } else if ((SelName || ALT) && !eventfile) {
        return getAntepostEventsWithoutEventFile(selectedBet);
    }
    return getGeneralEvents(selectedBet);
};

export const convertOldFormattedBet = (oldFormattedBet) => {
    if (!oldFormattedBet) {
        return null;
    }
    return {
        coi:                oldFormattedBet.coi || "000",
        eventFileName:      oldFormattedBet.eventfile,
        eventGroup:         !isNaN(Number(oldFormattedBet.EventGroup)) ? Number(oldFormattedBet.EventGroup) : 0,
        eventsNumber:       !isNaN(Number(oldFormattedBet.Events)) ? Number(oldFormattedBet.Events) : 0,
        gPrice:             !isNaN(Number(oldFormattedBet.gprice)) ? Number(oldFormattedBet.gprice) : 0,
        gPriceCode:         oldFormattedBet.gpricecode,
        isAvailable:        false, // @FIXME: by default ?
        isEachWay:          false,
        price:              oldFormattedBet.price,
        priceCode:          oldFormattedBet.pricecode,
        selectionNumber:    !isNaN(Number(oldFormattedBet.selection)) ? Number(oldFormattedBet.selection) : 0,
    };
};