import React, {Component} from 'react';
import {BarLoader} from 'react-spinners';

const override = `
    display: block;
    margin: 0 auto;
    border-color: #ffc727;
    margin-bottom:5px;
    border-radius: 3px;
`;

export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Show: true
        }
        this.SetProps = this
            .SetProps
            .bind(this);
    }

    SetProps(props) {
        if (typeof props != "undefined" && typeof props.Show != "undefined") 
            this.setState({Show: props.Show});
        }
    
    componentWillReceiveProps(newProps) {
        this.SetProps(newProps);
    }

    componentDidMount() {
        this.SetProps(this.props);
    }

    render() {
        return (
            <div className={"loader-container " + (this.state.Show ? "" : "hidden")}>
                <BarLoader className="progress-loader" css={override} color={'#ffc727'} loading={this.state.Show}/>
            </div>
        )
    }
}