import React from "react";
import postscribe from "postscribe";
class SignupFormStack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    console.log("wolf mount");
    postscribe(
      "#signup-form",
      '<script type="text/javascript"src="https://geoffbanks.formstack.com/forms/js.php/gbreg"></script><noscript><a href="https://geoffbanks.formstack.com/forms/gbreg" title="Online Form">Online Form - GBReg</a></noscript>'
    );
  };

  render() {
    return <div id="signup-form"></div>;
  }
}
export default SignupFormStack;
