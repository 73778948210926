import React from "react";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
import _ from "lodash";

class RacesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sportCode: "",
      sportsEvent: [],
      raceTimeMenu: {
        Events: [],
      },
      eventIdx: "",
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.sportCode !== this.props.sportCode)
      this.LoadFromProps(newProps);
  }

  LoadFromProps(props) {
    let eventDetail = [];
    let meetingEvent = [];
    const that = this;
    const params = new URLSearchParams(window.location.search);
    let sportcode = params.get("sportcode");
    let eventfile = params.get("eventfile");
    const eventgroup = params.get("EventGroup");
    const eventindex = params.get("Events");

    if (
      props.sportCode != undefined &&
      props.sportCode != "" &&
      props.sportCode != null
    ) {
      sportcode = props.sportCode;
      if (props.eventData && props.eventData != undefined) {
        eventfile = props.eventData.ef;
      }
    }

    if (typeof sportcode != "undefined" && sportcode != null) {
      Api.GetEvents(
        sportcode,
        (events) => {
          if (
            typeof events == "undefined" ||
            events.length <= 0 ||
            typeof events[0].Meetings == "undefined"
          )
            return;
          const eventData = _.find(events, function (o) {
            return o.Meetings[0].EventFile === eventfile;
          });
          if (eventData !== undefined) {
            eventDetail.push(eventData);
          } else {
            eventDetail = events;
          }
          if (eventDetail && eventDetail.length > 0) {
            _.each(eventDetail, function (eventData, key) {
              const EventMeeting = _.find(eventData.Meetings, function (meeting) {
                return (
                    meeting.EventFile === eventfile &&
                    meeting.EventGroup === eventgroup
                );
              });
              if (EventMeeting !== undefined) {
                meetingEvent = EventMeeting;
              }
            });
          }
          that.setState({
            sportsEvent: eventDetail,
            raceTimeMenu: meetingEvent,
          });
        },
        (err) => {
          console.log("error", err);
        }
      );
      this.setState({ sportCode: sportcode, eventIdx: eventindex });
    }

    // if (typeof props.SportCode != "undefined") {     sportCode = props.SportCode;
    //     Api.GetEvents(sportCode, events => {         if (typeof events !=
    // "undefined") {             eventDetail = events;         } that.setState({
    // sportsEvent: eventDetail });     }) } this.setState({ sportCode: sportCode })
  }
  componentDidMount = () => {
    var that = this;
    this.LoadFromProps(this.props);
  };

  render() {
    return (
      <div className="horse-racing">
        <div className="collapse" id="otherRaces">
          {this.state.sportsEvent.map(function (event, i) {
            return (
              <div className="uk-races" key={i}>
                <div className="uk-races-heading">
                  <div className="col-md-6">
                    <h3>
                      <i className="fa fa-angle-down" />
                      {event.EventName}
                    </h3>
                  </div>

                  <div className="col-md-2 text-right">
                    <label>
                      <em>P</em>
                      Priced
                    </label>
                  </div>

                  <div className="col-md-2 text-right">
                    <label>
                      <em className="race-off">0</em>
                      Race off
                    </label>
                  </div>

                  <div className="col-md-2 text-right">
                    <label>
                      <img src="images/Play_For_Work_Icon_1.png" />
                      Result
                    </label>
                  </div>
                </div>

                <div className="list-view">
                  <div className="col-md-3" />
                  <div className="col-md-3 text-center active">
                    <h3>TODAY</h3>
                  </div>

                  <div className="col-md-3">
                    <h3>TOMORROW</h3>
                  </div>

                  <div className="col-md-3 text-right">
                    {/* <h3><a href="#">LIST VIEW</a></h3> */}
                  </div>
                </div>

                {event.Meetings.map(function (meeting, j) {
                  return (
                    <div className="list-view-inner" key={j}>
                      <div className="col-md-3 text-left">
                        <label>{meeting.MeetinName}</label>
                        {/* <div><a href={'/show-events?'+meeting.BetLink}>more>></a></div> */}
                      </div>
                      <div className="col-md-9">
                        {meeting.Events
                          ? meeting.Events.map(function (meetingEvent, k) {
                              return (
                                <div className="col-md-3 text-center" key={k}>
                                  {meetingEvent.Status &&
                                  meetingEvent.Status == "Result" ? (
                                    <a
                                      href={
                                        "/single-event-result?sportcode=" +
                                        this.state.sportCode + "&" +
                                        meetingEvent.EventLink
                                      }
                                    >
                                      <span className="first-child">
                                        <img
                                          src="images/Play_For_Work_Icon_1.png"
                                          className="mr-5"
                                        />{" "}
                                        {meetingEvent.Time}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      href={
                                        "/single-event?sportcode=" +
                                        this.state.sportCode +
                                        "&" +
                                        meetingEvent.EventLink
                                      }
                                    >
                                      <span className="first-child">
                                        <em className="priced mr-5">P</em>
                                        {meetingEvent.Time}
                                      </span>
                                    </a>
                                  )}
                                </div>
                              );
                            }, this)
                          : ""}
                      </div>
                    </div>
                  );
                }, this)}
              </div>
            );
          }, this)}
        </div>

        <div className="timeMenu">
          {this.state.raceTimeMenu &&
          this.state.raceTimeMenu != undefined &&
          this.state.raceTimeMenu.Events &&
          this.state.raceTimeMenu.Events != undefined &&
          this.state.raceTimeMenu.Events.length > 0 ? (
            <div
              className="resCarousel"
              data-items="2-4-5-6"
              data-interval="false"
              data-slide="1"
            >
              <div className="resCarousel-inner">
                {this.state.raceTimeMenu.Events.map(function (event, i) {
                  return event.Status && event.Status == "Result" ? (
                    <div className="item" key={i}>
                      <a
                        className={
                          this.state.eventIdx != "" &&
                          this.state.eventIdx != undefined &&
                          this.state.eventIdx == event.EventIdx
                            ? "highlighted"
                            : ""
                        }
                        href={
                          "/single-event-result?sportcode=" +
                          this.state.sportCode + "&" +
                          event.EventLink
                        }
                      >
                        {event.Time}
                      </a>
                    </div>
                  ) : (
                    <div className="item" key={i}>
                      <a
                        className={
                          this.state.eventIdx != "" &&
                          this.state.eventIdx != undefined &&
                          this.state.eventIdx == event.EventIdx
                            ? "highlighted"
                            : ""
                        }
                        href={
                          "/single-event?sportcode=" +
                          this.state.sportCode +
                          "&" +
                          event.EventLink
                        }
                      >
                        {event.Time}
                      </a>
                    </div>
                  );
                }, this)}
              </div>
              <button className="btn btn-default leftRs">
                <span className="glyphicon glyphicon-menu-left" />
              </button>
              <button className="btn btn-default rightRs">
                <span className="glyphicon glyphicon-menu-right" />
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* <div className="timeMenu">
                    {this.state.raceTimeMenu && this.state.raceTimeMenu != undefined && this.state.raceTimeMenu.Events && this.state.raceTimeMenu.Events != undefined && this.state.raceTimeMenu.Events.length > 0
                        ? <div className="scrollmenu raceTimeMenu">
                            {this
                                .state
                                .raceTimeMenu
                                .Events
                                .map(function (event, i) {
                                    return (event.Status && event.Status == 'Result'
                                        ? <a
                                            className={this.state.eventIdx != '' && this.state.eventIdx != undefined && this.state.eventIdx == event.EventIdx
                                                ? 'highlighted'
                                                : ''}
                                            key={i}
                                            href={'/single-event-result?sportcode=' + this.state.sportCode + event.EventLink}>
                                            {event.Time}
                                        </a>
                                        : <a
                                            className={this.state.eventIdx != '' && this.state.eventIdx != undefined && this.state.eventIdx == event.EventIdx
                                                ? 'highlighted'
                                                : ''}
                                            key={i}
                                            href={'/single-event?sportcode=' + this.state.sportCode + '&' + event.EventLink}>
                                            {event.Time}
                                        </a>)
                                }, this)
                            }
                        </div>
                        : ''
                    }
                </div> */}
      </div>
    );
  }
}
export default RacesList;
