import React from 'react';
import './style.css';

export const PriceChangeIndicator = (props) => {
    const { direction } = props;

    if (direction === 'up' || direction === 'down') {
        return (
            <span className={'price-change-indicator ' + direction}></span>
        );
    }

    return null;
};