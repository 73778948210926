import React from 'react';
import { BetSlipActions } from './BetSlipActions';
import { BetSlipWagers } from './BetSlipWagers';
import { ChosenBetList } from './ChosenBetList';
import { EmptyBetList } from './EmptyBetList';
import './style.css';

export const BetSlipCart = (props) => {
  const {
    betslip,
    keepBetslip,
    setKeepBetslip,
    acceptPriceChanges,
    setAcceptPriceChanges,
    oddsFormat,
    deleteBet,
    isBetslipLoaded,
    isLoading,
    setSelectedWagerIndex,
    selectedWagerIndex,
    loginOrSignup,
    isZeroStake,
    setIsZeroStake,
    selectedWagerStake,
    setSelectedWagerStake,
    selectedWagerEachway,
    setSelectedWagerEachway,
    submitBetslip,
    isSubmitting,
    isBetslipSubmitted,
  } = props;
  const isSpinnerShown = !isBetslipLoaded || isLoading;

  const getWagerList = () => {
    return betslip.wagers || [];
  }

  const addStakeToSelected = (stakeIncrement) => {
    if (selectedWagerIndex === -1) {
      return;
    }

    const currStake = selectedWagerStake || 0;
    const sum = !isNaN(Number(currStake)) ? Number(currStake) + stakeIncrement : stakeIncrement;
    setSelectedWagerStake(sum);
  };

  const isFormDisabled = () => {
    return getWagerList().length === 0
      || selectedWagerIndex === -1
      || !selectedWagerStake;
  };

  const isStakeLadderHidden = () => {
    return getWagerList().length === 0 || selectedWagerIndex === -1;
  }

  const renderBetslipContent = () => {

    if (!betslip || !betslip.bets || betslip.bets.length === 0) {
      return (
         <EmptyBetList />
      );
    }

    return (
      <div>
        <ChosenBetList
          oddsFormat={oddsFormat}
          deleteBet={deleteBet}
          betList={betslip.bets}
          isBetslipSubmitted={isBetslipSubmitted}
        />

        { getWagerList().length === 0
          ? (
            <div className="no-wagers-available">
              It seems at least one of the events is ended. Please remove it from the Bet Slip to continue
            </div>
          )
          : (
            <BetSlipWagers
              wagers={getWagerList()}
              betList={betslip.bets}
              selectedWagerStake={selectedWagerStake}
              setSelectedWagerStake={setSelectedWagerStake}
              selectedWagerEachway={selectedWagerEachway}
              setSelectedWagerEachway={setSelectedWagerEachway}
              keepBetslip={keepBetslip}
              setKeepBetslip={setKeepBetslip}
              acceptPriceChanges={acceptPriceChanges}
              setAcceptPriceChanges={setAcceptPriceChanges}
              selectedWagerIndex={selectedWagerIndex}
              setSelectedWagerIndex={setSelectedWagerIndex}
              isZeroStake={isZeroStake}
              setIsZeroStake={setIsZeroStake}
            />
          )
        }

        {isSpinnerShown ? (
          <div className="no-data-wrapper-loading">
            <p>
              { isSubmitting
                  ? 'Wager processing with Geoff Banks.'
                  : 'Loading...' }
            </p>
          </div>
        ) : null}
        
          <BetSlipActions
            addStakeToSelected={addStakeToSelected}
            isBetslipLoaded={betslip != null}
            submitBetslip={submitBetslip}
            loginOrSignup={loginOrSignup}
            isFormDisabled={isFormDisabled()}
            isStakeLadderHidden={isStakeLadderHidden()}
            isZeroStake={isZeroStake}
            IsCustomerLoggedIn={props.IsCustomerLoggedIn}
          />
      </div>
    );
  };

  return (
    <div>
      <div className="bet-slip-wrapper">
        <div className="bet-triangle"></div>
        { renderBetslipContent() }
      </div>
    </div>
  );
}