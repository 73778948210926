import React from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as Utility from "../../js/utility";

class ManualDeposite extends React.Component {
  componentDidMount() {
    
    // var authorization = JSON.parse(localStorage.getItem('authorization'));
    // if (authorization != undefined) {
    // } else {
    //     this
    //         .props
    //         .history
    //         .push('/');
    // }
  }
  render() {
    var content = (
      <div>
        <div className="my-account">
          <div className="container">
            <AccountSidebar />
            <div className="account-right-container">
              <div className="transaction">
                <div className="acount-inner">
                  <h3>Manual Deposit</h3>
                </div>

                <div style={{ padding: "8px 6px", background: "#eeeeee" }}>
                  <iframe
                    height="485"
                    allowtransparency="true"
                    frameBorder="0"
                    scrolling="no"
                    className="withdrawIframe"
                    src="https://geoffbanks.wufoo.com/forms/m1taqv761q8ob3e"
                  >
                    <a href="https://geoffbanks.wufoo.com/forms/m1taqv761q8ob3e"></a>
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default ManualDeposite;
