import React from "react";
import * as Api from "../js/api-service";
import * as Util from "../js/utility";
import InfoBar from "./Shared/InfoBar";
import _ from "lodash";


export default class CustomNotifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            showBar: false
        };
        this.closeNotification = this.closeNotification.bind(this);

    }

    componentWillReceiveProps(newProps) {
        this.LoadFromProps(newProps);
    }

    LoadFromProps(props) {
    }

    componentDidMount = () => {
        this.LoadFromProps(this.props);
        this.getNotifications()
    };

    async getNotifications() {
        const params = new URLSearchParams(window.location.search);
        var sportcode = params.get("sportcode");
        
        if (this.props.eventID == undefined || this.props.eventID.length <= 0||Util.GetPath()==""||
        sportcode == undefined||sportcode=="") return;

        let obj={
            eventId:this.props.eventID,
            path:escape(Util.GetPath()),
            sportcode: sportcode
        }

        var eventResponse = await Api.GetSingleEventNotification(obj);

        var data = eventResponse.data.data;

        if (typeof data == "undefined" || data.length == 0) {
            this.setState({ showBar: false })
        }
        else {
            this.setState({ showBar: true, content: data[0].content })
        }

    // var filteredEvent = await Api.GetSingleEventNotification(this.props.eventID);
    // console.log(filteredEvent, "zzzzzz");
    // if (filteredEvent != undefined && filteredEvent.data.data != undefined && filteredEvent.data.data.length != 0) {
    //     filteredEvent = filteredEvent.data.data;
    //     this.setState({ showBar: true, content: filteredEvent[0].content });
    // }
}


    closeNotification = () => {
        this.setState({ showBar: false });
    }

    render() {
        if (!this.state.showBar || this.state.content == "") return "";
        return (
            <div className={this.props.css + " customNotification animated flipInX"}>
                <i class="fa fa-bell bellIcon" aria-hidden="true"></i>&nbsp;{this.state.content}
                <i class={this.props.css + " fa fa-times closeIcon"} aria-hidden="true" onClick={this.closeNotification}></i>
            </div>

        );
    }
}
