import React from "react";
import subscribe from "unstated-subscribe-hoc";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import {FavouriteContainer} from "../Containers/FavouriteContainer";
import Sports_EventList from "./Sports_EventList";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";

import {PreferenceContainer} from "../Containers/PreferenceContainer";

let sportName;
const EventName = "";
let autoReload;

class Sports extends React.Component {
    constructor(props) {
        super(props);
        sportName = props.match.params.name;
        this.state = {
            errorMessage: "",
            Events: [],
            headerTitle: "",
            FullCardOptions: [],
            SportCode: "",
            SportName: "",
            ShowLoader: true,
            InPlayEvents: [],
            InPlayEventsUpper: [],
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.InPlayEventsUpper !== this.state.InPlayEventsUpper) {
            const _this = this;
            setTimeout(function () {
                _this.setState({
                    InPlayEventsUpper: nextState.InPlayEventsUpper,
                });
            }, 500);
        }
    }

    componentWillUnmount() {
        clearInterval(autoReload);
        this.changeOddsFormat();
    }

    componentDidMount() {
        
        const params = new URLSearchParams(window.location.search);
        const sportcode = params.get("sportcode");
        const sportInfo = Api.GetSportInfo(sportcode);

        this.setState({
            headerTitle: sportInfo.Name,
            FullCardOptions: sportInfo.FullCardOptions,
            SportCode: sportInfo.SportCode,
            ShowLoader: true,
            SportName: sportInfo.Name,
        });
    }

    changeOddsFormat() {
        const params = new URLSearchParams(window.location.search);
        const sportcode = params.get("sportcode");
        if (sportcode === "sb") {

            let e = {target: {value: "decimal"}};
            this.props.PreferenceStore.SetOddFormat(e, (cb) => {
            });
        } else {
            let e = {target: {value: "fractional"}};
            this.props.PreferenceStore.SetOddFormat(e, (cb) => {
            });
        }
    }

    render() {
      const content = (
          <div>
            <div className="homepage">
              <div className="container">
                <div className="content-section-inner">
                  <LeftContainer/>
                  <div className="center-container">
                    <div className="sports">
                      <div className="headerContainer">
                        <div className="headerTitle">
                          {this.state.headerTitle}
                          <i
                              className="fa fa-star fav_star"
                              className={
                                this.props.FavouriteStore.state.IsFav
                                    ? "fa fa-star fav_star"
                                    : "fa fa-star fav_star not_fav_star"
                              }
                              aria-hidden="true"
                              onClick={(e) =>
                                  this.props.FavouriteStore.AddSportToFavorite(
                                      EventName != null && EventName != ""
                                          ? EventName
                                          : this.state.headerTitle,
                                      (cb) => {
                                      }
                                  )
                              }
                          ></i>
                        </div>
                      </div>

                      <Sports_EventList
                          SportName={this.state.SportName}
                          props={this.props}
                      ></Sports_EventList>
                    </div>
                  </div>
                  <RightContainer/>
                </div>
              </div>
            </div>
            <Footer/>
          </div>
      );
      return <div>{content}</div>;
    }
}

export default subscribe(Sports, {
    FavouriteStore: FavouriteContainer,
    PreferenceStore: PreferenceContainer,
});
