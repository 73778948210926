import React from "react";
import * as Api from "../js/api-service";
import * as Util from "../js/utility";
import InfoBar from "./Shared/InfoBar";
import _ from "lodash";

const virtualStreamLiveURL= "https://vcdata-l1.inseincvirtuals.com/inggWebViewer/?cust=abeta&ch=";

const BASE_URL =
  "https://vcdata-st1.inseincvirtuals.com/inggWebViewer/?cust=abeta&ch=";
export default class VirtualVideoStream extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      streamSrc: "",
      events: [],
      selectedEvent: "",
    };
  }

  componentWillReceiveProps(newProps) {
    this.LoadFromProps(newProps);
  }

  LoadFromProps(props) {
    this.setState({ events: Util.virtualEventsList[this.props.page] });
  }
  componentDidMount = () => {
    this.LoadFromProps(this.props);
    //Showing virtual stream only for virtual horse and virtual greyhound
    if(this.props.page=="virtualHorse"||this.props.page=="virtualGreyhound"){
      this.getEvent();
    }
  };

  componentWillUnmount() {}

  getEvent() {
      
    var URL = Api.virtualStreamLiveURL + Util.virtualEventsList[this.props.page][0];

    this.setState({ streamSrc: URL});
  }

  switchEvent = (e) => {
    this.setState({
      selectedEvent: e.target.value,
      streamSrc: Api.virtualStreamLiveURL + this.state.events[e.target.value],
    });
  };

  render() {
    if(this.props.page!=="virtualHorse"&&this.props.page!=="virtualGreyhound")return "";
    if (!Util.IsCustomerLoggedIn())
      return (
        <InfoBar msg="Please login to your account to view live streams of games/races" />
      );

        return (
          <div>
        <div className="live-stream-container">
          <div id="loaderContainer">
            <iframe
              sandbox
              frameBorder="none"
              src={this.state.streamSrc}
              allow="autoplay"
              width="100%"
              height="400"
              className="border0"
              ></iframe>
          </div>
          <div class="video-stream-info animated flipInX">
            <div className="video-stream-info-right">
              {this.state.events.length > 1 ? (
                <select
                className="live-event-selector"
                onChange={this.switchEvent}
                >
                  {this.state.events.map((evt, i) => {
                    return <option value={i}>{_.startCase(evt)}</option>;
                  })}
                </select>
              ) : (
                ""
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
