import React, {Component} from "react";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as Api from "../../js/api-service";
import * as utility from "../../js/utility";

import "./MyProfile.css";
import {AccountContainer} from "../../Containers/AccountContainer";
import subscribe from "unstated-subscribe-hoc";
import {PayPalButton} from "react-paypal-button-v2";


class PaypalDeposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowLoader: false,
            showSuccess: false,
            amount: "",
            email: "",
            userID: "",
            payerID: "",
            userPayerID: "",
            userPayerEmail: "",
            capturedID: "",
            emailExists: false,
            full_name: ""
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        if (utility.checkAuth() != undefined) {
            Api.GetUserDetailFromDirectus((res) => {
                if (res.data.length !== 0) {
                    let userData = res["data"][0]
                    this.setState({
                        userID: userData.id,
                        email: userData.emailaddress,
                        userPayerID: userData.payer_id,
                        userPayerEmail: userData.payer_email
                    });
                    Api.checkPaypalEmailExists(userData.emailaddress, cb => {
                        var paypalData = cb["data"];
                        paypalData.length > 0 ? this.setState({emailExists: true}) : this.setState({emailExists: false})
                    });
                } else {
                    let obj = {
                        status: "published",
                        username: utility.User().username
                    }
                    Api.SaveUserDetailToNewDB(obj, cb => {
                        this.setState({userID: cb.data.id});

                    })
                }
            });

            // 

            utility.GetMyProfileDetailsByUser((response) => {
                if(response.length > 0){
                    this.setState({
                        ...this.state,
                        full_name: `${response[0].first_name} ${response[0].last_name}`
                    })
                }
            });

        } else {
            this.props.history.push("/");
            utility.ShowNotification(
                "Please Sign in to your account to access this page!"
            );
        }
    }


    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    createOrder = (data, actions) => {
        if (this.state.amount.trim() == "" || parseFloat(this.state.amount) < 10) {
            utility.ShowNotification("Minimum amount for deposit is £10");
            return;
        }
        // utility.ShowLoading("Loader");

        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        // currency_code: "GBP",
                        value: this.state.amount
                    }
                }
            ]
        });

    };

    onApprove = (data, actions) => {

        actions.order.capture().then(details => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };

        });
    };

    updateUserDetail(data) {
        let flag = 0;

        if ((this.state.userPayerID == undefined || this.state.userPayerID == "") || (this.state.userPayerEmail == undefined || this.state.userPayerEmail == "")) {
            var obj = {
                id: this.state.userID,
                status: "published",
                // emailaddress: this.state.email,
                payer_id: this.state.payerID,
                payer_email: data.payer.email_address
            };
            Api.UpdateUserDetailToNewDB(obj, (ab) => {
            });
            return false;
        } else if (this.state.userPayerID !== "" && this.state.userPayerID !== this.state.payerID) {
            let capturedData = { orderID: this.state.capturedID, username: data.username, email_address: data.payer.email_address, amount: data.purchase_units[0].amount.value }
            // refund amount and send refund email
            Api.getRefund(Api.Reg_API_BASE_URL_V1 + "GetRefund", capturedData, refund => {
                utility.ShowNotification("Paypal email not linked to your account! Refund initiated to " + data.payer.email_address);
            });
            return true;
        }
        // will default return undefined ~ false 
    }

    onSuccess = (details, content) => {
        try{
            this.setState({payerID: details.payer.payer_id, capturedID: details.purchase_units[0].payments.captures[0].id});

            /* Checks
                - first paypal use by user, updates details in users
                - subsequent use of paypal, verifies if the same paypal payerid was used, else refunds. If refund
                occurs the 'flag' will be true, so we don't proceed to save record in table 'paypal_deposits' and
                thus we don't get the success email.
            */
            var isInvalidTransaction = this.updateUserDetail(details);
            if (isInvalidTransaction) {
                return;
            }

            Api.savePaypalDeposit(Api.Reg_API_BASE_URL_V1 + "paypal_deposits", details, cb => {
                var data = JSON.parse(cb.data["data"]);
                var transactionDetails = {
                    "Username": utility.User().username,
                    "Amount": data.purchase_units[0].amount.value,
                    "Transaction ID": data.id,
                    "Email": data.payer.email_address,
                    "Payer ID": data.payer.payer_id
                }

                // let capturedData={orderID:this.state.capturedID}
                //FIRST PAYMENT WITH EXISTING EMAIL
                // if(this.state.email==data.payer.email_address){
                // this.updateUserDetail(data);
                // }
                //FIRST PAYMENT WITH SOMEONE ELSE's EMAIL
                // if(this.state.payerID!==data.payer.payer_id||this.state.email!==data.payer.email_address){
                //     Api.getRefund(Api.Reg_API_BASE_URL_V1+"GetRefund",capturedData,refund=>{
                //     console.log("AMOUNT REFUNDED PAYPAL",refund);
                //     utility.ShowNotification("Paypal email not linked to your account! Refund initiated to "+data.payer.email_address);
                // });
                // return;
                // }

                // utility.LoadEmailTemplate("PaypalDeposit", transactionDetails, (template) => {
                //     Api.SendEmail("", utility.config.email, "Paypal Deposit Details", template); 
                //     Api.SendEmail("", utility.config.officeEmail, "Paypal Deposit Details", template); 
                // });

                this.setState({amount: "", showSuccess: true});

                // utility.ShowNotification("Transaction completed by " + details.payer.name.given_name + " " + details.payer.name.surname);
            })
        } catch (err){
            console.log('err', err);
        }
    };

    render() {
        return (
            <div>
                <div className="my-account">
                    <div className="container">
                        <AccountSidebar/>
                        <div className="account-right-container">
                            <div className="sign-up user-detail myProfile">
                                <div className="acount-inner">
                                    <h3>Deposit Funds Using PayPal</h3>
                                </div>

                                <div className="user-details myProfileForm " id="Loader">
                                    {/* <Shared.Loader  Show={this.state.UserDetail.ShowLoader}  /> */}
                                    {this.state.showSuccess == true ? (
                                        <div className="successMessage bgBlack">
                                            <img src={"../images/checkCircle.png"} alt=""/>
                                            <p>Your request has been submitted.</p>
                                            <p>Many thanks for your continuing support for Geoff Banks Online</p>
                                            <span>FEEL FREE TO GET IN TOUCH WITH OUR SUPPORT TEAM IN CASE YOU NEED FURTHER HELP ON THIS.</span>
                                        </div>
                                    ) : (
                                        <div>
                                            <form
                                                className="my-profile-form"
                                                id="paypal-form"
                                                // onSubmit={this.handleSubmit}
                                            >
                                                <div id="formContent">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-block">
                                                                <label className="amountTitle">
                                                                    ENTER AMOUNT TO DEPOSIT</label>
                                                                <br/>
                                                                <input
                                                                    type="number"
                                                                    id="amount"
                                                                    name="amount"
                                                                    step={"0.01"}
                                                                    min={1.0}
                                                                    className="fadeIn"
                                                                    placeholder="Enter Amount"
                                                                    value={this.state.amount}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="paypalBtn">
                                                        <PayPalButton
                                                            amount={this.state.amount}
                                                            // onError={(err) => { utility.ShowNotification("Please enter valid amount!") }}
                                                            createOrder={(data, actions) => this.createOrder(data, actions)}
                                                            onApprove={(data, actions) => this.onApprove(data, actions)}
                                                            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                            onSuccess={(details, data) => {
                                                                this.onSuccess({...details, fullName: this.state.full_name, username: utility.GetUserName()}, data)
                                                            }}
                                                            options={{
                                                                // clientId: "sb", //FOR TESTING (SANDBOX)
                                                                // merchantId: "sb-43mqwi2265476@business.example.com", //FOR TESTING (SANDBOX)
                                                                clientId: Api.paypalProdClientId,
                                                                merchantId: "support@geoffbanks.bet",
                                                                intent: "capture",
                                                                currency: "GBP",
                                                                debug: "true",
                                                                disableFunding: "card"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }
}

export default subscribe(PaypalDeposit, {AccountStore: AccountContainer});
