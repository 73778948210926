import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import BetSlipContent from '../Component/BetSlipContent';
import * as $ from "jquery";
import _ from "lodash";
import * as Api from '../js/api-service';
import * as betSlip from '../js/bet-slip';
import * as Utility from '../js/utility';
import subscribe from 'unstated-subscribe-hoc'
import { PreferenceContainer } from '../Containers/PreferenceContainer';

//import { Link } from 'react-router-dom';

class BetPlacedModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countdown: 0
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(newProps) {
        if (newProps.show) {
            var timeleft = 10;
            var downloadTimer = setInterval(function () {
                if (document.getElementById("countdown") != undefined && document.getElementById("countdown") != null) {
                    document.getElementById("countdown").innerHTML = "This message will close in " + timeleft + " second";
                    timeleft -= 1;
                    if (timeleft <= 0) {
                        clearInterval(downloadTimer);
                        //document.getElementById("countdown").innerHTML = "Finished"
                    }
                }
            }, 1000);
        }

    }

    render() {
        if (typeof this.props.bets == "undefined")
            return "";
        return (
            <Modal {...this.props} className="betPaced-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        BET PLACED
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="placed-bets">
                        <div className="head">
                            <b>You have succesfully placed {this.props.bets.length} bet(s)</b>
                        </div>
                        <div>
                            {
                                this.props.bets.map(function (slip, i) {
                                    return (
                                        // <div className="betsDetail " key={i}>
                                        <div key={i}>
                                            {i + 1}.
                                                <span>{slip.WagerName} @ {slip.Detail} {this.props.PreferenceStore.ConvertPrice(slip.Price)} {slip.NewPC}</span>
                                        </div>

                                    )
                                }, this)
                            }
                        </div>
                    </div>

                    <div className="wager-info">
                        <div className="text-center head">
                            <b>Bet Receipt</b>
                        </div>

                        {
                            this.props.wagers.map(function (wager, i) {
                                return (
                                    <div className="row wagerDetail" key={i}>
                                        <div className="col-sm-6">
                                            {wager.WagerTypeName}
                                            <span className="wagerType" >{wager.WType}</span>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: wager.ResultMessage }} className={'WagerResult ' + wager.ResultClass}></div>
                                        </div>
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4">
                                            £{wager.BetDetailAmt}
                                        </div>
                                    </div>

                                )
                            }, this)
                        }
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <b>Total Payment</b>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-4">
                            £{this.props.totalbalance}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <b>Current balance</b>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-4">
                            £{this.props.betdetail.CustBalance}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer >
                    <div id="countdown"></div>
                    <Button onClick={this.props.onHide} className="closeBetPlace">CLOSE AND CONTINUE</Button>
                </Modal.Footer>
            </Modal>

        )

    }
}


export default subscribe(BetPlacedModal, {  PreferenceStore: PreferenceContainer});