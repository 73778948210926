import React from 'react';
import { detectBetslipErrors } from '../../../_helpers';
import { BetSlipIsNotSubmitted } from './BetSlipIsNotSubmitted';
import { EventEndedMessage } from './EventEndedMessage';
import { InsufficientFundsMessage } from './InsufficientFundsMessage';
import { LiabilityExceedMessage } from './LiabilityExceedMessage';
import { PriceChangedMessage } from './PriceChangedMessage';
import { SelectionNoLongerAvailable } from './SelectionNoLongerAvailable';

export const BetSlipStatusResponse = (props) => {
    const {
        betslip,
        isBetslipSubmitted,
    } = props;

    const errors = detectBetslipErrors(betslip, isBetslipSubmitted);

    if (!errors) {
        return null;
    }

    const renderErrors = (errors) => {
        if (!betslip || !betslip.wagers || !betslip.wagers.length) {
            return null;
        }

        if (errors.isPriceChanged) {
            return (
                <PriceChangedMessage {...props} />
            );
        }

        if (errors.isLiabilityExceeded) {
            return (
                <LiabilityExceedMessage {...props} />
            );
        }

        if (errors.isEventEnded) {
            return (
                <EventEndedMessage {...props} />
            );
        }

        if (errors.isInsufficientFunds) {
            return (
                <InsufficientFundsMessage {...props} />
            );
        }

        if (errors.isSelectionNoLongerAvailable) {
            return (
                <SelectionNoLongerAvailable {...props} />
            );
        }

        if (errors.isBetslipFailedInGeneral || errors.isNothingToSubmit) {
            return (
                <BetSlipIsNotSubmitted {...props} />
            );
        }

        return null;
    }
    
    return renderErrors(errors);
}