import React from 'react'
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as Utility from "../../js/utility";
class Withdrawal extends React.Component {
    componentDidMount() {
        
    }
    render() {
        var content = (
            <div>
                <div className="my-account">

                    <div className="container">

                        <AccountSidebar />
                        <div className="account-right-container">


                            <div className="transaction">

                                <div className="acount-inner">
                                    <h3>Withdraw from your account</h3>
                                </div>


                                <div style={{padding: '8px 6px', background: '#eeeeee'}}>
                                    <iframe height="485"
                                        allowtransparency="true"
                                        frameBorder="0"
                                        scrolling="no"
                                        className="withdrawIframe"
                                        src="https://geoffbanks.wufoo.com/embed/sods3770ek3re8/def/embedKey=sods3770ek3re8140137&amp;entsource=&amp;referrer=https%3Awuslashwuslashgeoff-banks.comwuslashcashierwuslash" >
                                        <a href="https://geoffbanks.wufoo.com/embed/sods3770ek3re8/def/embedKey=sods3770ek3re8140137&amp;entsource=&amp;referrer=https%3Awuslashwuslashgeoff-banks.comwuslashcashierwuslash">
                                        </a>
                                    </iframe >
                                </div>








                            </div>




                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}


export default Withdrawal;