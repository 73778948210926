import React, { Component } from "react";
import * as Utility from "../../js/utility";
import * as Api from "../../js/api-service";

export default class SportsEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Events: [],
      Sport: {},
      Antepost: []
    };
    this.ProcessProps = this.ProcessProps.bind(this);
  }

  ProcessProps(props) {
    var sport = {
      Name: "Sport"
    };
    if (typeof props.Sport != "undefined") {
      sport = props.Sport;
    }
    var events = Utility.SortEvents(props.Events);
    var filterData = Utility.filterBoxingAndUfcEvents(sport.SportCode, events); //filter data for boxing and UFC
    if (filterData != undefined && filterData.length > 0) {
      events = filterData;
    }
    var antepost = [];
    if (sport.AntepostSportCode && sport.AntepostSportCode != undefined) {
      Api.GetAntepostEvents(sport.AntepostSportCode, antePostEvents => {
        if (typeof antePostEvents != undefined) {
          antepost = Utility.SortEvents(antePostEvents);
          var baseBallantepost = Utility.antepostFilter(
            "Antepost Baseball",
            antepost
          ); //For baseball only
          if (baseBallantepost != undefined && baseBallantepost.length > 0) {
            antepost = baseBallantepost;
          }
        }

        this.setState({ Antepost: antepost });
      });
    }
    this.setState({ Events: events, Sport: sport });
  }
  componentWillReceiveProps(newProps) {
    this.ProcessProps(newProps);
  }
  componentDidMount() {
    this.ProcessProps(this.props);
  }

  render() {
    var sport = this.state.Sport;
    return (
      <div className="sidebar-selected-sport-events">
        <div className="go-back-to-all-sports">
          <i className="fa fa-angle-double-left" aria-hidden="true"></i>
          <a
            className="carousel-control in-menu"
            href="#left-menu-carousel"
            data-slide="prev"
          >
            Show All Sports
          </a>
        </div>
        {this.state.Events && this.state.Events.length > 0 ? (
          <div>
            <h4>
              <i className="fa fa-chevron-down"></i>
              {this.state.Sport ? this.state.Sport.Name : "Sport"}
            </h4>
            <ul>
              {this.state.Events.map(function(event, j) {
                return (
                  <li key={j}>
                    {/* {sport.SportCode=="vh"||sport.SportCode=="$"?
                    (<a href={sport.PathName + "&eventName=" + event.Name}>
                      {event.Name}
                    </a>
                    ):
                    (<a href={sport.PathName + "&eventName=" + event.EventName}>
                        {event.EventName}
                      </a>
                    )
                    } */}

                    
                    <a href={sport.PathName + "&eventName=" + event.EventName}>
                        {event.EventName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}

        {this.state.Sport &&
        this.state.Sport.SubItems &&
        this.state.Sport.SubItems.length > 0 ? (
          <span>
            <h4>
              <i className="fa fa-chevron-down"></i>
              {this.state.Sport ? this.state.Sport.Name + " Options" : "Sport"}
            </h4>
            <ul>
              {this.state.Sport.SubItems.map(function(sport, j) {
                return (
                  <li key={j}>
                    <a href={sport.Url}>{sport.Title}</a>
                  </li>
                );
              })}
            </ul>
          </span>
        ) : (
          ""
        )}

        {this.state.Antepost && this.state.Antepost.length > 0 ? (
          <span>
            <h4>
              <i className="fa fa-chevron-down"></i>
              Antepost
            </h4>
            <ul>
              {this.state.Antepost.map(function(antepostEvent, j) {
                return (
                  <li key={j}>
                    <a
                      href={
                        sport.AntepostPathName +
                        "&eventName=" +
                        antepostEvent.EventName
                      }
                    >
                      {antepostEvent.EventName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }
}
