import React from "react";
import "../css/main.css";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import ShowEvents from "../Component/ShowEvents";
import * as utility from "../js/utility";
import MainLive from "../Component/mainLive";
import VideoStream from "../Component/VideoStream";
var autoReload;

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      streamSrc: "",
      streamLoc: "",
      streamDesc: "",
      streamVenue: "",
      loggedIn:utility.IsCustomerLoggedIn()
    };
  }

  componentDidMount() {
    
    // utility.UpdateIsEnableInPlay();
    autoReload = setInterval(() => {
      if (localStorage.getItem("CustInfo") != null) {
        this.getEvent();
      }
    }, 5 * 1000);
  }

  getEvent() {
    clearInterval(autoReload);
    this.setState({
      loggedIn:true
    })
  }

  render() {
    return (
      <div>
        <div className="homepage">
          <div className="container">
            <div className="content-section-inner">
              <LeftContainer />
              <div className="center-container">
                    <VideoStream IsLogin={this.state.loggedIn} sportcode={"H,h"}/>
                   <MainLive/>
                <ShowEvents/>
              </div>
              <RightContainer />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
