import React from 'react';
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility"
import $ from 'jquery';
const mobRegEx = RegExp("^([0|+[0-9]{1,5})?([0-9]{10})$");


class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                header:'',
                headerImage:'',
                content:[]
            },
            AffiliateUser:{
                Id:0, //Add new user
                FirstName:"",
                LastName:"",
                Email: "",
                Password: "",
                MobileNo: "",
                Address:"",
                VerificationFile:"",
            },
            errors: {
                MobileNo: "",
                isTermCondCheck: "",
            },
            errorMsg:"",
            isTermCondCheck: true,
            SuccesfullSignup:false
        }
    }


    componentDidMount() {
        $("form").on("change", ".file-upload-field", function () {
            $(this).parent(".file-upload-wrapper").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
        });
        Api.pageData('Affiliate', (cb) => {
            var data=  {
                heading:'WHY JOIN GEOFF ONLINE AS AN AFFILIATE',
                headerImage:cb.data[0].image.data.full_url,
                content:Utility.AffiliateContent
            }
            this.setState({data:data})
          });
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        //VALIDATION FOR MOBILE NUMBER
        if(id == 'MobileNo'){
            let err = value.length == 0
                        ? "Mobile Number is required"
                        : value.length < 10
                        ? "Enter valid Mobile Number"
                        : !mobRegEx.test(value)
                            ? "Please include country code in mobile number, ex: +44"
                            : "";
            this.setState({  errors: {
                ...this.state.errors,
                    MobileNo: err,
            }, })
        }
        this.setState({
            AffiliateUser: {
                ...this.state.AffiliateUser,
                [event.target.id]: event.target.value,
            },
        });
    };

    onIdVerificationFileChange = (event) =>{       
        var verFile = event.target.files[0];       
        if (verFile == undefined) {
            return;
        }
        this.setState({
            AffiliateUser: {
                ...this.state.AffiliateUser,
                VerificationFile:verFile
            },
        });
    }

    onTermConditionChange = (event) => {
        let name = "isTermCondCheck";
        //  let value=event.target.files[0].name;
        let errors = this.state.errors;
        switch (name) {
          case "isTermCondCheck":
            this.state.errors.isTermCondCheck =
              this.state.isTermCondCheck == true
                ? "Please Check Term and Condition"
                : "";
            break;
          default:
            break;
        }
        this.setState(
          { errors, isTermCondCheck: !this.state.isTermCondCheck },
          () => {
          }
        );
      };

    handleSubmit = (event) => {        
        event.preventDefault();
        Utility.ShowLoading("Loader");
        //DO NOT ALLOW TO SUBMIT FORM IF MOBILE NUMBER IS INVALID AND NOT AGREE TERMS & CONDITION 
        if(this.state.errors.MobileNo.length>0 && this.state.errors.isTermCondCheck.length>0){
            Utility.HideLoading("Loader");
            return
        }
        let formData = new FormData();
        formData.append("Id", this.state.AffiliateUser.Id);
        formData.append("FirstName", this.state.AffiliateUser.FirstName);
        formData.append("LastName", this.state.AffiliateUser.LastName);
        formData.append("Email", this.state.AffiliateUser.Email);
        formData.append("Password", this.state.AffiliateUser.Password);
        formData.append("MobileNo", this.state.AffiliateUser.MobileNo);
        formData.append("Address", this.state.AffiliateUser.Address);
        formData.append("VerificationFile", this.state.AffiliateUser.VerificationFile);        

        Api.AffiliateSignup(formData, (res) => {
            if(res.status == 200){
                this.setState({SuccesfullSignup:true})
                Utility.HideLoading("Loader");
            } else{
                if(res.message){
                    this.setState({errorMsg:res.message })
                }
                Utility.HideLoading("Loader");
            } 
        });
    }



    render() {
       return (<div>
                <div>
                    <div className="homepage affiliate-page">
                        <div className="container">
                            <div className="content-section-inner">
                            <LeftContainer />
                            <div className="right-content">
                                {this.state.data.headerImage != undefined ?
                                    (<img src={this.state.data.headerImage} width="100%" />)
                                    : 
                                    ("")
                                }

                                <div className="content">
                                    <div className ="header">{this.state.data.heading}</div>
                                        <div className="affiliate-content">
                                            <div className="row">
                                            {
                                                this.state.data.content.map(function (cont, i) {
                                                    return <div className="col-md-4" key={i}>
                                                        <img src={cont.img} />
                                                        <div className="title"> {cont.title}</div>
                                                        <div className="description">{cont.description}</div>
                                                    </div>
                                                }, this)
                                            }
                                            </div>          
                                        </div>
                                   
                                </div>


                                {
                                    Utility.isExternalAffiliateForm() ? <iframe
                                    src="https://geoffbanks.formstack.com/forms/affiliate_registration"
                                    title="GBReg - AFFILIATE"
                                    width="100%"
                                    height="1000px"
                                    ></iframe> : <div className="affiliate-signup" id="Loader">
                                    {this.state.SuccesfullSignup?
                                        <div className="success-msg">
                                            Thanks for signing up. Please check your email for account details and getting started.
                                        </div>:
                                        <form  onSubmit={this.handleSubmit}>

                                        <div className="row">
                                            <div className="col-md-6 input-box">
                                                <label>First Name</label>
                                                <input 
                                                    id="FirstName"
                                                    type="text"
                                                    value={this.state.AffiliateUser.FirstName}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 input-box">
                                                <label>Last Name</label>
                                                <input 
                                                    id="LastName"
                                                    type="text"
                                                    value={this.state.AffiliateUser.LastName}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    required
                                                />
                                            </div>    
                                        </div>

                                        <div className="row">                                           
                                            <div className="col-md-6 input-box">
                                                <label>Mobile Number including country code (Ex: +44 888 888 8888)</label>
                                                <input 
                                                    id="MobileNo"
                                                    type="text"
                                                    value={this.state.AffiliateUser.MobileNo}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    required
                                                />
                                                {this.state.errors.MobileNo.length > 0  && (
                                                    <span className="error">
                                                        {this.state.errors.MobileNo}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6 input-box">
                                                <label>Password</label>
                                                <input 
                                                    id="Password"
                                                    type="password"
                                                    value={this.state.AffiliateUser.Password}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    required
                                                />
                                            </div>            
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-6 input-box">
                                                <label>Email</label>
                                                <input 
                                                    id="Email"
                                                    type="email"
                                                    value={this.state.AffiliateUser.Email}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 input-box">
                                                <label>ID Verification Document</label>
                                                <div className="input_block_inner">
                                                    <div className="file-upload-wrapper" data-text="Upload ID">
                                                    <input
                                                        type="file"
                                                        id="VerificationFile"
                                                        name="file-upload-field"
                                                        className="file-upload-field"
                                                        onChange={(e) => this.onIdVerificationFileChange(e)}
                                                        required
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">                                            
                                            <div className="col-md-12 input-box">
                                            <label>Address</label>
                                                <input 
                                                    id="Address"
                                                    type="text"
                                                    //placeholder="Address"
                                                    value={this.state.AffiliateUser.Address}
                                                    onChange={(e)=>this.handleChange(e)}
                                                    required
                                                />                                               
                                            </div>
                                        </div>
                                        <div className="termsAndCond">
                                            <label className="form-check-label checkbox-container" htmlFor="gridCheck">
                                            <small>
                                                I hereby agree to {" "}
                                                <a href="/show-page/affiliates"target="_blank">
                                                Affiliates for GB Sports Advisors ltd
                                                </a>
                                            </small>

                                            <input
                                                type="checkbox"
                                                checked={
                                                this.state.isTermCondCheck ? "checked" : ""
                                                }
                                            />
                                            <span
                                                className="checkmark"
                                                name="isTermCondCheck"
                                                onClick={(e) => this.onTermConditionChange(e)}>
                                            </span>

                                            </label>
                                            {this.state.errors.isTermCondCheck.length > 0 &&(
                                                <span className="error">
                                                {this.state.errors.isTermCondCheck}
                                                </span>
                                            )}
                                        </div>
                                        
                                                
                                        <div>
                                            {this.state.errorMsg.length > 0 ?
                                                <div className="error-msg">
                                                    {this.state.errorMsg}
                                                </div>:""
                                            }
                                            <div className="input-box sigupbtn">
                                                <button type="submit">Signup</button>
                                            </div>
                                        </div>
                                    </form>
                                    }
                                </div>
                                }
                                
                                
                            </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
           </div>)
    }
}

export default Affiliate;