import React from 'react';
import './style.css';

export const LiabilityExceedMessage = (props) => {
    const {
        closeMessage,
        betslip,
    } = props;

    const getMaxStakeAvailable = () => {
        const maxStakeAvailable = (betslip.wagersSubmitResult || [])
            .filter(wager => !wager.isSubmitted && wager.isLiabilityExceeded)
            .map(wager => wager.maxStakeAvailable);
        return maxStakeAvailable.length ? maxStakeAvailable[0] : null;
    };

    const getLiabilityExceededOn = () => {
        const liabilityExceededOn = (betslip.wagersSubmitResult || [])
            .filter(wager => !wager.isSubmitted && wager.isLiabilityExceeded)
            .map(wager => wager.liabilityExceededOn);
        return liabilityExceededOn.length ? liabilityExceededOn[0] : null;
    };

    const acceptLiabilityValue = () => {
        const maxStake = getMaxStakeAvailable();
        props.acceptLiabilityValue(maxStake);
    };

    return (
        <div className="stake-exceed-message">
            <div className="stake-exceed-message-header">
                <span className="stake-exceed-message-title">Liability exceeded</span>
                <button
                    className="stake-exceed-message-close"
                    onClick={closeMessage}>
                    ×
                </button>
            </div>

            <div className="stake-exceed-message-wrapper">
                <div className="stake-exceed-message-body">
                    <img src="/images/warningIcon.png" alt="type"></img>
                    <div>
                        <p>
                            Sorry, the maximum online liability {
                                getLiabilityExceededOn() != null ? ( ' (' + getLiabilityExceededOn() + ')' ) : null 
                            } has been exceeded.
                        </p>
                        <p>
                            Should you wish to place more than the permitted stake offered{
                                getMaxStakeAvailable() != null ? ( ' (' + getMaxStakeAvailable() + ').' ) : '.'
                            }
                        </p>
                        <p>
                            Please call the office on 01344 873334. Thank you.
                        </p>
                    </div>
                </div>
                <div className="stake-exceed-message-footer">
                    <p>Press Accept to take the New Stake and Place Bet.</p>
                </div>
                <div className="stake-exceed-message-buttons">
                    <button
                        className="message-button"
                        onClick={acceptLiabilityValue}>
                        Accept
                    </button>
                    <button
                        className="message-button"
                        onClick={closeMessage}>
                        Decline
                    </button>
                </div>
            </div>
        </div>
    );
};