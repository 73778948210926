import React from "react";
//import '../css/main.css'
import { compose } from "redux";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import SwiperContainer from "../Component/SwiperContainer";
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
//import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import * as Shared from "../Component/Shared/Index";
import AntePostEvents from "../Views/AntePostEvents";

var updatePrice
class AntePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      antePostTiming: [],
      SportCode: "",
      ShowLoader: true,
      antepostData: {},
      EventName: ""
      //antePostMeeting: []
    };

    //this.getAntepostMeetingEvents = this.getAntepostMeetingEvents.bind(this);
  }

  componentWillUnmount() {
    console.log("will unmount called");
    clearInterval(updatePrice);    
  }

  componentDidMount() {
    
    // this.antePost();
    const _this = this;
    const params = new URLSearchParams(_this.props.location.search);
    const sportcode = params.get("sportcode");
    localStorage.setItem("sportcode", sportcode);


    //ar myInfo = Api.GetSportInfo(sportcode);

    // var myInfo = Api.GetAntePostSportInfo(sportcode);
    // console.log("myInfo--->", myInfo);
    //_this.setState({ headerTitle: myInfo.Name });
    this.getAntepostData(sportcode);

    //Update price every 5 Sec if RefreshOdds is Enable 
    updatePrice = setInterval(() => {
      //Get refreshOddsEnable value from localstorage
      if (Utility.isRefreshOddsEnable()) {
        _this.getAntepostData(sportcode);
      } else {
        clearInterval(updatePrice);
      }
    }, 10 * 1000);
    
  }

  getAntepostData=(sportcode)=>{
    const _this = this;
    Api.GetAntepostEvents(sportcode, antePostEvents => {
      var antepost = [];
      if (typeof antePostEvents != undefined) {
        antepost = Utility.SortEvents(antePostEvents);
        // if (sportcode == "z" && myInfo.Name == "Baseball") {
        //   antepost = Utility.antepostFilter("Antepost Baseball", antepost);
        // }
      }

      //CHECKING FOR EVENT CODE HERE-> IF NOT AVAILABLE THEN USING EventFile from the api.
      if(antepost!==undefined&&antepost.length!==0){
      if(antepost[0].Meetings[0].EventCode==undefined){
        this.showAntepostBlock(
          antepost[0].Meetings[0].MeetinName,
          antepost[0].Meetings[0].EventFile, //USING EVENT FILE IF EVENTCODE UNDEFINED
          antepost[0].Meetings[0].EventGroup
        );
      }else{

      this.showAntepostBlock(
        antepost[0].Meetings[0].EventName,
        antepost[0].Meetings[0].EventCode, 
        antepost[0].Meetings[0].EventGroup
      );
    }
  }
    /////////////////////////////////////////////
      _this.setState({
        antePostTiming: antepost,
        SportCode: sportcode,
        ShowLoader: false
      });
    });
    
  }

  showAntepostBlock = (eventname, eventfile, eventgroup) => {
    var obj = {
      eventFile: eventfile,
      eventGroup: eventgroup,
      event: 0
    };

    this.setState({ antepostData: obj, EventName: eventname });
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 600);
  };

  render() {
    var content = (
      <div>
        <div className="homepage">
          <div className="container">
            <div className="content-section-inner">
              <LeftContainer />

              <div className="center-container">
                <div className=" antepost-matches">
                  <div className="match_result">
                    <div className="accordion-option">
                      <div className="col-md-6 accordion-header">
                        <h4>
                          <i className="fa fa-angle-down"></i>
                          AntePost
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <a
                          href="javascript:void(0)"
                          className="toggle-accordion active"
                          accordion-id="#accordion"
                        ></a>
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.state.antePostTiming == undefined ||
                      this.state.antePostTiming.length == 0 && !this.state.ShowLoader ? (
                        <div className="EmptyEvents">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          There are Currently no active events, Please check
                          back later.
                        </div>
                      ) : (
                        ""
                      )}
                      <Shared.Loader Show={this.state.ShowLoader} />

                      <div>
                        {/* <div className="event-name">{this.state.EventName}</div> */}
                        {this.state.antepostData.event!==undefined||this.state.antepostData.event == 0 ? (
                          <AntePostEvents
                            defaultEvent={this.state.antepostData}
                          />
                        ) : (
                          ""
                        )}
                      </div>                      
                      {this.state.antePostTiming.map(function(event, i) {
                        return (
                          <div className="panel panel-default" key={i}>
                            <div
                              className="panel-heading"
                              role="tab"
                              id="headingOne"
                            >
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href={"#collapse" + i}
                                  aria-expanded="true"
                                  aria-controls={"collapse" + i}
                                >
                                  {event.EventName}
                                </a>
                              </h4>
                              .
                            </div>
                            <div
                              id={"collapse" + i}
                              className="panel-collapse collapse in"
                              role="tabpanel"
                              aria-labelledby="headingOne"
                            >
                              <div className="panel-body">
                                {event.Meetings.map(function(meeting, j) {
                                  return (
                                    <div key={j}>
                                      <div className={"antepost-main"}>
                                        <a className="antepost-link">
                                          <div
                                            className="antepost-name"
                                            onClick={() =>
                                              meeting.EventCode==undefined||meeting.EventName==undefined?
                                              this.showAntepostBlock(
                                              meeting.MeetinName,
                                              meeting.EventFile, //USING EVENT FILE IF EVENTCODE UNDEFINED
                                              meeting.EventGroup
                                              )
                                              :
                                              this.showAntepostBlock(
                                                meeting.EventName,
                                                meeting.EventCode,
                                                meeting.EventGroup
                                              )
                                            }
                                          >
                                            {meeting.MeetingName==undefined?meeting.MeetinName:meeting.MeetingName}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  );
                                }, this)}
                              </div>
                            </div>
                          </div>
                        );
                      }, this)}
                    </div>
                  </div>
                </div>
              </div>

              <RightContainer />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default AntePost;
