import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel, Alert } from "react-bootstrap";
//import Iframe from 'react-iframe'

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {
        return (
            <div className="sign-up">
                <div className='row1'>
                    <div className="headerLogo1">
                        <div className='gb-logo'>

                        <a href="/"><img src="/images/logo.svg" alt="Geoff Banks"></img></a>
                        </div>

                    </div>


                    <div className='go-back'>
                        <a href="/"><img src="/images/left-arrow.png" alt="go back" className='back'></img></a>
                    </div>
                </div>



                <div>
                    <iframe height="1000"
                        allowtransparency="true"
                        frameBorder="0"
                        scrolling="no"
                        className="forgetIframe"
                        src=" https://geoffbanks.wufoo.com/forms/p4muiq90sozq0c" >
                        <a href=" https://geoffbanks.wufoo.com/forms/p4muiq90sozq0c">
                            Fill out my Wufoo form!
                        </a>
                    </iframe >
                </div>
            </div>
        );
    }
}