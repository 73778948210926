import React from 'react'
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as utility from "../../js/utility";
import * as Api from "../../js/api-service";
import $ from "jquery";
import moment from "moment";

class PaydooDeposit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuccess: false,
            showError: false,
            amount: "",
            shopperResultUrl: window.location.origin + window.location.pathname,
            checkoutId: "",
            endTimeForId: 0,
            basePaydooUri: ""
        };
    }

    componentDidMount() {
        const {checkoutId, basePaydooUri, endTimeForId} = this.state;
        if(utility.checkAuth() != undefined) {
            this.addPaydooOptions()
            if (checkoutId && checkoutId.length > 0 && basePaydooUri && basePaydooUri.length > 0) {
                // if checkoutId is active check timestamp
                if (new Date().getTime() < endTimeForId.getTime()) {
                    this.importJS(basePaydooUri)
                } else {
                    utility.ShowNotification("Time to pay is over!");
                    this.setState({checkoutId: "", basePaydooUri: ""})
                }
            } else {
                const params = new URLSearchParams(window.location.search);
                if (params.get("resourcePath") && params.get("id")) {
                    utility.ShowLoading("Loader");
                    this.getPaymentStatus(params.get("id"))
                }
            }
        } else {
            this.props.history.push("/");
            utility.ShowNotification(
                "Please Sign in to your account to access this page!"
            );
        }
    }

    addPaydooOptions = () => {
        window['wpwlOptions'] = {
            maskCvv: true,
            brandDetection: true,
            registrations: {
                requireCvv: true
            },
            onReady: function() {
                var createRegistrationHtml = '<div class="customLabel">Store payment details?</div><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
                $('form.wpwl-form-card').find('.wpwl-button').before(createRegistrationHtml);
            }
        };
    }

    importJS = (uri) => {
        let script = document.createElement('script');
        let head   = document.getElementsByTagName('head')[0];
        script.type = 'text/javascript';
        script.src  = uri;
        head.appendChild(script);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (parseFloat(this.state.amount) < 10) {
            utility.ShowNotification("Minimum amount for deposit is £10");
            return;
        }

        utility.ShowLoading("Loader");
        const details = {
            amount: Number(this.state.amount).toFixed(2),
            currency: 'GBP',
            paymentType: 'DB',
            user: localStorage["username"]
        }

        Api.getCheckoutIdForPaydoo(details, data => {
            utility.HideLoading("Loader");
            if (data.id && data.id.length > 0 && data.url && data.url.length > 0) {
                if (new Date().getTime() < moment(data.timestamp).add(30, 'minutes').valueOf()) {
                    this.setState({
                        basePaydooUri: data.url,
                        checkoutId: data.id,
                        endTimeForId: Date.parse(data.timestamp) + 60*60000
                    })
                    this.importJS(data.url)
                } else {
                    utility.ShowNotification("Time to pay is over!");
                    this.setState({checkoutId: "", basePaydooUri: ""})
                }
            }
        })
    }

    getPaymentStatus = (id) => {
        Api.getPaydooPaymentStatus(id, localStorage["username"], data => {
            utility.HideLoading("Loader");
            let successfullyRegex = /^(000\.000\.|000\.100\.1|000\.[36])/
            let successfullyManuallyRegex = /^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)/
            if (successfullyRegex.test(data.result.code) || successfullyManuallyRegex.test(data.result.code)) {
                this.setState({showSuccess: true})
            } else {
                this.setState({showError: true})
            }
            window.history.pushState({}, document.title, window.location.pathname);
        })
    }

    renderPaymentWidget = () =>{
        return(
            <div className="deposit-form">
                <form action={this.state.shopperResultUrl} className="paymentWidgets" data-brands="MASTER VISA"></form>
            </div>
        )
    }

    renderForm = () => {
        return(
            <form
                className="my-profile-form"
                id="deposit-form"
                onSubmit={this.handleSubmit}
            >
                <div id="formContent">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block">
                                <label className="amountTitle">
                                    ENTER AMOUNT TO DEPOSIT</label>
                                <br/>
                                <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    step={"0.01"}
                                    min={1.0}
                                    className="fadeIn"
                                    placeholder="Enter Amount"
                                    value={this.state.amount}
                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="depositBtn">
                        <button type="submit">Deposit</button>
                    </div>
                </div>
            </form>
        )
    }

    renderMessage = () => {
        const image = (this.state.showSuccess)
            ? (<img src={"../images/checkCircle.png"} alt="" />)
            : (<img
                src="/images/warningIcon.png"
                className="warningIcon"
                width="50px"
                alt=""
            />)
        const message = (this.state.showSuccess)
            ? "Payment completed successfully!"
            : "Payment declined!"

        return (
            <div className="depositMessage bgBlack">
                {image}
                <p>{message}</p>
                <span>
                    FEEL FREE TO GET IN TOUCH WITH OUR SUPPORT TEAM IN CASE
                    YOU NEED FURTHER HELP ON THIS.
                </span>
                <div className="depositBtn">
                    <button className="depositBtn_btn" onClick={() => {this.props.history.push("/")}}>Return to the homepage</button>
                </div>
            </div>
        )
    }

    render() {
        const {checkoutId, showSuccess, showError} = this.state;

        let depositContent = this.renderForm();
        if (checkoutId.length === 0 && (showSuccess || showError)) {
            depositContent =  this.renderMessage()
        }
        if (checkoutId.length > 0) depositContent = this.renderPaymentWidget()

        return (
            <div>
                <div className="my-account">

                    <div className="container">

                        <AccountSidebar />
                        <div className="account-right-container">

                            <div className="transaction" id="Loader">

                                <div className="acount-inner">
                                    <h3>Online card payments</h3>
                                </div>
                                {depositContent}

                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        )
    }
}

export default PaydooDeposit;