import React from "react";
import * as Api from "../js/api-service";
import subscribe from "unstated-subscribe-hoc";
import {BetSlipContainer} from "../Containers/BetSlipContainer";
import {PreferenceContainer} from "../Containers/PreferenceContainer";
// import LiveRaces from "../Component/LiveRaces";
import NextRace from "../Component/NextRaces";
import * as Shared from "./Shared/Index";
import PlacementBanners from "../Component/PlacementBanners";
import CustomNotifications from "../Component/CustomNotifications";

var requestComplete = true;

class MainLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            race1: {},
            race2: {},
            race3: {},
            races: [],
            threeRaces: [],
            ShowLoader: true,
            SkipLoading: false
        };
        this.LoadLiveData = this.LoadLiveData.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.LoadFromProps(newProps);
    }

    LoadFromProps(props) {
    }

    componentDidMount() {
        var _this = this;
        this.keepLoading = setInterval(function () {
            if (requestComplete)
                _this.LoadFromProps(_this.props); //Loading props here for the right container next races block
            _this.LoadLiveData("");
        }, 5000);
    }

    LoadLiveData(sportcode) {
        requestComplete = false;
        if (this.props.sportcode != undefined || this.props.sportcode != null) {
            sportcode = this.props.sportcode;
        }


        Api.GetSingleRace(
            sportcode,
            (liveraces) => {
                if (liveraces !== undefined && liveraces.length !== 0) {
                    var races = liveraces.map((v) => {
                        return v;
                    });

                    // if (this.props.sportcode != undefined) {
                    //   console.log(races.slice(0, 1), "in if block");
                    //   races = races.slice(0, 1);
                    // }

                    if (this.props.isFromRightBlock !== undefined && this.props.isFromRightBlock != null && this.props.isFromRightBlock === "true") {
                        races = races.slice(0, 1);
                    }

                    this.setState({races: races});
                    this.setState({ShowLoader: false});
                    this.setState({race1: liveraces[0]});
                    this.setState({race2: liveraces[1]});
                    this.setState({race3: liveraces[2]});

                    var threeRaces = [];
                    if (liveraces[0].Races !== undefined && liveraces[1].Races !== undefined && liveraces[2].Races !== undefined) {
                        threeRaces.push(
                            liveraces[0].Races[0].EventId,
                            liveraces[1].Races[0].EventId,
                            liveraces[2].Races[0].EventId
                        );
                        this.setState({threeRaces: threeRaces});
                    }
                    requestComplete = true;
                }
            },
            (err) => {
                this.setState({ShowLoader: false});
                requestComplete = true;
            }
        );
    }

    render() {
        if (this.state.SkipLoading) return ""; //DON'T LOAD FOR VIRTUAL RACES
        return (
            <div className="mainLive">
                <PlacementBanners placementbannername={"AboveLiveRaces"}/>
                {this.state.threeRaces.length > 0 ? (
                    <CustomNotifications eventID={this.state.threeRaces}/>
                ) : (
                    ""
                )}
                <div className="next-race">NEXT RACES</div>

                {/* <div className="row nextrace_sections">
          {!_.isEmpty(this.state.race1) ? (
            <div className={_.isEmpty(this.state.race2) ? "col-md-12" : "col-md-6"}>
              <NextRace mainState={this.state.race1} />
            </div>
          ) : (
            ""
          )}
          {!_.isEmpty(this.state.race2) ? (
            <div className={_.isEmpty(this.state.race3) ? "col-md-6" : "col-md-4"}>
              <NextRace mainState={this.state.race2} />
            </div>
          ) : (
            ""
          )}
          {!_.isEmpty(this.state.race3) ? (
            <div className="col-md-4">
              <NextRace mainState={this.state.race3} />
            </div>
          ) : (
            ""
          )}
        </div> */}
                {this.state.races.length > 0 ? (
                    <div className="row nextrace_sections">

                        {this.state.races.map((data, index) => {
                            return (
                                <div
                                    className={
                                        this.state.races.length == 1
                                            ? "col-md-12 single"
                                            : this.state.races.length == 2
                                            ? "col-md-6"
                                            : this.state.races.length == 3
                                                ? "col-md-4"
                                                : ""
                                    }
                                    key={index}
                                >
                                    <NextRace mainState={data} limit={this.props.limit}/>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Shared.Loader Show={this.state.ShowLoader}/>
                )}
                {this.props.sportcode == undefined ?
                    <div className="BelowLiveRacesBanner">
                        <PlacementBanners placementbannername={"BelowLiveRaces"}/>
                    </div>
                    : ""
                }
            </div>
        );
    }
}

export default subscribe(MainLive, {
    Store: BetSlipContainer,
    PreferenceStore: PreferenceContainer,
});
