import React from "react";
import { StakeInput } from "../StakeInput";
import "./style.css";

export const BetSlipWagers = (props) => {
  const {
    selectedWagerIndex,
    setSelectedWagerIndex,
    wagers,
    betList,
    setKeepBetslip,
    keepBetslip,
    isZeroStake,
    setIsZeroStake,
    setSelectedWagerEachway,
    selectedWagerEachway,
    selectedWagerStake,
    setSelectedWagerStake,
    acceptPriceChanges,
    setAcceptPriceChanges,
  } = props;

  const renderEachWay = () => {
    if (selectedWagerIndex !== -1 && wagers[selectedWagerIndex].isEachWay) {
      return (
        <div className="each-way">
          <input
            type="checkbox"
            checked={!!selectedWagerEachway}
            onChange={(e) => setSelectedWagerEachway(e.target.checked)}
          />
          <div className="wager-condition">Each Way</div>
        </div>
      );
    }
    return (
      <div className="each-way win-only">
        <span className="win-only">Win only</span>
      </div>
    );
  };

  const renderWagerInput = () => {
    if (selectedWagerIndex !== -1 && wagers[selectedWagerIndex]) {
      return (
        <React.Fragment>
          {renderEachWay()}
          <div className="potential-return-wrapper">
            <StakeInput
              selectedWagerIndex={selectedWagerIndex}
              wagers={wagers}
              betList={betList}
              isZeroStake={isZeroStake}
              setIsZeroStake={setIsZeroStake}
              selectedWagerStake={selectedWagerStake}
              setSelectedWagerStake={setSelectedWagerStake}
              selectedWagerEachway={selectedWagerEachway}
            />
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  const renderNumberOfLines = () => {
    if (
      wagers[selectedWagerIndex] &&
      wagers[selectedWagerIndex].numberOfLines
    ) {
      return (
        <div className="wager-lines">
          ({wagers[selectedWagerIndex].numberOfLines} Lines)
        </div>
      );
    }
    return null;
  };

  return (
    <div className="wager-form">
      <div className="titles-wrapper">
        <h3 className="bet-title">Wager type</h3>
        <div className="bet-condition">(win only)</div>
      </div>

      <div className="wager-list-wrapper">
        <div className="wager-list">
          <div className="wager-item">
            <div className="wager-type-wrapper">
              <select
                id="wagerSelector"
                className="wager-type"
                onChange={(e) => setSelectedWagerIndex(e.target.value)}
                placeholder="Select Wager"
              >
                {props.wagers.map((wager, wagerIndex) => (
                  <option
                    className="wager-type-option"
                    key={wagerIndex}
                    value={wagerIndex}
                  >
                    {wager.name}
                  </option>
                ))}
              </select>

              {renderNumberOfLines()}
            </div>

            {renderWagerInput()}
          </div>
        </div>
      </div>

      <label className="retain-betslip-label">
        <input
          type="checkbox"
          className="retain-betslip-checkbox"
          checked={!!keepBetslip}
          onChange={(e) => setKeepBetslip(e.target.checked)}
        />
        Retain Selections in Betslip
      </label>

      <label className="retain-betslip-label">
        <input
          type="checkbox"
          className="retain-betslip-checkbox"
          checked={!!acceptPriceChanges}
          onChange={(e) => setAcceptPriceChanges(e.target.checked)}
        />
        Accept Any Price Changes
      </label>
    </div>
  );
};
