import * as axios from 'axios';
import { AUTH_TOKEN_KEY } from '../_constants/localStorageKeys';
import { localStorageService } from './localStorageService';

let betslipApiService = null;

export const betslipApi = (logoutIfError) => {
    if (betslipApiService) {
        return betslipApiService;
    }
    
    betslipApiService = (function(logoutIfError) {
        function BetslipApiSingleton() {
            let authToken = null;
            
            let client = null;
            const baseUrl = process.env.REACT_APP_ENV === 'prod'
                ? `${process.env.REACT_APP_API_URL}/api`
                : '/api';
        
            const init = (isLoggedIn) => {
                let headers = {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                };
    
                if (isLoggedIn) {
                    authToken = localStorageService.getItem(AUTH_TOKEN_KEY);        
                    if (authToken) {
                        headers.Authorization = `Bearer ${authToken}`
                    }
                }
    
                client = axios.create({
                    baseURL: baseUrl,
                    timeout: 350000,
                    headers
                });
    
                client.interceptors.response.use(
                    response => {
                        return response;
                    },
                    error => {
                        if (error.response.status === 401) {
                            logoutIfError();
                        }
                        return error;
                    }
                );
        
                return client;
            };
        
            const getActiveBetslip = (betslipId, isLoggedIn) => {
                return init(isLoggedIn)
                    .get(`/betslip/${betslipId}`)
                    .then(resp => resp.data);
            };
        
            const createEmptyBetslip = (isLoggedIn) => {
                return init(isLoggedIn)
                    .post('/betslip', null)
                    .then(resp => resp.data);
            };
        
            const addBetToBetslip = (bet, betslipId, isLoggedIn) => {
                return init(isLoggedIn)
                    .post(`/betslip/${betslipId}`, bet)
                    .then(resp => resp.data);
            };
        
            const deleteBetFromBetslip = (betslipId, betId, isLoggedIn) => {
                return init(isLoggedIn)
                    .delete(`/betslip/${betslipId}/${betId}`)
                    .then(resp => resp.data);
            };
        
            const clearBetslip = (betslipId, isLoggedIn) => {
                return init(isLoggedIn)
                    .delete(`/betslip/${betslipId}`)
                    .then(resp => resp.data);
            };
        
            const submitBetslip = (betslip, isLoggedIn) => {
                return init(isLoggedIn)
                    .post('/betslip/submit', betslip)
                    .then(resp => resp.data);
            };
    
            const submitBetslipAsync = (betslip, isLoggedIn) => {
                return init(isLoggedIn)
                    .post('/betslip/submitasync', betslip)
                    .then(resp => resp.data);
            };
        
            return {
                getActiveBetslip,
                createEmptyBetslip,
                addBetToBetslip,
                deleteBetFromBetslip,
                clearBetslip,
                submitBetslip,
                submitBetslipAsync,
            };
        }
    
        let instance = null;
    
        if (instance == null) {
            instance = new BetslipApiSingleton();
    
            // Hide the constructor so the returned object can't be new'd...
            instance.constructor = null;
        }
        return instance;
    })(logoutIfError);

    return betslipApiService;
}