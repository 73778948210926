import { AUTH_TOKEN_EXPIRED_AT } from "../_constants";
import { localStorageService } from "../_services";

export const isTokenExpired = () => {
    let tokenExpirationDate = localStorageService.getItem(AUTH_TOKEN_EXPIRED_AT);
    if (!tokenExpirationDate) {
        return true;
    }

    const dateNow = new Date();
    const dateNowInUTCMs = Date.UTC(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
        dateNow.getUTCHours(),
        dateNow.getUTCMinutes(),
        dateNow.getUTCSeconds()
    );
    const tokenExpirationDateMs = new Date(tokenExpirationDate).getTime();

    if (tokenExpirationDateMs <= dateNowInUTCMs) {
        return true;
    }
    return false;
};