import React, {useEffect, useState} from 'react';

export default function TransactionBlock(props) {
    const [wagers, setWagers] = useState([]);

    useEffect(() => {
        if (props.data && props.data.Wagers && props.data.Wagers.length !== 0) {
            setWagers(props.data.Wagers)
        }
    }, [props])

    function wagersText(data) {
        let fullText = ''
        if (data) {
            if (data.Odds) {
                fullText = ' ' + data.Odds
            }
            if (data.Price && data.Price != null) {
                fullText = fullText + ' ' + data.Price
            }
            if (data.SelectionName && data.SelectionName != null) {
                fullText = fullText + ' ' + data.SelectionName
            }
            if (data.WagerText && data.WagerText != null) {
                fullText = fullText + ' ' + data.WagerText
            }
        }
        return fullText
    }

    if (wagers && wagers !== null && wagers !== undefined && wagers.length) {
        return (
            <div className='transaction-block'>
                {wagers.map(item => (
                    <div className='transaction-data'>
                        <h2 className='block-data'>{wagersText(item)}</h2>
                        <h2 className='block-data-place'>{item.Place ? item.Place : 'N/A'}</h2>
                    </div>

                ))}
            </div>
        )
    } else return "";
}
