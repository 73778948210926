import React from 'react';
import { google } from "../js/utility"
import * as Api from "../js/api-service";
import Select from "react-select";
import _ from "lodash";

class AddressAutoComplete extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.initialState()
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
        this.autocomplete = null
      }
    
      componentDidMount() {
        var options = {
          //types: ['(cities)'],
          componentRestrictions: {country: 'gb'}//show UK places only
        };
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),
        options)
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
      }
    
      initialState() {
        return {
            street_number: "",
            route: "",
            locality: "",
            administrative_area_level_1: "",
            administrative_area_level_2:"",
            country: "",
            postal_code: "",
            postal_town:"",
            isZipCodeRequired:false,
            showAddressdrp: false,
            isAddressFieldVisible: false,
            addressList: [],
            isAddressFieldVisible: false,
            isInvalidCode: false,
        }
      }
  
 
      handlePlaceSelect() {
        let place = this.autocomplete.getPlace()
        if(place.address_components!=undefined){
          for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {
              if (place.address_components[i].types[j] == "postal_code") {
                //document.getElementById('postal_code').innerHTML = place.address_components[i].long_name;
                this.setState({postal_code:place.address_components[i].long_name})
                this.props.onChangeValue(this.state.postal_code)
              }
            }
          }
        }else if(place.name){
          this.props.onChangeValue(place.name)
        }
      }

    
      render() {
        return(
          <div>
              <input id="autocomplete"
                // className="input-field"
                className="form-control"
                ref="input"
                type="text"
                placeholder={this.props.placeholder}/>
          </div>
        )
      }
    


}
export default AddressAutoComplete;