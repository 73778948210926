import React from "react";
import { Button, Modal } from "react-bootstrap";
import subscribe from "unstated-subscribe-hoc";
import BetSlipContent from "../Component/BetSlipContent";
import BetPlacedModal from "../Component/BetPlacedModal";
import { AccountContainer } from "../Containers/AccountContainer";
import * as $ from "jquery";
import _ from "lodash";
import * as Api from "../js/api-service";
import * as betSlip from "../js/bet-slip";
import * as utility from "../js/utility";
import MainLive from "../Component/mainLive";
import PlacementBanners from "../Component/PlacementBanners";
//import { Link } from 'react-router-dom';

var banner;
class RightContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
    };
  }

  async componentDidMount() {
    var rightBannerId = 4;
    var payPalId = 8;

    let res = await Api.GetBanner();
    var data = res.data.data;
    var banners = data.filter((k) => {
      return k.id == rightBannerId || k.id == payPalId;
    });
    this.setState({ banner: banners });
    this.props.AccountStore.GetSetting();
  }

  render() {
    return (
      <div className="right-container bet-slip">

        <PlacementBanners
          placementbannername={"AboveBetSlip"}
        />
        <div className="right-sidebar">

          <BetSlipContent />
        </div>
        <PlacementBanners
          placementbannername={"BelowBetSlip"}
        />
        <div className="greyhound">
          <MainLive sportcode="g,q" limit="6" isFromRightBlock="true" />
        </div>
        {utility.IsCustomerLoggedIn() ? (
          <div className="nano_player"></div>
        ) : (
            ""
          )}

        {this.state.banner.length > 0 ? (
          <div>
            {!this.props.AccountStore.state.Setting
              .disable_rightbanner_on_loggedin &&
              utility.IsCustomerLoggedIn() &&
              this.state.banner[0].image != undefined &&
              this.state.banner[0].status == "published" ? (
                <div className="RightBanner">
                  <a href={this.state.banner[0].link}>
                    <img src={this.state.banner[0].image.data.full_url} />
                  </a>
                </div>
              ) : !utility.IsCustomerLoggedIn() &&
                this.state.banner[0].image != undefined &&
                this.state.banner[0].status == "published" ? (
                  <div className="RightBanner">
                    <a href={this.state.banner[0].link}>
                      <img src={this.state.banner[0].image.data.full_url} />
                    </a>
                  </div>
                ) : (
                  ""
                )}
            {!this.props.AccountStore.state.Setting
              .disable_rightbanner_on_loggedin &&
              utility.IsCustomerLoggedIn() &&
              this.state.banner[1].image != undefined &&
              this.state.banner[1].status == "published" ? (
                <div className="RightBanner">
                  <a href={this.state.banner[1].link}>
                    <img
                      className="banner_logo"
                      src={this.state.banner[1].image.data.full_url}
                    />
                  </a>
                </div>
              ) : !utility.IsCustomerLoggedIn() &&
                this.state.banner[1].image != undefined &&
                this.state.banner[1].status == "published" ? (
                  <div className="RightBanner">
                    <a href={this.state.banner[1].link}>
                      <img
                        className="banner_logo"
                        src={this.state.banner[1].image.data.full_url}
                      />
                    </a>
                  </div>
                ) : (
                  ""
                )}
          </div>
        ) : (
            ""
          )}

        <PlacementBanners
          placementbannername={"RightSidebarBelowBanner"}
        />

        {/* stream */}
      </div>
    );
  }
}

export default subscribe(RightContainer, {
  AccountStore: AccountContainer,
});
