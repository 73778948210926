import React from 'react';
import { BetSlipLadder } from '../BetSlipLadder';
import './style.css';

export const BetSlipActions = (props) => {
    const {
        isBetslipLoaded,
        submitBetslip,
        loginOrSignup,
        addStakeToSelected,
        isStakeLadderHidden,
        isFormDisabled,
        isZeroStake,
    } = props;

    const renderActionButton = () => {
        if (!props.IsCustomerLoggedIn()) {
            return (
                <button
                    className="bet-slip-submit-login"
                    type="button"
                    onClick={() => loginOrSignup()}>
                    Please Login/Signup to Place the Bet
                </button> 
            );
        }
        return (
            <div className="submit-betslip-wrapper">
                <button
                    className="bet-slip-submit"
                    type="button"
                    disabled={!isBetslipLoaded || isFormDisabled || isZeroStake}
                    onClick={() => submitBetslip()}>
                    Place bet
                </button>
                <div className="bet-slip-warning">Just press once</div>
            </div>
        );
    };

    return (
        <div>
            <div className="bet-slip-actions">
                { renderActionButton() }
            </div>

            { isStakeLadderHidden ? null : (
                <BetSlipLadder
                    isBetslipLoaded={isBetslipLoaded}
                    addStakeToSelected={addStakeToSelected}
                />
            ) }
        </div>
    );
};