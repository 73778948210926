import React from 'react'
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as Utility from "../../js/utility";

class ResponsibleGambling extends React.Component {
    componentDidMount() {
        
    }
    render() {
        var content = (
            <div>
                <div className="my-account">

                    <div className="container">

                        <AccountSidebar />
                        <div className="account-right-container">


                            <div className="transaction">

                                <div className="acount-inner">
                                    <h3>Responsible Gambling</h3>
                                </div>


                                <div style={{padding: '8px 6px', background: '#eeeeee',overflow:'scroll',height:'576px'}}>
                                    <iframe height="485"
                                        allowtransparency="true"
                                        frameBorder="0"
                                        scrolling="no"
                                        className="responsible-gambling"
                                        //src="https://geoffbanks.wufoo.com/forms/?formname=sub1d4h1nd8kqs&embed=1&embedKey=sub1d4h1nd8kqs508561&entsource=&referrer=http:wuslashwuslashv0.geoff-banks.comwuslashclosure.asp" 
                                        src="https://geoffbanks.wufoo.com/forms/r6vu9ru1jw3aij">
                                        <a href="https://geoffbanks.wufoo.com/forms/r6vu9ru1jw3aij">
                                        </a>
                                    </iframe >
                                </div>








                            </div>




                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}


export default ResponsibleGambling;