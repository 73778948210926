import React, { Component } from "react";
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as Shared from "../../Component/Shared/Index";
import * as Api from '../../js/api-service';
import * as utility from '../../js/utility';
import $ from 'jquery';
import { ClimbingBoxLoader } from "react-spinners";


export default class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowLoader: false,
            file: 'https://www.nextpageit.com/wp-content/uploads/2019/09/Development-Solutions.png',
            firstname: "",
            lastname: "",


        }
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        $("form").on("change", ".file-upload-field", function () {
            $(this).parent(".file-upload-wrapper").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
        });

        $("form").on("change", ".file-upload-field-utility-bill", function () {
            $(this).parent(".file-upload-wrapper-utility-bill").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
        });



        utility.ShowLoading("Loader");

        //this.setState({ ShowLoader: true });
        var that = this;

        utility.GetMyProfileDetailsById(21, response => {

            var doc_id = response[0].doc_id;
            var doc_utility_bill = response[0].doc_utility_bill;
            var profile_pic = response[0].profile_pic;
            var first_name = response[0].first_name;
            var last_name = response[0].last_name;

            $(".file-upload-wrapper").attr("data-text", doc_id);
            $(".file-upload-wrapper-utility-bill").attr("data-text", doc_utility_bill);

            this.setState({
                file: profile_pic,
                firstname: first_name,
                lastname: last_name,
                upload_id: doc_id,
                upload_utility_bill: doc_utility_bill
            })
            utility.HideLoading("Loader");
        })
    }

    async handleSubmit(event) {
        event.preventDefault();
        utility.ShowLoading("Loader");

        let formData = new FormData();

        if (typeof event.target["file-upload-field"].files[0] === "undefined") {

            formData.append("docid", this.state.upload_id);
        }
        else {
            formData.append("docid", event.target["file-upload-field"].files[0]);
        }


        if (typeof event.target["file-upload-field-utility-bill"].files[0] === "undefined") {
            formData.append("docutilitybill", this.state.upload_utility_bill);
        }
        else {
            formData.append("docutilitybill", event.target["file-upload-field-utility-bill"].files[0]);
        }


        if (typeof event.target["myFile"].files[0] === "undefined") {
            formData.append("profilepic", this.state.file)
        }
        else {
            formData.append("profilepic", event.target["myFile"].files[0]);
        }

        formData.append("firstname", event.target.firstname.value);
        formData.append("lastname", event.target.lastname.value);
        formData.append("userid", 21);

      utility.HideLoading("Loader");


        utility.UpdateMyProfileDetail(formData, response => {
            utility.HideLoading("Loader");
        })

    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });

    }
    onChangeHandler(event) {
        let f = event.target.files[0];
        const data = new FormData();
        data.append("myFile", f);
        this.setState({
            file1: f,
            file: URL.createObjectURL(f)
        });


    };

    render() {

        return (
            <div>
                <div className="my-account">
                    <div className="container">
                        <AccountSidebar />
                        <div className="account-right-container">
                            <div className="sign-up user-detail myProfile">
                                <div className="acount-inner">
                                    <h3>Update My Profile</h3>
                                </div>

                                <div className="user-details myProfileForm " id="Loader">


                                    <form className="my-profile-form" id="my-profile" onSubmit={this.handleSubmit}>
                                        <div id="formContent">

                                            <div className="row">

                                                <div className="client_profile">


                                                    <input type="file" name="myFile" onChange={this.onChangeHandler} />
                                                    <img src={this.state.file} alt="pic" />
                                                </div>
                                                <div className="input-block">
                                                    <label>First Name</label>
                                                    <input type="text" name="firstname" id="firstname" className="fadeIn" placeholder="First Name"
                                                        value={this.state.firstname}
                                                        onChange={this.handleChange} required />
                                                </div>
                                                <div className="input-block">
                                                    <label>Last Name</label>
                                                    <input type="text" id="lastname" name="lastname" className="fadeIn" placeholder="Last Name"
                                                        value={this.state.lastname}
                                                        onChange={this.handleChange} required />
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="hr_line">
                                                            <label>Upload Documents</label>
                                                            <hr />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="input-block">
                                                    <label>ID</label>

                                                    <div class="input_block_inner">

                                                        <div class="file-upload-wrapper" data-text="Upload ID">
                                                            <input name="file-upload-field" type="file" class="file-upload-field" />
                                                        </div>
                                                        <a href={this.state.upload_id} class="icn"> <i class="fa fa-download" aria-hidden="true"></i>Download</a>

                                                    </div>
                                                </div>
                                                <div className="input-block">
                                                    <label>Utility Bill</label>
                                                    <div class="input_block_inner">

                                                        <div class="file-upload-wrapper-utility-bill" data-text="Upload Utility Bill">
                                                            <input name="file-upload-field-utility-bill" type="file" class="file-upload-field-utility-bill" />
                                                        </div>
                                                        <a href={this.state.upload_utility_bill} class="icn"> <i class="fa fa-download" aria-hidden="true"></i>Download</a>

                                                    </div>
                                                </div>


                                            </div>


                                            <div className="row1 save-button">
                                                <input
                                                    type="submit"
                                                    className="fadeIn fourth submit sub"
                                                    value="Update"

                                                />
                                                <input
                                                    type="reset"
                                                    className="fadeIn fourth submit button cancel"
                                                    value="Cancel"
                                                />


                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        );
    }
}