import React, { Component } from "react";
import * as ApiService from "../js/api-service";
import * as Utility from "../js/utility";

export default class LeftHighlights extends Component {
  constructor(props) {
    super(props);
    //console.log(props, 'props')
    this.state = {
      featuredSports: [],
    };
  }

  componentDidMount() {
    ApiService.getFeaturedSports((res) => {
      var data = res["data"];
      this.setState({ featuredSports: data });
    //   console.log(this.state, "ABC");
    });
  }
  render() {
    return (
      <div className="left-sidebar_1">
        <h4>
          <i className="fa fa-chevron-down"></i>
          Highlights
        </h4>

        {this.state.featuredSports.map((sport, index) => {
          return (
            <a
              key={index}
              href={
                "/sports/" +
                Utility.getLowerCase(sport.name) +
                "?sportcode=" +
                sport.sport_code
              }
            >
              <h3>
                <em>
                  <img
                    src={
                      sport.image != null
                        ? sport.image.data.full_url
                        : "/images/Image_27.png"
                    }
                  />
                </em>
                {sport.name}
              </h3>
            </a>
          );
        })}

        {/* <a target="_blank" href="http://www.geoffbankscasino.bet">
          <h3>
            <em>
              <img src={"/images/casino.png"} />
            </em>
            Online Casino
          </h3>
        </a> */}

        {/*         
        <a href="/sports/football?sportcode=f">
          <h3>
            <em>
              <img src="/images/Image_27.png" />
            </em>
            Football
          </h3>
        </a>
        <a href="/sports/tennis?sportcode=N">
          <h3>
            <em>
              <img src="/images/tennis.png" />
            </em>
            Tennis
          </h3>
        </a>
        <a href="/sports?sportcode=H,h">
          <h3>
            <em>
              <img src="/images/hourse.png" />
            </em>
            Horse Racing
          </h3>
        </a>
        <a href="/sports/basketball?sportcode=E">
          <h3 className="last">
            <em>
              <img src="/images/basketball.png" />
            </em>
            Basketball
          </h3>
        </a> */}
      </div>
    );
  }
}
