import React from "react";
import "../css/main.css";
import * as Utility from "../js/utility";
import * as Shared from "./Shared/Index";
import subscribe from "unstated-subscribe-hoc";
import { BetSlipContainer } from "../Containers/BetSlipContainer";
import { PreferenceContainer } from "../Containers/PreferenceContainer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import _ from "lodash";
import * as Api from "../js/api-service";
import * as $ from "jquery";
import { SSL_OP_MICROSOFT_SESS_ID_BUG } from "constants";
var arr = "";
var CurrentSlideIdx = 0;
var prevSliderData = 0;
//var isComplete="true";
class InPlayEvents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      Events: [],
      headerTitle: "",
      showLess: false,
      FullCardOptions: [],
      SportCode: "",
      ShowLoader: true,
      liveEvents: [],
      InPlayEvents: [],
      isComplete: true
    };
  }

  componentDidMount() {
    this.loadUpperSlider();
    const that = this;
    // Utility.UpdateIsEnableInPlay();

    setInterval(function() {
      if (that.state.isComplete == true) {
        //that.props.clk();
        that.loadUpperSlider();
      }
    }, 5000);

    //console.log(this.props.InPlayEventsData, "InPlayEventsData");
  }

  // componentWillMount() {
  //     this.loadUpperSlider();
  //     // PubSub.subscribe('broadcast', function (data) {
  //     //     that.loadUpperSlider();

  //     // });

  // }

  RemoveInPlayEvent = (id, dcode, eventgroup) => {
    this.setState({ isComplete: true });
    Utility.RemoveInPlayEvents(id, responseresult => {
      this.props.clk();
      var save_url =
        "https://api.geoff-banks.com/Geoff/GetEventsFromAPI_Idx/" +
        dcode +
        "/" +
        eventgroup +
        "/0";
      //uncomment this line while inplay
      Utility.DeleteInPlayEvents_Urls(save_url, responseresult1 => {
        $(".upper-toggle").addClass("active");
      });
    });
  };

  loadUpperSlider = () => {
    this.setState({ isComplete: false });
    // console.log(this.state.isComplete, "isCompleteisCompleteisComplete1");
    var dataparse;
    Api.getInplayData(cb => {
      if (cb.data.length == 0) {
        this.setState({ isComplete: true });
        //this.loadSliderData(dataparse);
        return;
      }

      try {
        var result = cb.data;
        var parses = JSON.parse(result);
        dataparse = parses;
        //var parse2 = JSON.parse(parses[0]);
        //dataparse = parse2;

        this.loadSliderData(dataparse);
      } catch (e) {
      }
    });
  };

  loadSliderData = dataparse => {
    var results;

    if (dataparse != null && typeof dataparse != "undefined") {
      results = dataparse;
    }

    Api.getLiveEvents(responseresult => {
      this.setState({
        InPlayEventsToggle: responseresult,
        getapidata: results
      });

      var i = 0;
      var arr = [];
      var pick = [];
      if (responseresult.length == 0) {
        this.props.loaders();
        //this.props.clk();
        this.setState({
          InPlayEvents: arr,
          InPlayEventsToggle: responseresult
        });
      } else {
        this.props.loaders();
        //this.props.clk();
      }

      if (responseresult.length != prevSliderData) {
        this.props.clk();
      }
      prevSliderData = responseresult.length;

      let requests = responseresult.map(d1 => {
        var that = this;
        if (this.state.getapidata != null) {
          this.state.getapidata.map(result => {
            var result = JSON.parse(result);
            // console.log(result, "in play");
            var url = result.url;

            var afterSplit = url.split("/");
            var dcode = afterSplit[5];
            var eventgroup = afterSplit[6];
            var d1event_group = d1.event_group;

            if (dcode == d1.dcode) {
              if (eventgroup == d1event_group) {
                //var res = JSON.parse(result.data);
                var res = result.data;
                that.respick = res;
                if (that.respick.length > 0) {
                  if (Utility.hasFullTimeResult(res)) {
                    that.pick = res;
                    that.pick = Api.ParseEventArrayData(that.pick);

                    that.pick[0]["title"] = d1.title;
                    that.pick[0]["id"] = d1.id;
                    that.pick[0]["deventid"] = d1event_group;
                    that.pick[0]["dcode"] = d1.dcode;
                    arr.push(that.pick[0]);
                  }
                }
              }
            }
          });
        }
      });

      var uniqArr = _.uniqBy(arr, "id");

      //this.props.loaders();
      //this.props.clk();
      this.holdInterval = true;
      this.setState({
        InPlayEvents: uniqArr,
        isComplete: true,
        InPlayEventsToggle: responseresult,
        CurrentSlide: CurrentSlideIdx
      });
      //var that=this;
      // if (this.state.isComplete == true) {
      //     that.props.clk();
      // }
    });
  };
  slidechanged = e => {
    //console.log(e.item.index,"slidechanged*****")
    if (e.item.index != null) {
      CurrentSlideIdx = e.item.index;
      //this.setState({"CurrentSlide": e.item.index})
    }
  };

  render() {
    var content = (
      <div className="Events live_betting">
        {/* <Shared.Loader Show={this.state.ShowLoader} /> */}
        <section className="regular slider"></section>
        <div className={this.state.InPlayEvents.length > 0 ? "show" : "hide"}>
          <div className="play_betting_strip hide" id="isShowInPlay">
            <h4>
              <span>
                <em>
                  <i className="fa fa-play-circle-o" aria-hidden="true"></i>{" "}
                  LIVE
                </em>
              </span>{" "}
              <label>In Play Betting</label>
            </h4>
          </div>
          <OwlCarousel
            id="in-play-slider"
            startPosition={this.state.CurrentSlide}
            margin={10}
            items={2}
            className="owl-theme"
            onTranslated={this.slidechanged}
          >
            {this.state.InPlayEvents.map(function(live_events_data, idx) {
              var isIdSame = this.state.InPlayEvents.length - 1;

              if (isIdSame == idx) {
                setTimeout(function() {
                  $("#isShowInPlay").removeClass("hide");
                  $("#isShowInPlay").addClass("show");
                }, 1000);
              }
              return (
                <div className="sport-event-list item" key={idx}>
                  <div className="play_bet_outer">
                    <div className="col-md-12">
                      <div className="play_bet">
                        <div className="bet-heading">
                          <div className="row">
                            <div className="col-md-7 text-left">
                              <h3>{live_events_data.title}</h3>
                            </div>
                            <div className="col-md-5 text-right">
                              <em>Live</em>
                              <label>
                                {this.props.Store.state.ShowPlaceBetButton &&
                                Utility.IsEnablePlayEvents() == "true" ? (
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-toggle active upper-toggle"
                                    data-toggle="button"
                                    aria-pressed="false"
                                    autoComplete="off"
                                    onClick={e =>
                                      this.RemoveInPlayEvent(
                                        live_events_data.id,
                                        live_events_data.dcode,
                                        live_events_data.deventid
                                      )
                                    }
                                  >
                                    <div className="handle"></div>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="play_bet_inner">
                          <div className="row">
                            <div className="play_bet_bg">
                              <div className="col-md-5">
                                <span>
                                  {live_events_data.Events[0].SelName != null &&
                                  live_events_data.Events[0].SelName != ""
                                    ? live_events_data.Events[0].SelName
                                    : ""}
                                  {/* live_events_data.Events[0].SelName!=null && live_events_data.Events[0].SelName!='' */}
                                  {/* {live_events_data.Events[0].SelName} */}
                                  {/* {live_events_data.Events[0].SelName==null?live_events_data.first_player: live_events_data.home_team} */}
                                </span>
                              </div>
                              <div className="col-md-2">
                                <div className="first_set">
                                  <span>
                                    {live_events_data.second_player == null ||
                                    live_events_data.second_player == "Draw"
                                      ? "V"
                                      : live_events_data.second_player}
                                    {/* {(live_events_data.second_player==null || live_events_data.second_player=='Draw') ?'V':live_events_data.second_player} */}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-5">
                                <div className="first_set">
                                  <span>
                                    {live_events_data.Events[2].SelName !=
                                      null &&
                                    live_events_data.Events[2].SelName != ""
                                      ? live_events_data.Events[2].SelName
                                      : ""}
                                  </span>

                                  <span>
                                    {live_events_data.third_player == null
                                      ? live_events_data.third_player
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="play_bet_row">
                              <div className="col-md-4 text-center">
                                <span>1</span>
                              </div>
                              <div className="col-md-4 text-center">
                                <span>X</span>
                              </div>

                              <div className="col-md-4 text-center">
                                <span>2</span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="play_bet-btn">
                              <div className="col-md-4 text-center">
                                {/* <label className="cursor-pointer"
                                                        onClick={(e) => this.props.Store.AddBet_LiveEvents(e, live_events_data, live_events_data[0].Events[0], 0)}>{typeof live_events_data[0]=="undefined"?'':(live_events_data[0].Events.length>=3?live_events_data[0].Events[0].price:'')}</label> */}
                                <label
                                  className="cursor-pointer"
                                  onClick={e =>
                                    this.props.Store.AddBet_LiveEvents(
                                      e,
                                      live_events_data,
                                      live_events_data.Events[0],
                                      0
                                    )
                                  }
                                >
                                  {live_events_data.Events[0].price}
                                </label>
                              </div>

                              <div className="col-md-4 text-center">
                                {/* <label className="cursor-pointer"
                                                        onClick={(e) => this.props.Store.AddBet_LiveEvents(e, live_events_data, live_events_data[0].Events[1], 1)}>{typeof live_events_data[0]=="undefined"?'':(live_events_data[0].Events.length>=3?live_events_data[0].Events[1].price:'')}</label> */}

                                {live_events_data.Events[1].price != null ? (
                                  <label
                                    className="cursor-pointer"
                                    onClick={e =>
                                      this.props.Store.AddBet_LiveEvents(
                                        e,
                                        live_events_data,
                                        live_events_data.Events[1],
                                        1
                                      )
                                    }
                                  >
                                    {live_events_data.Events[1].price}
                                  </label>
                                ) : (
                                  <label className="cursor-pointer">NO</label>
                                )}
                              </div>

                              <div className="col-md-4 text-center">
                                {/* <label className="cursor-pointer "
                                                        onClick={(e) => this.props.Store.AddBet_LiveEvents(e, live_events_data, live_events_data[0].Events[2], 2)}>{typeof live_events_data[0]=="undefined"?'':(live_events_data[0].Events.length>=3?live_events_data[0].Events[2].price:'')}</label>
                                                    */}
                                <label
                                  className="cursor-pointer "
                                  onClick={e =>
                                    this.props.Store.AddBet_LiveEvents(
                                      e,
                                      live_events_data,
                                      live_events_data.Events[2],
                                      2
                                    )
                                  }
                                >
                                  {live_events_data.Events[2].price}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }, this)}
          </OwlCarousel>
          {/* </div> */}
        </div>
      </div>
    );
    return <div>{content}</div>;
  }
}

//export default InPlayEvents;
export default subscribe(InPlayEvents, {
  Store: BetSlipContainer,
  PreferenceStore: PreferenceContainer
});
