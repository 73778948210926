import { AUTH_TOKEN_KEY } from '../_constants/localStorageKeys';
import { localStorageService } from './localStorageService';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { isTokenExpired } from '../_helpers/isTokenExpired';

export const signalRService = (function(){    
    function SignalRSingleton() {
        let authToken = null;

        const baseUrl = process.env.REACT_APP_ENV === 'prod'
            ? `${process.env.REACT_APP_API_URL}`
            : '';

        const buildHub = (isLoggedIn, submitResponceCallback, logout) => {
            authToken = localStorageService.getItem(AUTH_TOKEN_KEY);

            if (!isLoggedIn || !authToken) {
                return {
                    isError: true,
                    error: 'Please log in to submit betslips',
                };
            }

            if (isTokenExpired()) {
                return {
                    isToLogout: true,
                    isError: true,
                    error: 'Session is expired',
                };
            }

            try {
                const connect = new HubConnectionBuilder()
                    .withUrl(baseUrl + '/hubs/betslip', { accessTokenFactory: () => authToken })
                    .withAutomaticReconnect()
                    .configureLogging(LogLevel.Information)
                    .build();

                connect.onreconnecting((err) => {
                    console.warn('Reconnecting to the websocket');
                    if (isTokenExpired()) {
                        connect.stop();
                        logout();
                    }
                });
                connect.onreconnected(() => {
                    console.warn('Reconnected to the websocket');
                });
                connect.onclose((err) => {
                    console.warn('Connection was closed');
                });

                connect.on('SubmitResponse', submitResponceCallback);

                connect.start()
                    .then(_ => {
                        console.warn('Connection established!');
                    })
                    .catch(err => {
                        console.error('signalr connecting error', err);
                        if (isTokenExpired()) {
                            logout();
                        }
                    });

                return {
                    isError: false,
                    connect,
                };
            } catch (err) {
                console.error('build hub', err);
                return {
                    isError: true,
                    error: err.message || 'Cannot establish connection. Please try later',
                };
            }
        };
    
        return {
            buildHub,
        };
    }

    let instance = null;

    if (instance == null) {
        instance = new SignalRSingleton();

        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null;
    }
    return instance;
})();