import React from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import * as Shared from "../Component/Shared/Index";
import * as Api from '../js/api-service';
import * as Utility from '../js/utility';
import $ from "jquery";

class Promotions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Promotion: [],
            ShowLoader: true,
        }
    }


    componentDidMount() {
        
        Api.GetPromotion(promo => {
            this.setState({ Promotion: promo, ShowLoader: false });


            $("h2#lastWord").html(function () {
                var text = $(this).text().trim().split(" ");
                var last = text.pop();
                return text.join(" ") + (text.length > 0 ? " <span class='green-hightlight'>" + last + "</span>" :
                    last);
            });
        }, err => {
        })

    }



    render() {
        var content = (
            <div>
                <div className="homepage promotion">
                    <div className="container">
                        <div className="content-section-inner">
                            <div className="promo-container">

                                <Shared.Loader Show={this.state.ShowLoader} />
                                {
                                    this.state.Promotion.map(function (promo, i) {
                                        if (promo.status == "published" && promo.image.data.full_url)
                                            return (
                                                <div className="promo" key={i}>
                                                    <img src={promo.image.data.full_url} />
                                                    <div className="promo-title">
                                                        <h2 id="lastWord">{promo.title}
                                                            {/* <strong className="green-hightlight"> Guaranteed</strong> */}
                                                        </h2>
                                                        {/* <h2 className="green-hightlight">Guaranteed</h2> */}
                                                        <h3>{promo.subtitle1}</h3>
                                                        <h4>{promo.subtitle2}</h4>
                                                    </div>
                                                    <a href={promo.link}><button className="more-info">MORE INFO</button></a>
                                                    <div className="description">
                                                        <h3>{promo.description_heading}</h3>
                                                        <h4>{promo.description_content}</h4>
                                                    </div>
                                                </div>

                                            )
                                    }, this)
                                }





                                {/*
                                
                                 <div className="promo">
                                    <img src="/images/best-odd.png" />
                                    <div className="promo-title">
                                        <h2>Best Odds
                                        <strong className="green-hightlight"> Guaranteed</strong>
                                        </h2>
                                        <h3>On UK & Irish Horse Racing</h3>
                                        <h4> Terms and Conditions Apply  </h4>
                                    </div>
                                    <button className="more-info">MORE INFO</button>
                                    <div className="description">
                                        <h3>Best Odds Guaranteed</h3>
                                        <h4>On UK & Irish Horse Racing</h4>
                                    </div>
                                </div>
                                
                                
                                
                                <div className="promo">
                                    <img src="/images/text-betting.png" />
                                    <div className="promo-title">
                                        <h2>Text
                                            <strong className="green-hightlight"> Betting</strong>
                                        </h2>
                                        <h3>with Geoff Banks</h3>
                                        <h4>Fast, Competitive, Simple</h4>
                                    </div>
                                    <button className="more-info">MORE INFO</button>

                                    <div className="description">
                                        <h3>Text Betting</h3>
                                        <h4>With Geoff Banks. Fast, Competitive, Simple</h4>
                                    </div>
                                </div> */}





                            </div>

                        </div >
                    </div >
                </div >


                <Footer />
            </div >
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

export default Promotions;