import React from 'react';
import './style.css';

export const BetSlipIsNotSubmitted = (props) => {
    const { closeMessage, betslip } = props;

    return (
        <div className="stake-exceed-message">
            <div className="stake-exceed-message-header">
                <span className="stake-exceed-message-title">Bet Slip Status</span>
                <button
                    className="stake-exceed-message-close"
                    onClick={closeMessage}>
                    ×
                </button>
            </div>
            <div className="stake-exceed-message-wrapper">
                <div className="stake-exceed-message-body">
                    <img src="/images/warningIcon.png" alt="type"></img>
                    <span>
                        Bet&#160;Slip&#160;is&#160;not&#160;submitted.
                        Failure&#160;reason:&#160;{
                            betslip.failureReason
                            || betslip.message
                            || '-'
                        }.
                    </span>
                </div>
                <div className="stake-exceed-message-footer">
                    <p>Press Ok to go back to the Bet Slip.</p>
                </div>
                <div className="stake-exceed-message-buttons">
                    <button
                        className="message-button"
                        onClick={closeMessage}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
}