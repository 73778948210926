import React from 'react';

export const InsufficientFundsMessage = (props) => {
    const { closeMessage, goToDeposit } = props;

    return (
        <div className="stake-exceed-message">
            <div className="stake-exceed-message-header">
                <span className="stake-exceed-message-title">Insufficient funds in account</span>
                <button
                    className="stake-exceed-message-close"
                    onClick={closeMessage}>
                    ×
                </button>
            </div>

            <div className="stake-exceed-message-wrapper">
                <div className="stake-exceed-message-body">
                    <img src="/images/warningIcon.png" alt="type"></img>
                    <div>
                        <p>
                            You have insufficient Funds! To Deposit Funds Click{" "}
                            <a
                                    href="javascript:;"
                                    className="change_color"
                                    onClick={goToDeposit}
                            >
                                    Here
                            </a>
                        </p>
                    </div>
                </div>
                <div className="stake-exceed-message-buttons">
                    <button
                        className="message-button"
                        onClick={closeMessage}>
                        Back to Betslip
                    </button>
                </div>
            </div>
        </div>
    );
};