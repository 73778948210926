import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import * as Util from "../../js/utility";

export default class Tooltip extends Component {
    render() {
        if(!Util.isAdmin()) return "";
        return (
            <ReactTooltip clickable={true} delayHide={1000} isCapture={true} />
        );
    }
}