import React from "react";
import subscribe from "unstated-subscribe-hoc";
import { AccountContainer } from "../Containers/AccountContainer";
import _ from "lodash";
import * as Api from "../js/api-service";

class PlacementBanners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
    };
  }
  LoadBanners(data, placementBannerName) {
    var banners = data.filter((k) => {
      return k.placement == placementBannerName;
    });
    this.setState({ banner: banners });
  }

  async componentDidMount() {
    var placementBannerName = this.props.placementbannername;

    let that = this;
    //TO REDUCE REQUEST TO DIRECTUS^M
    setTimeout(async function(){
      let res = await Api.GetBanner();
      var data = res.data.data;
      localStorage.setItem("LocalStoragePlacementBanners", JSON.stringify(data));
      that.LoadBanners(data, placementBannerName);
    }, 1000);

    // let res = await Api.GetBanner();
    // var data = res.data.data;
    // localStorage.setItem("LocalStoragePlacementBanners", JSON.stringify(data));
    // this.LoadBanners(data, placementBannerName);
  }

  render() {
    if (this.state.banner.length <= 0) return "";
    return (
      <div>
        {this.state.banner[0].image != undefined &&
        this.state.banner[0].status == "published" ? (
          <div
            className={
              this.state.banner[0].placement == "BelowLeftMenu"
                ? "LeftBanner"
                : "RightBanner"
            }
          >
            <a href={this.state.banner[0].link}>
              <img
                className="banner_logo"
                src={this.state.banner[0].image.data.full_url}
              />
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default subscribe(PlacementBanners, {
  AccountStore: AccountContainer,
});
