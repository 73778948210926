import React from 'react';
import './style.css';

export const Spinner = () => {
    return (
        <div className="betslip-container-loading">
            <img
                className="betslip-loading"
                src="/images/loading.png"
                alt="load"
            />
        </div>
    );
}