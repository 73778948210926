import React from 'react'
import '../css/main.css'


class HeaderLinks extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var content = (
            <ul>
                {this
                    .props
                    .headermenudata
                    .map(function (data, index) {
                        return (<li key={index}>
                            <a href={data.links} id={data.link_id}>{data.title}</a>
                        </li>
                        )
                    })}
            </ul>
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

export default HeaderLinks;