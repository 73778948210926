import React from 'react'
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';

class ReactivateAccount extends React.Component {
   
    render() {
        var content = (
            <div>
                <div className="my-account">

                    <div className="container">

                        <AccountSidebar />
                        <div className="account-right-container">


                            <div className="transaction">

                                <div className="acount-inner">
                                    <h3>Reactivate Account</h3>
                                </div>


                                <div style={{padding: '8px 6px', background: '#eeeeee'}}>
                                    <iframe height="485"
                                        allowtransparency="true"
                                        frameBorder="0"
                                        scrolling="no"
                                        className="withdrawIframe"
                                        src="https://geoffbanks.wufoo.com/forms/mqw3sva0o23muc" >
                                        <a href="https://geoffbanks.wufoo.com/forms/mqw3sva0o23muc">
                                        </a>
                                    </iframe >
                                </div>








                            </div>




                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}


export default ReactivateAccount;