import React from "react";
import subscribe from "unstated-subscribe-hoc";
import EventList from "../Component/EventList";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
import _ from "lodash";
import * as Shared from "../Component/Shared/Index";
import { FavouriteContainer } from "../Containers/FavouriteContainer";

import { PreferenceContainer } from "../Containers/PreferenceContainer";
import CustomNotifications from "../Component/CustomNotifications";

var sportName;
var autoReload;
var updatePrice;
var EventName = "";

class Sports extends React.Component {
  nextProps;
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      Events: [],
      headerTitle: "",
      FullCardOptions: [],
      SportCode: "",
      ShowLoader: true,
      SportName: "",
      InPlayEvents: [],
      InPlayEventsUpper: [],
    };
  }
  componentWillUpdate(nextProps, nextState) {
    this.nextProps = nextProps;

    if (nextState.InPlayEventsUpper !== this.state.InPlayEventsUpper) {
      const _this = this;
      setTimeout(function () {
        _this.setState({
          InPlayEventsUpper: nextState.InPlayEventsUpper,
          ShowLoader: nextState.showLoader
        });
      }, 500);
    }
  }
  componentWillUnmount() {
    clearInterval(autoReload);
    clearInterval(updatePrice);  
    this.changeOddsFormat();
  }
  componentDidMount() {
    // if (
    //   this.state.SportCode != "sb" &&
    //   window.location.href.indexOf("show-events") >= 0
    // ) {
    //   return;
    // }
    sportName = this.props.SportName;

    this.loadData((cb) => {
      this.changeOddsFormat();
    },true);

    const _this = this;

    const params = new URLSearchParams(window.location.search);
    const sportcode = params.get("sportcode");

    if (sportcode == "sb") {

      autoReload = setInterval(() => {
        _this.loadData((cb) => {});
      }, 60 * 1000);
    }

    //Update price/odds every 5 Sec if RefreshOdds is Enable 
    updatePrice = setInterval(() => {
      //Get refreshOddsEnable value from localstorage 
        if (Utility.isRefreshOddsEnable()) {
          _this.loadData((cb) => {});
        } else {
         clearInterval(updatePrice);
       }
    }, 5 * 1000);
  }
  changeOddsFormat() {
    const params = new URLSearchParams(window.location.search);
    const sportcode = params.get("sportcode");
    if (sportcode == "sb") {

      let e = { target: { value: "decimal" } };
      this.props.PreferenceStore.SetOddFormat(e, (cb) => {});
    } else {
      let e = { target: { value: "fractional" } };
      this.props.PreferenceStore.SetOddFormat(e, (cb) => {});
    }
  }
  loadtoggleOnOff = () => {
    Api.getInPlayEvents((responseresult) => {
      this.setState({
        InPlayEvents: responseresult
      });
    });
  };

  loadUpperSlider = () => {
    this.loadData();
  };

  loadData = (cb,loadFirstTime) => {
    //const _this = this;
    const params = new URLSearchParams(window.location.search);

    //console.log(_this.props.match.params.name)
    sportName = params.get("name");


    if (params.get("eventName") != null) {
      EventName = params.get("eventName");
    }

    const sportcode = params.get("sportcode");
    // const eventCode = params.get("Code");
    const sportInfo = Api.GetSportInfo(sportcode);
    //console.log("sportInfo-------", sportInfo)
    this.setState({
      headerTitle: sportInfo.Name,
      FullCardOptions: sportInfo.FullCardOptions,
      SportCode: sportInfo.SportCode,
      //ShowLoader: false,
    });
    
    if(loadFirstTime){
      this.loadtoggleOnOff();
    }
    //_this.loadData(sportcode);
    // Utility.IsFavorite((val,data) => {
    //     this.setState({ IsFav: val })
    // })

    Api.GetEvents(
      sportcode,
      (events) => {
        //console.log(events);
         //GETTING EVENT OBJECT FROM ARRAY
        let meetingName;
//var arr=[];
        var eventData = [];
        var sliderData = [];
        window.eventData = events;
        if (typeof events == "undefined") return;
        eventData = events;

        if (eventData.length) {

          //********************************* */
          //REDIRECTING TO SINGLE VIRTUAL EVENTS PAGE  FOR VIRTUAL HORSE AND VIRTUAL GREYHOUND
          // if (
          //   this.state.SportCode == "vh" ||
          //   (this.state.SportCode == "$" &&
          //     window.location.href.indexOf("single-event") <= 0)
          // ) {
          //   let event = eventData[0].Event;
          //   meetingName = eventData[0].MeetingName; //EVENT NAME

          //   //can use these following commented values as well. For now directly using event link from api.
          //   // var eF = event.EventFile;
          //   // var eG = event.EventGroup;
          //   // var ev= event.EventIdx;

          //   var eventLink = event.EventLink;
          //   console.log(meetingName, eventLink, "VH, $ DATA");

          //   //REDIRECT TO SINGLE EVENT PAGE
          //   this.nextProps.props.history.push(
          //     "/single-event?sportcode=" +
          //       this.state.SportCode +
          //       "&" +
          //       eventLink +
          //       "&EventName=" +
          //       meetingName
          //   );
          // }

          //******************************** */

          //NO IDEA WHAT IS CODE IS DOING
          if (
            eventData.length > 0 ||
            eventData[0].Competitions !== undefined && eventData[0].Competitions.length > 0 &&
            eventData[0].Competitions[0].details.length > 0
          ) {
              console.log(
                eventData[0].Competitions[0].details[0],
                eventData[0].Competitions[0],
                eventData[0].code,

                "custom navigation"
              );
              let data = eventData[0].Competitions[0].details[0];
              let eg = data.eventGroup;
              let eventName = data.homeTeam + "-" + data.awayTeam;
              let ef = eventData[0].Code;

              if (
                this.state.SportCode == "sb" &&
                window.location.href.indexOf("show-events") <= 0
              ) {
                this.nextProps.props.history.push(
                  `/show-events?sportcode=${this.state.SportCode}&eventName=${eventName}&eventfile=${ef}&EventGroup=${eg}`
                );
              }

              if (this.state.SportCode == "sb") {
                meetingName = this.nextProps.en;
                const eF = this.nextProps.ef;
                const eG = this.nextProps.eg;
                
                console.log("old", this.nextProps, this.props);
                console.log(
                  "old",
                  meetingName,
                  eF,
                  eG,
                  "new",
                  eventName,
                  ef,
                  eg
                );
                if (meetingName + eF + eG !== eventName + ef + eg) {
                  this.nextProps.props.history.push(
                    `/show-events?sportcode=${this.state.SportCode}&eventName=${eventName}&eventfile=${ef}&EventGroup=${eg}`
                  );
                }
              }
            }
          }
        
        eventData.map((data, i) => {
          data.Competitions.map((data1, i) => {
            data1.details.map((data2, i) => {
              if (data2.firstPlayer !== undefined) {
                data2.homeTeam = data2.firstPlayer;
                data2.awayTeam = data2.secondPlayer;
                data2.homeodds = data2.firstodds;
                data2.drawodds = data2.secondodds;
                data2.awayodds = data2.thirdodds;
                data2.kickoff = data2.time;
              }
              this.checkIsLiveMatch(data2);
            });
          });
        });

        console.log("event+++++++", eventData);
        //eventData = Utility.FilterEventsByCode(eventData);
        //eventData = Utility.SortEventsByCode(eventData);
        eventData = Utility.SortEvents(eventData);
        sliderData = eventData;

        var filterData = Utility.filterBoxingAndUfcEvents(sportcode, eventData); //filter data for boxing and UFC
        if (filterData !== undefined && filterData.length > 0) {
          eventData = filterData;
        }
        console.log(sliderData, "sliderData");
        // sliderData.forEach(d1 => {
        //     Api.getLiveEvents1(d1.Code, responseresult => {
        //       if (responseresult.length > 0) {
        //         responseresult.forEach((v, i) => {
        //           arr.push(v);
        //         });
        //       }

        //     });
        //   })
        //    console.log(arr,"sportdata");

        if(loadFirstTime){
          //NOT USING THIS LIVE EVENT API IN LOOP FOR VIRTUAL RACES
          if (sportcode !== "vh" && sportcode !== "$") {
            var i = 0;
            var arr = [];
            var pick = [];
            Api.getLiveEvents((responseresult) => {
              console.log(responseresult, "RESULT");
              let requests = responseresult.map((d1) => {
                return new Promise((resolve) => {
                  Api.GetSportsEvents(
                    d1.dcode,
                    d1.event_group,
                    (result) => {
                      console.log(result, "hello");
                      pick.push(result);
                      if (typeof pick[i] != "undefined" && pick.length > 0) {
                        pick[i]["title"] = d1.title;
                        pick[i]["id"] = d1.id;
                        pick[i]["home_team"] = d1.home_team;
                        pick[i]["away_team"] = d1.away_team;
                        pick[i]["eventId1"] = d1.event_id1;
                        pick[i]["eventId2"] = d1.event_id2;
                        pick[i]["eventId3"] = d1.event_id3;
                        pick[i]["first_player"] = d1.first_player;
                        pick[i]["second_player"] = d1.second_player;
                        pick[i]["third_player"] = d1.third_player;
                        arr.push(pick[i]);

                        i = i + 1;
                      }
                      resolve();
                    },
                    (error) => {
                      console.log(error, "err");
                    }
                  );
                });
              });

              Promise.all(requests).then(
                () => (
                  this.setState({ InPlayEventsUpper: arr }),
                  console.log("final--outtttt", arr)
                )
              );
            });
          }
        }

        this.setState({ Events: eventData, ShowLoader: false });
        console.log(this.state.Events, "eventdata");
        cb();
      },
      (err) => {
        this.setState({ShowLoader: false,});
        console.log("error", err);
      }
    );
  };

  checkIsLiveMatch(event) {
    console.log("inside", this.state.InPlayEvents);
    _.find(this.state.InPlayEvents, function (o) {
      event["isLive"] = false;

      if (o.event_id1 === event.eventId1) {
        event["isLive"] = true;
        event["id"] = o.id;

        var toggle_old = "toggle-" + event.eventId1;
        return true;
      }
    });
  }

  render() {
    if (
      this.state.SportCode != "sb" &&
      window.location.href.indexOf("show-events") >= 0
    ) {
      return "";
    }
    const mystyle = {
      width: "100%",
      "margin-top": "10px",
      height: "450px",
    };
    var content = (
      <div>
        <div className="sports">
          <CustomNotifications eventID={"-1"} />
          {!this.state.ShowLoader &&
          this.state.Events != undefined &&
          this.state.Events.length == 0 ? (
            <div>
              {this.state.SportCode == "$" || this.state.SportCode == "vh" ? (
                <div>
                  {/* <VideoStream eventName="TRP"/> */}
                  {/* <VirtualVideoStream page={Utility.getCurrentPage()} /> */}
                </div>
              ) : (
                ""
              )}
              <div className="EmptyEvents">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                There are Currently no active events. Please check back later.
              </div>
            </div>
          ) : (
            ""
          )}

          <div>
            <Shared.Loader Show={this.state.ShowLoader} />
            {this.state.SportCode == "$" || this.state.SportCode == "vh" ? (
              <div>
                {/* <VirtualVideoStream page={Utility.getCurrentPage()} /> */}
                {/* <MainLive sportcode={this.state.SportCode} limit="6" /> */}
              </div>
            ) : (
              ""
            )}

            {this.state.Events.map(function (event, i) {
              return (
                // <div>
                //   {this.state.SportCode == "$" || this.state.SportCode == "vh" ? (
                //     <div>
                //       <MainLive sportcode={this.state.SportCode} limit="6" />
                //       {/* <VirtualVideoStream page={Utility.getCurrentPage()} /> */}
                //     </div>
                //   ) : (
                <div>
                  <EventList
                    Event={event}
                    clk={this.loadUpperSlider}
                    InPlayEventsData={this.state.InPlayEventsUpper}
                    FullCardOptions={this.state.FullCardOptions}
                    SportCode={this.state.SportCode}
                    key={i}
                  />
                </div>
                // )}
                // </div>
              );
            }, this)}
          </div>
        </div>
      </div>
    );
    return <div>{content}</div>;
  }
}

export default subscribe(Sports, {
  FavouriteStore: FavouriteContainer,
  PreferenceStore: PreferenceContainer,
});
