import React from 'react';
//import Modal from 'react-awesome-modal';
import { Modal } from "react-bootstrap";
export default class TransactionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transsummary: "",
            dataValue: "",
            WinDetails: "",
            winstack: "",
            Summary: [],
            Debit: '',
        }

    }
    SetStateFromProps(props) {
        if (typeof props.data && typeof props.data.WagerInfo !== "undefined" && props.data.DebitAmount != undefined && props.data.DebitAmount != null) {
            var deb = parseInt(props.data.DebitAmount).toLocaleString('en');
            this.setState({ transsummary: props.data, winstack: props.data.WagerInfo.WinStake, Debit: deb });

        }
    }
    componentWillReceiveProps(nextProps) {
        this.SetStateFromProps(nextProps);
    }

    componentDidMount() {
        this.SetStateFromProps(this.props);
    }
    render() {
        //  var transsummary = this.props.data;
        var newValue = this
            .state
            .WinDetails
            .replace('&euro;', '');
        var content = (
            <div>
                <Modal
                    id="data"
                    show={this.props.show}
                    width="500"
                    overflow="scroll"
                    height="600"
                    effect="fadeInUp"
                    onHide={this.props.hide}
                    className="transactionDetailModal animated faster slideInRight"
                >
                    <div className="modal-body">
                        <div className="account_statement">
                            <a onClick={this.props.hide}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            Selection Detail</div>

                        <div className="heading">
                            Summary</div>


                        <div className="getTransaction">

                            <div className="TransactionType win-Details">
                                {this.state.transsummary.HasProfit
                                    ? <label className="result-transaction win">{this.state.transsummary.IsOpen == true ? "" : <i class="fa fa-trophy" aria-hidden="true"><span>Profit: £</span> </i>}{this.state.transsummary.IsOpen == true ? "Pending Bet" : this.state.transsummary.ResultAmount}
                                    </label>
                                    : <label className="result-transaction loss">{this.state.transsummary.IsOpen == true ? "" : "Loss: £ "}{this.state.transsummary.IsOpen == true ? "Pending Bet" : this.state.transsummary.ResultAmount}
                                    </label>
                                }

                            </div>

                            <div className="TransactionType">
                                <div className='col-md-6'>
                                    <b>Date - Reference</b>
                                </div>
                                <div className='col-md-6'>
                                    {this.state.transsummary.date + ' - ' + this.state.transsummary.RefNo}
                                </div>
                            </div>

                            {/* <div className="TransactionType">
                                <div className='col-md-6'>
                                    <b>Reference</b>
                                </div>
                                <div className='col-md-6'>
                                    {this.state.transsummary.RefNo}
                                </div>
                            </div> */}

                            <div className="TransactionType">
                                <div className='col-md-6'>
                                    <b>Stake</b>
                                </div>
                                <div className='col-md-6'>
                                    {/* £ {this.state.winstack} */}
                                    £ {this.state.Debit}
                                </div>
                            </div>

                            <div className="TransactionType">
                                <div className='col-md-6'>
                                    <b>Balance</b>
                                </div>
                                <div className='col-md-6'>
                                    £ {this.state.transsummary.Balance}
                                </div>
                            </div>
                        </div>
                        {/* <div className="heading">Detail</div> */}
                        <div className="win-sing">{"£ " + this.props.newData}</div>
                        {this
                            .props
                            .summary
                            .map(function (transaction, i) {
                               // console.log(transaction, "odds_transaction123");
                                if (transaction.EventName != undefined)
                                   
                                if (transaction.Odds != undefined) {
                                    var earnedBOG = 0;
                                   
                                    var Odds = (transaction.Odds).split('-');
                                    var OddsEP = "";
                                    var PotentialReturn = 0;
                                    //console.log(Odds.length, "odds_odds");
                                    if (Odds.length == 2) {
                                        OddsEP = transaction.Odds;
                                        PotentialReturn = (parseFloat(Odds[0] / Odds[1]) * parseFloat(this.props.newData)) + parseFloat(this.props.newData);
                                    }
                                    else if (Odds.length == 3) {
                                        var oddRes = (transaction.Odds).split('EP');
                                        OddsEP = oddRes[0];
                                        //console.log(OddsEP,"length3")
                                        Odds = (OddsEP).split('-');
                                        PotentialReturn = (parseFloat(Odds[0] / Odds[1]) * parseFloat(this.props.newData)) + parseFloat(this.props.newData);
                                    }
                                    else if (Odds.length == 1) {
                                        var oddRes = (transaction.Odds).split('EP');
                                        OddsEP = oddRes[0];
                                        PotentialReturn = transaction.PotentialReturn;
                                    }
                                    // console.log(PotentialReturn, "odds_PotentialReturn");
                                    // console.log(this.props.data.CreditAmount, "odds_CreditAmuount");

                                    


                                    if (transaction.IsBOG + '' == "true") {
                                        earnedBOG = parseFloat(this.props.data.CreditAmount) - (parseFloat(PotentialReturn))
                                    }
                                
                                        //  console.log("642973-1",Odds)
                                        //  console.log(Odds.length, "642973-2");
                                        // // console.log(PotentialReturn, "6429733")
                                        // console.log(parseFloat(this.props.data.CreditAmount), "642973-3");
                                        // console.log(parseFloat(PotentialReturn), "642973-4");
                                    
                                }
                                return (
                                    <div className="getTransaction" key={i}>
                                        <div className="TransactionType">
                                            <div className='col-md-6'>
                                                <b>Event Name</b>
                                            </div>
                                            <div className='col-md-6'>
                                                {typeof transaction.EventName == "undefined" || transaction.EventName == ""
                                                    ? "NA"
                                                    : transaction.EventName}
                                                <span className={transaction.IsBOG + '' == "true" ? "bog_span show transaction flipInX animated" : "bog_span hide"}>BOG </span>
                                            </div>
                                        </div>

                                        <div className="TransactionType">
                                            <div className='col-md-6'>
                                                {/* <b>Selection</b> */}
                                                <b>Selection and odds</b>
                                            </div>
                                            <div className='col-md-6'>
                                                {transaction.SelectionName == ""
                                                    ? "NA"
                                                    : transaction.SelectionName}
                                                <span className="underneath">
                                                    { typeof transaction.Odds == "undefined" || transaction.Odds == ""
                                                        ? "NA"
                                                        : ' @' + OddsEP}
                                                </span>
                                            </div>
                                        </div>

                                        {/* <div className="TransactionType">
                                            <div className='col-md-6'>
                                                <b>Odds</b>
                                            </div>
                                            <div className='col-md-6'>
                                                {transaction.Odds == ""
                                                    ? "NA"
                                                    : transaction.Odds}
                                            </div>
                                        </div> */}

                                        {/* <div className="TransactionType">
                                            <div className='col-md-6'>
                                                <b>Price</b>
                                            </div>
                                            <div className='col-md-6'>
                                                {transaction.Price == ""
                                                    ? "NA"
                                                    : transaction.Price}
                                            </div>
                                        </div> */}
                                        <div className={parseFloat(earnedBOG) == 0 ? "hide" : "show"}>
                                            <div className={this.state.transsummary.HasProfit + '' === "false" ? "hide" : "show"}>
                                                <div className={this.state.transsummary.IsOpen + '' == "false" ? (transaction.IsBOG + '' == "true" ? "extra-bog" : "extra-bog hide") : ("hide")}>
                                                    <span><span className="bog_spanextrabog">BOG </span> You won £{isNaN(parseFloat(earnedBOG)) == true ? '0' : earnedBOG.toFixed(2).toString().replace('-', '')} extra with our best price guarantee</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }, this)
                        }
                        <div className="logo-mesg">
                            <div className="logo_footer_mesg">
                                <img src="/images/logo.svg" height="65" />
                                {/* <img
                                alt="img"
                                className="logo"
                                src="https://m1.geoff-banks.com/assets/imgs/logo1.png" /> */}
                            </div>
                            <div className="message">
                                Many thanks for your continued custom with Geoff Banks Online
                    </div>
                        </div>
                    </div>
                </Modal>


            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}