import * as axios from 'axios';
import { AUTH_TOKEN_EXPIRED_AT, AUTH_TOKEN_KEY, BETSLIP_ID_BACKUP } from '../_constants/localStorageKeys';
import { localStorageService } from './localStorageService';

export const authApiService = (function(){
    function BetslipApiSingleton() {        
        let client = null;

        const baseUrl = process.env.REACT_APP_ENV === 'prod'
            ? `${process.env.REACT_APP_API_URL}/api`
            : '/api';
    
        const init = () => {
            const headers = {
                'Content-Type': 'application/json',
                Accept: '*/*',
            };

            client = axios.create({
                baseURL: baseUrl,
                timeout: 230000,
                headers
            });
    
            return client;
        }
    
        const login = (credsPayload) => {
            return init()
                .post('/auth/token', credsPayload)
                .then(resp => resp.data)
                .then(resp => {
                    if (resp) {
                        localStorageService.setItem(AUTH_TOKEN_KEY, resp.access_token || '');
                        localStorageService.setItem(AUTH_TOKEN_EXPIRED_AT, resp.expires_at || '');
                    }
                });
        };

        const logout = () => {
            localStorageService.removeItem(AUTH_TOKEN_KEY);
            localStorageService.removeItem(AUTH_TOKEN_EXPIRED_AT);
            localStorageService.removeItem(BETSLIP_ID_BACKUP);
        };
    
        return {
            login,
            logout
        };
    }

    let instance = null;

    if (instance == null) {
        instance = new BetslipApiSingleton();

        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null;
    }
    return instance;
})();