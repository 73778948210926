import { calculatePotentialResult } from "./calculatePotentialResult";

export const getProfitMessage = (betList, wagers, index, currentWagerStake, eachWay) => {
    const valueString = currentWagerStake || 0;

    const isEachWay = Boolean(eachWay);

    const stake = Number(valueString);

    if (isNaN(stake) || stake === 0 || stake < 1) {
      return '';
    }

    const multipliedStake = stake * wagers[index].numberOfLines;

    const wagerTypeName = wagers[index].name.toLocaleLowerCase();

    let profit = 0;
    switch (wagerTypeName) {
      case 'singles':
        profit = calculatePotentialResult(betList, multipliedStake, 'single', 1, isEachWay);
        break;
      case 'doubles':
        profit = calculatePotentialResult(betList, multipliedStake, 'double', 2, isEachWay);
        break;
      case 'trebles':
        profit = calculatePotentialResult(betList, multipliedStake, 'treble', 3, isEachWay);
        break;
      default:
        profit = 0;
    }

    return profit > 0 ? `Potential Return: £${profit}` : '';
};