import React from "react";
import { isExternalSignup } from "../../js/utility";
import SignupV2 from "./Signup-v2";
import SignupFormStack from "./SignupFormStack";
class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if(window.location.href.includes('sign-up-v3') && !isExternalSignup()){
      this.props.history.push('/sign-up-v2')
    }
    if(window.location.href.includes('sign-up-v2') && isExternalSignup()){
      this.props.history.push('/sign-up-v3')
    }
    
  }

  render() {
    return isExternalSignup() ? <SignupFormStack /> : <SignupV2 />;
  }
}
export default SignupForm;
