import React, { useEffect, useState } from 'react';
import { getOddFormatted } from '../../../../_helpers';
import './style.css';

export const PriceChangedMessage = (props) => {
    const {
        closePriceChangedDialog,
        betslip,
        oddsFormat,
        submitAcceptingChangedPrice,
    } = props;

    const [filteredBets, setFilteredBets] = useState([]);

    const convertPriceToNumber = (betPrice) => {
        if (!betPrice || isNaN(Number(betPrice))) {
            return 0;
        }
        return Number(betPrice);
    };

    const compareIfNewPriceLess = (bet) => (
        convertPriceToNumber(getOddFormatted(bet.price, 'decimal'))
            < convertPriceToNumber(getOddFormatted(bet.oldPrice, 'decimal'))
    );

    useEffect(() => {
        if (!betslip) {
            return;
        }
        setFilteredBets((betslip.bets || []).filter(compareIfNewPriceLess));
    }, [betslip]);

    const renderBetWithChangedPrice = () => {
        if (!betslip) {
            return null;
        }
        return (
            <ul className="price-change-list">
                { filteredBets
                    .map((bet, betId) => (
                        <li className="price-change-item" key={betId}>
                            <p>
                                <span>{ bet.name }</span>
                                &#160;
                                <span>@</span>
                                &#160;
                                <span>{ bet.detail }</span>
                                &#160;
                                <span>{ bet.newPC }</span>
                            </p>
                            <p>
                                <span>
                                    <b>Old price:</b>
                                    &#160;
                                    { getOddFormatted(bet.oldPrice, oddsFormat) }
                                </span>
                                &#160;
                                &#160;
                                <span>
                                    <b>New price:</b>
                                    &#160;
                                    { getOddFormatted(bet.price, oddsFormat) }
                                </span>
                            </p>
                        </li>
                    )) }
            </ul>
        );
    };

    const renderPriceChangedWithAcceptingDialog = () => {
        return (
            <div className="stake-exceed-message">
                <div className="stake-exceed-message-header">
                    <span className="stake-exceed-message-title">Price change</span>
                    <button
                        className="stake-exceed-message-close"
                        onClick={closePriceChangedDialog}>
                        ×
                    </button>
                </div>
                <div className="stake-exceed-message-wrapper">
                    <div className="stake-exceed-message-body">
                        <img src="/images/warningIcon.png" alt="type" />
                        <div className="stake-exceed-text">
                            <p>The odds for your selection have changed.</p>
                            { renderBetWithChangedPrice() }
                        </div>
                    </div>
                    <div className="stake-exceed-message-footer">
                        <p>Press Accept to take the New Price and Place Bet.</p>
                    </div>
                    <div className="stake-exceed-message-buttons">
                        <button
                            className="message-button"
                            onClick={submitAcceptingChangedPrice}>
                            Accept
                        </button>
                        <button
                            className="message-button"
                            onClick={closePriceChangedDialog}>
                            Decline
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    if (filteredBets.length > 0) {
        return renderPriceChangedWithAcceptingDialog();
    }
    
    const renderCommonPriceChangedDialog = () => {
        return (
            <div className="stake-exceed-message">
                <div className="stake-exceed-message-header">
                    <span className="stake-exceed-message-title">Stake exceed</span>
                    <button
                        className="stake-exceed-message-close"
                        onClick={closePriceChangedDialog}>
                        ×
                    </button>
                </div>
                <div className="stake-exceed-message-wrapper">
                    <div className="stake-exceed-message-body">
                        <img src="/images/warningIcon.png" alt="type" />
                        <span>Price has changed.</span>
                    </div>
                    <div className="stake-exceed-message-footer">
                        <p>Press Ok to go back to the Bet Slip.</p>
                    </div>
                    <div className="stake-exceed-message-buttons">
                        <button
                            className="message-button"
                            onClick={closePriceChangedDialog}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return renderCommonPriceChangedDialog;
};