import React, {Component} from 'react';
import * as utility from '../../js/utility';

export default class TranTypeBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BtnClass: "",
            BtnLabel: "",
            ResultAmt: ""
        }
    }

    componentDidMount() {
        var transaction = this.props.transaction;
        if (typeof transaction == "undefined") 
            return;
        var tranType = utility.GetTranType(transaction);
        var btnClass = "pending";
        var btnLabel = "";
        if(transaction.MainTransaction.Type!="1" && transaction.MainTransaction.Type!="2"){
            btnLabel = tranType;    
            btnClass = tranType;        
        }
        else if (transaction.TransactionSummary.IsOpen){
            this.setState({BtnClass: "pending", BtnLabel: "PENDING"});
            return;
        }
        else if (transaction.TransactionSummary.HasProfit) {
            btnClass = "win";
            btnLabel = "PROFIT";
        } else {
            btnClass = "loss";
            btnLabel = "LOSS";
        }
        this.setState({
            BtnClass: btnClass,
            BtnLabel: btnLabel,
            ResultAmount: "£" + transaction.TransactionSummary.ResultAmount
        });
    }

    render() {
        var transaction = this.props.transaction;
        if (typeof transaction == "undefined") 
            return "";
        
        return (
            <div className="Account Credit">
                <button className={this.state.BtnClass}>
                    {this.state.BtnLabel}
                    <br/> {this.state.ResultAmount}
                </button>
            </div>
        );
    }
}