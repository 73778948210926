import React from "react";
import { Link } from "react-router-dom";
import { GetCurrentYear } from "../js/utility";
import Version from "./Shared/version";
//import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer>
          <div className="container">
            <div className="col-md-4 mt-md-0 mt-3 footer-logo">
              <img src="/images/logo.svg" alt="Logo" className="geoffLogo"/>
              <div className="footer-text">
                <p className="gambling-content">
                  Licensed by the UK Gambling Commission<br/>
                  Licensee name – GB Sports Advisors Limited<br/>
                  Account number – 54095<br/>
                  <a href="https://www.gamblingcommission.gov.uk/public-register/business/detail/54095">
                  https://www.gamblingcommission.gov.uk/public-register/business/detail/54095
                  </a>
                </p>
                <p>
                  By accessing, continuing to use or navigating throughout this
                  site you accept that we will use certain browser cookies to
                  improve your customer experience with us. Geoff only uses
                  cookies which will improve your experience with us and will
                  not interfere with your privacy. Please refer to our <b>Cookies Policy </b>
                  for further information on our use of cookies
                  and how you can disable or manage their use should you wish.
                </p>
              </div>

              {/* harshiv changes */}
              <div className="footer-img">
                <a href="https://www.ibas-uk.com/">
                  {" "}
                  <img src="/images/Fotter1.png" alt="Footer1" />{" "}
                </a>
                <a href="/show-page/over18">
                  {" "}
                  <img src="/images/Fotter2.png" alt="Footer2" />{" "}
                </a>
                <a href="https://www.gamblingcommission.gov.uk">
                  <img src="/images/Fotter3.png" alt="Footer3" />{" "}
                </a>
                <a href="https://www.gamstop.co.uk">
                  <img
                    src="https://www.gamstop.co.uk/images/logo.svg"
                    alt="Footer3"
                    className="gamstop"
                  />{" "}
                </a>
              </div>
            </div>
            <div className="col-md-2 mb-md-0 mb-3">
              <ul className="list-unstyled">
                <li>
                  <a href="/show-page/aboutus">About Us</a>
                </li>
                <li>
                  <a href="/show-page/contact-detail">Contact Details</a>
                  {/* <Link to="/show-page/contact-detail">Contact Details</Link> */}
                </li>
                <li>
                  <a href="https://www.indeed.co.uk/viewjob?t=trading+assistant&jk=917cbd12c2234b2f&_ga=2.154254520.1857144260.1541691432-1777259780.1541691432">
                    Job at Geoff Banks
                  </a>
                </li>
                <li>
                  <a href="/show-page/termsandconditions">Terms & Conditions</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-md-0 mb-3">
              <div className="footer-second">
                {/* <h5 className="text-uppercase">MORE</h5> */}
                {/* <span>Smartbets - personalised odds</span>
                <span>Compaison</span> */}
                <ul className="list-unstyled">
                  <li>
                    <a href="/show-page/bettingrule">Betting Rules</a>
                  </li>
                  <li>
                    <a href="/promotions/BOG">Best Odds Guaranteed</a>
                  </li>
                  <li>
                    <a href="/show-page/privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/show-page/cookies">Cookie Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 mb-md-0 mb-3">
              <div className="footer-second">
                {/* <h5 className="text-uppercase">RSS FEEDS</h5>
                <span>Get all tips from RSS</span> */}
                <ul className="list-unstyled">
                  <li>
                    <a href="/Responsible-gambling">Responsible Gambling</a>
                  </li>
                  <li>
                    <a href="/show-page/help">Help</a>
                  </li>
                  <li>
                    <a href="https://geoffbanksracing.com">Geoff Banks Blog</a>
                  </li>
                  <li>
                    <a href="/show-page/affiliates">Affiliates</a>
                  </li> 
                  {/* <li>
                    <a href="/Affiliate">Affiliate Signup</a>
                  </li>   */}
                </ul>
              </div>
            </div>
            <div className="col-md-2 mb-md-0 mb-3">
              <div className="footer-second">
                <i className="fa fa-twitter"></i>
                <i className="fa fa-facebook-f"></i>
                <i className="fa fa-linkedin-in"></i>
                <i className="fa fa-pinterest-square"></i>
                <i className="fa fa-google-plus-g"></i>
              </div>
              <div className="social-img">
                <a
                  href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks"
                  title="Android App"
                  target="_blank"
                >
                  <img src="/images/play-store-btn.png" />
                </a>
                <a
                  target="_blank"
                  href="https://itunes.apple.com/gb/app/geoff-banks-betting/id881898186?mt=8"
                  title="IOS App"
                >
                  <img src="/images/appstore-btn.png" />
                </a>
              </div>
            </div>
          </div>
          <div className="version">
            <Version />
          </div>
        </footer>

        <div className="meta-navigation">
          <ul>
            <li>
              <a href="/show-page/aboutus">About Us</a>
            </li>
            <li>
              <a href="/show-page/contact-detail">Contact Details</a>
            </li>
            <li>
              <a href="https://www.indeed.co.uk/viewjob?t=trading+assistant&jk=917cbd12c2234b2f&_ga=2.154254520.1857144260.1541691432-1777259780.1541691432">
                Job at Geoff Banks
              </a>
            </li>
            <li>
              <a href="/show-page/termsandconditions">Terms & Conditions</a>
            </li>
            <li>
              <a href="/show-page/bettingrule">Betting Rules</a>
            </li>
            <li>
              <a href="/show-page/bestodds">Best Odds Guaranteed</a>
            </li>
            <li>
              <a href="/show-page/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="/show-page/cookies">Cookie Policy</a>
            </li>
            <li>
              <a href="/Responsible-gambling">Responsible Gambling</a>
            </li>
            <li>
              <a href="/show-page/help">Help</a>
            </li>
            <li>
              <a href="https://geoffbanksracing.com">Geoff Banks Blog</a>
            </li>
            <li>
              <a href="/show-page/affiliates">Affiliates</a>
            </li>            
          </ul>
        </div>

        <div className="copyright">
          © Copyright {GetCurrentYear()}, GB Sports Advisors Limited is licensed and regulated in Great Britain by the Gambling Commission under account number 54095 
          {/* GB Sports advisors Ltd All Rights Reserved This site
          is Licensed and Regulated by the UK Gambling Commission License number
          000-054095-R-330366-001 */}
        </div>
      </div>
    );
  }
}
export default Footer;
