import React, { Component } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as Shared from "../../Component/Shared/Index";
import * as Api from "../../js/api-service";
import * as utility from "../../js/utility";
import $ from "jquery";
import { ClimbingBoxLoader } from "react-spinners";
import "./MyProfile.css";
var ApiPath = "https://apiv1.geoffbanks.bet/assets/images/";

export default class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowLoader: false,
      //file: 'https://www.nextpageit.com/wp-content/uploads/2019/09/Development-Solutions.png',
      file: "/images/avatar.png",
      prefix: "",
      doc_id_base64:"",
      doc_utility_bill_base64:"",
      profile_pic_base64:"",
      UserDetail: {
        firstname: "",
        lastname: "",
        dob: "",
        emailaddress: "",
        telephonenumber: "",
        work_tel: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        country: "",
        mobile: "",
        postcode: "",
        id: "",
        upload_id: "",
        upload_utility_bill: "",
        userid: "",
      },
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.input = React.createRef();
    this.input2 = React.createRef();
  }
  componentDidMount() {
    
    $("#save").show();
    $("#update").hide();
    if (utility.checkAuth() != undefined) {
      utility.ShowLoading("Loader");
      $("form").on("change", ".file-upload-field", function () {
        $(this)
          .parent(".file-upload-wrapper")
          .attr(
            "data-text",
            $(this)
              .val()
              .replace(/.*(\/|\\)/, "")
          );
      });

      $("form").on("change", ".file-upload-field-utility-bill", function () {
        $(this)
          .parent(".file-upload-wrapper-utility-bill")
          .attr(
            "data-text",
            $(this)
              .val()
              .replace(/.*(\/|\\)/, "")
          );
      });

      utility.GetMyProfileDetailsByUser(
        (response) => {
          utility.HideLoading("Loader");
          if (response.length > 0) {
            var doc_id = response[0].doc_id;
            //this.DocInBase64(doc_id,"doc_id_base64")
            var doc_utility_bill = response[0].doc_utility_bill;
            var profile_pic = response[0].profile_pic;

            var first_name = response[0].first_name;
            var last_name = response[0].last_name;
            var id = response[0].id;
            var dob = response[0].dob;

            $("#file-upload-id").removeAttr("required");
            $("#file-upload-document-bill").removeAttr("required");
            $("#myFile").removeAttr("required");
            if (doc_id == "") {
              doc_id = "Upload ID";
            }
            if (doc_utility_bill == "") {
              doc_utility_bill = "Upload Utility Bill";
            }
            $(".file-upload-wrapper").attr("data-text", doc_id);
            $(".file-upload-wrapper-utility-bill").attr(
              "data-text",
              doc_utility_bill
            );
            $("#save").hide();
            $("#update").show();
            $(".file_downloads").show();

            this.setState({
              file: profile_pic,
              prefix: ApiPath,
              UserDetail: {
                ...this.state.UserDetail,

                firstname: first_name,
                lastname: last_name,
                upload_id: doc_id,
                upload_utility_bill: doc_utility_bill,
                userid: id,
                dob: dob,
              },
            });
          } else {
            $("#save").show();
            $("#update").hide();
            $(".file_downloads").hide();
          }
        },
        (err) => {
        }
      );
    } else {
      this.props.history.push("/");
      utility.ShowNotification("Please Sign in to your account to access this page!");
    }
  }

  async handleSubmit(event) {
    var btn = $(document.activeElement).attr("id");

    utility.ShowLoading("Loader");

    if (btn == "save") {
      event.preventDefault();
      this.Save(event);
    } else {
      event.preventDefault();

      this.Update(event);
    }
  }

  Save(event) {
    let that = this;
    let formData = new FormData();
    formData.append("docid", event.target["file-upload-field"].files[0]);
    formData.append(
      "docutilitybill",
      event.target["file-upload-field-utility-bill"].files[0]
    );
    formData.append("profilepic", event.target["myFile"].files[0]);
    formData.append("firstname", event.target.firstname.value);
    formData.append("lastname", event.target.lastname.value);
    formData.append("dob", event.target.dob.value);

    utility.SaveMyProfileDetail(formData, (response) => {
      utility.HideLoading("Loader");
      that.SendProfileEmail(event);
    });
  }

  Update(event) {
    event.preventDefault();
    let that = this;
    let formData = new FormData();

    if (typeof event.target["file-upload-field"].files[0] === "undefined") {
      //formData.append("docid", this.state.UserDetail.upload_id);
      if(this.state.UserDetail.upload_id != ''){
        formData.append("docid", this.state.UserDetail.upload_id);
      }else{
        formData.append("docid", "Upload ID");
      }
      //formData.append("docid", "Upload ID");     
    } else {
      formData.append("docid", event.target["file-upload-field"].files[0]);
    }

    if (
      typeof event.target["file-upload-field-utility-bill"].files[0] ===
      "undefined"
    ) {
      // formData.append("docutilitybill", this.state.UserDetail.upload_utility_bill);
      if(this.state.UserDetail.upload_utility_bill != ''){
        formData.append("docutilitybill", this.state.UserDetail.upload_utility_bill);
      }else{
        formData.append("docutilitybill", "Upload Utility Bill");
      }
      //formData.append("docutilitybill", "Upload Utility Bill");
    } else {
      formData.append(
        "docutilitybill",
        event.target["file-upload-field-utility-bill"].files[0]
      );
    }

    if (typeof event.target["myFile"].files[0] === "undefined") {
      formData.append("profilepic", this.state.file);
    } else {
      formData.append("profilepic", event.target["myFile"].files[0]);
    }

    formData.append("firstname", event.target.firstname.value);
    formData.append("lastname", event.target.lastname.value);
    formData.append("dob", event.target.dob.value);

    formData.append("userid", this.state.UserDetail.userid);

    //utility.HideLoading("Loader");

    utility.UpdateMyProfileDetail(formData, (response) => {
      utility.HideLoading("Loader");
      // this.props.history.push('/users')
      that.SendProfileEmail(event);
    });
  }

  SendProfileEmail = (event) => {
    var profileInfo={
      //"profilepic": event.target["myFile"].files[0],
      "firstname" : this.state.UserDetail.firstname,
      "lastname" :  this.state.UserDetail.lastname,
      "dob":this.state.UserDetail.dob,
      "doc_id_base64" :this.state.doc_id_base64,
      "doc_utility_bill_base64":this.state.doc_utility_bill_base64,
      //"profile_pic_base64":this.state.profile_pic_base64
    }

    //utility.HideLoading("Loader");
    utility.SendProfileEmail(profileInfo).then((res) => {
    });
  }



  handleChange = (event) => {
    this.setState({
      UserDetail: {
        ...this.state.UserDetail,
        [event.target.id]: event.target.value,
      },
    });
  };
  handleCancel() {
    //this.props.history.push('/sign-up');
    window.location.href = "/";
  }
  onChangeHandler(event) {
    let f = event.target.files[0];
    const data = new FormData();
    data.append("myFile", f);
    this.setState({
      file1: f,
      file: URL.createObjectURL(f),
      prefix: "",
    });
  }
  onClear = (e) => {
    // alert('clicked');
    // alert('file-');
    // alert(e.target["myFile"].files[0])
    $(".file-upload-wrapper").attr("data-text", "No File Selected");
    $(this).attr("data-text", "");
    $("#file-upload-id").attr("data-text", "");
    // alert($('#file-upload-id').attr('data-text'))
    this.input.current.value = null;
    e.target.value = null;
    // $('#file-upload-id').val('')
  };
  onClearBill = (e) => {
    $(".file-upload-wrapper-utility-bill").attr(
      "data-text",
      "No File Selected"
    );
    $("#file-upload-document-bill").attr("data-text", "");
    // alert($('#file-upload-document-bill').attr('data-text'))
    this.input2.current.value = null;
    e.target.value = null;
  };

  


  // DocInBase64=async (imageurl,filename)=>{
  //   if(imageurl!="" && imageurl!=undefined){  
  //     let self = this;  
  //     let fileInfo = {
  //       name: imageurl,
  //       type: utility.getFileTypeFromURL(imageurl),
  //       //base64: reader.result,
  //     };
  //     var xhr = new XMLHttpRequest();
  //     xhr.onload = function() {
  //         var reader = new FileReader();
  //         reader.readAsDataURL(xhr.response);
  //         reader.onloadend = function() {
  //           fileInfo.base64 = reader.result;
  //           console.log("fileInfo--",fileInfo)
  //           self.setState({ [filename]: fileInfo });
  //         }
  //     };    
  //     xhr.open('GET',ApiPath+imageurl);
  //     //xhr.open('GET',+imageurl);
  //     //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  //     xhr.responseType = 'blob';
  //     xhr.overrideMimeType(utility.getFileTypeFromURL(imageurl))
  //     xhr.send();
  //   }
  // }
  onFileChange=(event,filename)=>{
    var verFile = event.target.files[0];
    if (verFile == undefined) {
      return;
    }

   
    var self = this;
      // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(verFile);

    // on reader load somthing...
    reader.onload = () => {
      let fileInfo = {
        name: verFile.name,
        type: verFile.type,
        base64: reader.result,
      };
      self.setState({ [filename]: fileInfo });
    };

  }

  
  render() {
    return (
      <div>
        {/*  /> */}
        <div className='my-account'>
          <div className='container'>
            <AccountSidebar />
            <div className='account-right-container'>
              <div className='sign-up user-detail myProfile'>
                <div className='acount-inner'>
                  <h3>My Profile</h3>
                </div>

                <div className='user-details myProfileForm ' id='Loader'>
                  {/* <Shared.Loader  Show={this.state.UserDetail.ShowLoader}  /> */}

                  <form
                    className='my-profile-form'
                    id='my-profile'
                    onSubmit={this.handleSubmit}
                  >
                    <div id='formContent'>
                      <div className='row'>
                        <div className='client_profile'>
                          {/* <img src="https://www.nextpageit.com/wp-content/uploads/2019/09/Development-Solutions.png" alt="pic" /> */}
                          <input
                            type='file'
                            name='myFile'
                            id='myFile'
                            onChange={this.onChangeHandler}
                            required
                            accept='image/*'
                          />
                          {/* <img src={this.state.prefix+this.state.file} alt="pic" /> */}

                          <img
                            src={
                              this.state.file == "undefined"
                                ? "images/avatar.png"
                                : this.state.prefix + this.state.file
                            }
                            alt='pic'
                          />
                        </div>
                        <div className='col-md-6'>
                          <div className='input-block'>
                            <label>First Name</label>
                            <input
                              type='text'
                              name='firstname'
                              id='firstname'
                              className='fadeIn'
                              placeholder='First Name'
                              value={this.state.UserDetail.firstname}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='input-block'>
                            <label>Last Name</label>
                            <input
                              type='text'
                              id='lastname'
                              name='lastname'
                              className='fadeIn'
                              placeholder='Last Name'
                              value={this.state.UserDetail.lastname}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='input-block'>
                            <label>Date of Birth</label>
                            <input
                              type='date'
                              id='dob'
                              name='dob'
                              className='fadeIn'
                              placeholder='DOB'
                              value={this.state.UserDetail.dob}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='hr_line'>
                              <label>Upload Documents</label>
                              <hr />
                            </div>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className='input-block'>
                            <label>ID</label>

                            <div className='input_block_inner'>
                              <div
                                className='file-upload-wrapper'
                                data-text='Upload ID'
                              >
                                <input
                                  name='file-upload-field'
                                  type='file'
                                  id='file-upload-id'
                                  className='file-upload-field'
                                  ref={this.input}
                                  onChange={(e) => this.onFileChange(e,"doc_id_base64")}
                                />
                                <span className='clear-btn-right'>
                                  <button
                                    type='button'
                                    type='button'
                                    className='btn btn-default'
                                    onClick={this.onClear}
                                  >
                                    <i className='fa fa-times'></i>
                                  </button>
                                </span>
                              </div>
                              <a
                                download={
                                  ApiPath + this.state.UserDetail.upload_id
                                }
                                href={ApiPath + this.state.UserDetail.upload_id}
                                target='_blank'
                                className='icn download_bill'
                              >
                                {" "}
                                <i
                                  className='fa fa-download'
                                  aria-hidden='true'
                                ></i>
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='input-block'>
                            <label>Utility Bill</label>
                            <div className='input_block_inner'>
                              <div
                                className='file-upload-wrapper-utility-bill'
                                data-text='Upload Utility Bill'
                              >
                                <input
                                  name='file-upload-field-utility-bill'
                                  id='file-upload-document-bill'
                                  type='file'
                                  className='file-upload-field-utility-bill'
                                  accept="image/x-png,image/gif, application/pdf"
                                  ref={this.input2}
                                  onChange={(e) => this.onFileChange(e,"doc_utility_bill_base64")}
                                />
                                <span className='clear-btn-right'>
                                  <button
                                    type='button'
                                    type='button'
                                    className='btn btn-default'
                                    onClick={this.onClearBill}
                                  >
                                    <i className='fa fa-times'></i>
                                  </button>
                                </span>
                              </div>
                              {/* <a href="#" className="icn"> <i className="fa fa-download" aria-hidden="true"></i>Download</a> */}
                              <a
                                download={
                                  ApiPath +
                                  this.state.UserDetail.upload_utility_bill
                                }
                                href={
                                  ApiPath +
                                  this.state.UserDetail.upload_utility_bill
                                }
                                target='_blank'
                                className='icn download_bill'
                              >
                                {" "}
                                <i
                                  className='fa fa-download'
                                  aria-hidden='true'
                                  target='_blank'
                                ></i>
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row1 save-button'>
                        <input
                          type='submit'
                          className='fadeIn fourth submit sub'
                          value='Save'
                          id='save'
                        />
                        <input
                          type='submit'
                          className='fadeIn fourth submit sub'
                          value='Save'
                          id='update'
                        />
                        <input
                          type='reset'
                          className='fadeIn fourth submit button cancel'
                          value='Cancel'
                          onClick={this.handleCancel}
                        />
                      </div>
                      {/* <div id='info' className='text-for-info'>
                        Updating details feature under maintenance right now
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
