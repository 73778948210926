import {
    Container
} from 'unstated'
import $ from 'jquery';
import * as Utility from "../js/utility";

var _ = require("lodash");

export class FavouriteContainer extends Container {
    state = {
        Favorites: [],
        IsFav: false,
    };


    UpdateFavorites = () => {
        var that = this;
        var id = Utility.GetCustomerInfo().Cust_Ref;
        if (id != '') {
            Utility.GetFavorite(id, fav => {
                //console.log("favv-------", fav);
                var favorite = [];
                if (fav != undefined) {
                    favorite = fav;
                }
                that.setState({
                    Favorites: favorite
                });
                that.IsFavorite((val, data) => {
                    this.setState({
                        IsFav: val
                    })
                })

            })
        }
    }


    IsFavorite(callback) {
        var that = this;
        var id = Utility.GetCustomerInfo().Cust_Ref;
        Utility.GetFavorite(id, fav => {
            if (fav.length > 0) {
                var favLink = _.find(fav, function (o) {
                    return o.link == Utility.GetPath();
                });
                if (favLink != undefined) {
                    this.setState({
                        IsFav: true
                    })
                    callback(true, favLink);
                } else {
                    this.setState({
                        IsFav: false
                    })
                    callback(false);
                }
            } else {
                this.setState({
                    IsFav: false
                })
                callback(false);
            }
        })
    }

    CheckFavorite(name, url, callback) {
        var Name = name;
        var Link = url;
        var id = Utility.GetCustomerInfo().Cust_Ref;
        Utility.GetFavorite(id, fav => {
            //console.log("fav----", fav);
            if (fav.length > 0) {
                var favLink = _.find(fav, function (o) {
                    return o.link == Link && o.name == Name;
                });
                //console.log("fav----", favLink);
                if (favLink != undefined) {
                    callback(true, favLink);
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        })
    }

    CheckEventIsFavorite(link) {
        var data = _.find(this.state.Favorites, function (o) {
            return o.link == link;
        });
        if (data != undefined) {
            return true;
        } else {
            return false;
        }
    }


    GetFavoriteByLink = (link , callback)=>{
        var data = _.find(this.state.Favorites, function (o) {
            return o.link == link;
        });
        if (data != undefined) {
            callback(true, data);
        } else {
            callback(false);
        }

    }

    RemoveFromFavourite = (id, callback) => {
        var that = this;
        let Url = 'https://be.geoff-banks.com/_/items/user_favorites/' + id + "?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
        fetch(Url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(function (response) {
                //console.log(response, "res");
                return response.json();
            })
            .then(function (res) {
                //console.log(res, "res");
                that.UpdateFavorites();
                callback(false);
            })
            .catch(function (err) {
                //console.log(err, "err");
                that.UpdateFavorites();
                callback(false);
            });
    }

    AddInFavourite = (data, callback) => {
        var that = this;
        $.post("https://be.geoff-banks.com/_/items/user_favorites?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
            data, res => {
                that.UpdateFavorites();
                callback(true);
            });

    }

    AddEventToFavorite = (name, url, callback) => {
        var that = this;
        var Link = url;
        var Name = name;

        this.CheckFavorite(name, url, (cb, data) => {
            if (cb) {
                //console.log("1111");
                if (data != undefined) {
                    this.RemoveFromFavourite(data.id, cb => {
                        //console.log("removed", cb);
                        callback(false);
                    })
                }

            } else {
                //console.log("22222");
                var FavData = {
                    "userid": Utility.GetCustomerInfo().Cust_Ref,
                    "link": Link,
                    "name": Name,
                    "status": "published",
                }
                this.AddInFavourite(FavData, cb => {
                    //console.log("add in fav", cb)
                    callback(true);
                })

            }
        })

    }

    AddSportToFavorite = (name, callback) => {
        var that = this;
        this.IsFavorite((val, data) => {
            if (val) {
                //console.log("delete favorite", data);
                if (data != undefined) {
                    let Url = 'https://be.geoff-banks.com/_/items/user_favorites/' + data.id + "?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
                    fetch(Url, {
                                method: 'DELETE',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                            }

                        ).then(function (response) {
                            //console.log(response, "res");
                            return response.json();
                        })
                        .then(function (res) {
                            //console.log(res, "res");
                            that.UpdateFavorites();
                            callback(false);
                        })
                        .catch(function (err) {
                            //console.log(err, "err");
                            that.UpdateFavorites();
                            callback(false);
                        });
                }
            } else {
                var data = {
                    "userid": Utility.GetCustomerInfo().Cust_Ref,
                    "link": Utility.GetPath(),
                    "name": name,
                    "status": "published",
                }
                $.post("https://be.geoff-banks.com/_/items/user_favorites?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
                    data, res => {
                        that.UpdateFavorites();
                        callback(true);
                    });


            }
        })

    }




}