import React from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
//import SwiperContainer from '../Component/SwiperContainer';
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import _ from "lodash";
import { getAntepostMeetingDetail } from "../js/api-service";
import * as BetSlip_v1_Provider from "../js/bet-slip";
import subscribe from "unstated-subscribe-hoc";
import { BetSlipContainer } from "../Containers/BetSlipContainer";
import { PreferenceContainer } from "../Containers/PreferenceContainer";
import * as Shared from "../Component/Shared/Index";
import * as Utility from "../js/utility";

var eventfile = "";
var EventGroup = "";
var event = "";
class AntePostEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      antepostMeetingDetail: [],
      events: [],
      tableContent: [],
      ShowLoader: true
    };
  }

  componentDidMount() {
    const _this = this;

    if (this.props.defaultEvent != undefined) {
      eventfile = this.props.defaultEvent.eventFile;
      EventGroup = this.props.defaultEvent.eventGroup;
      event = "0";
    } else {
      const params = new URLSearchParams(_this.props.location.search);
      eventfile = params.get("eventfile");
      EventGroup = params.get("EventGroup");
      event = params.get("Events");
    }
    this.getAntepostData(eventfile, EventGroup, event);
  }

  getAntepostData(eventfile, EventGroup, event) {
    var _this = this;
    getAntepostMeetingDetail(eventfile, EventGroup, event, function(
      mettingRespose
    ) {
      if (mettingRespose != undefined) {
        var Antepost = mettingRespose;
        _.forEach(Antepost, function(antepost) {
          var evt = antepost.Events.filter((k, l) => {
            return k.PriceLink1 != null;
          });
          antepost.Events = evt;
        });
      }

      // if()

      _this.setState({ antepostMeetingDetail: Antepost, ShowLoader: false });
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.defaultEvent != undefined) {
      eventfile = nextProps.defaultEvent.eventFile;
      EventGroup = nextProps.defaultEvent.eventGroup;
      event = "0";
      this.getAntepostData(eventfile, EventGroup, event);
    }
  }

  render() {
    var content = (
      <div>
        {this.props.defaultClass == undefined}
        <div
          className={
            this.props.defaultClass == undefined
              ? "homepage"
              : "homepage-custom"
          }
        >
          <div className="">
            <div className="content-section-inner">
              {this.props.defaultEvent == undefined ? <LeftContainer /> : null}

              <div className={this.props.defaultEvent==undefined?"center-container":""}>
                <Shared.Loader Show={this.state.ShowLoader} />
                {this.state.antepostMeetingDetail.length == 0 &&
                !this.state.ShowLoader? (
                  <div className="EmptyEvents">
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                    There are Currently no active events, Please check back
                    later.
                  </div>
                ) : (
                  ""
                )}
                <div>
                  {this.state.antepostMeetingDetail.map(function(antepost, i) {
                    if (!_.isEmpty(antepost.Events))
                      return (
                        <div className="antepost-event" key={i}>
                          <div className="header">
                            <div className="detail-header">
                              <h3>{antepost.EvtAr}</h3>
                            </div>
                            <div className="detail-subheader">
                              <div className="start-date">
                                Starting Time : {antepost.TimeText}
                              </div>
                              <div className="race-type">{antepost.Pl}</div>
                            </div>
                          </div>

                          <div>
                            <div className="antepost-events-view">
                              <div className="antepost-view-inner">
                                <div className="antepost-sections">
                                  {antepost.Events.map(function(data, j) {
                                    return (
                                      <div
                                        className="antepost-sub-section cursor-pointer"
                                        key={j}
                                        onClick={e =>
                                          this.props.Store.AddBet(
                                            e,
                                            data,
                                            data,
                                            data.selection
                                          )
                                        }
                                      >
                                        <span>{data.SelName}</span>
                                        <label>
                                          {this.props.PreferenceStore.ConvertPrice(
                                            data.price
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }, this)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                  }, this)}
                </div>
              </div>
              {this.props.defaultEvent == undefined ? <RightContainer /> : null}
            </div>
          </div>
        </div>
        {this.props.defaultEvent == undefined ? <Footer /> : null}
      </div>
    );
    return <div>{content}</div>;
  }
}

export default subscribe(AntePostEvents, {
  Store: BetSlipContainer,
  PreferenceStore: PreferenceContainer
});
