import React, { useEffect, useRef, useState } from 'react';
import { CustomModal, Spinner } from '../../components';
import { BETSLIP_LOADER, BETSLIP_STATUSES, BETSLIP_ID_BACKUP } from '../../_constants';
import { betslipApi, betTransferStore, signalRService } from '../../_services';
import { BetSlipCart } from './BetSlipCart';
import { BetSlipSubmitResponse } from './BetSlipSubmitResponse';
import { BetSlipStatusResponse } from './BetSlipStatusResponse';
import { BetSlipTopPanel } from './BetSlipTopPanel';
import { detectBetslipErrors, getOddFormatted, isTokenExpired } from '../../_helpers';
import './style.css';

export const BetSlipPanel = (props) => {
    // @TODO: they might be useful later
    // const {
    //     externalAccountStore,
    //     externalPreferenceStore,
    //     externalStore,
    // } = props;

    const [connection, setConnection] = useState(null);

    const [betslipLoaderStatus, setBetslipLoaderStatus] = useState(BETSLIP_LOADER.IDLE);
    const [betslip, setBetslip] = useState(null);
    const [betslipBackup, setBetslipBackup] = useState(null);

    // null instead of false for setting it initially when page is loaded
    const [keepBetslip, setKeepBetslip] = useState(null);
    const [acceptPriceChanges, setAcceptPriceChanges] = useState(null);
    
    const [betState, setBetState] = useState(betTransferStore.initialState);

    const [isBetslipSubmitted, setIsBetslipSubmitted] = useState(null);
    const [submitResponseShow, setSubmitResponseShow] = useState(false);
    const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectedWagerIndex, setSelectedWagerIndex] = useState(-1);

    const [isZeroStake, setIsZeroStake] = useState(false);
    const [selectedWagerStake, setSelectedWagerStake] = useState(0);

    const [isResultFromSignalr, setIsResultFromSignalr] = useState(false);

    // null instead of false for setting it initially when page is loaded
    const [selectedWagerEachway, setSelectedWagerEachway] = useState(null);

    const betslipIdRef = useRef(null);

    let errorCloseTimerId;

    const closeErrorMessage = () => {
        setIsErrorMessageShown(false);
    };

    const logoutIfError = () => {
        props.externalLogout();
    };

    const acceptLiabilityValue = (liabilityValue) => {
        if (selectedWagerIndex !== -1) {
            liabilityValue = liabilityValue != null ? Number(liabilityValue) : 0;
            setSelectedWagerStake(Number(liabilityValue));
            closeErrorMessage();
        }
    };

    const updateSubmittedBetslip = (betslipResp) => {
        if (!betslipResp) {
            return null;
        }

        if (betslipResp.bets.length > 0) {
            setBetslipBackup(betslipResp);
        }

        const updatePromise = betslipResp.status === BETSLIP_STATUSES.SUCCEED
            ? betslipApi(logoutIfError)
                .createEmptyBetslip(props.IsCustomerLoggedIn())
            : Promise.resolve(betslipResp)

        return updatePromise
            .then(resp => {
                setBetslip(resp);
                setIsResultFromSignalr(true);
                return betslipResp;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const clearWagerData = () => {
        setSelectedWagerIndex(-1);
        setSelectedWagerStake(0);
        setSelectedWagerEachway(null);
    };

    const setWagerForm = (betslip, chosenWagerIndex) => {
        if (!betslip) {
            return;
        }

        setSelectedWagerIndex(chosenWagerIndex);
        const currWager = (betslip.wagers || [])[chosenWagerIndex];

        setSelectedWagerStake(currWager ? currWager.stake : 0);
        setSelectedWagerEachway(currWager ? currWager.eachWay : false);
    };

    const submitResponseHandler = (betslipResp) => {
        Promise.resolve(betslipResp)
            .then(updateSubmittedBetslip)
            .catch(err => console.error('SubmitResponse', err));
    };

    const setupSignalR = (isLoggedIn) => {
        return signalRService.buildHub(
            isLoggedIn,
            submitResponseHandler,
            props.externalLogout
        );
    };

    const loadBetslipInitial = () => {
        setBetslipLoaderStatus(BETSLIP_LOADER.LOADING);

        let requestPromise = betslipIdRef.current == null
            ? betslipApi(logoutIfError)
                .createEmptyBetslip(props.IsCustomerLoggedIn())
            : betslipApi(logoutIfError)
                .getActiveBetslip(betslipIdRef.current, props.IsCustomerLoggedIn());

        requestPromise
            .then((betslipResp => {
                console.warn('initial betslip (get or create)', betslipResp)

                setBetslip(betslipResp);
                const spinnerStatus = betslipResp.status === BETSLIP_STATUSES.PROCESSING
                    ? BETSLIP_LOADER.LOADING
                    : BETSLIP_LOADER.FINISHED;
                setBetslipLoaderStatus(spinnerStatus);

                setKeepBetslip(betslipResp.keepBetslip);
                setAcceptPriceChanges(betslipResp.acceptPriceChanges);

                betslipIdRef.current = betslipResp.id;
                localStorage.setItem(BETSLIP_ID_BACKUP, betslipIdRef.current);

                setWagerForm(betslipResp, 0);
            }))
            .catch(err => {
                console.error('request betslip', err);
            });
    };

    const processSubmittedResult = (resp) => {
        if (resp) {
            setIsBetslipSubmitted(true);

            if (betslipIdRef.current && betslipIdRef.current !== resp.id) {
                betslipIdRef.current = resp.id;
                localStorage.setItem(BETSLIP_ID_BACKUP, betslipIdRef.current);
            }

            const actualBetslip = resp && resp.bets.length === 0
                ? betslipBackup
                : resp;

            if (!actualBetslip) {
                return;
            }

            if (actualBetslip.status === BETSLIP_STATUSES.SUCCEED) {
                showSubmittedIfSuccess();
                setBetslipLoaderStatus(BETSLIP_LOADER.FINISHED);
                if (resp.keepBetslip) {
                    setWagerForm(resp, selectedWagerIndex);
                } else {
                    setWagerForm(resp, 0);
                }
            } else {                                       
                const errors = detectBetslipErrors(actualBetslip, isBetslipSubmitted);

                const isAnyError = (errors == null || errors.isAnyError);
    
                if (isAnyError) {
                    setIsErrorMessageShown(true);
                    setWagerForm(actualBetslip, selectedWagerIndex);
                    setBetslipLoaderStatus(BETSLIP_LOADER.FINISHED);
                    setIsBetslipSubmitted(false);
                } else if (actualBetslip.status === BETSLIP_STATUSES.PROCESSING) {
                    setBetslipLoaderStatus(BETSLIP_LOADER.LOADING);
                } else {
                    setWagerForm(actualBetslip, 0);
                    showSubmittedIfSuccess();
                    setBetslipLoaderStatus(BETSLIP_LOADER.FINISHED);
                }
            }

            setIsSubmitting(false);
        }
    };

    const removeAll = () => {
        if (!betslip) {
            return;
        }

        setBetslipLoaderStatus(BETSLIP_LOADER.LOADING);

        betslipApi(logoutIfError)
            .clearBetslip(betslip.id, props.IsCustomerLoggedIn())
            .then(setBetslip)
            .catch(err => {
                console.error(err);
            })
            .finally(() => setBetslipLoaderStatus(BETSLIP_LOADER.FINISHED));
    };

    const handleSubmitResponseClose = () => {
        setSubmitResponseShow(false);
        setIsBetslipSubmitted(false);
        if (errorCloseTimerId) {
            clearTimeout(errorCloseTimerId);
        }
    };

    const showSubmittedIfSuccess = () => {
        setSubmitResponseShow(true);

        errorCloseTimerId = setTimeout(() => {
            setSubmitResponseShow(false);
            setIsBetslipSubmitted(false);
        }, 10000);
    };

    const submitBetslip = () => {
        if (!betslip || !betslip.wagers) {
            return;
        }

        setIsSubmitting(true);

        betslip.wagers[selectedWagerIndex].stake = Number(selectedWagerStake);
        betslip.wagers[selectedWagerIndex].eachWay = selectedWagerEachway;
        betslip.wagers[selectedWagerIndex].toSubmit = true;

        const wagersWithProcessedStakes = betslip.wagers
            .map(wager => {
                wager.stake = !wager.stake ? 0 : wager.stake;
                return wager;
            });

        if (!wagersWithProcessedStakes[selectedWagerIndex].stake) {
            return;
        }

        const updatedBetslip = {...betslip};
        updatedBetslip.wagers = wagersWithProcessedStakes;
        updatedBetslip.keepBetslip = keepBetslip;
        updatedBetslip.acceptPriceChanges = acceptPriceChanges;

        setBetslipLoaderStatus(BETSLIP_LOADER.LOADING);

        betslipApi(logoutIfError)
            .submitBetslipAsync(updatedBetslip, props.IsCustomerLoggedIn())
            .catch(err => {
                console.error(err);
            });
    };

    const submitAcceptingChangedPrice = () => {
        closePriceChangedDialog();
        submitBetslip();
    };

    const closePriceChangedDialog = () => {
        // @TODO: check later if we need to show price insicators during 10sec
        // starting only when the popup is closed
        closeErrorMessage();
    };

    const loginOrSignup = () => {
        props.showLoginBox();
    };

    const deleteBet = (bet) => {
        if (!bet || !betslip) {
            return;
        }

        setBetslipLoaderStatus(BETSLIP_LOADER.LOADING);

        betslipApi(logoutIfError)
            .deleteBetFromBetslip(betslip.id, bet.id, props.IsCustomerLoggedIn())
            .then(setBetslip)
            .catch(err => {
                console.error(err);
            })
            .finally(() => setBetslipLoaderStatus(BETSLIP_LOADER.FINISHED));
    };


    // effects

    // bet stream initialization & subscription and setting betslipId
    useEffect(() => {
        const subscription = betTransferStore.subscribe(setBetState);
        betTransferStore.init();

        const currBetslipId = localStorage.getItem(BETSLIP_ID_BACKUP);

        betslipIdRef.current = currBetslipId && currBetslipId !== 'undefined'
            ? currBetslipId: null;

        const cleanup = () => {
            if (subscription && subscription.unsubscribe) {
                subscription.unsubscribe();
            }
        };
        return cleanup;
    }, []);

    // each time user authorized, check and create hub\start connection
    // load existing or create new betslip
    useEffect(() => {
        if (!connection) {
            const connectionHubResp = setupSignalR(props.IsCustomerLoggedIn());
            if (connectionHubResp.isError) {
                setConnection(null);
                if (connectionHubResp.isToLogout) {
                    props.externalLogout();
                }
            } else {
                setConnection(connectionHubResp.connect);
                console.warn('add connection hub', connectionHubResp.connect)
            }
        }

        const cleanup = () => {
            if (connection && connection.close) {
                connection.close();
            }
        };
        return cleanup;
    }, [connection, props.IsCustomerLoggedIn()]);

    // each time betslip changes
    useEffect(() => {
        if (!betslip) {
            loadBetslipInitial();
        }
        if (betslip && !isResultFromSignalr) {
            const isNonEmptyCart = betslip.bets
                && betslip.bets.length > 0;
            const isAfterSubmitting = betslip.wagersSubmitResult
                && betslip.wagersSubmitResult.length > 0;

            if (isNonEmptyCart && isAfterSubmitting) {
                setWagerForm(betslip, selectedWagerIndex);
            }
            if (isNonEmptyCart && !isAfterSubmitting) {
                setWagerForm(betslip, 0);
            }
            if (!isNonEmptyCart) {
                clearWagerData();
            }
        }
        if (betslip && isResultFromSignalr) {
            processSubmittedResult(betslip);
            setIsResultFromSignalr(false);
        }
    }, [betslip, isResultFromSignalr]);

    // each time new bet comes from stream, add the bet to betslip
    useEffect(() => {
        if (betState && betState.bet && betslipIdRef.current) {
            setBetslipLoaderStatus(BETSLIP_LOADER.LOADING);

            betslipApi(logoutIfError)
                .addBetToBetslip(betState.bet, betslipIdRef.current, props.IsCustomerLoggedIn())
                .then(setBetslip)
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setBetslipLoaderStatus(BETSLIP_LOADER.FINISHED);
                    setBetState({ bet: null });
                });
        }
    }, [betState, props.IsCustomerLoggedIn()]);
    
    // render

    const renderSubmitResponse = () => {
        if (!betslip) {
            return null;
        }

        if (betslipLoaderStatus === BETSLIP_LOADER.FINISHED && isBetslipSubmitted) {
            const errors = detectBetslipErrors(betslipBackup, isBetslipSubmitted);

            if (!errors || errors && !errors.isAnyError) {
                return submitResponseShow && (
                    <CustomModal handleClose={handleSubmitResponseClose}>
                        <BetSlipSubmitResponse
                            betslip={betslipBackup}
                            oddsFormat={props.oddsFormat}
                        />
                    </CustomModal>
                );
            }
            return null;
        }
        return null;
    };

    const renderErrorResponse = () => {
        if (!betslip || !isErrorMessageShown) {
            return null;
        }

        const errors = detectBetslipErrors(betslip, isBetslipSubmitted);
        const isAnyError = (errors == null || errors.isAnyError);

        if (betslipLoaderStatus === BETSLIP_LOADER.FINISHED && isAnyError) {
            return (
                <BetSlipStatusResponse
                    betslip={betslip}
                    closeMessage={closeErrorMessage}
                    closePriceChangedDialog={closePriceChangedDialog}
                    acceptLiabilityValue={acceptLiabilityValue}
                    goToDeposit={props.goToDeposit}
                    isBetslipSubmitted={isBetslipSubmitted}
                    oddsFormat={props.oddsFormat}
                    submitAcceptingChangedPrice={submitAcceptingChangedPrice}
                />
            );
        }
        return null;
    };

    const renderBetslip = () => {
        const showInitialSpinner = !betslip && betslipLoaderStatus !== BETSLIP_LOADER.FINISHED;

        if (showInitialSpinner) {
            return (
                <Spinner />
            );
        }

        if (!betslip) {
            return null;
        }

        return (
            <div className="betslip-container">
                <BetSlipTopPanel
                    removeAll={removeAll}
                    isBetslipLoaded={betslip != null}
                    isLoading={betslipLoaderStatus === BETSLIP_LOADER.LOADING}
                    betslip={betslip}
                />
                <BetSlipCart
                    betslip={betslip}
                    isBetslipLoaded={betslip != null}
                    isLoading={betslipLoaderStatus === BETSLIP_LOADER.LOADING}
                    submitBetslip={submitBetslip}
                    deleteBet={deleteBet}
                    oddsFormat={props.oddsFormat}
                    keepBetslip={keepBetslip}
                    setKeepBetslip={setKeepBetslip}
                    acceptPriceChanges={acceptPriceChanges}
                    setAcceptPriceChanges={setAcceptPriceChanges}
                    selectedWagerIndex={selectedWagerIndex}
                    setSelectedWagerIndex={setSelectedWagerIndex}
                    loginOrSignup={loginOrSignup}
                    IsCustomerLoggedIn={props.IsCustomerLoggedIn}
                    isZeroStake={isZeroStake}
                    setIsZeroStake={setIsZeroStake}
                    selectedWagerStake={selectedWagerStake}
                    setSelectedWagerStake={setSelectedWagerStake}
                    selectedWagerEachway={selectedWagerEachway}
                    setSelectedWagerEachway={setSelectedWagerEachway}
                    isSubmitting={isSubmitting}
                    isBetslipSubmitted={isBetslipSubmitted}
                />

                { renderErrorResponse() }
            </div>
        );
    };

    return (
        <div>
            { renderSubmitResponse() }
            { renderBetslip() }
        </div>
    );
}