import React, { Component } from 'react';
import * as ApiService from "../js/api-service";
import * as Utility from "../js/utility";

export default class BaseSportList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="base-sports-list-left">
                <h4>
                    <i className="fa fa-chevron-down"></i>
                    Sports
                </h4>
                <div className="sportsLists">
                    <ul className="sportListContainer">
                        {ApiService
                            .AllSports
                            .map(function (sport, i) {
                                // console.log(Utility.GetRootBare() +'asdfasdf'+ sport.PathName,'nikhil')
                                return (
                                    <li className="sidebar-dropdown" key={i}>
                                        <a className="sidebar-header" href={(Utility.GetRootBare() + sport.PathName)}>
                                            <em>
                                                <img src={"/images/" + sport.Image} />
                                            </em>
                                            <span>{sport.Name}</span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}