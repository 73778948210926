import React, { useEffect } from 'react';
import { getProfitMessage as profitMessageHelper } from '../../../../_helpers';
import './style.css';

export const StakeInput = (props) => {
    const {
        betList,
        wagers,
        selectedWagerIndex,
        isZeroStake,
        setIsZeroStake,
        selectedWagerStake,
        setSelectedWagerStake,
        selectedWagerEachway
    } = props;

    useEffect(() => {
        const isInvalid = !selectedWagerStake
            || selectedWagerStake === '0'
            || Number(selectedWagerStake) === 0;
        setIsZeroStake(isInvalid);
    }, [selectedWagerStake, setIsZeroStake]);

    const onKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-/.test(keyValue)) {
            event.preventDefault();
        }
    }

    const updateStake = (e) => {
        const isInvalid = !e.target.value
            || e.target.value === '0'
            || Number(e.target.value) === 0;
        setIsZeroStake(isInvalid);
        setSelectedWagerStake(e.target.value);
    };

    const getProfitMessage = () => {
        return profitMessageHelper(
            betList,
            wagers,
            selectedWagerIndex,
            selectedWagerStake,
            selectedWagerEachway
        );
    };

    return (
        <div>
            <input
                name={`wagerStake${selectedWagerIndex}`}
                type="number"
                className={`wager-input ${isZeroStake ? 'zero' : ''}`}
                value={selectedWagerStake}
                onKeyPress={onKeyPress}
                onChange={updateStake}
            />

            { !isZeroStake && !getProfitMessage() ? null : (
                <span className="potential-return">
                    { getProfitMessage() }
                </span>
            ) }
        </div>
    );
}