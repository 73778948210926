import React, { useEffect, useRef, useState } from "react";
import { PriceChangeIndicator } from "../../../../components";
import { getOddFormatted } from "../../../../_helpers";
import "./style.css";

export const ChosenBetList = (props) => {
  const { oddsFormat, deleteBet, betList } = props;

  const indicatorTimerId = useRef(null);

  const [isPriceIndicatorShown, setIsPriceIndicatorShown] = useState(null);

  useEffect(() => {
    if (betList && betList.length > 0) {
      if (indicatorTimerId.current) {
        clearTimeout(indicatorTimerId.current);
      }

      setIsPriceIndicatorShown(true);

      indicatorTimerId.current = setTimeout(() => {
        setIsPriceIndicatorShown(false);
      }, 10000);
    }

    const cleanup = () => {
      if (indicatorTimerId.current) {
        clearTimeout(indicatorTimerId.current);
      }
    };
    return cleanup;
  }, [betList, setIsPriceIndicatorShown]);

  const isPriceForSpHidden = (bet) => {
    return !bet.price || bet.price === "0-0";
  };

  const renderIsBog = (bet) => {
    if (bet.isBog) {
      return (
        <span className="bog_span bet-slip-bog flipInX animated">BOG</span>
      );
    }
    return null;
  };

  const convertPriceToNumber = (betPrice) => {
    if (!betPrice || isNaN(Number(betPrice))) {
      return 0;
    }
    return Number(betPrice);
  };

  const compareIfNewPriceLess = (bet) =>
    convertPriceToNumber(getOddFormatted(bet.price, "decimal")) <
    convertPriceToNumber(getOddFormatted(bet.oldPrice, "decimal"));

  const compareIfNewPriceGreater = (bet) =>
    convertPriceToNumber(getOddFormatted(bet.price, "decimal")) >
    convertPriceToNumber(getOddFormatted(bet.oldPrice, "decimal"));

  const renderPriceChangeIndicator = (bet) => {
    let direction = null;

    if (bet.oldPrice != null && compareIfNewPriceLess(bet)) {
      direction = "down";
    }

    if (bet.oldPrice != null && compareIfNewPriceGreater(bet)) {
      direction = "up";
    }

    return <PriceChangeIndicator direction={direction} />;
  };

  const renderPrice = (bet, oddsFormat) => {
    return (
      <React.Fragment>
        {isPriceIndicatorShown ? renderPriceChangeIndicator(bet) : null}
        <span>{getOddFormatted(bet.price, oddsFormat)}</span>
      </React.Fragment>
    );
  };

  return (
    <div className="bet-slip-list-wrapper">
      <ul className="bet-slip-list">
        {betList.map((bet, betIndex) => (
          <li
            className={`bet-slip-item ${
              bet.isAvailable ? "" : "not-available"
            }`}
            key={betIndex}
          >
            <div className="bet-slip-info">
              <div className="bet-slip-name trancate-text">{bet.name}</div>
              <div className="bet-slip-extra-info trancate-text">
                {bet.detail}@{" "}
                {isPriceForSpHidden(bet)
                  ? null
                  : getOddFormatted(bet.price, oddsFormat)}{" "}
                {bet.newPC}
              </div>
            </div>
            <div className="bet-slip-actions-wrapper">
              <div className="bet-slip-coefficient-wrapper">
                <div className="bet-slip-coefficient">
                  {isPriceForSpHidden(bet)
                    ? null
                    : renderPrice(bet, oddsFormat)}
                </div>
                <img
                  src="/images/delete.png"
                  className="bet-slip-delete"
                  onClick={() => deleteBet(bet)}
                />
              </div>
              {renderIsBog(bet)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
