import React from "react";
//import { Link } from 'react-router-dom';
import subscribe from "unstated-subscribe-hoc";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
import LeftHighlights from "./LeftHighlights";
import BaseSportList from "./BaseSportList";
import SportsEvents from "./LeftSidebar/SportsEvents";
import $ from "jquery";
import * as Shared from "./Shared/Index";
import { FavouriteContainer } from "../Containers/FavouriteContainer";
import PlacementBanners from "../Component/PlacementBanners";

class LeftContainer extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props, 'props')
    this.state = {
      SportsEvents: [],
      SelectedSport: {},
      ShowLoader: true,
      Favorites: []
    };
  }

  componentWillReceiveProps(nextProps) {
    // /console.log("left prop---",nextProps);
  }

  componentDidMount = () => {
    var that = this;

    // var id = Utility.GetCustomerInfo().Cust_Ref;
    // if (id != '') {
    //   Utility.GetFavorite(id, fav => {
    //     console.log("favv-------", fav);
    //     var favorite = [];
    //     if (fav != undefined) {
    //       favorite = fav;
    //     }
    //     this.setState({
    //       Favorites: favorite
    //     })
    //   })
    // }

    this.props.FavouriteStore.UpdateFavorites();

    var code = Utility.GetSpotCodeFromUrl();
    var activeSlide = "#base-sports-carousel-item";
    if (typeof code != "undefined" && code != null && code.length > 0) {
      var selectedSport = [];
      if (Utility.IsAntepostUrl()) {
        selectedSport = Api.GetAntePostSportInfo(code);
        if (selectedSport.SportCode != undefined) {
          code = selectedSport.SportCode;
        }
      } else {
        selectedSport = Api.GetSportInfo(code);
      }
      if (
        typeof selectedSport.URL != "undefined" &&
        selectedSport.URL.length > 0
      ) {
        setTimeout(() => {
          //LOAD THE LEFT MENU AFTER 2 SECONDS
          Api.GetEvents(code, events => {
            // console.log("events :", events);
            if (typeof events != "undefined") {
              //var selectedSport = Api.GetSportInfo(code);
              that.setState({
                SportsEvents: events,
                SelectedSport: selectedSport,
                ShowLoader: false
              });
              activeSlide = "#sports-events-carousel-item";
              $(activeSlide).addClass("active");
            } else {
              that.ShowBaseSports();
            }
          });
        }, 2000);
      } else {
        this.setState({
          SportsEvents: [],
          SelectedSport: selectedSport,
          ShowLoader: false
        });
        activeSlide = "#sports-events-carousel-item";
        $(activeSlide).addClass("active");
        //this.ShowBaseSports();
      }
    } else {
      this.ShowBaseSports();
    }
  };

  ShowBaseSports = () => {
    var activeSlide = "#base-sports-carousel-item";
    $(activeSlide).addClass("active");
    this.setState({ ShowLoader: false });
  };

  render() {
    return (
      <div className="left-container text-center">
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="left-sidebar">
            <PlacementBanners
              placementbannername={"AboveLeftMenu"}
            />
            <LeftHighlights />

            {/* <div className="odd-format left-sidebar_1 next text-center">
              <h4>
                Odds Format
              </h4>
              <div className="odd-format-option ">
              <select name="odds" onChange={(e) => Utility.SetOddFormat(e)} defaultValue = {Utility.GetOddFormat()}>
                <option value="decimal" >Decimal</option>
                <option value="fractional">Fractional</option>
              </select>
              </div>

            </div> */}

            <div className="favorite">
              <div className="left-sidebar_1 next fav">
                <h4>
                  <i className="fa fa-chevron-down"></i>
                  Favourites
                  <i className="fa fa-star fav_star" aria-hidden="true"></i>
                </h4>
                {this.props.FavouriteStore.state.Favorites.map(function (
                  fav,
                  i
                ) {
                  return (
                    <span key={i}>
                      <a href={fav.link}>
                        <h3>{fav.name}</h3>
                      </a>
                      <i
                        className="fa fa-minus"
                        title="Delete item"
                        onClick={e =>
                          this.props.FavouriteStore.RemoveFromFavourite(
                            fav.id,
                            cb => {

                            }
                          )
                        }
                      ></i>
                    </span>
                  );
                },
                  this)}

                {this.props.FavouriteStore.state.Favorites.length <= 0 ? (
                  <span>
                    <h3 className="empty-fav">
                      Select your favourite sports with the Star Symbol in the
                      betting area to add them to this section.
                    </h3>
                  </span>
                ) : (
                    ""
                  )}
              </div>
            </div>

            <div className="left-sidebar_1 next sidebar-menu">
              <Shared.Loader Show={this.state.ShowLoader} />
              <div
                id="left-menu-carousel"
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="carousel-inner">
                  <div id="base-sports-carousel-item" className="item">
                    <BaseSportList />
                  </div>
                  <div id="sports-events-carousel-item" className="item">
                    <SportsEvents
                      Events={this.state.SportsEvents}
                      Sport={this.state.SelectedSport}
                    />

                  </div>
                </div>

              </div>
            </div>
            {
              Utility.isShowLeftBanner() ? <PlacementBanners
              placementbannername={"BelowLeftMenu"}
              /> : null
            }
          </div>
        </nav>
      </div>
    );
  }
}

export default subscribe(LeftContainer, { FavouriteStore: FavouriteContainer });
