import React, {Component} from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as Shared from "../../Component/Shared/Index";
import * as Api from "../../js/api-service";
import * as utility from "../../js/utility";
import $ from "jquery";
import Select from "react-select";
import _ from "lodash";

let InitialData = {
    username: "",
    password: "",
    EmailAddress: "",
    TelephoneNumber: "",
    dob: "",
    // work_tel: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    country: "",
    mobile: "",
    postCode: "",
    // id: "",
}
const customStyles = {
    container: base => ({
        ...base,
        background: "#232323",
    }),
    control: base => ({
        ...base,
        height: "30px",
        minHeight: "35px",
        border: "1px solid #444444",
        borderRadius: "5px",
        background: "#232323",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px',
        background: "#232323",

    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
        background: "#232323",

    }),
    menu: (provided, state) => ({
        ...provided,
        background: "#232323",
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        background: "#232323",

    }),
};
export default class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowLoader: false,
            UserDetail: {...InitialData},
            isZipCodeRequired: false,
            showAddressdrp: false,
            addressList: [],
            address: "",
            isInvalidCode: false,
            isAddressFieldVisible: false,

        };
        this.clearInput = this.clearInput.bind(this);
        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
    }

    componentDidMount() {
        
        // var fund = JSON.parse(localStorage.getItem('authorization'));
        // if (fund != undefined) {

        if (utility.checkAuth() != undefined) {
            utility.ShowLoading("Loader");
            //this.setState({ ShowLoader: true });
            var that = this;

            Api.GetUserDetailFromNewDB((res) => {
                //     console.log("Not in New DB");
                //     utility.GetUserDetails((res) => {
                //       var data = {
                //         password: localStorage["password"],
                //         username: localStorage["username"],
                //         emailaddress: res.EmailAddress,
                //         telephonenumber: res.TelephoneNumber,
                //         work_tel: res.Work_Tel,
                //         address1: res.address1,
                //         address2: res.address2,
                //         address3: res.address3,
                //         address4: res.address4,
                //         country: res.country,
                //         mobile: res.mobile,
                //         postcode: res.postCode,
                //       };
                //       //add user detail if not present in new DB
                //       Api.AddUserDetailToNewDB(data, (resp) => {
                //         console.log("resp", resp);
                //         var res = resp.data;
                //         this.setState({
                //           UserDetail: res,
                //         });
                //         utility.HideLoading("Loader");
                //       });
                //     });
                //   } else {
                var data = {
                    username: utility.User().username,
                    EmailAddress: res.EmailAddress,
                    dob: res.dob,
                    TelephoneNumber: res.TelephoneNumber,
                    Work_Tel: res.Work_Tel,
                    address1: res.address1,
                    address2: res.address2,
                    address3: res.address3,
                    address4: res.address4,
                    country: res.country,
                    mobile: res.mobile,
                    postCode: res.postCode,
                };
                this.setState({
                    UserDetail: data,
                });
                utility.HideLoading("Loader");
            });

            // utility.GetUserDetails(res => {
            //     console.log(res);
            //     $("#EmailAddress").val(res.EmailAddress);
            //     $("#TelephoneNumber").val(res.TelephoneNumber);
            //     $("#Work_Tel").val(res.Work_Tel);
            //     $("#address1").val(res.address1);
            //     $("#address2").val(res.address2);
            //     $("#address3").val(res.address3);
            //     $("#address4").val(res.address4);
            //     $("#country").val(res.country);
            //     $("#mobile").val(res.mobile);
            //     $("#postCode").val(res.postCode);
            //     //this.setState({ ShowLoader: false });
            //     utility.HideLoading("Loader");
            // });
        } else {
            // this.props.history.push("/");
            // utility.ShowNotification(
            //   "Please Sign in to your account to access this page!"
            // );
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // if(utility._calculateAge(this.state.UserDetail.dob)<18){
        //   utility.ShowNotification("Age must be 18 years or above!");
        //   return;
        // }
        utility.ShowLoading("Loader");

        // this.state.UserDetail["username"] = utility.User().username;

        if (utility.IsCustomerLoggedIn()) {


            Api.checkDuplicateEmail(this.state.UserDetail, (cb) => {
                var data = cb["data"];

                if (data.length == 0) {
                    Api.GetUserDetailFromDirectus(userdata => {
                      const obj = {
                        id: userdata["data"][0].id,
                        status: "published",
                        emailaddress: this.state.UserDetail["EmailAddress"],
                        dob:this.state.UserDetail.dob
                      };
                      Api.UpdateUserDetailToNewDB(obj, (ab) => {
                        });
                    });

                    // Api.SendEmailForInfoSubmitted(form);


                    Api.AddUserDetailToNewDB(this.state.UserDetail, (response) => {
                        this.state.UserDetail["town/city"] = this.state.UserDetail.address4
                        delete this.state.UserDetail.address4;
                        utility.LoadEmailTemplate("UserDetail", this.state.UserDetail, (template) => {
                            Api.SendEmail("", utility.config.email, "Account Details updated by " + this.state.UserDetail.username, template);
                        });
                        setTimeout(() => {
                            utility.ShowNotification("Info Updated!");
                            utility.HideLoading("Loader");
                        }, 2000);
                    });
                } else {
                    utility.HideLoading("Loader");
                    utility.ShowNotification("Email already in use!");
                }
            });
        } else {
            this.state.UserDetail["town/city"] = this.state.UserDetail.address4
            delete this.state.UserDetail.address4;

            utility.LoadEmailTemplate("UserDetail", this.state.UserDetail, (template) => {
                Api.SendEmail("", utility.config.email, "Account Details updated by " + this.state.UserDetail.username, template);
                this.setState({
                    ...this.state,
                    UserDetail: {
                        ...InitialData,
                        Work_Tel: ""
                    }
                })
                setTimeout(() => {
                    utility.ShowNotification("Info Updated!");
                    utility.HideLoading("Loader");
                }, 2000);
            });
        }

    };

    handleChange = (event) => {
        this.setState({
            UserDetail: {
                ...this.state.UserDetail,
                [event.target.id]: event.target.value,
            },
        });
    };

    clearInput(e) {
        document.getElementById(e).value = "";
    }

    focus = (e) => {
        document.getElementById(e).style.display = "block";
    };

    blur = (e) => {
        document.getElementById(e).style.display = "none";
    };

    findMyAddress = (event) => {
        event.preventDefault();

        if (this.state.UserDetail.postCode == "") {
            utility.ShowNotification("Please enter a valid Postal Code!");
            return;
        }

        this.setState({
            showAddressdrp: false,
            isAddressFieldVisible: false,

        });
        Api.getaddressByZipcode(this.state.UserDetail.postCode).then((result) => {
            if (result != null) {
                if (result.addresses != undefined) {
                    var drpLoc = [];
                    drpLoc.push({value: "-1", label: "Select Address"});
                    for (var i = 0; i < result.addresses.length; i++) {
                        drpLoc.push({
                            value: result.addresses[i],
                            label: result.addresses[i],
                        });
                    }
                    this.setState({
                        addressList: drpLoc,
                        showAddressdrp: true,
                        isInvalidCode: false,
                        isAddressFieldVisible: false,

                    });
                } else {
                    this.setState({
                        showAddressdrp: false,
                        isInvalidCode: true,
                        isAddressFieldVisible: true,

                    });
                }
            }
        });
        // }
    };

    onAddressChange = (value) => {
        // console.log(value, "Value")
        if (value.value != -1) {
            var address = _.split(value.value, ",");
            var thirdLineAddress =
                typeof address[2] === "undefined" ? "" : address[2] + ",";
            thirdLineAddress =
                thirdLineAddress + typeof address[3] === "undefined"
                    ? ""
                    : address[3] + ",";
            thirdLineAddress =
                thirdLineAddress + typeof address[4] === "undefined"
                    ? ""
                    : address[4] + ",";

            // console.log(address[0], address[1], address[5], address[6], thirdLineAddress)
            this.setState({
                UserDetail: {
                    ...this.state.UserDetail,
                    address1: typeof address[0] === "undefined" ? "" : address[0],
                    address2: typeof address[1] === "undefined" ? "" : address[1],
                    address3: thirdLineAddress,
                    address4: address[5],
                    country: address[6],
                },
                isAddressFieldVisible: true,

            });
        }
    };

    render() {
        return (
            <div>
                <div className="my-account">
                    <div className="container">
                        <AccountSidebar/>
                        <div className="account-right-container">
                            <div className="sign-up user-detail changePassword">
                                <div className="acount-inner">
                                    <h3>User Details</h3>
                                </div>

                                <div className="user-details changePassForm " id="Loader">
                                    {/* <Shared.Loader  Show={this.state.UserDetail.ShowLoader}  /> */}

                                    <form
                                        className="user-details-form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div id="formContent">
                                            {!utility.IsCustomerLoggedIn() ? (
                                                <div>

                                                    <div className="input-block relPos ">
                                                        <label>Username</label>
                                                        <input
                                                            id="username"
                                                            type="text"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.username}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("1")}
                                                            onFocus={(e) => this.focus("1")}
                                                            required
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="1"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("username")}
                                                        />
                                                    </div>

                                                    <div className="input-block relPos ">
                                                        <label>Password</label>
                                                        <input
                                                            id="password"
                                                            type="password"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.password}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("2")}
                                                            onFocus={(e) => this.focus("2")}
                                                            required
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="2"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("password")}
                                                        />
                                                    </div>
                                                </div>

                                            ) : (
                                                ""
                                            )}
                                            <div className="input-block relPos ">
                                                <label>Email Address</label>
                                                <input
                                                    id="EmailAddress"
                                                    type="email"
                                                    className="fadeIn"
                                                    value={this.state.UserDetail.EmailAddress}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.blur("3")}
                                                    onFocus={(e) => this.focus("3")}
                                                    // pattern={"[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"}
                                                    required
                                                />
                                                <img
                                                    src="/images/close.png"
                                                    id="3"
                                                    className="crossIcon"
                                                    onMouseDown={(e) => this.clearInput("EmailAddress")}
                                                />
                                            </div>
                                            <div className="input-block relPos ">
                                                <label>Deposit Limit</label>
                                                <input
                                                    // type="text"
                                                    type="number"
                                                    id="TelephoneNumber"
                                                    className="fadeIn"
                                                    value={this.state.UserDetail.TelephoneNumber}
                                                    onChange={this.handleChange}
                                                    placeholder="Deposit Limit"
                                                    // readOnly="readonly"
                                                    onBlur={(e) => this.blur("4")}
                                                    onFocus={(e) => this.focus("4")}
                                                />
                                                <img
                                                    src="/images/close.png"
                                                    id="4"
                                                    className="crossIcon"
                                                    onMouseDown={(e) =>
                                                        this.clearInput("TelephoneNumber")
                                                    }
                                                />
                                            </div>
                                            <div className="input-block relPos">
                                                <label>Work Phone</label>
                                                <input
                                                    type="text"
                                                    id="Work_Tel"
                                                    className="fadeIn"
                                                    value={this.state.UserDetail.Work_Tel}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.blur("5")}
                                                    onFocus={(e) => this.focus("5")}
                                                />
                                                <img
                                                    src="/images/close.png"
                                                    id="5"
                                                    className="crossIcon"
                                                    onMouseDown={(e) => this.clearInput("Work_Tel")}
                                                />
                                            </div>
                                            <div className="input-block relPos postCode">
                                                <label>Postal Code</label>
                                                {/* <input
                                                    type="text"
                                                    id="postCode"
                                                    className="fadeIn"
                                                    value={this.state.UserDetail.postCode}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.blur("6")}
                                                    onFocus={(e) => this.focus("6")}
                                                />
                                                <img
                                                    src="/images/close.png"
                                                    id="6"
                                                    className="crossIcon"
                                                    onMouseDown={(e) => this.clearInput("postCode")}
                                                />
                                                <button
                                                    className="btn btn-primary findBtn"
                                                    onClick={this.findMyAddress.bind(this)}
                                                    title="Search your address by entering Post Code"
                                                >
                                                    Find
                                                </button>
                                                <br/> */}

                                                <div>
                                                    <input
                                                        type="text"
                                                        id="postCode"
                                                        className="fadeIn"
                                                        value={this.state.UserDetail.postCode}
                                                        onChange={this.handleChange}
                                                        onBlur={(e) => this.blur("6")}
                                                        onFocus={(e) => this.focus("6")}
                                                    />
                                                    <img
                                                        src="/images/close.png"
                                                        id="6"
                                                        className="crossIcon"
                                                        onMouseDown={(e) => this.clearInput("postCode")}
                                                    />
                                                    <button
                                                        className="btn btn-primary findBtn"
                                                        onClick={this.findMyAddress.bind(this)}
                                                        title="Search your address by entering Post Code"
                                                    >
                                                        Find
                                                    </button>
                                                    {/* <br/> */}
                                                </div>

                                                {this.state.isZipCodeRequired && (
                                                    <span className="error text-danger">
                                                        PostalCode is required
                                                    </span>
                                                )}
                                                {this.state.isInvalidCode && (
                                                    <span className="error text-danger">
                                                        Please recheck your postcode, it seems to be
                                                        incorrect
                                                        <br/>
                                                    </span>
                                                )}
                                                <a
                                                    // className="floatRight"
                                                    href="#"
                                                    onClick={() =>
                                                        this.setState({
                                                            isAddressFieldVisible: !this.state
                                                                .isAddressFieldVisible,
                                                        })
                                                    }
                                                >
                                                    Enter Address Manually
                                                </a>
                                            </div>

                                            {this.state.showAddressdrp ? (
                                                // <div className="form-inline input-block selector">
                                                <div className="form-inline input-block selector">
                                                    <label>Select Address</label>
                                                    <Select
                                                        options={this.state.addressList}
                                                        defaultValue={this.state.addressList[0]}
                                                        onChange={(value) => this.onAddressChange(value)}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                // </div>
                                            ) : ""}
                                            {this.state.isAddressFieldVisible ? (
                                                <div>

                                                    <div className="input-block relPos">
                                                        <label>Address 1</label>
                                                        <input
                                                            type="text"
                                                            id="address1"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.address1}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("7")}
                                                            onFocus={(e) => this.focus("7")}
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="7"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("address1")}
                                                        />
                                                    </div>
                                                    <div className="input-block relPos">
                                                        <label>Address 2</label>
                                                        <input
                                                            type="text"
                                                            id="address2"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.address2}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("8")}
                                                            onFocus={(e) => this.focus("8")}
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="8"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("address2")}
                                                        />
                                                    </div>
                                                    <div className="input-block relPos">
                                                        <label>Address 3</label>
                                                        <input
                                                            type="text"
                                                            id="address3"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.address3}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("9")}
                                                            onFocus={(e) => this.focus("9")}
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="9"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("address3")}
                                                        />
                                                    </div>
                                                    <div className="input-block relPos">
                                                        <label>Town/City</label>
                                                        <input
                                                            type="text"
                                                            id="address4"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.address4}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("10")}
                                                            onFocus={(e) => this.focus("10")}
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="10"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("address4")}
                                                        />
                                                    </div>
                                                    {/* <div className="input-block relPos">&nbsp;</div> */}
                                                    {/* <div className="input-block relPos">&nbsp;</div> */}
                                                    <div className="input-block relPos">
                                                        <label>Country</label>
                                                        <input
                                                            type="text"
                                                            id="country"
                                                            className="fadeIn"
                                                            value={this.state.UserDetail.country}
                                                            onChange={this.handleChange}
                                                            onBlur={(e) => this.blur("11")}
                                                            onFocus={(e) => this.focus("11")}
                                                        />
                                                        <img
                                                            src="/images/close.png"
                                                            id="11"
                                                            className="crossIcon"
                                                            onMouseDown={(e) => this.clearInput("country")}
                                                        />
                                                    </div>
                                                </div>
                                            ) : ""}

                                            <div className="input-block relPos">
                                                <label>Mobile</label>
                                                <input
                                                    type="text"
                                                    id="mobile"
                                                    className="fadeIn"
                                                    value={this.state.UserDetail.mobile}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.blur("12")}
                                                    onFocus={(e) => this.focus("12")}
                                                />
                                                <img
                                                    src="/images/close.png"
                                                    id="12"
                                                    className="crossIcon"
                                                    onMouseDown={(e) => this.clearInput("mobile")}
                                                />
                                            </div>
                                            {/* <div className="input-block relPos">
                        <label>Postal Code</label>
                        <input
                          type="text"
                          id="postCode"
                          className="fadeIn"
                          value={this.state.UserDetail.postCode}
                          onChange={this.handleChange}
                          onBlur={(e) => this.blur("10")}
                          onFocus={(e) => this.focus("10")}
                        />
                        <img
                          src="/images/close.png"
                          id="10"
                          className="crossIcon"
                          onMouseDown={(e) => this.clearInput("postCode")}
                        />
                      </div> */}
                                            <div className="input-block relPos">
                                                <label>Date of birth</label>
                                                <input
                                                    type="date"
                                                    id="dob"
                                                    className="fadeIn"
                                                    value={this.state.UserDetail.dob}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.blur("13")}
                                                    onFocus={(e) => this.focus("13")}
                                                    required
                                                />
                                                <img
                                                    src="/images/close.png"
                                                    id="13"
                                                    className="crossIcon"
                                                    onMouseDown={(e) => this.clearInput("dob")}
                                                />
                                            </div>
                                            <div className="row1">
                                                <input
                                                    type="submit"
                                                    className="fadeIn fourth submit disabled"
                                                    value="Submit"
                                                />
                                                {/* <div className="msg light">Updating details feature under maintenance right now</div> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
