import React from "react";
import _ from "lodash";
import $ from "jquery";
import * as Utility from "../js/utility";
import subscribe from "unstated-subscribe-hoc";
import {BetSlipContainer} from "../Containers/BetSlipContainer";
import {PreferenceContainer} from "../Containers/PreferenceContainer";
import {FavouriteContainer} from "../Containers/FavouriteContainer";
import Tooltip from "./Shared/Tooltip";
import * as Api from "../js/api-service";
class EventList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Event: {},
            FullCardOptions: [],
            FullCardOptionHeadings: [],
            FullCardOptionHeadingsCount: 2,
            SportCode: "",
            toggleDisable: false,
        };
        this.LoadFilteredEvents = this.LoadFilteredEvents.bind(this);
        this.ParseFilteredEvents = this.ParseFilteredEvents.bind(this);
        this.ParseFilteredEvents = this.ParseFilteredEvents.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.LoadFromProps(newProps);
    }

    LoadFromProps(props) {
        let event = {};
        if (typeof props.Event != "undefined") {
            event = props.Event;
            this.ParseFilteredEvents(event);
        }
        if (typeof props.FullCardOptions != "undefined") {
            //this.setState({ FullCardOptions: props.FullCardOptions })
        }
        if (typeof props.SportCode != "undefined") {
            this.setState({SportCode: props.SportCode});
        }
    }

    componentDidMount = () => {
        //Utility.UpdateIsEnableInPlay();
        const that = this;
        this.LoadFromProps(this.props);

        Utility.getSetting((setting) => {
            if (setting.live_odds_enabled) {
                setInterval(function () {
                    that.ReloadAll();
                }, 15000);
            }
        });
    };

    ParseFilteredEvents(myEvent) {
        let events = [];
        let FullCardOptions = [];
        let activeIndex = 0;
        if (
            typeof myEvent != "undefined" &&
            typeof myEvent.Competitions != "undefined" &&
            typeof myEvent.Competitions[0].details != "undefined" &&
            typeof myEvent.Competitions[0].details[0] != "undefined" &&
            typeof myEvent.Competitions[0].details[0].FullCard != "undefined" &&
            typeof myEvent.Competitions[0].details[0].FullCard[0] != "undefined" &&
            typeof myEvent.Competitions[0].details[0].FullCard[0].Events !=
            "undefined"
        ) {
            events = myEvent.Competitions[0].details[0].FullCard[0].Events;
            FullCardOptions =
                myEvent.Competitions[0].details[0].AvailableFullCardOptions;
            activeIndex = 0;
        } else if (
            typeof myEvent != "undefined" &&
            typeof myEvent.Competitions != "undefined" &&
            myEvent.Competitions.length > 1 &&
            typeof myEvent.Competitions[1].details != "undefined" &&
            typeof myEvent.Competitions[1].details[0] != "undefined" &&
            typeof myEvent.Competitions[1].details[0].FullCard != "undefined" &&
            typeof myEvent.Competitions[1].details[0].FullCard[0] != "undefined" &&
            typeof myEvent.Competitions[1].details[0].FullCard[0].Events !=
            "undefined"
        ) {
            events = myEvent.Competitions[1].details[0].FullCard[0].Events;
            FullCardOptions =
                myEvent.Competitions[1].details[0].AvailableFullCardOptions;
            activeIndex = 1;
        }

        FullCardOptions = _.filter(FullCardOptions, function (o) {
            return o.Count <= 3;
        });

        var headingCount = 0;
        if (FullCardOptions.length > 0) {
            ///TODO: THIS WHOLE CODE BELOW LOOKS WRONG, EXTRA LOOPS. WE SHOULD BE CHECKING THIS ONLY ONE EVENT THAT HAS DATA BASED ON INDEX FOUND IN THE CODE ABOVE
            myEvent.Competitions.map((comp, idx) => {
                comp.details.map((detail, index) => {
                    if (window.location.pathname.split("/")[2] == "golf")
                        detail.middlePlayer = detail.FullCard[0].Events[1].SelName; //Harshiv changes

                    comp.FullCardOptionHeadingsCount = 0; //JUST TO AVOID UNDEFINED
                    if (detail && detail.FullCard[0]) {
                        events = detail.FullCard[0].Events;
                        comp.FullCardOptionHeadings = [];
                        events.map((evt, idx) => {
                            const name = evt.SelName.replace(detail.homeTeam, "Home").replace(
                                detail.awayTeam,
                                "Away"
                            );
                            comp.FullCardOptionHeadings.push(name);
                        });
                        comp.FullCardOptionHeadingsCount =
                            comp.FullCardOptionHeadings.length;
                        headingCount = comp.FullCardOptionHeadingsCount;
                    }
                });
            });
        }

        if (this.state.SportCode === "sb") {
            if (myEvent.Competitions.length) {
                var newMyEvent = myEvent.Competitions.filter((v) => {
                    v.details = v.details.slice(0, 5);
                    return v.details;
                });
            }
        }
        // events.map((evt, idx) => {     FullCardOptionHeadings.push(evt.SelName); })
        // if (myEvent.Competitions[0].details[0] &&
        // myEvent.Competitions[0].details[0].FullCard &&
        // myEvent.Competitions[0].details[0].FullCard[0] &&
        // myEvent.Competitions[0].details[0].FullCard[0].ElRespose == 'Draw No Bet') {
        // FullCardOptionHeadings = ["Home", "Away"] };
        // FullCardOptionHeadings: FullCardOptionHeadings, FullCardOptionHeadingsCount:
        // FullCardOptionHeadings.length

        this.setState({
            Event: myEvent,
            FullCardOptions: FullCardOptions,
            FullCardOptionHeadingsCount: headingCount,
        });
    }

    GetFilteredEvents(val, callback) {
        let url = `https://api.geoff-banks.com/Geoff/GetEventsDetail/${this.state.SportCode}?fullcard=1&filter=${encodeURIComponent(val)}`;
        //url = Api.ApiProxy.Get + escape(url);
        if(Utility.isCacheApiProxyEnable()){
            url = Api.ApiProxy.Get + escape(url);
        }
        $.get(url,
            (data) => {
                if(Utility.isCacheApiProxyEnable()){
                    callback(data.Data);
                }else{
                    callback(data);
                }                
            }
        );
    }

    ReloadAll = () => {
        const that = this;
        const dropDowns = $(".dd-filter-full-card-events");
        dropDowns.each((idx, dd) => {
            // var ctrl = $(dd); var e = {}; e.target = {}; e.target.value = ctrl.val();
            // e.ShowLoader = false;

            that.LoadFilteredEvents(false);
        });

        // setTimeout(() => {
        //     that.ReloadAll();
        // }, 5000);
    };

    LoadFilteredEvents(showLoader) {
        const eventCode = this.state.Event.Code;
        const loaderTargetId = "event-list-" + eventCode;
        const ctrl = $("#filter-full-card-events-" + eventCode);
        if (showLoader) Utility.ShowLoader(loaderTargetId);

        const alreadyLoading = $("#" + loaderTargetId).hasClass("loading");
        if (alreadyLoading) return;
        $("#" + loaderTargetId).addClass("loading"); //WE USING THIS TO PREVENT MULTIPLE LOADING CALLS

        const val = ctrl.val();
        const that = this;
        //console.log("val======>", val)
        this.GetFilteredEvents(val, (events) => {
            if (events.length <= 0) {
                Utility.HideLoader(loaderTargetId);
            }
            const myEvent = _.find(events, function (o) {
                return o.Code === eventCode;
            });
            that.ParseFilteredEvents(myEvent);

            Utility.HideLoader(loaderTargetId);
            $("#" + loaderTargetId).removeClass("loading");
        });
    }

    toggleResult(
        item,
        eventgroup,
        eventawayteam,
        eventhometeam,
        event,
        title,
        Code,
        deventid
    ) {
        //var cl = event.target.className;
        this.setState({toggleDisable: true});

        var cl = event.currentTarget.className;
        var isClass = cl.indexOf("active") != -1;

        if (isClass === true) {
            Utility.CheckInPlayEvents_Individuals_Exist(
                item,
                (responsecheckisexist) => {
                    if (responsecheckisexist == 0) {
                        Utility.checkFullTimeResult(Code, eventgroup, (result) => {
                            if (result.length > 0) {
                                if (!Utility.hasFullTimeResult(result)) {
                                    alert(
                                        "Full Tme Result is not available for this event. Can’t start In-Play"
                                    );
                                    this.setState({toggleDisable: false});
                                } else {
                                    Utility.SaveInPlayEvents_Individuals(
                                        item,
                                        eventgroup,
                                        eventawayteam,
                                        eventhometeam,
                                        title,
                                        Code,
                                        deventid.Id,
                                        (responseresult) => {
                                            const toggle_old =
                                                `toggle-${responseresult.data.event_id1}`;
                                            const livetoggle = "#" + toggle_old;
                                            $(livetoggle).attr("class", responseresult.data.id);
                                            const save_url =
                                                `https://api.geoff-banks.com/Geoff/GetEventsFromAPI_Idx/${Code}/${eventgroup}/0`;
                                            //uncomment this line while inplay
                                            Utility.SaveInPlayEvents_Urls(
                                                save_url,
                                                (responseresult1) => {
                                                    this.setState({toggleDisable: false});
                                                }
                                            );
                                            //this.event.publish('updateSlider', true);
                                        }
                                    );
                                }
                            } else {
                                alert(
                                    "Full Time Result is not available for this event. Can’t start In-Play"
                                );
                                this.setState({toggleDisable: false});
                            }
                        });
                        this.props.clk();
                        // });
                    }
                }
            );
        } else if (isClass === false) {
            //var toggle = "toggle-" + item.eventId1;
            const toggle = `toggle-${item}`;
            const livetoggles = "#" + toggle;
            const id = $(livetoggles).attr("class");

            Utility.RemoveInPlayEvents(id, (responseresult) => {
                // $(livetoggle_delete).removeClass("show");
                //   $(livetoggle_delete).addClass("hide");
                // this.getLiveEvents_Slider(dcode);
                const save_url =
                    `https://api.geoff-banks.com/Geoff/GetEventsFromAPI_Idx/${Code}/${eventgroup}/0`;
                //uncomment this line while inplay
                Utility.DeleteInPlayEvents_Urls(save_url, (responseresult1) => {
                    this.props.clk();
                    this.setState({toggleDisable: false});
                });

                this.props.clk();
            });
        }

        //   this.checkIsLiveMatch(item);
    }

    render() {
        const event = this.state.Event;
        if (typeof event == "undefined" || typeof event.Competitions == "undefined")
            return "";
        return (
            <div
                className={
                    `int-highlights event-list-${event.Code} event-list-with-head-count-${this.state.FullCardOptionHeadingsCount}`
                }
                id={"event-list-" + event.Code}
            >
                <div className="int-heading">
                    <div className="mg-widget-header-left text-left">
                        <h3 className="header-title">
                            <i className="fa fa-chevron-down"></i>
                            {event.EventName}
                            <i
                                className="fa fa-refresh loading-indicator"
                                aria-hidden="true"
                            ></i>
                        </h3>
                    </div>

                    <div className="mg-widget-header-left-2 text-center">
                        <label>Result 1X2</label>
                    </div>

                    {this.state.FullCardOptions.length > 0 ? (
                        <div
                            className={
                                "mg-widget-header-right text-center result head-count-" +
                                this.state.FullCardOptionHeadingsCount
                            }
                        >
                            <select
                                id={"filter-full-card-events-" + event.Code}
                                className="dd-filter-full-card-events"
                                onChange={() => this.LoadFilteredEvents(true)}
                            >
                                {this.state.FullCardOptions.map(function (item, idx) {
                                    return (
                                        <option value={item.Name} key={idx} defaultValue={item.IsDefault}>
                                            {item.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                {event.Competitions.map(function (competitions, i) {
                    return (
                        <div key={i}>
                            <div className="sport-event-list">
                                <div className="col-md-4 result-left">
                                    <div className="team-name">
                                        <label>
                                            <i className="fa fa-star"></i>
                                            <span>{competitions.date}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 result-center">
                                    <span>1</span>
                                    <span>x</span>
                                    <span>2</span>
                                </div>

                                <div
                                    className={
                                        "col-md-4 result-right head-count-" +
                                        this.state.FullCardOptionHeadingsCount
                                    }
                                >
                                    {competitions.FullCardOptionHeadings &&
                                    competitions.FullCardOptionHeadings.length > 0
                                        ? competitions.FullCardOptionHeadings.map(function (
                                            heading,
                                            j
                                            ) {
                                                if (heading == competitions.details[0].homeTeam) {
                                                    return (
                                                        <span className="full-card-event-heading" key={j}>
                              1
                            </span>
                                                    );
                                                } else if (
                                                    heading == competitions.details[0].awayTeam
                                                ) {
                                                    return (
                                                        <span className="full-card-event-heading" key={j}>
                              2
                            </span>
                                                    );
                                                } else {
                                                    return (
                                                        <span className="full-card-event-heading" key={j}>
                              {heading}
                            </span>
                                                    );
                                                }

                                                // return (     <span className="full-card-event-heading"
                                                // key={j}>{heading}</span> )
                                            },
                                            this)
                                        : ""}
                                </div>
                            </div>

                            {competitions.details.map(function (detail, k) {
                                // console.log("competition",detail);
                                return (
                                    <div className="result-inner" key={k}>
                                        <Tooltip clickable={true}/>
                                        <div className="row">
                                            <div className="col-md-4 result-left" data-tip={detail.eventId1}>
                                                <div className="team-name">
                                                    {/* {this.state.SportCode != "sb" ? ( */}
                                                    <span className="kickoff">{detail.kickoff}</span>
                                                    {/* ) : (
                            ""
                          )} */}
                                                    <h6 className="teams">
                                                        <i
                                                            className={
                                                                this.props.FavouriteStore.CheckEventIsFavorite(
                                                                    "/show-events?eventfile=" +
                                                                    event.Code +
                                                                    "&EventGroup=" +
                                                                    detail.eventGroup
                                                                )
                                                                    ? "fa fa-star fav_star"
                                                                    : "fa fa-star  not_fav_star"
                                                            }
                                                            aria-hidden="true"
                                                            onClick={(e) =>
                                                                this.props.FavouriteStore.AddEventToFavorite(
                                                                    // window.location.pathname.split("/")[2] == 'golf' && detail.middlePlayer != undefined
                                                                    //? (detail.homeTeam + '-' + detail.middlePlayer + '-' + detail.awayTeam)
                                                                    window.location.pathname.split("/")[2] ==
                                                                    "golf" && detail.secondPlayer != undefined
                                                                        ? detail.homeTeam +
                                                                        "-" +
                                                                        detail.secondPlayer +
                                                                        "-" +
                                                                        detail.awayTeam
                                                                        : detail.homeTeam + "-" + detail.awayTeam,
                                                                    "/show-events?eventfile=" +
                                                                    event.Code +
                                                                    "&EventGroup=" +
                                                                    detail.eventGroup,
                                                                    (cb) => {
                                                                    }
                                                                )
                                                            }
                                                        ></i>
                                                        <a
                                                            href={
                                                                "/show-events?sportcode=" +
                                                                this.state.SportCode +
                                                                "&eventName=" +
                                                                detail.homeTeam +
                                                                "-" +
                                                                detail.awayTeam +
                                                                "&eventfile=" +
                                                                event.Code +
                                                                "&EventGroup=" +
                                                                detail.eventGroup
                                                            }

                                                        >
                                                            {detail.homeTeam}-
                                                            {window.location.pathname.split("/")[2] ==
                                                            "golf" && detail.secondPlayer != undefined
                                                                ? detail.secondPlayer + "-" + detail.awayTeam
                                                                : detail.awayTeam}
                                                        </a>

                                                        <span
                                                            className={detail.id}
                                                            id={"toggle-" + detail.eventId1}
                                                        >
                              {" "}
                            </span>
                                                        <div className="live_toggle"></div>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-md-4 result-center">
                                                {detail.homeodds == "NO" || detail.homeodds == "" ? (
                                                    <label
                                                        style={{
                                                            visibility: "hidden",
                                                        }}
                                                    >
                                                        {detail.homeodds}
                                                    </label>
                                                ) : (
                                                    <label
                                                        className="cursor-pointer"
                                                        data-odds={detail.homeodds}
                                                        onClick={(e) => this.props.Store.AddBet(e, detail, event, 0)}
                                                    >
                                                        {this.state.SportCode == "sb"
                                                            ? this.props.PreferenceStore.decimalPrice(
                                                                detail.homeodds
                                                            )
                                                            : this.props.PreferenceStore.ConvertPrice(
                                                                detail.homeodds
                                                            )}
                                                    </label>
                                                )}

                                                {detail.drawodds == "NO" || detail.drawodds == "" ? (
                                                    <label
                                                        style={{
                                                            visibility: "hidden",
                                                        }}
                                                    >
                                                        {detail.drawodds}
                                                    </label>
                                                ) : (
                                                    <label
                                                        className="cursor-pointer"
                                                        data-odds={detail.drawodds}
                                                        onClick={(e) => this.props.Store.AddBet(e, detail, event, 1)}
                                                    >
                                                        {this.state.SportCode == "sb"
                                                            ? this.props.PreferenceStore.decimalPrice(
                                                                detail.drawodds
                                                            )
                                                            : this.props.PreferenceStore.ConvertPrice(
                                                                detail.drawodds
                                                            )}
                                                    </label>
                                                )}

                                                {detail.awayodds == "NO" || detail.awayodds == "" ? (
                                                    <label
                                                        style={{
                                                            visibility: "hidden",
                                                        }}
                                                    >
                                                        {detail.awayodds}
                                                    </label>
                                                ) : (
                                                    <label
                                                        className="cursor-pointer"
                                                        data-odds={detail.awayodds}
                                                        onClick={(e) => this.props.Store.AddBet(e, detail, event, 2)}
                                                    >
                                                        {this.state.SportCode == "sb"
                                                            ? this.props.PreferenceStore.decimalPrice(
                                                                detail.awayodds
                                                            )
                                                            : this.props.PreferenceStore.ConvertPrice(
                                                                detail.awayodds
                                                            )}
                                                    </label>
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    "col-md-4 result-right head-count-" +
                                                    this.state.FullCardOptionHeadingsCount
                                                }
                                            >
                                                {detail.FullCard.length > 0 && detail.FullCard[0].Events
                                                    ? detail.FullCard[0].Events.map(function (evt, l) {
                                                        return (
                                                            <div className="price" key={l}>
                                                                <label
                                                                    className="cursor-pointer"
                                                                    data-odds={evt.price}
                                                                    onClick={(e) =>
                                                                        this.props.Store.AddBet(
                                                                            e,
                                                                            evt,
                                                                            evt,
                                                                            evt.selection
                                                                        )
                                                                    }
                                                                >
                                                                    {this.state.SportCode == "sb"
                                                                        ? this.props.PreferenceStore.decimalPrice(
                                                                            evt.price
                                                                        )
                                                                        : this.props.PreferenceStore.ConvertPrice(
                                                                            evt.price
                                                                        )}
                                                                </label>
                                                            </div>
                                                        );
                                                    }, this)
                                                    : ""}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="live-badge">
                                                {Utility.IsEnablePlayEvents() == "true" ? (
                                                    <div className="col-md-12">
                                                        {typeof detail.isLive == "undefined" ||
                                                        detail.isLive == false ? (
                                                            ""
                                                        ) : (
                                                            <div className="event_live">Live </div>
                                                        )}
                                                        {this.props.Store.state.ShowPlaceBetButton &&
                                                        Utility.IsEnablePlayEvents() == "true" ? (
                                                            <button
                                                                type="button"
                                                                className={
                                                                    typeof detail.isLive == "undefined" ||
                                                                    detail.isLive == false
                                                                        ? "btn btn-xs btn-toggle"
                                                                        : "btn btn-xs btn-toggle active"
                                                                }
                                                                data-toggle="button"
                                                                disabled={this.state.toggleDisable}
                                                                aria-pressed="false"
                                                                autocomplete="off"
                                                                onClick={(e) =>
                                                                    this.toggleResult(
                                                                        detail.eventId1,
                                                                        detail.eventGroup,
                                                                        detail.awayTeam,
                                                                        detail.homeTeam,
                                                                        e,
                                                                        event.EventName,
                                                                        event.Code,
                                                                        event
                                                                    )
                                                                }
                                                            >
                                                                <div className="handle"></div>
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }, this)}
                        </div>
                    );
                }, this)}
            </div>
        );
    }
}

export default subscribe(EventList, {
    Store: BetSlipContainer,
    PreferenceStore: PreferenceContainer,
    FavouriteStore: FavouriteContainer,
});
