import React from "react";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";

const IFrameForm = ({data = {url: '', title: '', height: ''}}) => {
  return (
    <div>
      <div>
        <div className="homepage affiliate-page">
          <div className="container">
            <div className="content-section-inner">
              <LeftContainer />
              <div className="right-content">
                <iframe
                  src={data.url}
                  title={data.title}
                  width="100%"
                  height={data.height}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default IFrameForm;