import React, { Component } from 'react';
import * as Shared from "../../Component/Shared/Index";
import * as Api from '../../js/api-service';
import * as utility from '../../js/utility';

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Transaction: {},
            //Summary: [], WinDetails: '',
            ShowLoader: true,
        }
    }

    componentWillReceiveProps(newProps) {
        this.LoadFromProps(newProps);
    }

    LoadFromProps(props) {
        var trans = {};
        if (typeof props.transaction != "undefined") {
            trans = props.transaction;
            if (trans.MainTransaction.OfficeNo == 1 && trans.MainTransaction.RefNo.substring(0, 1) != "-") {
                this.GetDetails(trans);
            } else {
                this.setState({ Transaction: trans, ShowLoader: false });
            }
        }

    }
    GetDetails(trans) {
        const _this = this;
        
        var refNo = trans.TransactionSummary.RefNo;
        var officeNo = trans.MainTransaction.OfficeNo;
        Api.getWagerInfo(officeNo, refNo, res => {
            trans.TransactionSummary.WagerInfo.EventFullName = res.Wagers[1].EventName;
            trans.TransactionSummary.WagerInfo.Selection = res.Wagers[1].SelectionName;
            trans.TransactionSummary.SportName = undefined;
            trans.TransactionSummary.WagerInfo.WagerType = undefined;
            trans.TransactionSummary.WagerInfo.EachWay = undefined;
            if (trans.TransactionSummary.WagerInfo.EventFullName.length <= 1) {
                trans.TransactionSummary.WagerInfo.EventFullName = trans.TransactionSummary.WagerInfo.Selection;
            }
            trans.TransactionSummary.WagerInfo.Odds = res.Wagers[1].Odds;
            _this.setState({ Transaction: trans, ShowLoader: false });
        });
    }
    componentDidMount = () => {


        this.LoadFromProps(this.props);
    }

    render() {
        var transaction = this.state.Transaction;
        if (typeof transaction.TransactionSummary == "undefined")
            return "";
        return (
            <div>
                {/* <div className='showDetail'>
                        Reference :{utility.TranHasDetails(transaction)
                            ? <a onClick={(e) => this.props.ShowDetail(transaction)} id={transaction.TransactionSummary.RefNo}>{transaction.TransactionSummary.RefNo}</a>
                            : transaction.TransactionSummary.RefNo}
                    </div> */}

                <div className='showDetail'>
                    Reference: {utility.TranHasDetails(transaction)
                        ? transaction.TransactionSummary.RefNo
                        : transaction.TransactionSummary.RefNo}
                </div>
                <div className="sports_name_outer">

                    <div className="wager-info">


                        <div className="wagerLoder">
                            <Shared.Loader Show={this.state.ShowLoader} />
                        </div>



                        {transaction.TransactionSummary.WagerInfo && transaction.TransactionSummary.WagerInfo.EventFullName
                            ? <h2>{transaction.TransactionSummary.WagerInfo.EventFullName}
                                    <label class={transaction.TransactionSummary.SportName==undefined?"hide":"sports_name"}>

                                    {transaction.TransactionSummary.SportName
                                        ? <div>
                                            Sport Name: {transaction.TransactionSummary.WagerInfo.SportImage
                                                ? <img src={'/images/' + transaction.TransactionSummary.WagerInfo.SportImage} />
                                                : ''
                                            }
                                            {transaction.TransactionSummary.SportName}
                                        </div>
                                        : ""

                                    }
                                </label>
                            </h2>
                            : <h2>NA  <label class="sports_name">

                                {transaction.TransactionSummary.SportName
                                    ? <div>
                                        Sport Name: {transaction.TransactionSummary.WagerInfo.SportImage
                                            ? <img src={'/images/' + transaction.TransactionSummary.WagerInfo.SportImage} />
                                            : ''
                                        }
                                        {transaction.TransactionSummary.SportName}
                                    </div>
                                    : ""

                                }
                            </label>

                            </h2>
                        }
                        {

                        }

                        <div className="trans-odd">
                            {transaction.TransactionSummary.WagerInfo.Odds
                                ? <div>
                                    <b>Odd:
                                </b>
                                    {' '+transaction.TransactionSummary.WagerInfo.Odds}
                                </div>
                                : ""}
                        </div>
                        <div className="trans-three">
                            <div className="trans-three_inner">
                                {transaction.TransactionSummary.WagerInfo.WagerType
                                    ? <div>
                                        <b>Wager Type: 
                                </b>
                                        {' '+transaction.TransactionSummary.WagerInfo.WagerType}
                                    </div>
                                    : ""
                                }
                            </div>

                            <div className="trans-three_inner">
                                {transaction.TransactionSummary.WagerInfo.EachWay
                                    ? <div>
                                        <b>Each Way: 
                                </b>
                                        {' '+transaction.TransactionSummary.WagerInfo.EachWay}
                                    </div>
                                    : ""
                                }
                            </div>


                            <div className="trans-three_inner">
                                {transaction.TransactionSummary.WagerInfo.Selection
                                    ? <div>
                                        <b>Selection: 
                                </b>
                                        {' '+transaction.TransactionSummary.WagerInfo.Selection}
                                    </div>
                                    : ""}
                            </div>

                        </div>


                    </div>



                </div>
            </div>
        );
    }
}