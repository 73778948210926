import {Container} from "unstated";
import * as BetSlip from "../js/bet-slip";
import $ from "jquery";
import * as UtilityProvider from "../js/utility";

import {
    convertOldFormattedBet,
    betTransferStore
} from '../Component/BetSlip/public-api';

const addBetExternal = (bet) => {
    betTransferStore.addBet(convertOldFormattedBet(bet));
};

var _ = require("lodash");

const LocalStorageBetBoxId = "BetSlipBox";

var hasPriceChange = false;
var bogArr = [];

export class BetSlipContainer extends Container {
    betData = null;
    state = {
        Bets: [],
        Wagers: [],
        SlipEmpty: true,
        ShowPlaceBetButton: false,
        BetSlip: [],
        basketItemIdOfEvent: "",
        //CustomInfo:[]
    };

    GetAllBetsFromLocalStorage() {
        var data = localStorage.getItem(LocalStorageBetBoxId);
        if (typeof data == "undefined" || data == null) return [];
        else return JSON.parse(data);
    }

    GetAllBOGBetsFromLocalStorage() {
        var data = localStorage.getItem("LocalStorageBogArr");

        if (typeof data == "undefined" || data == null) {
            bogArr = [];
            return bogArr;
        } else {
            bogArr = JSON.parse(data);
            return bogArr;
        }
    }

    clearAllBetsFromLocalStorage() {
        var data = localStorage.getItem(LocalStorageBetBoxId);
        if (data != undefined) {
            localStorage.removeItem(LocalStorageBetBoxId);
        }
    }

    AddBetInLocalStorage(e, selectedData, d, selection, basketResult) {
        var duplicate = false;
        if (basketResult.Bets.length <= 0) return;

        //GET BASKET ITEM ID
        var lastBet = basketResult.Bets[basketResult.Bets.length - 1];
        var localData = this.GetAllBetsFromLocalStorage();
        var that = this;

        if (localData != undefined) {
            let found = localData.find((v) => {
                return v.basketItemId == lastBet.ItemId;
            });
            if (found != undefined) {
                duplicate = true;
            }
        }
        // setTimeout(()=>{

        //          if(e.basketitemid != undefined ){
        //         let ls = this.GetAllBetsFromLocalStorage();
        //         console.log(ls,'checking');
        //         ls.forEach((v,i )=> {
        //             if(v.basketItemId == e.basketitemid){
        //              console.log(v, e.basketitemid,'checking',i);
        //                 ls.splice(i,1);

        //             } });
        //             localStorage.setItem(LocalStorageBetBoxId,JSON.stringify(ls));
        //             console.log(ls,'after  checking');
        // }
        //     },10000)
        if (!duplicate) {
            if (typeof selectedData.ToolTip != "undefined") {
                if (selectedData.ToolTip.toLowerCase() === "best odds guaranteed") {
                    this.GetAllBOGBetsFromLocalStorage();
                    bogArr.push({
                        ItemId: lastBet.ItemId,
                    });
                    localStorage.setItem("LocalStorageBogArr", JSON.stringify(bogArr));
                }
            }
            var bet = {};
            bet.id = UtilityProvider.GenerateGuid();
            bet.basketItemId = lastBet.ItemId;
            bet.e = e;
            bet.selectedData = selectedData;
            bet.d = d;
            bet.selection = selection;
            bet.hasPriceChange = hasPriceChange;
            var data = this.GetAllBetsFromLocalStorage();

            var basket = this.GetBetFromLocalStorage(this.state.basketItemIdOfEvent);

            if (basket != undefined) {
                var removePreviousEvent = _.remove(data, function (fildata) {
                    return fildata.basketItemId == that.state.basketItemIdOfEvent;
                });
            }

            data.push(bet);
            localStorage.setItem(LocalStorageBetBoxId, JSON.stringify(data));
        }
    }

    GetBetFromLocalStorage(basketItemId) {
        var data = this.GetAllBetsFromLocalStorage();
        var myBet = _.find(data, {
            basketItemId: basketItemId,
        });
        if (typeof myBet != undefined) return myBet;
    }

    UpdateInBetSlip(basketItem) {
        window.ShowLoader = false;
        var that = this;
        var bet = this.GetBetFromLocalStorage(basketItem.basketitemid);
        this.setState({
            basketItemIdOfEvent: basketItem.basketitemid,
        });
        hasPriceChange = true;

        this.RemoveBet(
            bet.basketItemId,
            () => {
                if (basketItem.selection == "0") {
                    bet.selectedData.homeodds = basketItem.EventSelections.homeodds;
                } else if (bet.selection == "1") {
                    bet.selectedData.drawodds = basketItem.EventSelections.drawodds;
                } else if (bet.selection == "2") {
                    bet.selectedData.awayodds = basketItem.EventSelections.awayodds;
                }
                // bet.e.basketitemid = basketItem.basketitemid
                that.AddBet(bet.e, bet.selectedData, bet.d, bet.selection);
                window.ShowLoader = true;
            },
            () => {
                //some error
            }
        );
    }

    // AddBet_LiveEvents=(e, selectedData_old, d_old, selection)=> {

    //     var selectedData = {
    //         'AvailableFullCardOptions': selectedData_old.available_full_card_options,
    //         'FullCard': selectedData_old.full_card,
    //         'FullCardTypes': selectedData_old.full_card_types,
    //         'awayTeam': selectedData_old.away_team,
    //         'away_gprice': selectedData_old.away_gprice,
    //         'awayodds': selectedData_old.away_odds,
    //         'awaypricecode': selectedData_old.away_price_code,
    //         'draw_gprice': selectedData_old.draw_gprice,
    //         'drawodds': selectedData_old.draw_odds,
    //         'drawpricecode': selectedData_old.away_price_code,
    //         'eventGroup': selectedData_old.event_group,
    //         'eventId1': selectedData_old.event_id1,
    //         'eventId2': selectedData_old.event_id2,
    //         'eventId3': selectedData_old.event_id3,
    //         'homeTeam': selectedData_old.home_team,
    //         'home_gprice': selectedData_old.home_gprice,
    //         'homeodds': selectedData_old.home_odds,
    //         'homepricecode': selectedData_old.home_price_code,
    //         'kickoff': selectedData_old.start_time,
    //         'pricecode': selectedData_old.price_code,
    //         'secondPlayer': selectedData_old.second_player
    //       }

    //       var d = {
    //         'title': selectedData_old.title,
    //         'Code': selectedData_old.dcode,
    //         'eventId': selectedData_old.deventid
    //       };

    //     this.AddBet(e, selectedData, d, selection);
    //   }

    AddBet_LiveEvents = (e, selectedData_extra, selectedData_old, selection) => {
        var selectedData = {
            AvailableFullCardOptions: selectedData_old.available_full_card_options,
            FullCard: selectedData_old.full_card,
            FullCardTypes: selectedData_old.full_card_types,
            awayTeam: selectedData_extra.away_team,
            away_gprice: selectedData_old.gprice,
            awayodds: selectedData_old.price,
            awaypricecode: selectedData_old.gpricecode,
            draw_gprice: selectedData_old.gprice,
            drawodds: selectedData_old.price,
            drawpricecode: selectedData_old.gpricecode,
            eventGroup: selectedData_old.EventGroup,
            eventId1: selectedData_old.event_id1,
            eventId2: selectedData_old.event_id2,
            eventId3: selectedData_old.event_id3,
            homeTeam: selectedData_extra.home_team,
            home_gprice: selectedData_old.gprice,
            homeodds: selectedData_old.price,
            homepricecode: selectedData_old.gpricecode,
            kickoff: selectedData_old.start_time,
            pricecode: selectedData_old.gpricecode,
            secondPlayer: selectedData_old.second_player,
        };

        var d = {
            title: selectedData_extra.title,
            //'Code': '6brUech',
            Code: selectedData_old.eventfile,
            eventId: selectedData_old.Events,
        };

        this.AddBet(e, selectedData, d, selection);
    };

    AddBet = (e, selectedData, d, selection) => {
        //e.persist()
        // SAMPLE DATA "eventfile": "qbqEprem", "EventGroup": " 1", "Events": " 0",
        // "selection": " 2", "pricecode": " C", "price": " 5-4", "gpricecode": " C",
        // "gprice": " 0"
        const eventIdx = 0;
        const that = this;
        // if(selection>3){     console.log('antepost if')     d.Code = d.eventfile
        // price=selectedData.price;       priceCode = selectedData.pricecode gPrice =
        // '0';         selectedData.eventGroup = selectedData.EventGroup   d.eventId =
        // d.Events; }

        let price = selectedData.homeodds || selectedData.firstodds;
        let priceCode = selectedData.homepricecode;
        let gPrice = selectedData.home_gprice;

        if (selection === 1) {
            price = selectedData.drawodds || selectedData.secondodds;
            priceCode = selectedData.drawpricecode;
            gPrice = selectedData.draw_gprice;
        }
        if (selection === 2) {
            price = selectedData.awayodds || selectedData.thirdodds;
            priceCode = selectedData.awaypricecode;
            gPrice = selectedData.away_gprice;
        }
        let eventId = d.EventId ? d.EventId : d.Id;
        if (eventId == null || typeof eventId == "undefined") eventId = d.eventId;
        if (eventId == null || typeof eventId == "undefined")
            eventId = selectedData.Events;

        let data = {};
        if (selectedData.SelName || selectedData.ALT) {
            if (typeof d.eventfile != "undefined") {
                eventId = selectedData.Events;
                gPrice = selectedData.gprice;
                gPrice = gPrice.substring(0, 1);
                // d.eventfile = d.EventFile } else if ((typeof selectedData.eventfile !=
                // "undefined")) {
                data = {
                    eventfile: selectedData.eventfile,
                    EventGroup: selectedData.EventGroup,
                    Events: eventId,
                    selection: selection,
                    pricecode: selectedData.pricecode,
                    price: selectedData.price,
                    gpricecode: selectedData.gpricecode,
                    gprice: gPrice,
                    coi: selectedData.coi,
                };
            } else {
                gPrice = selectedData.gprice;
                gPrice = gPrice.substring(0, 1);
                data = {
                    eventfile: d.EventFile,
                    EventGroup: d.EventGroup,
                    Events: d.Events,
                    selection: selection,
                    pricecode: d.pricecode,
                    price: d.price,
                    gpricecode: d.gpricecode,
                    gprice: gPrice,
                };
            }
        } else {
            if (eventId == null || undefined) {
                eventId = "0";
            }
            if (gPrice == null || undefined) {
                gPrice = "0";
            }
            data = {
                eventfile: d.Code,
                EventGroup: selectedData.eventGroup,
                Events: "0",
                selection: selection,
                pricecode: priceCode,
                price: price,
                gpricecode: priceCode,
                gprice: gPrice,
            };
            // if(selectedData.firstPlayer){   console.log('firstPlayer')   if(selection
            // ==0){price = selectedData.firstodds}   if(selection ==1){price =
            // selectedData.secondodds}   if(selection ==2){price = selectedData.thirdodds}
            // data = {     eventfile: d.Code,     EventGroup: selectedData.eventGroup,
            // Events: '0',     selection: selection,     pricecode: priceCode,     price:
            // price,     gpricecode: priceCode,     gprice: '0'   }; }
        }

        //FIX EVENS
        if (data.price === "EVENS") data.price = "Evens"; // converts to 1-1 on the server side

        addBetExternal(data);

        // @FIXME: we don';t need to call the old api for 16 april 2021

        // const settings = {
        //     async: true,
        //     crossDomain: true,
        //     url:
        //         `https://v0.geoff-banks.com/events/bettingslip-api.asp?basketId=${BetSlip.GetBaseketId()}&coi=000`,
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //     },
        //     data: data,
        // };

        // UtilityProvider.ShowAddBetLoader();

        // $.ajax(settings).done(function (result) {
        //     UtilityProvider.shakeBetSlip();

        //     // that.slipService.betSlipCount.next(result.Bets.length);
        //     if (
        //         result.Message == null ||
        //         result.Message == "" ||
        //         result.Message.indexOf("Price has") >= 0
        //     ) {
        //         var count = result.Bets.length;

        //         BetSlip.SaveBetsCount(count);
        //         BetSlip.SetBetSlipExpiry(count);

        //         that.AddBetInLocalStorage(e, selectedData, d, selection, result);

        //         that.UpdateBetSlip(result);
        //         // UtilityProvider.ShowNotification("Added to BetSlip..");

        //         if (localStorage.getItem("authorization")) {
        //         } else {
        //         }
        //     }
        //     if (result.Message != null && result.Message.length > 0) {
        //         // let alert = that   .alertCtrl   .create({     title: "Message",     subTitle:
        //         // result.Message,     buttons: ["Okay"]   }); alert.present();
        //         alert(result.Message);
        //     }
        // });
        // var count = this.result.Bets.length; SetUpdateSlip();
    };
    UserLoggedIn = () => {
        this.setState({
            ShowPlaceBetButton: true,
        });
    };

    // CustomInfo = () =>{
    //     this.setState({
    //         CustomInfo: UtilityProvider.GetCustomerInfo()
    //     });
    // }

    RemoveBet = (id, callback, err) => {
        UtilityProvider.ShowAddBetLoader();
        var that = this;
        // REMOVE THE BET FROM SERVER AND GET THE BASKET AGAIN return this   .http
        // .post(base_url + "RemoveItemFromBasket/" + GetBaseketId() + "/" + id, "");
        fetch(
            BetSlip.BaseUrl +
            "RemoveItemFromBasket/" +
            BetSlip.GetBaseketId() +
            "/" +
            id,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: "",
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                that.UpdateBetSlip(null, true);
                callback(response);
            })
            .catch(function (error) {
            });

        // return $.post(base_url + "RemoveItemFromBasket/" + GetBaseketId() + "/" + id,
        // "");
    };

    RemoveAll = (callback) => {
        localStorage.setItem("LocalStorageBogArr", "[]");
        var that = this;
        // CALL THE SERVICE TO REMOVE ALL BETS FROM BASKET return
        // this.http.post(this.base_url + "RemoveAllItems/" + this.GetBaseketId(), "");
        fetch(BetSlip.BaseUrl + "RemoveAllItems/" + BetSlip.GetBaseketId(), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: "",
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                that.UpdateBetSlip();
                that.clearAllBetsFromLocalStorage();
                //return response;
                callback(response);
            })
            .catch(function (error) {
            });
    };

    SetEmpty = () => {
        this.setState({
            BetSlip: [],
            SlipEmpty: true,
        });
    };

    AddSpBet = (d) => {
        var ef;
        if (d.eventfile) {
            ef = d.eventfile;
        } else {
            ef = d.EventFile;
        }
        var that = this;
        var data;
        data = {
            eventfile: ef,
            EventGroup: d.EventGroup,
            Events: d.Events,
            selection: d.selection,
            coi: d.coi,
            pricecode: "S",
            price: d.price,
        };

        addBetExternal(data);

        // @FIXME: we don't need the old api enymore

        // var settings = {
        //     async: true,
        //     crossDomain: true,
        //     url:
        //         "https://v0.geoff-banks.com/events/bettingslip-api.asp?basketId=" +
        //         BetSlip.GetBaseketId() +
        //         "&coi=000",
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //     },
        //     data: data,
        // };

        // UtilityProvider.ShowAddBetLoader();

        // $.ajax(settings).done(function (result) {
        //     UtilityProvider.shakeBetSlip();
        //     // that.slipService.betSlipCount.next(result.Bets.length);
        //     if (
        //         result.Message == null ||
        //         result.Message == "" ||
        //         result.Message.indexOf("Price has") >= 0
        //     ) {
        //         var count = result.Bets.length;

        //         // $(".mob-notify-bet").text(count);
        //         BetSlip.SaveBetsCount(count);
        //         BetSlip.SetBetSlipExpiry(count);

        //         if (localStorage.getItem("authorization")) {
        //         } else {
        //         }
        //     }
        //     if (result.Message != null && result.Message.length > 0) {
        //         // let alert = that.alertCtrl.create({     title: "Message",     subTitle:
        //         // result.Message,     buttons: ["Okay"]   }); alert.present();
        //     }
        //     that.UpdateBetSlip(result, false);
        // });
    };

    UpdateBetSlip = (data, shouldWait) => {
        var that = this;
        var loggedIn = UtilityProvider.IsCustomerLoggedIn();
        var timeout = 0;
        if (shouldWait) timeout = 3000;
        if (typeof data == "undefined" || data == null) {
            setTimeout(() => {
                BetSlip.GetBetSlipData((data) => {
                    //console.log("UpdateBetSlip from API", data);
                    if (typeof data != "undefined") {
                        var bets = [];
                        var wagers = [];
                        if (data.Bets) bets = data.Bets;
                        if (data.Wagers) wagers = data.Wagers;
                        var slipEmpty = bets.length <= 0;
                        that.setState({
                            SlipData: data,
                            BetSlip: bets,
                            SlipEmpty: slipEmpty,
                            Wagers: wagers,
                            ShowPlaceBetButton: loggedIn,
                        });
                    }
                });
                this.BetSlipLoaded();
            }, timeout);
        } else {
            if (typeof data != "undefined" && data.Bets) {
                that.setState({
                    SlipData: data,
                    BetSlip: data.Bets,
                    SlipEmpty: false,
                    Wagers: data.Wagers,
                    ShowPlaceBetButton: loggedIn,
                });
            }
            this.BetSlipLoaded();
        }
    };

    BetSlipLoaded = () => {
        UtilityProvider.HideAddBetLoader();
        setTimeout(() => {
            //console.log("Focus to first textbox set");
            var elem = document.getElementsByClassName("wager-input")[0];
            //console.log(elem);
            if (typeof elem != "undefined") {
                elem.focus();
                elem.click();
            }
        }, 1000);
    };
}
