import React from "react";
import subscribe from "unstated-subscribe-hoc";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import MainLive from "../Component/mainLive";
import VideoStream from "../Component/VideoStream";
import * as Utility from "../js/utility";
import * as Api from "../js/api-service";
import { FavouriteContainer } from "../Containers/FavouriteContainer";

import _ from "lodash";

var EventName = "";

class Races extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      sportsEvent: [],
      headerTitle: "",
      liveRacesEvent: [],
      races: [],
      liveEvents: [],
      events: [],
      hasLiveRaces: false,
      hasLiveEvents: false,
      timeLeftFormatted: "",
      timeLeft: "",
      antePostEvents: [],
      sportCode: "",
    };
  }

  componentDidMount() {
    
    const _this = this;
    const params = new URLSearchParams(_this.props.location.search);
    const sportcode = params.get("sportcode");

    if (params.get("eventName") != null) {
      EventName = params.get("eventName");
    }
    this.setState({ sportCode: sportcode });

    // Utility.IsFavorite((val, data) => {
    //   this.setState({ IsFav: val })
    // })

    var myInfo = Api.GetSportInfo(sportcode);
    _this.setState({ headerTitle: myInfo.Name });

    Api.GetEvents(
      sportcode,
      (events) => {
        if (typeof events != "undefined") {
          console.log("events-->>>>>>>>", events);
          //events = Utility.FilterEvents(events);
          events = Utility.SortEvents(events);
          _this.setState({ sportsEvent: events });
        }
      },
      (err) => {
        console.log("error", err);
      }
    );

    Api.GetAntepostEvents(myInfo.AntepostSportCode, (antePosts) => {
      console.log("Antepost data : ", antePosts);
      var antePostDetail = [];
      if (typeof antePosts != "undefined") {
        antePostDetail = antePosts;
      }
      console.log(antePostDetail, "antepostdetail");
      _this.setState({ antePostEvents: antePostDetail });
    });
  }

  render() {
    var content = (
      <div>
        <div className="homepage">
          <div className="container">
            <div className="content-section-inner">
              <LeftContainer />
              <div className="center-container">
                <div className="horse-racing">
                  <div className="horse-banner">
                    {this.state.headerTitle}

                    <i
                      className="fa fa-star fav_star"
                      className={
                        this.props.FavouriteStore.state.IsFav
                          ? "fa fa-star fav_star"
                          : "fa fa-star fav_star not_fav_star"
                      }
                      aria-hidden="true"
                      onClick={() =>
                        this.props.FavouriteStore.AddSportToFavorite(
                          EventName != null && EventName != ""
                            ? EventName
                            : this.state.headerTitle,
                          () => {}
                        )
                      }
                    />
                  </div>
                  {/* PASSING SPORTCODE HERE FOR SPECIFIC STREAMS EX-> HORSE/GREYHOUND */}
                  <VideoStream eventName="SD365" sportcode={this.state.sportCode} IsLogin={Utility.IsCustomerLoggedIn()}/> 
                  {/* <LiveRaces />  */}
                  <MainLive sportcode={this.state.sportCode}/>
                  {this.state.sportsEvent.map(function (event, i) {
                    return (
                      <div className="uk-races" key={i}>
                        <div className="uk-races-heading">
                          <div className="col-md-6">
                            <h3>
                              <i className="fa fa-angle-down" />
                              {event.EventName}
                            </h3>
                          </div>

                          <div className="col-md-2 text-right">
                            <label>
                              <em className="mr-5">P</em>
                              Priced
                            </label>
                          </div>

                          <div className="col-md-2 text-right">
                            <label>
                              <em className="race-off mr-5">0</em>
                              Race off
                            </label>
                          </div>

                          <div className="col-md-2 text-right">
                            <label>
                              <img
                                src="images/Play_For_Work_Icon_1.png"
                                className="mr-5"
                              />
                              Result
                            </label>
                          </div>
                        </div>

                        <div className="list-view">
                          <div className="col-md-3" />
                          <div className="col-md-3 text-center active">
                            <h3>TODAY</h3>
                          </div>

                          <div className="col-md-3">
                            <h3>TOMORROW</h3>
                          </div>

                          <div className="col-md-3 text-right">
                            {/* <h3>
                                <a href="#">LIST VIEW</a>
                              </h3> */}
                          </div>
                        </div>

                        {event.Meetings.map(function (meeting, j) {
                          return (
                            <div className="list-view-inner" key={j}>
                              <div className="col-md-3 text-left">
                                <label>{meeting.MeetinName}</label>
                                {/* <div><a href={'/show-events?'+meeting.BetLink}>more>></a></div> */}
                              </div>
                              <div className="col-md-9">
                                {meeting.Events
                                  ? meeting.Events.map(function (
                                      meetingEvent,
                                      k
                                    ) {
                                      return (
                                        <div
                                          className="col-md-3 text-center"
                                          key={k}
                                        >
                                          {meetingEvent.Status &&
                                          meetingEvent.Status == "Result" ? (
                                            <a
                                              href={
                                                "/single-event-result?sportcode=" +
                                                this.state.sportCode +
                                                "&" + 
                                                meetingEvent.EventLink
                                              }
                                            >
                                              <span className="first-child">
                                                <img
                                                  src="images/Play_For_Work_Icon_1.png"
                                                  className="mr-5"
                                                />{" "}
                                                {meetingEvent.Time}
                                              </span>
                                            </a>
                                          ) : (
                                            <a
                                              href={
                                                "/single-event?sportcode=" +
                                                this.state.sportCode +
                                                "&" +
                                                meetingEvent.EventLink+"&EventName="+event.EventName
                                              }
                                            >
                                              <span className="first-child">
                                                <em className="priced mr-5">
                                                  P
                                                </em>
                                                {meetingEvent.Time}
                                              </span>
                                            </a>
                                          )}

                                          {/* <a href={'/single-event?' + meetingEvent.EventLink}>
                                          <span className="first-child">
                                            {meetingEvent.Status && meetingEvent.Status == 'Result' ?
                                              <img src="images/Play_For_Work_Icon_1.png" className="mr-5" /> :
                                              <em className="priced mr-5" >P</em>
                                            }
                                            {meetingEvent.Time}
                                          </span>
                                        </a> */}
                                        </div>
                                      );
                                    },
                                    this)
                                  : ""}
                              </div>
                            </div>
                          );
                        }, this)}
                      </div>
                    );
                  }, this)}
                </div>

                <div className="int-highlights antepost-matches">
                  <div className="match_result">
                    <div className="accordion-option">
                      <div className="col-md-6 accordion-header">
                        <h4>
                          <i className="fa fa-angle-down" />
                          Future Racing & Specials
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <a
                          href="javascript:void(0)"
                          className="toggle-accordion active"
                          accordion-id="#accordion"
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.state.antePostEvents.map(function (event, i) {
                        return (
                          <div className="panel panel-default" key={i}>
                            <div
                              className="panel-heading"
                              role="tab"
                              id="headingOne"
                            >
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href={"#collapse" + i}
                                  aria-expanded="true"
                                  aria-controls={"collapse" + i}
                                >
                                  {event.EventName}
                                </a>
                              </h4>
                            </div>
                            <div
                              id={"collapse" + i}
                              className="panel-collapse collapse in"
                              role="tabpanel"
                              aria-labelledby="headingOne"
                            >
                              <div className="panel-body">
                                {event.Meetings.map(function (meeting, j) {
                                  return (
                                    <div className="antepost-main" key={j}>
                                      <a
                                        className="antepost-link"
                                        href={meeting.MeetingLink==undefined?
                                          `/antePostEvents?${meeting.BetLink}&Events=0`:`/antePostEvents?${meeting.MeetingLink}&Events=0`
                                        }
                                      >
                                        {meeting.MeetinName != undefined ? (
                                          <div className="antepost-name">
                                            {meeting.MeetinName}
                                          </div>
                                        ) : (
                                          <div className="antepost-name">
                                            {meeting.MeetingName}
                                          </div>
                                        )}
                                      </a>
                                    </div>
                                  );
                                }, this)}
                              </div>
                            </div>
                          </div>
                        );
                      }, this)}
                    </div>
                  </div>
                </div>
              </div>

              <RightContainer />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default subscribe(Races, { FavouriteStore: FavouriteContainer });
