import React from 'react';
import { getOddFormatted } from '../../../_helpers';
import "./style.css";

export const BetSlipSubmitResponse = (props) => {
    const { betslip, oddsFormat } = props;

    if (!betslip) {
        return null;
    }

    const getFormattedOdd = (odd, format) => {
        return getOddFormatted(odd, format);
    };

    const totalPayment = (betslip.wagersSubmitResult || [])
        .reduce((sum, next) => (sum + next.stakePlaced), 0);

    return (
        <div>
            { betslip.bets && betslip.bets.length ? (
                <div className="placed-bets">
                    <p>You have succesfully placed { betslip.bets.length } bet(s)</p>
                    <ul className="placed-bet-list">
                        { betslip.bets.map((bet, betIndex) => (
                            <li className="placed-bet-item" key={betIndex}>
                                <span>{ bet.name }</span>
                                &#160;
                                <span>@</span>
                                &#160;
                                <span>{ bet.detail }</span>
                                &#160;
                                <span>{ getFormattedOdd(bet.price, oddsFormat) }</span>
                                &#160;
                                <span>{ bet.newPC }</span>
                            </li>
                        )) }
                    </ul>
                </div>
            ) : null }

            { betslip.wagersSubmitResult && betslip.wagersSubmitResult.length > 0 ? (
                <div className="placed-receipt">
                    <h4 className="placed-receipt-header">Bet Receipt</h4>
                    <ul className="placed-receipt-list">
                        { betslip.wagersSubmitResult.map((wager, wagerIndex) => (
                            wager.isSubmitted && (
                                    <li className="placed-receipt-item" key={wagerIndex}>
                                        <div className="placed-receipt-info-container">
                                            <div className="placed-bet-name">
                                                <p>{ wager.name }</p>
                                                <p>{ wager.eachWay ? 'Each Way' : 'Win only' }</p>            
                                            </div>
                                            <div className="bet-ticket-number">
                                                Bet Placed: Ticket Number { wager.ticketNumber }
                                            </div>
                                            <div className="bet-potential-return">
                                                Potential Return: £{ wager.potentialReturn }
                                            </div>
                                        </div>
                                        <div className="placed-receipt-cost">
                                            £{ wager.stakePlaced }
                                        </div>
                                    </li>
                                )
                        )) }
                    </ul>
                </div>
            ) : null }
            
            <div className="placed-total-payment">
                <p>Total Payment</p>
                <p>£{ totalPayment }</p>
            </div>
            <div className="placed-total-payment">
                <p>Current Balance</p>
                <p>£{ betslip.customerBalance || 0 }</p>
            </div>
        </div>
    );
}