import React from 'react';
import './style.css';

export const EmptyBetList = () => (
    <div className="empty-bet-list">
        <p className="empty-bet-message">
            No bet has been selected.
            To select a bet, please click on the respective result.
        </p>
    </div>
)