import React from 'react'
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountSidebar from '../../Component/AccountSidebar';
import * as Utility from "../../js/utility";
class Deposit extends React.Component {
    componentDidMount() {


        // var fund = JSON.parse(localStorage.getItem('authorization'));
        // if (fund != undefined) {
        // } else {
        //     this
        //         .props
        //         .history
        //         .push('/');
        // }
    }
    render() {
        var content = (
            <div>
                <div className="my-account">

                    <div className="container">

                        <AccountSidebar />
                        <div className="account-right-container">

                            <div className="transaction">

                                <div className="acount-inner">
                                    <h3>Deposit</h3>
                                </div>

                                <div className="depositIframe-container">
                                    <iframe height="1245"
                                            allowtransparency="true"
                                            frameBorder="0"
                                            scrolling="vertical"
                                            className="depositIframe"
                                            src="https://v0.geoff-banks.com/Payments/Deposit/Index/?customerEmail=bets@geoff-banks.com" >
                                        <a href="https://v0.geoff-banks.com/Payments/Deposit/Index/?customerEmail=bets@geoff-banks.com">

                                        </a>
                                    </iframe>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}

export default Deposit;