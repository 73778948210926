import { Container } from "unstated";
import $ from "jquery";
import * as Utility from "../js/utility";
var odds = require("odds-converter");

var _ = require("lodash");

export class PreferenceContainer extends Container {
  state = {
    Preferences: {},
    OddFormat: "fractional",
  };

  //user_preferences

  UpdateOddFormat() {
    var that = this;
    if (Utility.IsCustomerLoggedIn()) {
      Utility.GetPreferences(Utility.GetCustomerInfo().Cust_Ref, (Pref) => {
        if (Pref.length > 0) {
          that.setState({
            Preferences: Pref[0],
            OddFormat: Pref[0].odds_format,
          });
        }
      });
    } else {
      that.setState({
        OddFormat: Utility.GetOddFormat(),
      });
    }
  }

  Preferences = () => {
    var that = this;
    Utility.GetPreferences(Utility.GetCustomerInfo().Cust_Ref, (Pref) => {
      var Preferences = {};
      if (Pref.length > 0) {
        Preferences = Pref[0];
      }
      that.setState({
        Preferences: Preferences,
        OddFormat: Pref[0].odds_format,
      });
    });
  };

  ConvertPrice(price) {
    var OddFormatValue = this.state.OddFormat;
    //console.log("OddFormatValue:", OddFormatValue);
    if (OddFormatValue == "decimal") {
      //console.log("decccccc", OddFormatValue);
      if (price == null) {
        return price;
      }
      var a = price.split("-");
      if (a[1] != undefined) {
        //var oddval = a[0] +',' +a[1];
        var decimalPrice = odds.fraction.toDecimal(a[0], a[1]);
        return decimalPrice.toFixed(2);
      } else {
        return price;
      }
    } else {
      //console.log("fraccccc:", OddFormatValue);
      return price;
    }
  }
  decimalPrice(price) {
    //console.log("decccccc", OddFormatValue);
    if (price == null) {
      return price;
    }
    var a = price.split("-");
    if (a[1] != undefined) {
      //var oddval = a[0] +',' +a[1];
      var decimalPrice = odds.fraction.toDecimal(a[0], a[1]);
      return decimalPrice.toFixed(2);
    } else {
      return price;
    }
  }

  SetOddFormat = (e, callback) => {
    var that = this;
    var oddFormatValue = e.target.value;

    if (Utility.IsCustomerLoggedIn()) {
      Utility.GetPreferences(Utility.GetCustomerInfo().Cust_Ref, (Pref) => {
        if (Pref.length > 0) {
          let Url =
            "https://be.geoff-banks.com/_/items/user_preferences/" +
            Pref[0].id +
            "?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
          fetch(Url, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              odds_format: oddFormatValue,
              //"status": "published",
            }),
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (res) {
              that.UpdateOddFormat();
              callback();
            })
            .catch(function (err) {
              that.UpdateOddFormat();
              callback();
            });
        } else {
          $.post(
            "https://be.geoff-banks.com/_/items/user_preferences?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
            {
              userid: Utility.GetCustomerInfo().Cust_Ref,
              odds_format: oddFormatValue,
              //"status": "published",
            },
            (res) => {
              that.UpdateOddFormat();
              callback();
            }
          );
        }
      });
    } else {
      localStorage.setItem("OddFormat", oddFormatValue);
      that.setState({
        OddFormat: oddFormatValue,
      });
    }
    // Utility.GetPreferences(Utility.GetCustomerInfo().Cust_Ref, Pref => {
    //     if(Pref.length>0){
    //         that.setState({Preferences:Pref,OddFormat:Pref[0].odds_format});
    //     }
    // })
  };
}
