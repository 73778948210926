import React from "react";
import "../css/main.css";
import EventList from "../Component/EventList";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
import InPlayEvents from "../Component/InPlayEvents";
import _ from "lodash";
import * as Shared from "./Shared/Index";

var updatePrice;
class ShowEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      Events: [],
      headerTitle: "",
      showLess: false,
      FullCardOptions: [],
      SportCode: "",
      ShowLoader: true,
      isFirstLoad: "1",
      isComplete: false,
      IsLiveReloadForInplay: ""
    };
  }
  componentWillUpdate(nextProps, nextState) {
    // if (nextState.InPlayEvents !== this.state.InPlayEvents) {
    //     var _this = this;
    //     setTimeout(function () {
    //         _this.setState({
    //             InPlayEvents: nextState.InPlayEvents
    //         });
    //     }, 500);
    // }
  }

  componentWillUnmount() {
    console.log("will mount called");
    clearInterval(updatePrice);  
  }

  componentDidMount() {
    //Utility.UpdateIsEnableInPlay();
    let _this = this;
    this.loadData(true);

    updatePrice = setInterval(() => {
      //Get refreshOddsEnable value from localstorage 
        if (Utility.isRefreshOddsEnable()) {
          _this.loadData();
        } else {
         clearInterval(updatePrice);
       }
    }, 5 * 1000);

  }

  loadData = (loadOnce) => {
    if(loadOnce){
      Api.getLiveEvents(responseresult => {
        this.setState({
          InPlayEventsToggle: responseresult
        });
      });
    }

    const params = new URLSearchParams(window.location.search);
    var sportcode = params.get("sportcode");
    if (window.location.pathname == "/") {
      sportcode = "f";
      this.setState({ showLess: true });
    }    
    this.sportsData(sportcode);
  };
  checkIsLiveMatch(event) {
    _.find(this.state.InPlayEventsToggle, function(o) {
      event["isLive"] = false;

      if (o.event_id1 === event.eventId1) {
        event["isLive"] = true;
        event["id"] = o.id;
        var toggle_old = "toggle-" + event.eventId1;
        var livetoggle = "#" + toggle_old;
        return true;
      }
    });
  }

  // loadUpperSlider = () => {
  //     Api.getLiveEvents(responseresult => {
  //         this.setState({
  //             InPlayEventsToggle: responseresult
  //             //, isComplete: false
  //         });
  //         console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn",responseresult);
  //         var i = 0;
  //         var arr = [];
  //         var pick = [];
  //         let requests = responseresult.map((d1) => {
  //             return new Promise((resolve) => {
  //                 Api.GetSportsEvents(d1.dcode, d1.event_group, result => {
  //                     console.log(d1.dcode, d1.event_group, "d111111111111111");
  //                     // if(result[0].Events.length<3 || result[1].Events.length<3 || result[2].Events.length<3)
  //                     // {
  //                     //     return;
  //                     // }
  //                     // if (result[0].ElRespose == "Full Time Result") {
  //                     if (result.length > 0) {
  //                         pick.push(result);
  //                     }
  //                     if (typeof pick[i] != "undefined" && pick.length > 0) {
  //                         pick[i]['title'] = d1.title;
  //                         pick[i]['id'] = d1.id;
  //                         pick[i]['home_team'] = d1.home_team;
  //                         pick[i]['away_team'] = d1.away_team;
  //                         pick[i]['eventId1'] = d1.event_id1;
  //                         pick[i]['eventId2'] = d1.event_id2;
  //                         pick[i]['eventId3'] = d1.event_id3;
  //                         pick[i]['first_player'] = d1.first_player;
  //                         pick[i]['second_player'] = d1.second_player;
  //                         pick[i]['third_player'] = d1.third_player;
  //                         arr.push(pick[i]);
  //                         i = i + 1;
  //                     }
  //                     // }
  //                     resolve()
  //                 },
  //                     error => { console.log(error, 'err') },
  //                 )
  //             });
  //         })
  //         Promise.all(requests).then(() => (
  //             this.setState({ InPlayEvents: arr, isComplete: true, InPlayEventsToggle: responseresult }),
  //             console.log('final--outtttt1', arr)));
  //     });
  // }

  sportsData = sportcode => {
    //console.log(sportcode, "sportcodesportcodesportcodesportcode");
    const _this = this;
    var sportInfo = Api.GetSportInfo(sportcode);
    _this.setState({
      headerTitle: sportInfo.Name,
      FullCardOptions: sportInfo.FullCardOptions,
      SportCode: sportInfo.SportCode,
      ShowLoader: _this.state.isFirstLoad == "1" ? true : false
    });

    Api.GetEvents(
      sportcode,
      events => {
        //var arr=[];
        var sliderData = [];
        events = Utility.FilterEvents(events);
        var eventDate = events;
        eventDate.map((data, i) => {
          data.Competitions.map((data1, i) => {
            data1.details.map((data2, i) => {
              if (data2.firstPlayer != undefined) {
                data2.homeTeam = data2.firstPlayer;
                data2.awayTeam = data2.secondPlayer;
                data2.homeodds = data2.firstodds;
                data2.drawodds = data2.secondodds;
                data2.awayodds = data2.thirdodds;
                data2.kickoff = data2.time;
              }
              _this.checkIsLiveMatch(data2);
            });
          });
        });
        sliderData = eventDate;
        if (_this.state.showLess) {
          eventDate = eventDate.slice(0, 3);
        }

        _this.setState({
          Events: eventDate,
          ShowLoader: false,
          isFirstLoad: "0"
        });
      },
      err => {
      }
    );
  };
  loaderset = () => {
    // this.setState({ ShowLoader: true,isFirstLoad:'1' });
    //alert('sdsdsd');
  };
  render() {
    var content = (
      <div className="Events">
        {Utility.IsEnablePlayEvents() == "true" ? (
          <InPlayEvents clk={this.loadData} loaders={this.loaderset} />
        ) : (
          ""
        )}
        <Shared.Loader Show={this.state.ShowLoader} />
        {this.state.Events.map(function(event, i) {
          return (
            <EventList
              Event={event}
              clk={this.loadData}
              InPlayEventsData={this.state.InPlayEvents}
              FullCardOptions={this.state.FullCardOptions}
              SportCode={this.state.SportCode}
              key={i}
            />
          );
        }, this)}
      </div>
    );
    return <div>{content}</div>;
  }
}

export default ShowEvent;
