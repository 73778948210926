import React from "react";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";

class AffiliateV2 extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div className="homepage affiliate-page">
            <div className="container">
              <div className="content-section-inner">
                <LeftContainer />
                <div className="right-content">
                  <iframe
                    src="https://geoffbanks.formstack.com/forms/gbreg_affiliate"
                    title="GBReg - AFFILIATE"
                    width="100%"
                    height="3000px"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default AffiliateV2;
