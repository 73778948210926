import Axios from "axios";

// Example custom build usage:
var engine = require("store/src/store-engine");
var storages = [
  require("store/storages/localStorage"),
  require("store/storages/cookieStorage"),
];
var plugins = [
  require("store/plugins/defaults"),
  require("store/plugins/expire"),
];

var cache = engine.createStore(storages, plugins);

export function ttl() {
  return new Date().getTime() +15000; //15 seconds cache by default
}

export function Get(key) {
  return cache.get(key);
}

export function SetAdv(key, value, ttl) {
  return cache.set(key, value, ttl);
}

export function SetMins(key, value, minutes) {
  return cache.set(key, value, new Date().getTime() + (minutes * 60 * 1000));
}

export function Set(key, value) {
  return SetAdv(key, value, ttl());
}

export function Del(key) {
  return cache.remove(key);
}

export function Exists(key) {
  let val = Get(key);
  return typeof val !== "undefined";
}

// export function GetFromApi(url, cb) {
//   return GetFromApiAdv(url, url); //  USING URL AS KEY HERE
// }

// export  function GetFromApiAdv(key, url) {
//   let res =  Get(key);
//   // utility.log.info("res===cache==",res,key, typeof res);
//   if (typeof res !== "undefined") return res;

//   res =  Axios.get(url);
//   if (typeof res !== "undefined") {
//     Set(key, res);
//     return Get(key);
//   }
// }

// export  function GetFromApiDelayed(url, delayInSeconds) {
//   setTimeout( function () {
//     return  GetFromApi(url);
//   }, delayInSeconds * 1000);
// }

export function ClearCache() {
  cache.clearAll();
}
