import React from "react";
//import {Link} from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LeftContainer from "../Component/LeftContainer";
import RightContainer from "../Component/RightContainer";
import RacesList from "../Component/RacesList";
import * as Api from "../js/api-service";
import * as Utility from "../js/utility";
import * as $ from "jquery";
import _ from "lodash";
import subscribe from "unstated-subscribe-hoc";
import { BetSlipContainer } from "../Containers/BetSlipContainer";
import { PreferenceContainer } from "../Containers/PreferenceContainer";
import { FavouriteContainer } from "../Containers/FavouriteContainer";
import VideoStream from "../Component/VideoStream";
import CustomNotifications from "../Component/CustomNotifications";
import Tooltip from "../Component/Shared/Tooltip";
import VirtualVideoStream from "../Component/VirtualVideoStream";

let bogArrBanner = [];
var autoReload;
var updatePrice;

class SingleEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Event: { Events: [] },
      sportsEvent: [],
      sportCode: "",
      bogBanner: false,
      singleStream: "",
      allStreams: [],
      streamSrc: "",
      showIframe: true,
      streamLoc: "",
      streamDesc: "",
      streamVenue: "",
      eventNumber: "",
      locationName: "",
      eventTime: "",
      eventName: "",
      eventFile:"",
      showLoader:false
    };
  }

  // checkActiveEvent(eventLink, sportcode, eventName) {
  //   console.log(eventLink, sportcode, eventName);

  //   Api.GetEvents(sportcode, (events) => {
  //     Utility.CheckActiveEventAndRedirect();
  //   });
  // }

  componentDidMount(){
    
    // WE NEED TO RUN THIS FOR VIRTUAL RACES ONLY FOR NOW
    if (Utility.CheckForEventExpiry()) {
      autoReload = setInterval(() => {
        Utility.CheckActiveEventAndRedirect();
      }, 15 * 1000);
    }

    this.GetSingleEvent()

    //UPDATE PRICE EVERY 5 SECONDS - https://nextpage.proofhub.com/bapplite/#app/todos/project-4734211540/list-147391440101/task-166653858275
    // updatePrice = setInterval(() => {
    //   this.GetSingleEvent();
    // }, 5000);

    let that = this;
    //Update price every 5 Sec if RefreshOdds is Enable 
    updatePrice = setInterval(() => {
      //Get refreshOddsEnable value from localstorage 
        if (Utility.isRefreshOddsEnable()) {
          that.GetSingleEvent();
        } else {
         clearInterval(updatePrice);
       }
    }, 5 * 1000); 

  }

  GetSingleEvent=()=> {
    const that = this;

    //Show loader only one time when page load
    if(!this.state.showLoader){
      Utility.ShowDataLoader("loaderContainer");
      this.setState({showLoader:true})
    }
    const params = new URLSearchParams(that.props.location.search);
    const ef = params.get("eventfile");
    const eg = params.get("EventGroup");
    const ev = params.get("Events");
    const sportcode = params.get("sportcode");
    const eventName = params.get("EventName");

    this.setState({ sportCode: sportcode, eventName: eventName });

    //let eventLink = "eventfile=" + ef + "&EventGroup=" + eg + "&Events=" + ev;  

    Api.GetSingleEvent(ef, eg, ev, (event) => {
      const eventName = event.MeetingName.split(" ");
      const locationName = eventName[0];
      const eventTime = eventName[1];

      this.setState({
        eventNumber: ev,
        locationName: locationName,
        eventTime: eventTime,
        eventFile:ef
      });

      // setTimeout(() => {
      //   console.log("Focus to first textbox set");
      //   this.getEvent(ev, locationName, eventTime);
      // }, 5000);

      //  localStorage.getItem("CustInfo")!=null? this.getEvent(ev, locationName, eventTime):console.log("LOGIN FIRST")

      // console.log("kjhbgvfcdxszZxdcfvgbhjmk,", ef, eg, ev, event);

      Utility.HideDataLoader("loaderContainer");
      var eventData = [];
      if (typeof event != "undefined") {
        if (event.Events != undefined) {
          eventData = event;
          // for(var x in eventData.Events){
          //     eventData.Events[x].PriceLink1 == "NR" ?
          //     eventData.Events.push( eventData.Events.splice(x,1)[0] ) : 0;
          // }
          eventData.Events = _.sortBy(eventData.Events, function (event) {
            return event.PriceLink1 === "NR" ? 1 : 0;
          });


          if (this.state.sportCode == "g,q") {
            eventData.Events = _.orderBy(eventData.Events, ["Image"], ["asc"]); //ORDER BY TRAP NUMBERS FOR DOG RACES
          } else if (this.state.sportCode == "$") {
            eventData.Events = Utility.FixVirtualDogRacesData(eventData.Events);
          }
          //Fix Tooltip issue
          if(eventData.Events[0]!=undefined && eventData.Events[0].Tooltip){
            eventData.Events[0].ToolTip = eventData.Events[0].Tooltip
          }
          that.setState({ Event: eventData });

          if (
            eventData.Events[0].ToolTip ||
            eventData.Events[0].ToolTipBoardPrice
          ) {
            $(".price").addClass("bestOddPrice");

            if (
              eventData.Events[0].ToolTip.toLowerCase() ==
                "best odds guaranteed" ||
              eventData.Events[0].ToolTipBoardPrice.toLowerCase() ==
                "best odds guaranteed"
            ) {
              this.setState({ bogBanner: false }); //changed here
            }
            const that = this;
            var bogSportidMatch = JSON.parse(
              localStorage.getItem("bog_sportid_match")
            );
            _.find(bogSportidMatch, function (o) {
              if (o.ItemId === that.state.Event.Events[0].EventId) {
                that.setState({ bogBanner: false });
              }
              return o.ItemId === that.state.Event.Events[0].EventId;
            });
          }
          if (
            event.Events[0].Tooltip != undefined &&
            event.Events[0].Tooltip.toLowerCase() == "best odds guaranteed"
          ) {
            this.setState({ bogBanner: false }); //changed here
          }
        } else {
          //Utility.GotoHomepage();
          //window.location.href = '/myfavourites';

          // CHECKING FOR VIRTUAL RACES HERE AND REDIRECTING TO FAVOURITES ONLY FOR OTHER EVENTS/GAMES
          if (sportcode !== "vh" && sportcode !== "$") {
            that.props.history.push({
              pathname: "/myfavourites",
              state: { Eventpath: Utility.GetPath() },
            });
          }
        }
      } else {
        that.setState({ Event: eventData });
      }

      // this.getEvent();
      // console.log(eventData, "EEEEEEEEEEEEE");
    });

    // Api.GetEvents(sportcode, events => {
    //     console.log("Races :", events);
    //     var eventDetail = [];
    //     if (typeof events != "undefined") {
    //         eventDetail = events;
    //     }
    //     this.setState({ sportsEvent: eventDetail });
    // })
    Utility.HideDataLoader("loaderContainer");

  }
  closeBogBanner() {
    this.GetAllBOGBetsFromLocalStorage();
    bogArrBanner.push({
      ItemId: this.state.Event.Events[0].EventId,
    });
    localStorage.setItem("bog_sportid_match", JSON.stringify(bogArrBanner));

    this.setState({ bogBanner: false });
  }
  bog_redirect() {
    this.props.history.push("/promotions/BOG");
    window.location.href = "/promotions/BOG";
  }
  GetAllBOGBetsFromLocalStorage() {
    var data = localStorage.getItem("bog_sportid_match");

    if (typeof data == "undefined" || data == null) {
      bogArrBanner = [];
      return bogArrBanner;
    } else {
      bogArrBanner = JSON.parse(data);
      return bogArrBanner;
    }
  }

  // getEvent(eventNumber, locationName, eventTime) {
  //   console.log("LLBG");
  //   var username = localStorage.getItem("username");
  //   // Utility.ShowDataLoader("loaderContainer");

  //   Api.getStreamEvents(eventNumber, locationName, eventTime, (cb) => {
  //     console.log(cb, "AAGGGG");

  //     if (cb == "No Event Available!") {
  //       //this.state.showIframe = false;
  //       this.setState({ showIframe: false });
  //       // Utility.HideDataLoader("loaderContainer");

  //     } else {
  //       this.setState({
  //         streamLoc: cb.Location,
  //         streamDesc: cb.Description,
  //         streamVenue: cb.VenueCode,
  //       });

  //       Api.getVideoGenKey(cb.ID, username, (videourl) => {

  //         // console.log(videourl,"DATAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
  //         this.setState({ showIframe: true, streamSrc: videourl });
  //         // Utility.HideDataLoader("loaderContainer");

  //       })

  //       // var url="http://bw197.attheraces.com/ps/player/default.aspx?partnercode=pkgc197&eventid=" +
  //       // cb.ID +
  //       // "&eventtype=live&userid=" +
  //       // username +
  //       // "&key=8e2538982eb7ee5ec5f1ec77d2ef1396";

  //       //    console.log(url,"videourlurl");
  //       // this.setState({ showIframe:true,streamSrc: url });
  //     }

  //   });
  // }

  componentWillUnmount() {
    clearInterval(autoReload);
    clearInterval(updatePrice);    
  }
  render() {
    var content = (
      <div>
        <div className="homepage">
          <div className="container">
            <div className="content-section-inner">
              <LeftContainer />

              <div className="center-container">
                <div className="show-events" id="loaderContainer">
                  {this.state.Event.Events[0] ? (
                    <CustomNotifications
                      eventID={this.state.Event.Events[0].EventId}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.bogBanner == true ? (
                    <div className="bog flipInX animated">
                      <div className="bog-label-outer">
                        {" "}
                        <img
                          src="/images/cross.svg"
                          className="cross_bg"
                          onClick={() => this.closeBogBanner()}
                        />
                        <div
                          className="col-md-12 bog-label"
                          onClick={() => this.bog_redirect()}
                        >
                          <label>BOG</label>

                          <div className="bog-text">
                            <h2>Best Odds Guaranteed </h2>

                            <p className="bog-label-left">
                              Whenever you see the sign, if you take an early
                              price and Starting Price (SP) is bigger, we will
                              pay out at the bigger odds (Singles only)!
                            </p>
                            <p className="bog-label-right">
                              <img src="/images/BOG.png" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {localStorage.getItem("CustInfo") != null ? */}
                  <div>
                    {/*Note- Adding virtual video stream for virtual horse race and virtual greyhound race */}
                    {/* <VirtualVideoStream page={Utility.getCurrentPage()} /> */}
                    <VideoStream
                      eventFile={this.state.eventFile}
                      eventnumber={this.state.eventNumber}
                      locationname={this.state.locationName}
                      eventtime={this.state.eventTime}
                      sportcode={this.state.sportCode}
                      eventName={this.state.eventName}
                      singleEvent={true}
                      IsLogin={Utility.IsCustomerLoggedIn()}
                    />
                  </div>
                  {/* : ""} */}

                  {/* SHOWING "NO CURRENT EVENTS" MESSAGE HERE IF EVENT IS NOT AVAILABLE */}
                  {(this.state.Event.Events[0] == undefined &&
                    this.state.sportCode == "vh") ||
                  (this.state.Event.Events[0] == undefined &&
                    this.state.sportCode == "$") ? (
                    <div className="EmptyEvents">
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                      There are Currently no active events. Please check back
                      later.
                    </div>
                  ) : (
                    // REST OF THE DATA SHOWING HERE
                    <div className="show-events-inner">
                      <div className="show-events-heading">
                        <h3>
                          {/* <i className="fa fa-chevron-down"></i> */}
                          <img
                            src="/images/9-dots.png"
                            data-toggle="collapse"
                            data-target="#otherRaces"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          />
                          {this.state.Event.MeetingName}
                          {this.state.Event.Events[0] &&
                          this.state.Event.Events[0].ToolTip ? (
                            <span> - {this.state.Event.Events[0].ToolTip}</span>
                          ) : (
                            ""
                          )}

                          <i
                            className="fa fa-star fav_star"
                            className={
                              this.props.FavouriteStore.CheckEventIsFavorite(
                                Utility.GetPath()
                              )
                                ? "fa fa-star fav_star"
                                : "fa fa-star  not_fav_star"
                            }
                            aria-hidden="true"
                            onClick={(e) =>
                              this.props.FavouriteStore.AddSportToFavorite(
                                this.state.Event.MeetingName,
                                (cb) => {}
                              )
                            }
                          ></i>
                          <span
                            className="single-event-activity-indicator animated flipInX"
                            style={{ display: "none" }}
                          ></span>
                        </h3>
                      </div>

                      {/* <div className="collapse" id="otherRaces" >
                                           <RacesList />
                                        </div> */}

                      <RacesList />

                      <div>
                        <div className="time-remaining">
                          <div className="col-md-6">
                            <h3 className="event-name">
                              {this.state.Event.EventName} -{" "}
                              {this.state.Event.PlText}
                            </h3>
                          </div>
                          <div className="col-md-3 text-right">
                            <label>{this.state.Event.PType}</label>
                          </div>
                          <div className="col-md-3 text-right">
                            {/* <label>TIME LEFT
                                                                        <span>05</span>
                                                            <em>:</em>
                                                            <span>45</span>
                                                            <em>:</em>
                                                            <span>27</span>
                                                        </label> */}
                          </div>
                        </div>

                        {this.state.Event.Events.map(function (event, i) {
                          return (
                            <div className="list" key={i}>
                              <Tooltip clickable={true} />
                              <div className="col-md-3">
                                <label data-tip={event.EventId}>
                                  {(this.state.sportCode == "g,q" ||
                                    this.state.sportCode == "$") &&
                                  event.Image &&
                                  event.Image != "" ? (
                                    <img
                                      src={
                                        "https://v0.geoff-banks.com/images/Dogs/" +
                                        event.Image
                                      }
                                      alt="image"
                                      style={{ marginRight: 10 + "px" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {event.SelName}
                                </label>
                              </div>
                              <div className="col-md-3">
                                <label className="jockey-name">
                                  {event.Jockey}
                                </label>
                              </div>

                              <div className="col-md-3 text-right">
                                {/* {event.price != "" && event.price  */}
                                {event.price != "" &&
                                event.Price1 !== "NR" &&
                                event.gprice ? (
                                  <span
                                    className="cursor-pointer price"
                                    onClick={(e) =>
                                      this.props.Store.AddBet(
                                        e,
                                        event,
                                        event,
                                        event.selection
                                      )
                                    }
                                  >
                                    {this.props.PreferenceStore.ConvertPrice(
                                      event.price
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-3 text-right">
                                {event.PriceLink1 != "NR" ? (
                                  <span
                                    className="cursor-pointer "
                                    onClick={(e) =>
                                      this.props.Store.AddSpBet(event)
                                    }
                                  >
                                    {" "}
                                    SP
                                  </span>
                                ) : (
                                  <span>{event.PriceLink1}</span>
                                )}
                              </div>
                            </div>
                          );
                        }, this)}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <RightContainer />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default subscribe(SingleEvent, {
  Store: BetSlipContainer,
  PreferenceStore: PreferenceContainer,
  FavouriteStore: FavouriteContainer,
});
