import React from 'react';
import './style.css';

export const EventEndedMessage = (props) => {
    const { closeMessage } = props;

    return (
        <div className="stake-exceed-message">
            <div className="stake-exceed-message-header">
                <span className="stake-exceed-message-title">Stake exceed</span>
                <button
                    className="stake-exceed-message-close"
                    onClick={closeMessage}>
                    ×
                </button>
            </div>
            <div className="stake-exceed-message-wrapper">
                <div className="stake-exceed-message-body">
                    <img src="/images/warningIcon.png" alt="type"></img>
                    <span>Event&#160;has&#160;ended. The&#160;selection&#160;is&#160;no&#160;longer&#160;available.</span>
                </div>
                <div className="stake-exceed-message-footer">
                    <p>Press Ok to go back to the Bet Slip.</p>
                </div>
                <div className="stake-exceed-message-buttons">
                    <button
                        className="message-button"
                        onClick={closeMessage}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};