import React from 'react';
import * as Utility from "../js/utility";

class AccountSidebar extends React.Component {
  render() {
    return (
      <div className="left-container">
        <div className="left-menu">
          <div className="left-menu-inner">
            <h4>My Account</h4>
            <ul>
              <li >
                <a href='/change-password' id ="ChangePwd">Change Password</a>
              </li>
              <li >
                <a href='/user-details' id="UserDetail">Update My Details</a>
              </li>
              <li >
                <a href='/my-profile' id="MyProfile">My Profile</a>
              </li>
              <li >
                <a href='/Responsible-gambling' id="DeactivateAccount">Deactivate Account</a>
              </li>
            </ul>
          </div>
          <div className="left-menu-inner">
            <h4>Account Movements</h4>
            <ul>

              <li >
                {/* <a href="/transaction">All Transactions</a> */}
                <a href='/transactions' id="trans">All Transactions</a>
              </li>
              {/* <li>
                <a href="/">Payment History</a>
              </li> */}
            </ul>
          </div>
          <div className="left-menu-inner">
            <h4>Payments</h4>
            <ul>
              {
                Utility.isOnlineCardPayments()? <li>
                <a href="/paydoo-deposit" id="PaydooDeposit">Deposits</a>
              </li>:""
              }
              {/* Utility.isOnlineDepositEnable() ? (
                  <li>
                    <a href="/deposit" id="Deposit">Online Deposits</a>
                  </li>)
                  :""
              */}
              {/* <li>
                <a href="/deposit" id="Deposit">Online Deposits</a>
              </li> */}

              <li>
                <a href="/withdrawals" id="Withdrawal">Withdrawals</a>
              </li>
              <li>
                <a href="/paypal-withdrawal" id="Withdrawal">Paypal Withdrawal</a>
              </li>

              {/* <li>
                <a href="/manual-deposite">Manual Deposit</a>
              </li>
              <li>
                <a href="/reverse-withdrawals">Reverse Withdrawal</a>
              </li> */}
              <li>
                <a href="/Deposit-limit" id="DepositLimit">Add or Alter a Deposit Limit</a>
              {/* <a href="http://v0.geoff-banks.com/depositlimit.asp">Add or Alter a Deposit Limit </a> */}
                
              </li>
              <li>
              {/* <a href="http://v0.geoff-banks.com/closure.asp">Responsible Gambling</a> */}
                <a href="/Responsible-gambling" id ="ResposibleGambling">Responsible Gambling</a>
              </li>
            </ul>
          </div>
          {/* <div className="left-menu-inner">
            <h4>Settings</h4>
            <ul>
              <li>
                <a href="/">Personal Details</a>
              </li>
              <li>
                <a href="/">Preferences</a>
              </li>
              <li>
                <a href="/">Change Password</a>
              </li>
              <li>
                <a href="/">Advance Settings</a>
                <i className="fa fa-plus"></i>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    )
  }
}
export default AccountSidebar;