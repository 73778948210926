import React from 'react';
import './style.css';

export const BetSlipLadder = (props) => {
    const betStakeLadder = [5, 10, 15, 50, 100, 125, 150, 200];
    const { isBetslipLoaded, addStakeToSelected } = props;

    return (
        <div className="betslip-ladder-container">
            <div className="bet-staking-ladder">
                <p className="bet-staking-ladder-message">
                    Use staking ladder to input your amount.
                    Click more than once to add to your stake!
                </p>
                <div className="staking-ladder-wrapper">
                    {
                        betStakeLadder.map((stake, index) => (
                            <button
                                className="staking-ladder-button"
                                key={index}
                                disabled={!isBetslipLoaded}
                                onClick={() => addStakeToSelected(stake)}>
                                {stake}+
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}