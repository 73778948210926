import React from 'react'
import '../css/main.css'
import Footer from '../Component/Footer';
import LeftContainer from '../Component/LeftContainer';
import * as Api from '../js/api-service';
import * as Utility from '../js/utility';
import * as Shared from "../Component/Shared/Index";

var data;
class ShowPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            title: '',
            data: '',
            ShowLoader: true

        }
    }


    componentDidMount() {
        
        Api.pageData(this.props.match.params.pageName, cb => {
            let data = cb.data[0].content;
            let title = cb.data[0].title
            data = data.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')
            this.setState({ title: title, data: data, ShowLoader: false });

        })
    }



    render() {
        var content = (
            <div>
                <div className="homepage show-pages">
                    <div className="container">

                        <div className="content-section-inner">
                            <LeftContainer />
                            <div className='content'>

                                <div className='title'> {this.state.title} </div>
                                <Shared.Loader Show={this.state.ShowLoader} />

                                <div className="center-container1" dangerouslySetInnerHTML={{ __html: this.state.data }} >
                                    {/* <LiveRaces /> */}

                                    {/* <ShowEvents /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}



export default ShowPage;