import _ from "lodash";
import $ from "jquery";
import Axios from "axios";
import * as Cache from "../Services/Cache";

export const base_url = "https://api.geoff-banks.com/Geoff/";
const base_url_v0 = "https://v0.geoff-banks.com/";
const base_url_v1 = "https://apiv1.geoffbanks.bet/";
const base_url_v2 = "https://api2.geoffbanks.bet/api/";
const items_url = "https://be.geoff-banks.com/_/items/";
const access_token = "RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
export const ApiProxy = {
  Get: `${base_url_v1}ApiProxy/Get?url=`,
};
export const Reg_API_BASE_URL_V1 = "https://apiv1.geoffbanks.bet/";
export const Reg_API_KEY = "ishFiWjhUEWbXCXcgO3F9g26831";
export const paypalProdClientId =
  "AeKbIqUt87EfrJvMUrx1XvaWttj3YJLIgOJft-RRc9VS5xwMkaFzYo-eKYrWr3AMVn1Cm9w2hFygG8MQ";
export const payPalClientId =
  "AbI14CNcYzC2KleOnbFO-pIK9trGIsyMStdFXpBtGcfhEvby-71JCyoGe3DoHBvLO3t2lCzuIstglKpz";
export const payPalClientSecret =
  "EA1HDDHJtJ6HHH_OrUwu8wDQRGaewvwQDW69Cpgispu8wLzscjdesbgTNWe_jqt6Kdq14ILRMNPBwGVG";

// const siteRoot = "https://www.geoffbanks.bet";
// const siteRootV1 = "https://www.v1.geoffbanks.bet";
const siteRoot = "https://www.v1.geoffbanks.bet";

export const virtualStreamLiveURL =
  "https://vcdata-l1.inseincvirtuals.com/inggWebViewer/?cust=abeta&ch=";

const utility = require("./utility");

const Sports = [
  {
    Name: "Horse Racing",
    URL: `${base_url_v0}events/sports-api.asp?sportcode=H,h`,
    //AntePostURL: `${base_url_v0}events/sports-api.asp?sportcode=a`,
    AntePostURL: `${base_url}GetAntepostEvents/a`,
    LiveRaceURL:
      base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
    SportCode: "H,h",
    AntepostSportCode: "a",
    Image: "hourse.png",
    PathName: "/sports?sportcode=H,h",
    AntepostPathName: "/antepost?sportcode=a",
    SubItems: [
      // {
      //     Title: "Horse Racing Events",
      //     Url: "/sports?sportcode=H,h",
      // },
      {
        Title: "UK Racing",
        Url: "/sports?sportcode=H,h",
        Name: "UK Horse Racing",
        SportCode: "H,h",
        URL: base_url_v0 + "events/sports-api.asp?sportcode=H,h",
        PathName: "/sports?sportcode=H,h",
      },
      {
        Title: "Irish Racing ",
        Url: "/sports?sportcode=H",
        Name: "Irish Horse Racing",
        SportCode: "H",
        URL: base_url_v0 + "events/sports-api.asp?sportcode=H",
        PathName: "/sports?sportcode=H",
        AntepostSportCode: "a",
        //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=a",
        AntePostURL: `${base_url}GetAntepostEvents/a`,
        LiveRaceURL:
          base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
        AntepostPathName: "/antepost?sportcode=a",
      },
      {
        Title: "International Racing",
        Url: "/sports?sportcode=o",
        Name: "International Horse Racing",
        SportCode: "o",
        URL: `${base_url_v0}events/sports-api.asp?sportcode=o`,
        PathName: "/sports?sportcode=o",
        AntepostSportCode: "a",
        //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=a",
        AntePostURL: `${base_url}GetAntepostEvents/a`,
        LiveRaceURL:
          base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
        AntepostPathName: "/antepost?sportcode=a",
      },
    ],
  },
  // {
  //     Name: "Horse Racing - UK MEETINGS",
  //     URL: base_url_v0 + "events/sports-api.asp?sportcode=h",
  //     AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=a",
  //     LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
  //     SportCode: "h",
  //     AntepostSportCode: "a",
  //     Image: "hourse.png",
  //     PathName: "/sports?sportcode=h",
  // },
  // {
  //     Name: "Horse Racing -IRISH MEETINGS ",
  //     URL: base_url_v0 + "events/sports-api.asp?sportcode=H",
  //     AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=a",
  //     LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
  //     SportCode: "H",
  //     AntepostSportCode: "a",
  //     Image: "hourse.png",
  //     PathName: "/sports?sportcode=H",
  // },
  // {
  //     Name: "Horse Racing - INTERNATIONAL RACING",
  //     URL: base_url_v0 + "events/sports-api.asp?sportcode=o",
  //     AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=a",
  //     LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
  //     SportCode: "o",
  //     AntepostSportCode: "a",
  //     Image: "hourse.png",
  //     PathName: "/sports?sportcode=o",
  // },
  // {
  //     Name: "Full Horse Racing",
  //     URL: base_url_v0 + "events/sports-api.asp?sportcode=H,h,o",
  //     AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=a",
  //     LiveRaceURL: base_url_v0 + "Ajax/Next/HomeEvent/api.asp?urid=upRegNextHomeEv",
  //     SportCode: "H,h,o",
  //     AntepostSportCode: "a",
  //     Image: "hourse.png",
  //     PathName: "/sports?sportcode=H,h",
  // },

  {
    Name: "Virtual Horse",
    //URL: base_url + "/GetGolfEvents/m",
    //URL: `${base_url}GetEventsDetail/vh?fullcard=1`,
    URL: `${base_url_v0}events/sports-api.asp?sportcode=vh`,
    //AntePostURL:`${base_url_v0}events/sports-api.asp?sportcode=vh`,
    SportCode: "vh",
    AntepostSportCode: "vh",
    Image: "hourse.png",
    PathName: "/sports?sportcode=vh",
    //AntepostPathName: "/antepost?sportcode=vh",
    // SubItems: [
    //     {
    //         Title: "Golf Antepost",
    //         Url: "/antepost?sportcode=G",
    //     }
    // ]
  },
  {
    Name: "Greyhound",
    URL: `${base_url_v0}events/sports-api.asp?sportcode=g,q`,
    // AntePostURL:"https://v0.geoff-banks.com/events/sports-api.asp?sportcode=p",
    AntePostURL: `${base_url}GetAntepostEvents/p`,
    SportCode: "g,q",
    AntepostSportCode: "p",
    LiveRaceURL: `${base_url_v0}Ajax/Next/Dogs/api.asp?urid=upRegNextDogRace&nextrace=1`,
    Image: "dog.png",
    PathName: "/sports?sportcode=g,q",
    AntepostPathName: "/antepost?sportcode=p",
    // SubItems: [
    //     {
    //         Title: "Greyhound Antepost",
    //         Url: "/antepost?sportcode=p",
    //     },
    // ]
  },

  {
    Name: "Virtual Greyhound",
    //URL: base_url + "/GetGolfEvents/m",
    //URL: `${base_url}GetEventsDetail/$?fullcard=1`,
    URL: `${base_url_v0}events/sports-api.asp?sportcode=$`,
    //AntePostURL:`${base_url_v0}events/sports-api.asp?sportcode=$`,
    SportCode: "$",
    AntepostSportCode: "$",
    Image: "dog.png",
    // PathName: "/sports/virtual?sportcode=$",
    PathName: "/sports?sportcode=$",
    //AntepostPathName: "/antepost?sportcode=$",

    // SubItems: [
    //     {
    //         Title: "Golf Antepost",
    //         Url: "/antepost?sportcode=G",
    //     }
    // ]
  },
  {
    Name: "Football",
    URL: `${base_url}GetEventsDetail/f?fullcard=1`,
    //AntePostURL: `${base_url_v0}events/sports-api.asp?sportcode=s`,
    AntePostURL: `${base_url}GetAntepostEvents/s`,
    SportCode: "f",
    AntepostSportCode: "s",
    Image: "Image_27.png",
    PathName: "/sports/football?sportcode=f",
    AntepostPathName: "/antepost?sportcode=s",
    FullCardOptions: [
      "Draw No Bet",
      "Over/Under 1.5",
      "Over/Under 2.5",
      "Over/Under 3.5",
      "Over/Under 4.5",
      "Over/Under 5.5",
      "Half-Time Totals Over/Under 0.5",
      "Half-Time Totals Over/Under 1.5",
      "Both Teams To Score",
      "To Qualify",
    ],
    // SubItems: [
    //     {
    //         Title: "Football Antepost",
    //         Url: "/antepost?sportcode=s",
    //     },
    // ],
  },
  // {
  //     Name: "Soccerbet",
  //     URL: `${base_url}GetEventsDetail/sb?fullcard=1`,
  //     AntePostURL: `${base_url_v0}events/sports-api.asp?sportcode=sb`,
  //     SportCode: "sb",
  //     AntepostSportCode: "sb",
  //     Image: "Image_27.png",
  //     PathName: "/sports/virtual?sportcode=sb",
  //     AntepostPathName: "/antepost?sportcode=sb",
  //     FullCardOptions: [
  //         "Draw No Bet",
  //         "Over/Under 1.5",
  //         "Over/Under 2.5",
  //         "Over/Under 3.5",
  //         "Over/Under 4.5",
  //         "Over/Under 5.5",
  //         "Half-Time Totals Over/Under 0.5",
  //         "Half-Time Totals Over/Under 1.5",
  //         "Both Teams To Score",
  //         "To Qualify",
  //     ],
  //     // SubItems: [
  //     //     {
  //     //         Title: "Football Antepost",
  //     //         Url: "/antepost?sportcode=s",
  //     //     },
  //     // ],
  // },
  {
    Name: "Golf",
    //URL: base_url + "/GetGolfEvents/m",
    URL: `${base_url}GetEventsDetail/m?fullcard=1`,
    //AntePostURL: `${base_url_v0}events/sports-api.asp?sportcode=G`,
    AntePostURL: `${base_url}GetAntepostEvents/G`,
    SportCode: "m",
    AntepostSportCode: "G",
    Image: "golf.png",
    PathName: "/sports/golf?sportcode=m",
    AntepostPathName: "/antepost?sportcode=G",
    // SubItems: [
    //     {
    //         Title: "Golf Antepost",
    //         Url: "/antepost?sportcode=G",
    //     }
    // ]
  },
  {
    Name: "Tennis",
    //URL: base_url + "/GetTennisEvents/N",
    URL: base_url + "GetEventsDetail/N?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=t",
    AntePostURL: `${base_url}GetAntepostEvents/t`,
    SportCode: "N",
    AntepostSportCode: "t",
    Image: "tennis.png",
    PathName: "/sports/tennis?sportcode=N",
    AntepostPathName: "/antepost?sportcode=t",
    FullCardOptions: ["Total Games 2-Way", "Handicap 2-Way - Games Won"],
    // SubItems: [
    //     {
    //         Title: "Tennis Antepost",
    //         Url: "/antepost?sportcode=t",
    //     }
    // ]
  },
  {
    Name: "American Football",
    //URL: base_url + "GetAmericanFootballEvents/F",
    URL: `${base_url}GetEventsDetail/F?fullcard=1`,
    //AntePostURL: `${base_url_v0}events/sports-api.asp?sportcode=A`,
    AntePostURL: `${base_url}GetAntepostEvents/A`,
    SportCode: "F",
    AntepostSportCode: "A",
    Image: "purepng.png",
    PathName: "/sports/nfl/?sportcode=F",
    AntepostPathName: "/antepost?sportcode=A",
    // SubItems: [
    //     {
    //         Title: "American Football Antepost",
    //         Url: "/antepost?sportcode=A",
    //     }
    // ]
  },
  {
    Name: "Baseball",
    //URL: base_url + "GetBaseballEvents/e",
    URL: base_url + "GetEventsDetail/e?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=z",
    AntePostURL: `${base_url}GetAntepostEvents/n`,
    SportCode: "e",
    AntepostSportCode: "n",
    Image: "baseball.png",
    PathName: "/sports/baseball?sportcode=e",
    AntepostPathName: "/antepost?sportcode=n",
    // SubItems: [
    //     {
    //         Title: "Baseball Antepost",
    //         Url: "/antepost?sportcode=z",
    //     }
    // ]
  },
  {
    Name: "Basketball",
    //URL: base_url + "GetBasketballEvents/E",
    URL: base_url + "GetEventsDetail/E?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=k",
    AntePostURL: `${base_url}GetAntepostEvents/k`,
    SportCode: "E",
    AntepostSportCode: "k",
    Image: "basketball.png",
    PathName: "/sports/basketball?sportcode=E",
    AntepostPathName: "/antepost?sportcode=k",
    FullCardOptions: [
      "Race to 20 Points",
      "1st Half Point Spread",
      "1st Half Money Line",
      "1st Half Totals",
      "Team with High Score Qtr",
    ],
    // SubItems: [
    //     {
    //         Title: "Basketball Antepost",
    //         Url: "/antepost?sportcode=k",
    //     }
    // ]
  },
  {
    Name: "Boxing",
    URL: base_url + "GetEventsDetail/b?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=b",
    SportCode: "box",
    Image: "gvb.png",
    PathName: "/sports/boxing?sportcode=box",
    FullCardOptions: ["Total Rounds", "Fight Result"],
  },
  {
    Name: "UFC/MMA",
    //URL: base_url + "GetUFCMMAEvents/b",
    URL: base_url + "GetEventsDetail/b?fullcard=1",
    SportCode: "b",
    Image: "gvb.png",
    PathName: "/sports/ufcmma?sportcode=b",
  },
  {
    Name: "Cricket",
    //URL: base_url + "GetCricketEvents/C",
    URL: base_url + "GetEventsDetail/C?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=K",
    AntePostURL: `${base_url}GetAntepostEvents/K`,
    SportCode: "C",
    AntepostSportCode: "K",
    Image: "fvg.png",
    PathName: "/sports/cricket?sportcode=C",
    AntepostPathName: "/antepost?sportcode=K",
    FullCardOptions: [
      "To Win the Toss",
      "1st Over Total Runs",
      "Total Match Sixes",
      "Fall of 1st Wicket",
      "A Fifty to be Scored",
      "A Century to be Scored",
      "Highest Individual Score",
    ],
    // SubItems: [
    //     {
    //         Title: "Cricket Antepost",
    //         Url: "/antepost?sportcode=K",
    //     }
    // ]
  },
  {
    Name: "Cycling",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=c",
    AntePostURL: `${base_url}GetAntepostEvents/c`,
    AntepostSportCode: "c",
    Image: "cycle-race.png",
    PathName: "/antepost?sportcode=c",
    AntepostPathName: "/antepost?sportcode=c",
    // SubItems: [
    //     {
    //         Title: "Cycling Antepost",
    //         Url:this "/antepost?sportcode=c",
    //     }
    // ]
  },
  {
    Name: "Darts",
    //URL: base_url + "GetDartEventList/I",
    URL: base_url + "GetEventsDetail/I?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=D",
    AntePostURL: `${base_url}GetAntepostEvents/D`,
    SportCode: "I",
    AntepostSportCode: "D",
    Image: "bgf.png",
    PathName: "/sports/darts?sportcode=I",
    AntepostPathName: "/antepost?sportcode=D",
    // SubItems: [
    //     {
    //         Title: "Darts Antepost",
    //         Url: "/antepost?sportcode=D",
    //     }
    // ]
  },
  {
    Name: "GAA",
    //URL: base_url + "GetGAAEvents/J",
    URL: base_url + "GetEventsDetail/J?fullcard=1",
    // AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=d",
    AntePostURL: `${base_url}GetAntepostEvents/d`,
    SportCode: "J",
    AntepostSportCode: "d",
    Image: "xc.png",
    PathName: "/sports/GAA?sportcode=J",
    AntepostPathName: "/antepost?sportcode=d",
    // SubItems: [
    //     {
    //         Title: "GAA Antepost",
    //         Url: "/antepost?sportcode=d",
    //     }
    // ]
  },
  {
    Name: "Ice Hockey",
    URL: base_url + "GetEventsDetail/y?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=Y",
    AntePostURL: `${base_url}GetAntepostEvents/Y`,
    AntepostSportCode: "Y",
    Image: "hockey.png",
    PathName: "/sports/icehockey?sportcode=y",
    AntepostPathName: "/antepost?sportcode=Y",
    SportCode: "y",
  },
  {
    Name: "Motor Racing",
    //URL: base_url + "/GetMotorRacingEvents/M",
    //URL: base_url + "GetEventsDetail/M?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=M",
    AntePostURL: `${base_url}GetAntepostEvents/M`,
    //AntePostURL: base_url + "/GetMotorRacingEvents/M",
    SportCode: "M",
    AntepostSportCode: "M",
    Image: "motorcycle.png",
    //PathName: "/sports/motor-racing?sportcode=M",
    PathName: "/antepost?sportcode=M",
    AntepostPathName: "/antepost?sportcode=M",
    // SubItems: [
    //     {
    //         Title: "Motor Racing Antepost",
    //         Url: "/antepost?sportcode=M",
    //     }
    // ]
  },
  {
    Name: "Rugby League",
    //URL: base_url + "GetRugbyEvents/l",
    URL: base_url + "GetEventsDetail/l?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=r",
    AntePostURL: `${base_url}GetAntepostEvents/r`,
    SportCode: "l",
    AntepostSportCode: "r",
    Image: "szcd.png",
    PathName: "/sports/rugby?sportcode=l",
    AntepostPathName: "/antepost?sportcode=r",
    // SubItems: [
    //     {
    //         Title: "Rugby League Antepost",
    //         Url: "/antepost?sportcode=r",
    //     }
    // ]
  },
  {
    Name: "Rugby Union",
    //URL: base_url + "GetRugbyEvents/v",
    URL: base_url + "GetEventsDetail/v?fullcard=1",
    SportCode: "v",
    Image: "sd.png",
    PathName: "/sports/rugby?sportcode=v",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=R",
    AntePostURL: `${base_url}GetAntepostEvents/R`,
    AntepostSportCode: "R",
    AntepostPathName: "/antepost?sportcode=R",
  },

  {
    Name: "Snooker",
    //URL: base_url + "GetSnookerEvents/j",
    URL: base_url + "GetEventsDetail/j?fullcard=1",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=S",
    AntePostURL: `${base_url}GetAntepostEvents/S`,
    SportCode: "j",
    AntepostSportCode: "S",
    Image: "snooker.png",
    PathName: "/sports/snooker?sportcode=j",
    AntepostPathName: "/antepost?sportcode=S",
    // SubItems: [
    //     {
    //         Title: "Snooker Antepost",
    //         Url: "/antepost?sportcode=S",
    //     }
    // ]
  },
  {
    Name: "Special/Politics",
    //AntePostURL: base_url_v0 + "events/sports-api.asp?sportcode=z",
    AntePostURL: `${base_url}GetAntepostEvents/z`,
    // AntepostSportCode: "special",
    AntepostSportCode: "z",
    Image: "special.png",
    // PathName: "/antepost?sportcode=special",
    // AntepostPathName: "/antepost?sportcode=special",
    PathName: "/antepost?sportcode=z",
    AntepostPathName: "/antepost?sportcode=z",
  },
];

export function getFeaturedSports(callback) {
  let key = "Api.getFeaturedSports";
  if (Cache.Exists(key)) return callback(JSON.parse(Cache.Get(key)));
  else {
    var URL =
      items_url +
      "sports?access_token=" +
      access_token +
      "&filter[isfeatured][eq]=1&fields=*.*";
    fetch(URL)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        Cache.SetMins(key, JSON.stringify(response), 60); //CACHE
        callback(response);
      })
      .catch(function (error) {});
  }
}

export function get20backClaim(username, callback) {
  const URL =
      `${items_url}20backclaim?access_token=${access_token}&filter[username]=${username}`;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function postClaim(data, callback) {
  var body = data;
  // body.status = "published";
  $.post(
    `${items_url}20backclaim?access_token=${access_token}`,
    body,
    (res) => {
      callback(res);
    }
  );
}

//MANUAL DEPOSIT
export function checkUserExists(username, callback) {
  var URL =
    items_url +
    "manual_deposit?access_token=" +
    access_token +
    "&filter[username]=" +
    username +
    "&sort=-created_on"; //SORT BY CREATED ON . Show latest on top
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

//MANUAL DEPOSIT
export function checkCardExists(username, callback) {
  var URL =
    items_url +
    "card_detail_paydoo?access_token=" +
    access_token +
    "&filter[account_number]=" +
    username +
    "&sort=-created_on"; //SORT BY CREATED ON . Show latest on top
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function saveManualDeposit(data, callback) {
  var body = data;
  body.status = "published";
  // body.card_number=utility.encrypt(body.card_number);
  // body.cvv=utility.encrypt(body.cvv)
  // body.expiry_date=utility.encrypt(body.expiry_date)

  $.post(
    items_url + "manual_deposit?access_token=" + access_token,
    body,
    (res) => {
      callback(res);
    }
  );
}

export function saveCardDetail(data, callback) {
  var body = data;
  body.status = "published";
  // body.card_number=utility.encrypt(body.card_number);
  // body.cvv=utility.encrypt(body.cvv)
  // body.expiry_date=utility.encrypt(body.expiry_date)

  $.post(
    items_url + "card_detail_paydoo?access_token=" + access_token,
    body,
    (res) => {
      callback(res);
    }
  );
}

export function updateManualDeposit(data, callback) {
  var bodyData = data;
  let Url =
    items_url +
    "manual_deposit/" +
    bodyData.id +
    "?access_token=" +
    access_token;
  fetch(Url, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (res) {
      callback(res);
    })
    .catch(function (err) {
      callback(err);
    });
}

export function GetSportInfo(sportCode) {
  // var sport = _.find(Sports, {
  //     "SportCode": sportCode
  // });
  var sportDetail = [];
  var sport = _.find(Sports, function (sport) {
    if (sport.SportCode == sportCode) {
      sportDetail = sport;
    } else {
      if (
        typeof sport != "undefined" &&
        typeof sport.SubItems != "undefined" &&
        sport.SubItems.length > 0
      ) {
        var subItemSport = _.find(sport.SubItems, function (subItem) {
          // subItem.SportCode == sportCode;
          if (subItem.SportCode == sportCode) {
            sportDetail = subItem;
          }
        });
      }
    }
  });
  return sportDetail;
  //     if(typeof sport == "undefined" && typeof sport.SubItems != "undefined" && sport.SubItems.length>0){
  //         sport = _.find(Sports.SubItems, function(o){
  //            o.SportCode== sportCode
  //        });
  //    }

  // return sport;
}

export function GetAntePostSportInfo(antepostSportCode) {
  var sport = _.filter(Sports, {
    AntepostSportCode: antepostSportCode,
  });
  return sport[0];
}

export function GetEvents(sportCode, callback, err) {
  const sport = GetSportInfo(sportCode);
  //USING ANTEPOST URL HERE FOR FETCHING EVENTS FROM MAIN SERVER(V0) FOR VIRTUAL HORSE AND VIRTUAL GREYHOUND RESPECTIVELY
  // URL (vh) ->https://apiv1.geoffbanks.bet/ApiProxy?url=https://v0.geoff-banks.com/events/sports-api.asp?sportcode=vh
  // URL ($) -> https://apiv1.geoffbanks.bet/ApiProxy?url=https://v0.geoff-banks.com/events/sports-api.asp?sportcode=$
  const key = `GetEvents-${sportCode}`;
  // let url =sportCode === "vh" || sportCode === "$" ? sport.AntePostURL : sport.URL;
  let url = sport.URL;
  if (Cache.Exists(key)) {
    callback(JSON.parse(Cache.Get(key)));
  } else {
    if (utility.isCacheApiProxyEnable()) {
      url = ApiProxy.Get + escape(url);
    }
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        let res = response;
        if (utility.isCacheApiProxyEnable()) {
          res = response.Data;
        }

        //Checking for virtual races
        // if (sportCode === "vh" || sportCode === "$") {
        //     // Calling GetActiveEvent(data) function from utility to return single object based on empty status.
        //     res = utility.GetActiveEvent(response.Data);
        // }
        Cache.Set(key, JSON.stringify(res));
        callback(res);
      })
      .catch(function (error) {
        callback([]);
      });
  }
}

export function GetAntepostEvents(AntepostSportCode, callback) {
  const sport = GetAntePostSportInfo(AntepostSportCode);
  let url = sport.AntePostURL;
  if (utility.isCacheApiProxyEnable()) {
    url = ApiProxy.Get + escape(sport.AntePostURL);
  }

  fetch(url)
    .then(function (response) {
      // if (AntepostSportCode == "vh") return response.json().data;
      return response.json();
    })
    .then(function (response) {
      let res = response;
      if (utility.isCacheApiProxyEnable()) {
        res = response.Data;
      }
      const data = _.filter(res, function (o) {
        return o.Meetings.length > 0;
      });

      callback(data);
    })
    .catch(function (error) {});
}

export function GetSportsList(callback) {
  return Sports;
}

function ParseEventData(data) {
  //console.log(data, "ParseEventData");
  if (data.Races) {
    //console.log("Races");
    data.Races.forEach((race) => {
      let pInfo = race.Price1;
      if (typeof pInfo == "undefined" || pInfo.trim().length <= 0)
        pInfo = race.Price2;
      var p1Sets = pInfo.split("&");
      p1Sets.forEach((element) => {
        var keyVals = element.split("=");
        race[keyVals[0]] = keyVals[1];
      });
    });
    //console.log(data, "parserdata");
    return data;
  } else if (data.Events) {
    //console.log("Events");
    data.Events.forEach((race) => {
      if (!(typeof race.PriceLink1 == "undefined")) {
        var pInfo = race.PriceLink1;
        if (
          pInfo.trim().length <= 0 &&
          !(typeof race.PriceLink1 == "undefined") &&
          race.PriceLink2.length > 0
        )
          pInfo = race.PriceLink2;
        var p1Sets = pInfo.split("&");
        p1Sets.forEach((element) => {
          var keyVals = element.split("=");
          race[keyVals[0]] = keyVals[1];
        });
      }
    });
    //console.log(data, "parserdata");
    return data;
  } else if (data.Meetings) {
    //console.log("MeetingLink");
    data.Meetings.forEach((race) => {
      if (!(typeof race.MeetingLink == "undefined")) {
        var pInfo = race.MeetingLink;
        if (
          pInfo.trim().length <= 0 &&
          !(typeof race.MeetingLink == "undefined") &&
          race.MeetingLink.length > 0
        )
          pInfo = race.MeetingLink;
        var p1Sets = pInfo.split("&");
        p1Sets.forEach((element) => {
          var keyVals = element.split("=");
          race[keyVals[0]] = keyVals[1];
        });
      }
    });
    return data;
  } else {
    return data;
  }
}

export function ParseEventArrayData(data) {
  data.forEach((event) => {
    if (event.Meetings) {
      event.Meetings.forEach((race) => {
        if (!(typeof race.MeetingLink == "undefined")) {
          var pInfo = race.MeetingLink;
          if (
            pInfo.trim().length <= 0 &&
            !(typeof race.MeetingLink == "undefined") &&
            race.MeetingLink.length > 0
          )
            pInfo = race.MeetingLink;
          var p1Sets = pInfo.split("&");
          p1Sets.forEach((element) => {
            var keyVals = element.split("=");
            race[keyVals[0]] = keyVals[1];
          });
        }
      });
    } else if (event.Events != null) {
      event.Events.forEach((race) => {
        if (
          !(typeof race.PriceLink1 == "undefined") &&
          race.PriceLink1 != null
        ) {
          var pInfo = race.PriceLink1;
          if (
            pInfo.trim().length <= 0 &&
            !(typeof race.PriceLink1 == "undefined") &&
            race.PriceLink2.length > 0
          )
            pInfo = race.PriceLink2;
          var p1Sets = pInfo.split("&");
          p1Sets.forEach((element) => {
            var keyVals = element.split("=");
            race[keyVals[0]] = keyVals[1];
          });
        }
      });
    }
  });
  return data;
}

// export function getAntepostMeetingDetail1(ef, eg, callback) {
//   //   "https://api.geoff-banks.com/Geoff/GetEventsFromAPI_Idx";

//   const url = base_url + "GetEventsFromAPI/" + ef + "/" + eg;
//   //const url = "https://v0.geoff-banks.com/Ajax/Event/api.asp?ef=" + ef + "&eg=" + eg + "&e=0";
//   fetch(url)
//     .then(function(response) {
//       return response.json();
//     })
//     .then(function(response) {
//       console.log("response----", response);
//       //var result = ParseEventData(response);
//       var result = ParseEventArrayData(response);

//       callback(result);
//       //callback(response);
//     })
//     .catch(function(error) {
//       console.log("err" + error);
//     });
// }
export function getAntepostMeetingDetail(ef, eg, e, callback) {
  // "https://api.geoff-banks.com/Geoff/GetEventsFromAPI_Idx";
  // let url = "";
  // if (
  //     localStorage.getItem("sportcode") != null &&
  //     localStorage.getItem("sportcode") === "s"
  // ) {
  //     url = `${base_url}GetEventsFromAPI/${ef}/${eg}`;
  // } else {
  //     url = `${base_url}GetEventsFromAPI_Idx/${ef}/${eg}/${e}`;
  // }

  //Need to use GetEventsFromAPI for all antepost (https://nextpage.nifty.pm/l/JxZ8XSkX1dGHJ)
  let url = `${base_url}GetEventsFromAPI/${ef}/${eg}`;
  if (utility.isCacheApiProxyEnable()) {
    url = ApiProxy.Get + escape(url);
  }

  // const url = base_url + "GetEventsFromAPI/" + ef + "/" + eg;
  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      //console.log("response----", response);
      // const result = ParseEventArrayData(response);
      // callback(result);

      let res = response;
      if (utility.isCacheApiProxyEnable()) {
        res = response.Data;
      }
      const result = ParseEventArrayData(res);
      callback(result);
    })
    .catch(function (error) {});
}

export function getMeetingDetail(ef, eg, e, callback) {
  const url = `${base_url_v0}Ajax/Event/api.asp?ef=${ef}&eg=${eg}&e=${e}`;
  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      const result = ParseEventData(response);
      callback(result);
      //callback(response);
    })
    .catch(function (error) {});
}

export function GetLiveRaceData(sportCode, callback, err) {
  var sport = GetSportInfo(sportCode);
  fetch(sport.LiveRaceURL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      var result = ParseEventData(response);
      return callback(result);
      //callback(response);
    })
    .catch(function (error) {});
}

export function GetSingleRace(sportCode, callback, err) {
  // var url = "http://localhost:4000/GetActiveRaces";
  // var url = "https://apiv1.geoffbanks.bet/GetActiveRaces";
  if (sportCode === "") {
    sportCode = utility.GetQueryStringValue("sportCode");
  }

  if (sportCode === "" || sportCode === "H,h") sportCode = "h,H";
  const url = `https://apiv1.geoffbanks.bet/newLive?sportcode=${sportCode}`;
  // var url = "http://localhost:4000/newLive?sportcode=" + sportCode;

  // var url = "http://localhost:4000/CurrentRace?sportCode=" + sportCode;

  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      const data = JSON.parse(response.data);
      if (data.length > 0) {
        const formattedArray = [];
        data.forEach((v) => {
          let res;
          if (sportCode === "g,q" || sportCode === "$") {
            res = utility.greyHoundRaceFormat(v, sportCode);
          } else {
            res = utility.liveRaceFormat(v, sportCode);
          }

          var result = ParseEventData(res);
          formattedArray.push(result);
        });
        return callback(formattedArray);
      }
    })
    .catch(function (error) {});
}

export function getInPlayEvents(callback) {
  const url =
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
  //var url = "https://v0.geoff-banks.com/events/inplay-api.asp";

  $.get(url, (res) => {
    callback(res.data);
  });
}

export function getWagerInfo(officeNo, referenceNo, callback) {
  const URL = `${base_url_v0}cashier/wagerinfo-api.asp?officeNum=${officeNo}&type=credit&locale=&wagerNo=${referenceNo}&events=c:*EVENTS*&audit=`;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function GetWagerInfoFromMainServer(officeNo, referenceNo, callback) {
  const URL = `${Reg_API_BASE_URL_V1}GetWagerInfo?officeNo=${officeNo}&referenceNo=${referenceNo}`;

  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response.Data);
    })
    .catch(function (error) {});
}

export function betSlipTicketInfo(ticketNo, callback) {
  const URL =
    "https://be.geoff-banks.com/_/items/bet_slip_history?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*&filter[bet_info][contains]=" +
    ticketNo;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      // var result = ParseEventData(response);
      callback(response);
    })
    .catch(function (error) {});
}

export function GetbetSlipTicketInfo(callback) {
  var URL =
    "https://be.geoff-banks.com/_/items/bet_slip_history?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*.*&filter[bet_info][contains][]=[644026,644034]";
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      // var result = ParseEventData(response);
      callback(response);
    })
    .catch(function (error) {});
}

export function betSlipTicketInfoAll(username, start_date, end_date, callback) {
  const URL = `https://be.geoff-banks.com/_/items/bet_slip_history?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*&filter[customer_id][eq]=${username}&filter[created_on][between]=${start_date},${end_date}`;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      // var result = ParseEventData(response);
      callback(response);
    })
    .catch(function (error) {});
}

export async function GetUsingProxyCache(url) {
  url = `${base_url_v1}ApiProxy/Get?url=${url}`;
  const res = await Axios.get(url);
  return res;
}

export async function GetUsingProxyCache_Callback(url, callback) {
  url = `${base_url_v1}ApiProxy/Get?url=${url}`;
  Axios.get(url).then((r) => callback(r));
}

export function GetSportsEvents(ef, eg, callback) {
  //https://api.geoff-banks.com/Geoff/GetEventsFromAPI/42rEngpr/4
  let URL = `${base_url}GetEventsFromAPI/${ef}/${eg}`;
  if (utility.isCacheApiProxyEnable()) {
    URL = ApiProxy.Get + escape(URL);
  }
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      let res = response;
      if (utility.isCacheApiProxyEnable()) {
        res = response.Data;
      }
      const result = ParseEventArrayData(res);
      callback(result);
    })
    .catch(function (error) {});
}

export function GetSingleEvent(ef, eg, ev, callback) {
  //https://api.geoff-banks.com/Geoff/GetEventsFromAPI/42rEngpr/4
  // const URL =
  //     `${base_url_v0}Ajax/Event/api.asp?ef=${ef}&eg=${eg}&e=${ev}`;
  let URL = `${base_url_v0}Ajax/Event/api.asp?ef=${ef}&eg=${eg}&e=${ev}`;

  if (utility.isCacheApiProxyEnable()) {
    URL = `${base_url_v1}SingleEvent/Get?ef=${ef}&eg=${eg}&e=${ev}`;
  }

  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      let res = response;
      if (utility.isCacheApiProxyEnable()) {
        res = response.Data;
      }
      const result = ParseEventData(res);
      callback(result);
    })
    .catch(function (error) {});
}

export function pageData(pageName, callback) {
  //https://api.geoff-banks.com/Geoff/GetEventsFromAPI/42rEngpr/4
  const URL = `https://be.geoff-banks.com/_/items/pages?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*&filter[url][eq]=${pageName}`;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      // var result = ParseEventData(response);
      callback(response);
    })
    .catch(function (error) {});
}

export function GetBasketForLiveOdds(basketId, callback) {
  const URL = `https://api.geoff-banks.com/Geoff/GetBasket/${basketId}`;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      // var result = ParseEventData(response);
      callback(response);
    })
    .catch(function (error) {});
}

export function ChangePassword(data, callback) {
  let URL =
    base_url +
    "UpdatePassword?username=" +
    data.Username +
    "&oldpassword=" +
    data.CurrentPassword +
    "&newpassword=" +
    data.NewPassword +
    "&confirmnewpassword=" +
    data.ConfirmPassword;

  // var URL =
  //   base_url_v0 +
  //   "/cashier/cpass-api.asp?LoginId=" +
  //   data.Username +
  //   "&CurrentPassword=" +
  //   data.CurrentPassword +
  //   "&NewPassword=" +
  //   data.NewPassword;
  fetch(URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function GetUserInfo(callback) {
  const URL = "https://api.geoff-banks.com/Geoff/GetUserInfo/es20/es20";

  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export async function GetBanner() {
  let key = "API.GetBanner";
  if (Cache.Exists(key)) {
    return JSON.parse(Cache.Get(key));
  } else {
    var url =
      items_url +
      "banners?access_token=" +
      access_token +
      "&fields=*.*&filter[platform]=Website";
    var res = await Axios.get(url);
    Cache.SetMins(key, JSON.stringify(res), 30); //CACHE FOR 5 MINS
    return res;
  }
}

export function GetPromotion(callback) {
  var url =
    "https://be.geoff-banks.com/_/items/promotions?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*";
  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {});
}

// Return promise for promise chaining
// export function Login(data, callback, err) {
export function Login(data, err) {
  var bodyOption = data;
  return (
    fetch("https://api.geoff-banks.com/Geoff/Login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: bodyOption,
    })
      .then(function (response) {
        return response.json();
      })
      // .then(function (response) {
      //     callback(response);
      // })
      .catch(function (error) {})
  );
}

export function GetLoginUserData(data, callback) {
  const body = data;
  const url = `https://be.geoff-banks.com/_/items/user?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&filter[username][eq]=${body.username}&filter[password][eq]=${body.password}`;

  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {});
}

export function AddUserToNewDB(data, callback) {
  const body = data;
  body.status = "published";
  $.post(
    "https://be.geoff-banks.com/_/items/user?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
    body,
    (res) => {
      callback(res);
    }
  );
}

// export function GetUserDetails(data, callback) {
//     console.log("res", data)
//     $.get("https://api.geoff-banks.com/Geoff/GetUserInfo/" + data.username + "/" + data.password, res => {
//         callback(res);
//     });
// }

export function GetUserDetailFromNewDB(callback) {
  const username = localStorage["username"];
  const password = localStorage["password"];

  const url = `https://api.geoff-banks.com/Geoff/GetUserInfo/${username}/${password}`;

  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function AddUserDetailToNewDB(data, callback) {
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");

  const body = data;
  // body.status = "published";
  $.post(
    "https://api.geoff-banks.com/Geoff/UpdateUser?userId=" +
      username +
      "&password=" +
      password,
    body,
    (res) => {
      callback(res);
    }
  );
}

export async function SendEmailForInfoSubmitted(data) {
  //var url = utility.GetRoot() + "email/userinfo-submitted.php";
  // var url = "https://www.v1.geoffbanks.bet/email/userinfo-submitted.php";

  var url = siteRoot + "/email/send.php?c=a";

  // let to = "geoffbanksoffice@gmail.com";
  // if (utility.IsDevMode()) to = "geoffbanks@mailspons.com";
  let to = utility.config.email;
  if (utility.IsDevMode()) to = "geoffbanks@mailspons.com";

  data.body = data;
  data.to = to;

  var settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: "POST",
    headers: {},
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
    data: data,
  };

  var res = await $.ajax(settings);
  return res;
}

export async function SendEmail(from, to, subject, data) {
  var url = siteRoot + "/email/send.php?c=a";

  // data.from=''
  if (to == undefined || to == null || to == "") data.to = utility.config.email;
  if (utility.IsDevMode()) data.to = "geoffbanks@mailspons.com";
  data.body = data;

  var settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: "POST",
    headers: {},
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
    data: data,
  };

  $.ajax(settings).done(function (response) {});
}

export function UpdateUserDetailToNewDB(data, callback) {
  var bodyData = data;
  let Url =
    items_url + "user_detail/" + bodyData.id + "?access_token=" + access_token;
  fetch(Url, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (res) {
      callback(res);
    })
    .catch(function (err) {
      callback(err);
    });
}

export function SaveUserDetailToNewDB(data, callback) {
  var bodyData = data;
  let Url = items_url + "user_detail?access_token=" + access_token;
  fetch(Url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (res) {
      callback(res);
    })
    .catch(function (err) {
      callback(err);
    });
}

export function getLiveEvents1(code, callback) {
  //var url = "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
  var url =
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&filter[dcode][eq]=" +
    code;
  //var url = "https://v0.geoff-banks.com/events/inplay-api.asp";
  $.get(url, (res) => {
    callback(res.data);
  });
}

export function getLiveEvents(callback) {
  var url =
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
  //    var url = "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&filter[dcode][eq]=" + code;

  $.get(url, (res) => {
    callback(res.data);
  });
}

export function getInplayData(callback) {
  //var url = "https://api.geoff-banks.com/Events/GetEventsData";
  //var url="http://localhost:4000/GetAllData";
  var url = "https://apiv1.geoffbanks.bet/GetAllData";
  $.get(url, (res) => {
    callback(res);
  });
}

// export async function gamstopStatus(userId) {
//   var url = "https://api.geoff-banks.com/Geoff/GetUserStatus/" + userId;
//   var data = await Axios.get(url);
//   return data.data;
// }

export function getStreamEvents(callback) {
  let random_no = Math.random().toString(36).substring(7);

  var URL = siteRoot + "/atr/events.php?c=" + random_no;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response.Events);
    })
    .catch(function (error) {});
}

export const AllSports = Sports;

export function getaddressByZipcode(zipCode) {
  return new Promise((resolve, reject) => {
    fetch(
      "https://api.getaddress.io/find/" + zipCode + "?api-key=" + Reg_API_KEY,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function PostRegDataWithBody(url, userData) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetDataReg(url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetPaypalData(url, data, callback) {
  data.mode = utility.Mode();
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
      .then((response) => response.json())
      .then((res) => {
        // resolve(res);
        callback(res);
      })
      .catch((error) => {
        // reject(error);
      });
  });
}

export function GetUserDetailFromDirectus(callback) {
  var username = utility.User().username;

  var url =
    items_url +
    "user_detail?access_token=" +
    access_token +
    "&filter[username]=" +
    username;

  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function checkDuplicateEmail(emailaddress, callback) {
  const username = utility.User().username;
  const url = `${items_url}user_detail?access_token=${access_token}&filter[emailaddress]=${emailaddress}&filter[username][neq]=${username}`;

  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function findMyAddress(event, postalcode) {
  var addressList = [];
  getaddressByZipcode(postalcode).then((result) => {
    if (result != null) {
      if (result.addresses != undefined) {
        addressList.push({ value: "-1", label: "Select Address" });
        for (var i = 0; i < result.addresses.length; i++) {
          addressList.push({
            value: result.addresses[i],
            label: result.addresses[i],
          });
        }
      }
    }
  });
  return addressList;
}

export async function SendSMS(data) {
  var url = siteRoot + "/email/geoffbanks-sms.php";

  data.mobileNumber = data;

  const settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: "POST",
    headers: {},
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
    data: data,
  };

  $.ajax(settings).done(function (response) {});
}

export function getRMGEventList(callback) {
  var URL = Reg_API_BASE_URL_V1 + "GetRMGEventList";
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function getRMGStream(data, callback) {
  $.post(`${Reg_API_BASE_URL_V1}GetRMGStream`, data, (res) => {
    callback(res);
  });
}

export function savePaypalDeposit(url, data, callback) {
    fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {"Content-Type": "application/json; charset=utf-8"},
    })
        .then((response) => response.json())
        .then((res) => {
            // resolve(res);
            callback(res);
        })
        .catch((error) => {
            // reject(error);
        });
}

export function getCheckoutIdForPaydoo(data, callback) {
    const url = "https://service.geoffbanks.bet/payment/paydoo/create_payment/"

    fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer OGE4Mjk0MTg1ZTVjNjFmNTAxNWU2MWQyNWY3ZDBkY2V8ZGZxbnRYTkgzOQ=="
        },
    })
        .then((response) => response.json())
        .then((res) => {
            // resolve(res);
            callback(res);
        })
        .catch((error) => {
            // reject(error);
        });
}

export function getPaydooPaymentStatus(id, userName, callback) {
    const url = "https://service.geoffbanks.bet/payment/paydoo/checkout_status/" + id + "/" + "?user=" + userName

    fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer OGE4Mjk0MTg1ZTVjNjFmNTAxNWU2MWQyNWY3ZDBkY2V8ZGZxbnRYTkgzOQ=="
        },
    })
        .then((response) => response.json())
        .then((res) => {
            // resolve(res);
            callback(res);
        })
        .catch((error) => {
            // reject(error);
        });
}

export function getSISEvents(sportcode, callback) {
  let sportId = "";
  if (sportcode != undefined && sportcode != "") {
    sportId = getSportId(sportcode);
  }
  let URL = `${Reg_API_BASE_URL_V1}GetSISEvents`;
  if (sportId != "") {
    URL += `?sportId=${sportId}`;
  }
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

function getSportId(sportcode) {
  if (
    sportcode == "H,h" ||
    sportcode == "H" ||
    sportcode == "o" ||
    sportcode == "vh"
  ) {
    return "HR";
  } else if (sportcode == "g,q" || sportcode == "$") {
    return "DG";
  } else {
    return "";
  }
}

export function getSISStream(data, callback) {
  $.post(`${Reg_API_BASE_URL_V1}GetSISStream`, data, (res) => {
    callback(res);
  });
}

export function checkPaypalEmailExists(email, callback) {
  const URL = `${items_url}paypal_deposits?access_token=${access_token}&filter[data][contains]=${email}`;
  fetch(URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function checkDuplicatePayerID(payerID, callback) {
  const username = utility.User().username;
  const url = `${items_url}user_detail?access_token=${access_token}&filter[payer_id]=${payerID}&filter[username][neq]=${username}`;

  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {});
}

export function getRefund(url, data, callback) {
  data.mode = utility.Mode();
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  })
    .then((response) => response.json())
    .then((res) => {
      // resolve(res);
      callback(res);
    })
    .catch((error) => {
      // reject(error);
    });
}

export function GetSingleEventNotification(data) {
  const url = `${items_url}event_notifications?access_token=${access_token}&fields=*.*&filter[event_id][in]=${data.eventId}&filter[url][logical]=or&filter[url][contains]=${data.URL}&filter[sportcode][logical]=or&filter[sportcode][contains]=${data.sportcode}&filter[status]=published`;
  return Axios.get(url);
}

export function saveWithdrawalReq(data, callback) {
  let url = `${Reg_API_BASE_URL_V1}saveWithdrawalRequest`;
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json; charset=utf-8" },
  })
    .then((response) => response.json())
    .then((res) => {
      callback(res);
    })
    .catch((error) => {});
}

export function SaveAffiliateUser(data, callback) {
  $.post(`${Reg_API_BASE_URL_V1}SaveAffiliateUser`, data, (res) => {
    callback(res);
  });
}

export function AffiliateSignup(data, callback) {
  // var settings = {
  //     "url": `${base_url_v2}Affiliate/Signup`,
  //     "method": "POST",
  //     "headers": {
  //       "Content-Type": "multipart/form-data",
  //     },
  //     "data": data
  //   };

  // $.ajax(settings).done(function (response) {
  //     callback(response);
  // }).fail(function (jqXHR) {
  //     console.log("Error in Affiliate/Signup :",jqXHR)
  //     callback(jqXHR )
  // });

  $.ajax({
    url: `${base_url_v2}Affiliate/Signup`,
    type: "POST",
    data: data,
    contentType: false,
    cache: false,
    processData: false,
    success: function (resp) {
      callback(resp);
    },
    error: function (XMLHttpRequest) {
      callback(XMLHttpRequest);
    },
  });
}

export function LogSignup(data, callback) {
  var settings = {
    url: `${base_url_v2}Affiliate/LogSignup?trackingCode=${data.trackingCode}&userId=${data.userId}`,
    method: "POST",
  };

  $.ajax(settings)
    .done(function (response) {
      callback(response);
    })
    .fail(function (jqXHR) {
      callback(jqXHR);
    });
}

export function LogPageView(trackingCode, callback) {
  var settings = {
    url: `${base_url_v2}Affiliate/LogPageView?trackingCode=${trackingCode}`,
    method: "POST",
  };
  $.ajax(settings)
    .done(function (response) {
      callback(response);
    })
    .fail(function (jqXHR) {
      callback(jqXHR);
    });
}

export function PaymentCharge(cardDetail, callback) {
  let token=''
  if (
    typeof localStorage.getItem("CustInfo") != "undefined" &&
    localStorage.getItem("CustInfo") != null
  ){
    token = JSON.parse(localStorage.getItem("CustInfo"));
    token= token.Token
  }
  $.ajax({
    url: `${base_url_v2}Payments/Charge`,
    method: "POST",
    headers: {
      accept: "text/plain",
      "Content-Type": "application/json-patch+json",
      "Authorization": "Bearer " + token
    },
    data: JSON.stringify(cardDetail),
    success: function (resp) {
      callback(resp);
    },
    error: function (XMLHttpRequest) {
      callback(XMLHttpRequest);
    },
  });
}

export function UserCards() {
  let token=''
  if (
    typeof localStorage.getItem("CustInfo") != "undefined" &&
    localStorage.getItem("CustInfo") != null
  ){
    token = JSON.parse(localStorage.getItem("CustInfo"));
    token= token.Token
  }
  return $.ajax({
    url: `${base_url_v2}UserCards/GetAll`,
    method: "GET",
    headers: {
      accept: "text/plain",
      "Content-Type": "application/json-patch+json",
      "Authorization": "Bearer " + token
    },
    data: {}
  });
}

export function UserCard(udid) {
  let token=''
  if (
    typeof localStorage.getItem("CustInfo") != "undefined" &&
    localStorage.getItem("CustInfo") != null
  ){
    token = JSON.parse(localStorage.getItem("CustInfo"));
    token= token.Token
  }
  return $.ajax({
    url: `${base_url_v2}UserCards/Get?udid=${udid}`,
    method: "GET",
    headers: {
      accept: "text/plain",
      "Content-Type": "application/json-patch+json",
      "Authorization": "Bearer " + token
    },
    data: {}
  });
}

export function GetFormsData() {
  const url = `${items_url}forms?access_token=${access_token}&fields=*.*&filter[status]=published`;
  return Axios.get(url);
}