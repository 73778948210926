import React from "react";
import {Alert, Modal} from "react-bootstrap";
import * as utility from "../js/utility";
import * as Api from "../js/api-service";
import * as Shared from "../Component/Shared/Index";
import $ from "jquery";
import subscribe from "unstated-subscribe-hoc";
import Reg500Modal from "../Component/Modals/Reg500modal";
import {BetSlipContainer} from "../Containers/BetSlipContainer";
import {AccountContainer} from "../Containers/AccountContainer";
import {FavouriteContainer} from "../Containers/FavouriteContainer";
import {PreferenceContainer} from "../Containers/PreferenceContainer";
import {Link} from "react-router-dom";
import HeaderLinks from "./HeaderLinks";
import { authApiService } from "./BetSlip/public-api";

var ApiPath = "https://apiv1.geoffbanks.bet/assets/images/";

var sport;

class Header extends React.Component {
    constructor(props) {
        //console.log(props, 'props')
        //console.log(sport, 'a')

        super(props);
        sport = props.sport;
        this.state = {
            username: "",
            password: "",
            Balance: "",
            AvailableFund: "",
            showAlert: false,
            showModal: false,
            ShowLoader: false,
            ShowReg500Modal: false,
            modalName: "",
            alertMsg: "",
            //file: '',
            file: "/images/avatar.png",
            prefix: "",
            firstname: "",
            headermenu: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.register = this.register.bind(this);
        this.close = this.close.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // /console.log("Header prop:", nextProps);
    }

    componentDidMount() {
        var that = this;
        this.sportHighlighter();

        this.props.AccountStore.CustomInfo();
        this.props.PreferenceStore.UpdateOddFormat();

        //this.SetBalance();

        if (utility.IsCustomerLoggedIn()) {
            $("body").removeClass("Signout");
        } else {
            $("body").addClass("Signout");
        }

        utility.UpdateIsEnableInPlay();
        // var RefreshBalance = setInterval(function () {
        //   if (utility.IsEnableAutoBalanceUpdate() == "true") {
        //     // console.log("INTERVAL")
        //     utility.UpdateUserData(() => {
        //       //that.SetBalance();
        //       that.props.AccountStore.CustomInfo();
        //     });
        //   }
        // }, 30000);

        this.getProfileImage();
        this.GetDynamicPages();
        //this.addBtagCookie();

        this.addAffiliateToken()
        utility.LogPageView(); //IF affiliateToken IS IN LOCALSTORAGE THEN LOGPAGEVIEW
        if (utility.IsCustomerLoggedIn()) {
            this.RefreshBalance(); //Update balance when user refreshes page
        }

        //Add a setting in backend. If ON we need to redirect to maintanence page
        if(utility.getMaintanenceMode() && window.location.pathname !="/maintanence"){
            window.location.href = "https://www.geoffbanks.bet/images/geoff-down.png";
        }
    }

    addAffiliateToken = () => {
        if (window.location.search != '' && window.location.search != undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            //Get affiliateToken from URL
            const affiliateToken = urlParams.get('affiliateToken')
            if (affiliateToken != '' && affiliateToken != null) {
                //Set affiliateToken in localstorage
                utility.setLocalStorage('affiliateToken', affiliateToken)
            }
        }
    }

    // addBtagCookie = () =>{
    //   if(window.location.search!='' && window.location.search!=undefined){
    //     // Parse the URL
    //     const urlParams = new URLSearchParams(window.location.search);

    //     //All params to track
    //     var allParams= [
    //       "btag","affid","siteid","adid","c"
    //     ]

    //     // Set btag cookies
    //     allParams.forEach((param) => {
    //       if(utility.GetCookie(param) == null || utility.GetCookie(param) == "") {
    //         utility.SetCookie(param, urlParams.get(param),365);
    //       }
    //     })
    //   }
    // }

    RefreshBalance = () => {
        $(".refreshBalance").addClass("rotating");
        utility.UpdateUserData(() => {
            this.props.AccountStore.CustomInfo();
            $(".refreshBalance").removeClass("rotating");
        });
    };

    sportHighlighter() {
        //console.log('sprthilight', window.location.pathname.split("/"))

        if (
            window.location.pathname.split("/")[1] == "sports" &&
            window.location.pathname.split("/")[2] != undefined
        ) {
            $(
                'ul li a[href^="/sports/' +
                window.location.pathname.split("/")[2] +
                '"]'
            ).addClass("highlight");
        } else if (
            window.location.pathname.split("/")[1] == "sports" &&
            window.location.pathname.split("/")[2] == undefined
        ) {
            $("#horse-racing").addClass("highlight");
        } else if (window.location.pathname.split("/")[1] == "promotions") {
            $("#promotions").addClass("highlight");
        } else {
            $("#sport-betting").addClass("highlight");
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close() {
        this.setState({showModal: false});
    }

    ShowRegModal = (name) => {
        this.setState({ShowReg500Modal: true, modalName: name});
    };

    HideRegModal = () => {
        this.setState({ShowReg500Modal: false});
    };

    logout() {
        utility.Logout();
        utility.GotoHomepage();

        // Additional auth for internal BetSlip module API
        authApiService.logout();
    }

    forgotPassword() {
        window.location.href = "/forgot-password";
    }

    register() {
        // window.location.href = "/sign-up";
        window.location.href = utility.isExternalSignup()?"/sign-up-v3":"/sign-up-v2";
    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };
    getProfileImage = (e) => {
        utility.GetMyProfileDetailsByUser((response) => {
            if (response.length > 0) {
                var profile_pic = response[0].profile_pic;
                var first_name = response[0].first_name;
                this.setState({
                    file: profile_pic,
                    prefix: ApiPath,
                    firstname: first_name,
                });
            }
        });
    };

    GetDynamicPages() {
        utility.GetHeaderMenu((response) => {
            if (response.length > 0) {
                this.setState({
                    headermenu: response,
                });
            }
        });
    }

    checkGamstop = async () => {
        return true;
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ShowLoader: true});
        this.setState({
            showAlert: false,
            alertMsg:
                "You have entered an incorrect Username or Password. Please try again!",
        });

        //utility.ShowLoading('loginLoder');
        var _this = this;

        var bodyOption =
            "UserName=" + _this.state.username + "&Password=" + _this.state.password;

        var bodyData = {
            username: _this.state.username,
            password: _this.state.password,
        };

        const authResponse = await Api.Login(bodyOption)
            .then(originalResp => {
                 // Additional auth for internal BetSlip module API
                 const externalAuthPayload = {
                    login: bodyData.username,
                    password: bodyData.password
                };
                return authApiService
                    .login(externalAuthPayload)
                    .then(_ => originalResp);
            });
        var msg = this.state.alertMsg;
        localStorage.setItem("username", _this.state.username);
        localStorage.setItem("password", _this.state.password);
        localStorage.setItem("CustInfo", JSON.stringify(authResponse)); //GOOD IDEA TO SAVE EVERYTHING WE GET 🙂
            localStorage.setItem("LastLoginAt", new Date().toISOString());
            if (authResponse.Status === "1") {
                var gamstop = await utility.isGamstopEnable();
                if (gamstop == "true") {
                    if (!(await this.checkGamstop())) {
                        localStorage.removeItem("CustInfo");
                    _this.setState({
                        showAlert: true,
                        ShowLoader: false,
                        alertMsg:
                            "Sorry your access to this website has been blocked. Powered by GAMSTOP.",
                    });
                    return;
                }
            }
            _this.AddUserInNewDB(bodyData);
            utility.ShowNotification("You Are Logged in to Geoff Banks Online");
            localStorage.setItem("authorization", JSON.stringify(authResponse));

            if (localStorage.getItem("BasketId") != null || undefined) {
                var baskteId = localStorage.getItem("BasketId");
                var cust_ref = JSON.parse(localStorage.getItem("authorization"));
                cust_ref = cust_ref.Cust_Ref;
                localStorage.setItem(cust_ref, baskteId);
            }

            _this.props.Store.UserLoggedIn();
            _this.props.AccountStore.CustomInfo();
            _this.props.AccountStore.GetSetting();
            _this.props.FavouriteStore.UpdateFavorites();
            _this.props.PreferenceStore.UpdateOddFormat();

            // update state once again to load profile image
            this.getProfileImage();

            $("body").removeClass("Signout");

            _this.setState({showAlert: false});
            _this.close();
        } else if (authResponse.Status === "0") {
            _this.setState({showAlert: true, ShowLoader: false});
        }
        _this.setState({ShowLoader: false});
    };

    //     Api.Login(
    //         bodyOption,
    //         async (response) => {
    //             console.log('AUTH_RESP', response);

    //             // Additional auth for internal BetSlip module API
    //             const externalAuthPayload = {
    //                 login: bodyData.username,
    //                 password: bodyData.password
    //             };
    //             await authApiService.login(externalAuthPayload);

    //             var msg = this.state.alertMsg;
    //             localStorage.setItem("username", _this.state.username);
    //             localStorage.setItem("password", _this.state.password);
    //             localStorage.setItem("CustInfo", JSON.stringify(response)); //GOOD IDEA TO SAVE EVERYTHING WE GET 🙂

    //             localStorage.setItem("LastLoginAt", new Date().toISOString());
    //             if (response.Status === "1") {
    //                 var gamstop = await utility.isGamstopEnable();
    //                 if (gamstop == "true") {
    //                     if (!(await this.checkGamstop())) {
    //                         localStorage.removeItem("CustInfo");
    //                         _this.setState({
    //                             showAlert: true,
    //                             ShowLoader: false,
    //                             alertMsg:
    //                                 "Sorry your access to this website has been blocked. Powered by GAMSTOP.",
    //                         });
    //                         return;
    //                     }
    //                 }
    //                 _this.AddUserInNewDB(bodyData);
    //                 utility.ShowNotification("You Are Logged in to Geoff Banks Sports");
    //                 localStorage.setItem("authorization", JSON.stringify(response));
    //                 if (localStorage.getItem("BasketId") != null || undefined) {
    //                     var baskteId = localStorage.getItem("BasketId");
    //                     var cust_ref = JSON.parse(localStorage.getItem("authorization"));
    //                     cust_ref = cust_ref.Cust_Ref;
    //                     localStorage.setItem(cust_ref, baskteId);
    //                     _this.props.Store.UserLoggedIn();
    //                     _this.props.AccountStore.CustomInfo();
    //                     _this.props.AccountStore.GetSetting();
    //                     _this.props.FavouriteStore.UpdateFavorites();
    //                     _this.props.PreferenceStore.UpdateOddFormat();
    //                     $("body").removeClass("Signout");
    //                 }
    //                 _this.setState({showAlert: false});
    //                 _this.close();
    //             } else if (response.Status === "0") {
    //                 _this.setState({showAlert: true, ShowLoader: false});
    //             }
    //             _this.setState({ShowLoader: false});
    //         },
    //         (err) => {
    //         }
    //     );
    // };

    AddUserInNewDB = (bodyData) => {
        var bodyData = bodyData;
        //add user to new DB if no data in new DB
        Api.GetLoginUserData(bodyData, (response) => {
            if (response.length <= 0) {
                Api.AddUserToNewDB(bodyData, (resp) => {
                });
            }
        });

        Api.GetUserDetailFromNewDB((response) => {
            if (response.length <= 0) {
                utility.GetUserDetails((res) => {
                    var data = {
                        password: bodyData.password,
                        username: bodyData.username,
                        emailaddress: res.EmailAddress,
                        telephonenumber: res.TelephoneNumber,
                        work_tel: res.Work_Tel,
                        address1: res.address1,
                        address2: res.address2,
                        address3: res.address3,
                        address4: res.address4,
                        country: res.country,
                        mobile: res.mobile,
                        postcode: res.postCode,
                    };
                    //add user detail if not present in new DB
                    Api.AddUserDetailToNewDB(data, (resp) => {
                    });
                });
            }
        });
    };

    render() {
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 text-left">
                            <a href="/">
                                <img src="/images/logo.svg" className="geoffLogo"/>
                            </a>
                        </div>
                        <div className="col-md-10 text-right">
                            {/* {utility.IsCustomerLoggedIn() ?
                <span className="amount">
                  <label>Welcome Back </label> {localStorage.getItem("username")}</span> : ''
              } */}

                            {utility.IsCustomerLoggedIn() ? (
                                <button
                                    className="reg500"
                                    onClick={(e) => this.ShowRegModal("30back")}
                                >
                                    30BACK Claim
                                </button>
                            ) : (
                                ""
                            )}
                            {/* {utility.IsCustomerLoggedIn() ? (
                <button
                  className="reg500"
                  onClick={(e) => this.ShowRegModal("REG500")}
                  disabled={true}
                >
                  REG500 Claim
                </button>
              ) : (
                ""
              )} */}
                            <Reg500Modal
                                name={this.state.modalName}
                                show={this.state.ShowReg500Modal}
                                onHide={this.HideRegModal}
                            />
                            {utility.IsCustomerLoggedIn() ? (
                                <button className="deactivate">
                                    <Link to="/Responsible-gambling">Deactivate Account</Link>
                                </button>
                            ) : (
                                ""
                            )}
                            {!utility.IsCustomerLoggedIn() ? (
                                <button className="register1">
                                    <Link to="/user-details">Update Account Information</Link>
                                </button>
                            ) : (
                                ""
                            )}
                            <button className="register1">
                                <Link to="/reactivate-account">Reactivate Account</Link>
                            </button>

                            {/* <button className="login">LOGIN</button> */}

                            {!utility.IsCustomerLoggedIn() ? (
                                <span>
                  {/* <a href="/sign-up">
                      <button className="register">REGISTER NOW</button>
                    </a> */}
                                    {/* <a href="/login"><button className="login">LOGIN</button></a> */}
                                    <button className="login" onClick={(e) => this.open()}>
                    REGISTER | LOGIN
                  </button>
                </span>
                            ) : (
                                <span>
                  <span className="amount">
                    <label>Balance:&nbsp;</label>
                      {/* {this.state.Balance} */}

                      {this.props.AccountStore.state.CustomInfo.funddetail !=
                      undefined
                          ? this.props.AccountStore.state.CustomInfo.funddetail
                              .Balance
                          : ""}
                  </span>
                  <span className="amount">
                    <label>Available Funds:&nbsp;</label>
                      {/* {this.state.AvailableFund} */}
                      {this.props.AccountStore.state.CustomInfo.funddetail !=
                      undefined
                          ? this.props.AccountStore.state.CustomInfo.funddetail
                              .AvailableFunds
                          : ""}
                  </span>
                  <button
                      className="refreshBtn"
                      onClick={(e) => this.RefreshBalance()}
                  >
                    <i
                        className="fa fa-refresh refreshBalance"
                        aria-hidden="true"
                        title="Refresh Balance"
                    ></i>
                  </button>

                  <a href="/transactions" className="myAccount">
                    My Account
                  </a>
                  <a onClick={(e) => this.logout()}>
                    <button className="login">LOGOUT</button>
                  </a>
                </span>
                            )}
                        </div>
                    </div>
                    <div className="clear"></div>
                    <div className="top-nav">
                        <div className="row">
                            <div className="col-md-8">
                                <ul>
                                    <li>
                                        <a href="/">HOME</a>
                                    </li>
                                    <li>
                                        <a href="/transactions">ACCOUNT</a>
                                    </li>
                                    {
                                        utility.IsCustomerLoggedIn() && utility.isOnlineCardPayments()? <li>
                                        <a href="/paydoo-deposit">DEPOSIT</a>
                                    </li>: ""
                                    }
                                    { utility.isOnlineDepositEnable() ? (
                                        <li>
                                        <a href="/deposit">ONLINE DEPOSIT</a>
                                        </li>)
                                        :""
                                    }

                                    {/* <li><a href="/deposit">ONLINE DEPOSIT</a></li> */}

                                    {/*<li className="paypal-deposit-header">*/}
                                    {/*    <a href="/paypal-deposit">*/}
                                    {/*        <img src="/images/paypal.png" alt="PayPal Deposit"/>*/}
                                    {/*        PAYPAL DEPOSIT*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    {/* <li>
                    <Link to="/manual-deposit">MANUAL DEPOSIT</Link>
                  </li> */}
                                    {
                                        utility.ReadLocal('show_manual_deposit', true)?
                                        <li>
                                            <a href={utility.ReadLocal('show_new_payment_gateway', true)?"/payment-gateway":"/manual_deposit_v1"}>MANUAL DEPOSIT</a>
                                        </li>:null
                                    }
                                    <li>
                                        {/* <a href="/sign-up-affiliate">AFFILIATE SIGNUP</a> */}
                                        <a href="/Affiliate">AFFILIATES</a>
                                    
                                    </li>
                                    {/* <li>
                    <a href="/reverse-withdrawals">REVERSE WITHDRAWAL</a>
                  </li> */}
                                </ul>
                            </div>

                            <div className="col-md-4 text-right headerUserDetail">
                                <ul className="paypalDepCss">
                                { utility.IsCustomerLoggedIn() && utility.isPaypalDepositEnable() ? (
                                    <li className="hvr-pop">
                                       <img src="/images/paypal.png" alt="PayPal Deposit"/>
                                       <a href="/paypal-deposit">PAYPAL DEPOSIT</a>
                                    </li>
                                ):""
                                }


                                    <li>
                                        <div className="user_profile">
                                            {utility.IsCustomerLoggedIn() ? (
                                                <label>
                          <span>
                            {/* {this.getProfileImage()} */}
                              {/* {this.state.file == "" ? '' : */}

                              <img
                                  className="img-profile"
                                  src={
                                      this.state.file == "undefined"
                                          ? "images/avatar.png"
                                          : this.state.prefix + this.state.file
                                  }
                                  alt="pic"
                              />

                              {/* } */}
                          </span>

                                                    <div className="welcome">
                                                        <div className="txt-welcome">WELCOME BACK</div>
                                                        <div className="txt-welcome badge-secondary">
                                                            {this.state.firstname == ""
                                                                ? localStorage.getItem("username")
                                                                : this.state.firstname}
                                                        </div>
                                                    </div>
                                                </label>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="menu-bar">
                                {this.state.headermenu != undefined ? (
                                    <HeaderLinks headermenudata={this.state.headermenu}/>
                                ) : (
                                    ""
                                )}

                                {/* {this
                    .state
                    .headermenu
                    .map(function (data) {
                      return (<li>
                        <a href={data.links} id={data.link_id}>{data.title}</a>
                      </li>
                      )
                    })} */}

                                {/* <li>
                    <a href="/" id='sport-betting' >Sports Betting</a>
                  </li>
                  <li>
                    <a href="/sports/football?sportcode=f" >Football</a>
                  </li>
                  <li>
                    <a href="/sports?sportcode=H,h" id='horse-racing'>Horse Racing</a>
                  </li>
                  <li>
                    <a href="/sports/tennis?sportcode=N" >Tennis</a>
                  </li>
                  <li>
                    <a href="/sports/basketball?sportcode=E" >Basketball</a>
                  </li>
                  <li>

                    <a href="/promotions" id="promotions">Promotions</a>
                  </li> */}
                                {/* </ul> */}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="odd-format">
                                <h4>Odds Format:</h4>
                                <div className="odd-format-option ">
                                    <select
                                        id="oddsFormat"
                                        name="odds"
                                        onChange={(e) =>
                                            this.props.PreferenceStore.SetOddFormat(e, (cb) => {
                                            })
                                        }
                                        value={this.props.PreferenceStore.state.OddFormat}
                                    >
                                        <option value="decimal">Decimal</option>
                                        <option value="fractional">Fractional</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ********************LOGIN MODAL********************* */}

                <Modal
                    className="loginModal"
                    show={this.state.showModal}
                    onHide={this.close}
                    dialogClassName="dialogClass"
                >
                    <div className="modal-arrow"></div>
                    <div className="pop-up-form">
                        <div className="modal-form">
                            <div className="modal-head">
                                <a href="/">
                                    <img src="/images/login-logo.svg" height="100"/>
                                </a>
                            </div>

                            <div className="modal-form-inner" id="loginLoder">
                                {this.state.showAlert == true ? (
                                    <Alert bsStyle="danger">{this.state.alertMsg}</Alert>
                                ) : (
                                    ""
                                )}
                                <form onSubmit={this.handleSubmit}>
                                    <div id="formContent">
                                        <input
                                            type="text"
                                            id="username"
                                            className="fadeIn second"
                                            name="Username"
                                            placeholder="Username"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                        />
                                        <span className="envelopes">
                      <i className="fa fa-envelope"></i>
                    </span>

                                        <input
                                            type="password"
                                            id="password"
                                            className="fadeIn third"
                                            name="login"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                        />
                                        <span className="lock">
                      <i className="fa fa-lock"></i>
                    </span>
                                        <div id="remember">
                                            <a className="remember">
                                                <input type="checkbox"/>
                                                <span>Remember Me</span>
                                            </a>
                                            <a className="underlineHover" href="/forgot-password">
                                                Forgot Password?
                                            </a>
                                        </div>
                                        <Shared.Loader Show={this.state.ShowLoader}/>
                                        <input
                                            type="submit"
                                            className="fadeIn fourth"
                                            value="Login"
                                            disabled={!this.validateForm()}
                                        />
                                        <div className="sign-up">
                      <span>
                        Don't have an Account?
                        <em onClick={(e) => this.register()}>
                          &nbsp;Sign up Now
                        </em>
                      </span>
                                        </div>
                                        <div className="mobile-app">
                                            <i className="fa fa-mobile-alt"></i>
                                            Download the Mobile App
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks&hl=en&fbclid=IwAR0JszrW-uOKRbaXTVXZlOTTaRQSRXIoOQBn2J3SAPaZlGNnUM7IrsBjeSs"
                                                title="Android App"
                                            >
                                                {" "}
                                                <img src="/images/google-play.png" height="25"/>
                                            </a>
                                            <a
                                                href="https://itunes.apple.com/gb/app/geoff-banks-betting/id881898186?mt=8"
                                                title="IOS App"
                                            >
                                                <img src="/images/apple-store.png" height="25"/>
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </header>
        );
    }
}

export default subscribe(Header, {
    Store: BetSlipContainer,
    AccountStore: AccountContainer,
    FavouriteStore: FavouriteContainer,
    PreferenceStore: PreferenceContainer,
});
