import React from "react";
import Footer from "../../Component/Footer";
import AccountSidebar from "../../Component/AccountSidebar";
import * as utility from "../../js/utility";
import * as api from "../../js/api-service";
import InputMask from "react-input-mask";
// import myJson from "../../email_data.json";

class ManualDeposit2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowLoader: false,
      showSuccess: false,
      depositDetail: {
        username: "",
        first_name: "",
        last_name: "",
        dob: "",
        card_number: "",
        cvv: "",
        expires_on: "",
        amount: "",
      },
      userExists: false,
      depositID: "",
      pattern: "****************",
      duplicateCardVal: {},
      userEmail: "",
    };

    // this.sendEmail = this.sendEmail.bind(this);
  }
  componentDidMount() {
    
    if (utility.checkAuth() != undefined) {
      //load the most recent one to prefill any info in form
      api.checkUserExists(utility.User().username, (cb) => {
        var data = cb.data[0]; //SORTED BY CREATED ON (DESCENDING). LATEST AT 0 INDEX
        if (cb.data.length != 0) {
          this.setState({
            userExists: true,
            depositID: data.id,
            duplicateCardVal: data.card_number,
            pattern: utility
              .decrypt(data.card_number)
              .replace(/\d(?=\d{4})/g, "*"),
            depositDetail: {
              username: data.username,
              first_name: data.first_name,
              last_name: data.last_name,
              dob: data.dob,
              card_number: "",
              // card_number: utility.decrypt(data.card_number),
              //cvv: utility.decrypt(data.cvv),
              expires_on: utility.decrypt(data.expiry_date),
              amount: data.amount,
            },
          });
        }
        // console.log(this.state, "HELLO");
        utility.GetUserDetails((ab) => {
          this.setState({ userEmail: ab.EmailAddress });
        });
      });
    } else {
      this.props.history.push("/");
      utility.ShowNotification(
        "Please Sign in to your account to access this page!"
      );
    }
  }

  // sendEmail = (data) => {
  //   var url = "https://www.geoffbanks.bet/email/send.php?c=a";
  //   data.body = data;
  //   console.log("SEND EMAIL");
  //   var settings = {
  //     async: true,
  //     crossDomain: true,
  //     url: url,
  //     method: "POST",
  //     headers: {},
  //     processData: false,
  //     contentType: false,
  //     mimeType: "multipart/form-data",
  //     data: data,
  //   };

  //   $.ajax(settings).done(function (response) {
  //     console.log("email resp:", response);
  //   });
  // };

  handleSubmit = (event) => {
    event.preventDefault();
    utility.ShowLoading("Loader");

    var username = utility.User().username;

    if (this.state.depositDetail.card_number == "") {
      this.state.depositDetail.card_number = utility.decrypt(
        this.state.duplicateCardVal
      );
    } else {
      this.setState({
        depositDetail: {
          ...this.state.depositDetail,
          card_number: this.state.depositDetail.card_number,
        },
      });
    }

    var obj = {
      username: username != null ? username : "",
      first_name: this.state.depositDetail.first_name,
      last_name: this.state.depositDetail.last_name,
      dob: this.state.depositDetail.dob,
      card_number: utility.encrypt(this.state.depositDetail.card_number),
      cvv: utility.encrypt(this.state.depositDetail.cvv),
      expiry_date: utility.encrypt(this.state.depositDetail.expires_on),
      amount: this.state.depositDetail.amount,
    };

    //CHECK DATES

    var d = new Date();
    var expiry = this.state.depositDetail.expires_on;
    expiry = expiry.split("/");
    var month = "0" + (d.getMonth() + 1).toString();
    var year = d.getFullYear().toString().slice(-2);
    // year.slice(-2);

    if ((expiry[1]=="20" && expiry[0]<=month)||(expiry[0] > 12 || expiry[1] < year)) {
      this.setState({ showSuccess: false });
      utility.HideLoading("Loader");

      utility.ShowNotification("Expires on is invalid!");
      return;
    }

    if (
      new Date(this.state.depositDetail.dob).getTime() >
      new Date(Date.now()).getTime()
    ) {
      utility.ShowNotification("Date of birth is invalid!");
      utility.HideLoading("Loader");
      this.setState({ showSuccess: false });

      return;
    }
    if (this.state.depositDetail.amount.trim() == "") {
      utility.ShowNotification("Missing Amount!");
      utility.HideLoading("Loader");
      this.setState({ showSuccess: false });

      return;
    }
    if (this.state.depositDetail.amount < 20) {
      utility.ShowNotification("Minimum Amount 20!");
      utility.HideLoading("Loader");
      this.setState({ showSuccess: false });
      return;
    }

    //if (this.state.userExists == false) {

    //Save a new request in Directus for every manual API submission (https://nextpage.proofhub.com/bapplite/#app/todos/project-4734211540/list-147391440101/task-182362833709)
    console.log(this.state, "DEPOSIT DATA");
    api.saveManualDeposit(obj, (cb) => {
      utility.HideLoading("Loader");
      this.setState({ showSuccess: true });
    });
  // } else {
  //   obj["id"] = this.state.depositID;
  //   api.updateManualDeposit(obj, (cb) => {
  //     console.log(cb, "UPDATED DATA");
  //     utility.HideLoading("Loader");
  //     this.setState({ showSuccess: true });
  //   });
  // }

  utility.LoadEmailTemplate("ManualDeposit",this.state.depositDetail,(template) => {
      api.SendEmail("", utility.config.email, "Manual Deposit", template);
    });
  };

  handleChange = (event) => {
    this.setState({
      depositDetail: {
        ...this.state.depositDetail,
        [event.target.id]: event.target.value,
      },
    });
  };

  render() {
    var content = (
      <div>
        <div className="my-account">
          <div className="container">
            <AccountSidebar />
            <div className="account-right-container">
              <div className="transaction sign-up user-detail changePassword">
                <div className="acount-inner">
                  <h3>Manual Deposit</h3>
                </div>

                {this.state.showSuccess == false ? (
                  <div className="user-details changePassForm" id="Loader">
                    {/* <Shared.Loader  Show={this.state.UserDetail.ShowLoader}  /> */}

                    <div className="manualHeading">
                      <p className="head">Manual Deposit</p>
                      {/* <p className="subHead">
                      PLEASE ONLY USE THIS FORM IF YOU HAVE PREVIOUSLY MADE A
                      PAYMENT TO GEOFF BANKS ONLINE
                    </p> */}
                    </div>

                    <form
                      className="user-details-form"
                      onSubmit={this.handleSubmit}
                      autoComplete="new-password"
                    >
                      <div id="manualForm">
                        <label>Name</label>
                        <div className="nameField">
                          <input
                            type="text"
                            placeholder="First Name"
                            maxLength="50"
                            id="first_name"
                            className="fadeIn"
                            value={this.state.depositDetail.first_name}
                            onChange={this.handleChange}
                            autoComplete="new-password"
                            required
                          />
                          <input
                            type="text"
                            placeholder="Last Name"
                            maxLength="50"
                            id="last_name"
                            className="fadeIn"
                            value={this.state.depositDetail.last_name}
                            onChange={this.handleChange}
                            autoComplete="new-password"
                            required
                          />
                        </div>

                        <div className="fields1">
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <div>
                              <input
                                type="date"
                                placeholder="DD/MM/YYYY"
                                id="dob"
                                className="fadeIn"
                                // min="2014-05-11" max={this.state.dateNow}
                                value={this.state.depositDetail.dob}
                                onChange={this.handleChange}
                                autoComplete="new-password"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group amountField">
                            <label>Amount</label>
                            <input
                              type="number"
                              id="amount"
                              name="amount"
                              step={"0.01"}
                              min={20}
                              className="fadeIn"
                              placeholder="Enter Amount"
                              value={this.state.depositDetail.amount}
                              onChange={this.handleChange}
                              autoComplete="new-password"
                              required
                            />
                            {/* <InputMask
                              className="maskField"
                              // type="text"
                              mask="9999999999"
                              placeholder="Enter Amount"
                              maskChar={""}
                              alwaysShowMask={true}
                              id="amount"
                              className="fadeIn "
                              step={"0.01"}
                              // pattern={"[0-9]{10}"}
                              value={this.state.depositDetail.amount}
                              onChange={this.handleChange}
                              required
                            /> */}
                          </div>
                        </div>

                        <div className="cardFields">
                          <div className="form-group cardnumField">
                            <label>Card Number</label>
                            <InputMask
                              className="maskField"
                              // type="text"
                              mask="9999999999999999"
                              // placeholder="**** **** **** ****"
                              placeholder={this.state.pattern}
                              maskChar={"*"}
                              // alwaysShowMask={true}
                              id="card_number"
                              className="fadeIn "
                              // pattern={"[0-9]{16}"}
                              value={this.state.depositDetail.card_number}
                              onChange={this.handleChange}
                              autoComplete="new-password"
                              // required
                            />
                          </div>
                          <div className="form-group cvvField">
                            <label>CVV Number</label>

                            <InputMask
                              className="maskField"
                              mask="999"
                              maskChar={""}
                              // type="text"
                              placeholder="Enter 3 digits"
                              id="cvv"
                              className="fadeIn "
                              value={this.state.depositDetail.cvv}
                              pattern={"[0-9]{3}"}
                              onChange={this.handleChange}
                              autoComplete="new-password"
                              required
                            />
                          </div>
                          <div className="form-group expiryField">
                            <label>Expires on</label>

                            <InputMask
                              className="maskField"
                              mask="99/99"
                              // type="text"
                              id="expires_on"
                              placeholder={"MM/YY"}
                              // alwaysShowMask={true}
                              // minLength="5"
                              // maxLength="5"
                              pattern="^((0[1-9])|(1[0-2]))[\/\.\-]*((0[8-9])|([0-9][0-9]))$"
                              className="fadeIn "
                              value={this.state.depositDetail.expires_on}
                              onChange={this.handleChange}
                              autoComplete="new-password"
                              required
                            />
                          </div>
                        </div>

                        <div className="row1">
                          <input
                            type="submit"
                            className="fadeIn fourth submit disabled"
                            value="Submit"
                          />
                          {/* <div className="msg light">Updating details feature under maintenance right now</div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="successMessage bgBlack">
                    <img src={"../images/checkCircle.png"} alt="" />
                    <p>Your request has been submitted.</p>
                    <p>
                      Many thanks for your continuing support for Geoff Banks
                      Online
                    </p>
                    <span>
                      FEEL FREE TO GET IN TOUCH WITH OUR SUPPORT TEAM IN CASE
                      YOU NEED FURTHER HELP ON THIS.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
    return <div>{content}</div>;
  }
}

export default ManualDeposit2;
