import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Alert,
  utils,
} from "react-bootstrap";
import * as Utility from "../js/utility";
import * as api from "../js/api-service";
export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      showAlert: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.register = this.register.bind(this);
    this.checkGamstop = this.checkGamstop.bind(this);
    this.abc();
  }

  forgotPassword() {
    this.props.history.push("/forgot-password");
  }
  checkGamstop = async () => {
    // var gamstop = await Utility.isGamstopEnable();
    // if (gamstop == "true") {
    //   var data = await api.gamstopStatus(Utility.GetCustomerInfo().Cust_Ref);
    // }
  };

  register() {
    this.props.history.push("/sign-up");
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  abc() {
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    var _this = this;

    //this.props.history.push('/')

    let bodyOption =
      "UserName=" + _this.state.username + "&Password=" + _this.state.password;

    fetch("http://apigeoffbanks.nextpageit.net/Geoff/Login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: bodyOption,
    })
      .then(function (response) {
        return response.json();
      })
      .then(async function (response) {
        localStorage.setItem("username", _this.state.username);
        localStorage.setItem("password", _this.state.password);
        localStorage.setItem("CustInfo", JSON.stringify(response)); //GOOD IDEA TO SAVE EVERYTHING WE GET 🙂
        localStorage.setItem("LastLoginAt", new Date().toISOString());
        if (response.Status === "1") {
          localStorage.setItem("authorization", JSON.stringify(response));
          if (localStorage.getItem("BasketId") != null || undefined) {
            var baskteId = localStorage.getItem("BasketId");
            var cust_ref = JSON.parse(localStorage.getItem("authorization"));
            cust_ref = cust_ref.Cust_Ref;
            localStorage.setItem(cust_ref, baskteId);
          }
          _this.setState({ showAlert: false });
          _this.props.history.push("/");
        } else if (response.Status === "0") {
          _this.setState({ showAlert: true });
        }
      })
      .catch(function (error) {
      });
  };

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          {this.state.showAlert == true ? (
            <Alert bsStyle="danger">
              You have entered an incorrect Username or Password. Please try
              again !
            </Alert>
          ) : (
            ""
          )}
          <FormGroup controlId="username" bsSize="large">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            bsStyle="primary"
          >
            Login
          </Button>
        </form>
        <div className="register-forget">
          <div className="forgot" onClick={this.forgotPassword}>
            Forgot Password?
          </div>
          OR
          <div className="forgot" onClick={this.register}>
            <b>Register Here</b>
          </div>
        </div>
      </div>
    );
  }
}
