import Noty from "noty";
import { authApiService } from "../Component/BetSlip/public-api";
import * as Api from "../js/api-service";
import * as Cache from "../Services/Cache";

export var config = require("../config.json");
const odds = require("odds-converter");
const CryptoJS = require("crypto-js");

const bogArr = [];
const virtualPlayer = false;
export const partnerCode = "pkgc197";
export const seedKey = "4f7dRw9$X=";

export const RMGPartnerName = "Geoff Banks";
export const RMGPartnerID = "10693";
export const RMGSeedKey = "jlY5rhm7LpUuqT6H";

export const virtualEventsList = {
  virtualHorse: ["HorsesJumps", "HorsesFlats", "HorsesSprints"],
  virtualGreyhound: ["Dogs2CH1", "Dogs2CH2"],
};

export const google = window.google;

const base_url_v1 = "https://apiv1.geoffbanks.bet/";

export function GetMode() {
  return config.mode;
}

export function IsDevMode() {
  return GetMode() == "dev";
}

export function getMaxDepositLimit() {
  return JSON.parse(localStorage.getItem("deposit_limit"));
}

export function getMaintanenceMode() {
  return JSON.parse(localStorage.getItem("maintanence_mode"));
}

function UpdateUserNav() {
  //SET ACTIVE BASED ON VALUE IN LOCALSTORAGE
  setTimeout(() => {
    var val = localStorage.getItem("ActiveTab");
    if (typeof val != "undefined" && val != "") {
      var selector = "ion-segment-button[value='" + val + "']";
      $(selector).click();
    }
  }, 500);
}

export function ShowNotification(content) {
  new Noty({
    text: content,
    timeout: 1000,
    animation: {
      open: "animated bounceInRight", // Animate.css class names
      close: "animated bounceOutRight", // Animate.css class names
    },
  }).show();
}

export function CustomTimeNoty(content, timer) {
  new Noty({
    text: content,
    timeout: timer,
    animation: {
      open: "animated bounceInRight", // Animate.css class names
      close: "animated bounceOutRight", // Animate.css class names
    },
  }).show();
}

export function encrypt(data) {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
}

export function decrypt(data) {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
}

export function GetMD5(input) {
  return CryptoJS.MD5(input).toString();
}

export function getLowerCase(data) {
  return data.toLowerCase();
}
export function MapEvents(data) {
  var Events = [];
  data.forEach((event, index) => {
    var abc = [];
    Events.push({
      title: event.EventName,
      Code: event.Code,
      eventId: event.Id,
      result: abc,
    });
    event.Competitions.forEach((value2, index) => {
      abc.push(value2);
    });
  });
  return Events;
}

export function GenerateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

function deepCopy(oldObj) {
  var newObj = oldObj;
  if (oldObj && typeof oldObj === "object") {
    newObj =
      Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
    for (var i in oldObj) {
      newObj[i] = this.deepCopy(oldObj[i]);
    }
  }
  return newObj;
}

function getInitial(fullName) {
  let initials = "";

  let nameArray = fullName.split(" ");
  if (nameArray.length) initials = nameArray[0].substring(0, 1).toUpperCase();
  if (nameArray.length >= 2)
    initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();

  if (initials == "") initials = "?";
  // if (names.length > 1) {
  //     initials += names[names.length - 1].substring(0, 1).toUpperCase(); }

  return initials;
}

/*eslint no-undef: 0*/

export function ShowLoader(ctrlId) {
  jQuery("#" + ctrlId).loading({
    theme: "dark",
    onStart: function (loading) {
      loading.overlay.slideDown(400);
    },
    onStop: function (loading) {
      loading.overlay.slideUp(400);
    },
    // overlay: $("#custom-overlay")
  });
}

export function HideLoader(ctrlId) {
  jQuery("#" + ctrlId).loading("stop");
}

export function ShowLoginBox() {
  $(".login").click();
}

export function ShowDataLoader(ctrlId) {
  jQuery("#" + ctrlId).loading({
    theme: "dark",
    onStart: function (loading) {
      loading.overlay.slideDown(400);
    },
    onStop: function (loading) {
      loading.overlay.slideUp(400);
    },
  });
}

export function HideDataLoader(ctrlId) {
  jQuery("#" + ctrlId).loading("stop");
}

export function ShowPageLoader() {
  jQuery("body").loading();
}

export function HidePageLoader() {
  jQuery("body").loading("stop");
}

export function ShowBetPlacingLoader() {
  jQuery("#betSlipLoaderContainer").loading({
    theme: "dark",
    onStart: function (loading) {
      loading.overlay.slideDown(400);
    },
    onStop: function (loading) {
      loading.overlay.slideUp(400);
    },
    message: "wager processing with Geoff Banks.",
    // overlay: $("#custom-overlay")
  });
}

export function HideBetPlacingLoader() {
  jQuery("#betSlipLoaderContainer").loading("stop");
}

export function ShowAddBetLoader() {
  if (typeof window.ShowLoader != "undefined" && window.ShowLoader == false)
    return;
  jQuery("#addingBetLoader").loading({
    theme: "dark",
    onStart: function (loading) {
      loading.overlay.slideDown(400);
    },
    onStop: function (loading) {
      loading.overlay.slideUp(400);
    },
    message: "Loading...",
  });
  //TO AUTO HIDE IN CASE OF ERRORS
  setTimeout(() => {
    HideAddBetLoader();
  }, 7000);
}

export function HideAddBetLoader(ctrlId) {
  jQuery("#addingBetLoader").loading("stop");
}

export function ShowLoading(ctrlId) {
  jQuery("#" + ctrlId).loading({
    theme: "dark",
    onStart: function (loading) {
      loading.overlay.slideDown(400);
    },
    onStop: function (loading) {
      loading.overlay.slideUp(400);
    },
    message: "Loading...",
  });
}

export function HideLoading(ctrlId) {
  jQuery("#" + ctrlId).loading("stop");
}

//show alert
export function showAlert(title, subTitle) {
  let alert = this.alertCtrl.create({
    title: title,
    subTitle: subTitle,
    buttons: ["Ok"],
  });
  alert.present();
}

function loginAlert(callback) {
  let alert = this.alertCtrl.create({
    title: "Sign in required",
    subTitle: "Please login to continue",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "Ok",
        handler: callback,
      },
    ],
  });
  alert.present();
}

export function GetBetsCount() {
  var count = localStorage.getItem("BetCount");
  if (count == undefined) count = "0";
  return count;
}

export function SetUpdateSlip() {
  // localStorage["UpdateSlip"] = true;
  BetSlipContainer.UpdateBetSlip();
}

function goBack() {
  // window
  //   .history
  //   .back();
  window.location.href = localStorage.getItem("view");
}

export function GotoHomepage() {
  window.location.href = GetRoot();
}

export function GetRoot() {
  return GetRootBare() + "/";
}

export function GetRootBare() {
  var url = window.location.href;
  var arr = url.split("/");
  var result = arr[0] + "//" + arr[2];
  return result;
}

export function GetQueryStringValue(key) {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
}

export function FilterEvents(events) {
  var eventName = GetQueryStringValue("eventName");
  if (typeof eventName != "undefined" && eventName.length > 0) {
    events = _.filter(events, function (o) {
      return o.EventName == eventName;
    });
  }
  return events;
}

// export function FilterEventNumber(events) {
//   var eventNum = parseInt(GetQueryStringValue("Events"))+1;
//   console.log(eventNum,"LLB")
//   if (typeof eventNum != "undefined" && eventNum > 0) {
//     events = _.filter(events.Events, function (o) {
//       console.log(o.EventNumber == eventNum,"LLB")
//       return o.EventNumber == eventNum ;
//     });
//   }
//   return events;
// }

export function getUTCtime(dateTime) {
  var d = new Date(dateTime);
  var hour = d.getUTCHours() + 1;
  var min =
    d.getUTCMinutes() == "0" ? d.getUTCMinutes() + "0" : d.getUTCMinutes();

  if (parseInt(min).length < 2) {
    return hour + ":" + parseInt(min) + "0";
  } else {
    return hour + ":" + min;
  }
}
export function SortEvents(events) {
  var eventName = GetQueryStringValue("eventName");
  if (typeof eventName != "undefined" && eventName.length > 0) {
    events = _.sortBy(events, function (o) {
      return o.EventName == eventName ? 0 : 1;
    });
  }
  return events;
}
export function antepostFilter(needData, antepost) {
  //console.log(needData, antepost, 'filter')
  var antepostData = _.filter(antepost, function (o) {
    return o.EventName == needData;
  });

  return antepostData;
}

export function filterBoxingAndUfcEvents(sportCode, antepost) {
  var needData = "";
  var eventData = [];
  if (sportCode == "box") {
    needData = "Antepost Boxing";
  } else {
    needData = "Antepost Mma";
  }

  eventData = _.filter(antepost, function (o) {
    return o.EventName == needData;
  });

  return eventData;
}

export function FilterEventsByCode(events) {
  var eventCode = GetQueryStringValue("Code");

  if (
    typeof eventCode != "undefined" &&
    eventCode.length > 0 &&
    eventCode != null
  ) {
    events = _.filter(events, function (o) {
      return o.Code == eventCode;
    });
  }
  return events;
}

export function SortEventsByCode(events) {
  var eventCode = GetQueryStringValue("Code");
  if (
    typeof eventCode != "undefined" &&
    eventCode.length > 0 &&
    eventCode != null
  ) {
    events = _.sortBy(events, function (o) {
      return o.Code == eventCode ? 0 : 1;
    });
  }
  return events;
}

export function GetSpotCodeFromUrl() {
  const params = new URLSearchParams(window.location.search);
  var sportcode = params.get("sportcode");
  return sportcode;
}

export function IsAntepostUrl() {
  //console.log(window.location.pathname);
  return window.location.pathname.toLowerCase().indexOf("antepost") >= 0;
}

export function isAdmin() {
  return (
    (localStorage.getItem("username") == "es20" ||
      localStorage.getItem("username") == "temp" ||
      localStorage.getItem("username") == "test") &&
    this.IsCustomerLoggedIn() //Show Toolip if User loggedIn - https://nextpage.proofhub.com/bapplite/#app/todos/project-4734211540/list-147391440101/task-165203864760
  );
}

export function IsHomePage() {
  return window.location.pathname == "/";
}

export function shakeBetSlip() {
  $(".mob-notify-bet").addClass("animated tata infinite");
  setTimeout(function () {
    $(".mob-notify-bet").removeClass("animated tata infinite");
  }, 1000);
}

export function getSetting(callback) {
  let key = "Utility.GetSetting";
  if (Cache.Exists(key)) return callback(JSON.parse(Cache.Get(key)));
  else {
    var URL =
      "https://be.geoff-banks.com/_/items/settings/1?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";

    fetch(URL)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        Cache.SetMins(key, JSON.stringify(response.data), 60); //CACHE FOR 5 MINS
        localStorage.setItem("gamstop", response.data.gamstop);
        callback(response.data);
      })
      .catch(function (error) {});
  }
}

/**
 * AUTO LOGOUT USER AFTER 3 HOURS
 * SHOULD CHECK THIS ON START ONLY
 * TO MAKE SURE WE DO NOT LOGOUT USER WHILE USING THE APP OR PLACING BETS
 */
export function AutoLogout() {
  if (!this.IsCustomerLoggedIn()) {
    return;
  }
  var lastLoginAt = Date.parse(localStorage.getItem("LastLoginAt"));
  var currentDateTime = Date.now();
  var diff = (currentDateTime - lastLoginAt) / 60000; //WE GETTING THIS IN MINUTES
  //LOGOUT AFTER 3 HOUR (180 min)
  if (diff >= 180) {
    //if (diff >= 60) {
    this.Logout();

    authApiService.logout();
  }
}
// export function CheckInPlaySocket() {
//   var that = this;
//   var connection = $.hubConnection("https://api.geoff-banks.com");

//   const hubProxy = connection.createHubProxy('ChatHub');

//   connection.start()
//     .done(function () {
//       console.log('connected*******');
//       connection.send("success?");
//     })
//     .fail(function (a) {
//       console.log('not connected' + a);
//     });

//   hubProxy.on('addNewMessageToPage', function (message) {
//     //console.log(JSON.stringify(message), "message");
//    // if (typeof message.Data != "undefined") {
//     //  var dataparse = JSON.parse(message);

//       localStorage.setItem("InPlayDataStorage", message);
//       PubSub.publish('broadcast', 'helloworld')
//    // }
//     //that.event.publish('broadcast', dataparse.Data);

//   });

// }
export function SetCookieMins(name, value, minutes) {
  var expires = "";
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function SetCookie(name, value, days) {
  SetCookieMins(name, value, days * 24 * 60);
}

export function GetCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function DeleteCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

export function User() {
  return GetUserFromLocal();
}

export function GetUserFromLocal() {
  return {
    username: localStorage["username"],
    password: localStorage["password"],
  };
}

export function GetUserDetails(callback) {
  var username = localStorage["username"];
  var password = localStorage["password"];
  $.get(
    "https://api.geoff-banks.com/Geoff/GetUserInfo/" +
      username +
      "/" +
      password,
    (res) => {
      callback(res);
    }
  );
}

export function UpdateUserData(callback) {
  if (this.IsCustomerLoggedIn()) {
    let bodyDetail =
      "UserName=" +
      localStorage.getItem("username") +
      "&Password=" +
      localStorage.getItem("password");
    fetch(Api.base_url + "GetBalance", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: bodyDetail,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (res) {
        //console.log("=>>>", res);
        localStorage.setItem("CustInfo", JSON.stringify(res));
        localStorage.setItem("authorization", JSON.stringify(res));
        callback();
      })
      .catch(function (error) {});
  }
}
export function GetCustomerInfo() {
  //GOOD TO HAVE A DEFAULT VALUE HERE TO AVOID CHECKING NULLS AND UNDEFINEDS
  var custInfo = {
    Cust_Ref: "",
    Message: "",
    Status: "",
    funddetail: {
      AvailableFunds: "",
      Balance: "0",
      DisplayName: "",
    },
  };
  if (
    typeof localStorage.getItem("CustInfo") != "undefined" &&
    localStorage.getItem("CustInfo") != null
  )
    custInfo = JSON.parse(localStorage.getItem("CustInfo"));
  return custInfo;
}

export function GetCustomerBalance() {
  return GetCustomerInfo().funddetail.Balance;
}

export function Logout() {
  //this.event.publish('loggedIn');
  localStorage.removeItem("authorization");
  localStorage.removeItem("BasketId");
  localStorage.removeItem("BetSlipCache");
  localStorage.removeItem("BasketCountId");
  localStorage.removeItem("CustInfo");
  localStorage.removeItem("BetSlipBox"); //THE OBJECT THAT STORES ALL BETS
  localStorage.setItem("BetCount", "0");
  localStorage.removeItem("OddFormat");
  localStorage.removeItem("username");
  localStorage.removeItem("password");
  $("body").addClass("Signout");
}

export function GetTranType(transaction) {
  if (transaction.MainTransaction.Type == "10") return "Reverse Withdrawal";
  else if (transaction.MainTransaction.Type == "1") return "Stake";
  else if (transaction.MainTransaction.Type == "2") return "Payout";
  else if (transaction.MainTransaction.Type == "4") return "Voided";
  else if (transaction.MainTransaction.Type == "7") return "Account Credit";
  else if (transaction.MainTransaction.Type == "14") return "Account Debit";
  else if (transaction.MainTransaction.Type == "9")
    return "Payment to Customer";
  else if (transaction.MainTransaction.Type == "11")
    return "Payment from Customer";
  else return transaction.MainTransaction.TranType;
}

export function FilterData(transType, Data) {
  var filterData = Data;
  if (transType == 9 || transType == 10 || transType == 11) {
    var items = _.filter(filterData.Transactions, function (item) {
      return item.MainTransaction.Type == transType;
    });
    filterData.Transactions = items;
  } else if (transType == 15) {
    var items = _.filter(filterData.Transactions, function (item) {
      return (
        item.MainTransaction.Type == 9 ||
        item.MainTransaction.Type == 10 ||
        item.MainTransaction.Type == 11
      );
    });
    filterData.Transactions = items;
  }
  return filterData;
}

export function TranHasDetails(transaction) {
  return (
    transaction.MainTransaction.Type != "7" &&
    //transaction.MainTransaction.Type != "9" &&
    transaction.MainTransaction.Type != "14" &&
    //transaction.MainTransaction.Type != "11" &&
    transaction.MainTransaction.Type != "10" &&
    transaction.MainTransaction.Type != "22"
  );
}

export function IsCustomerLoggedIn() {
  //return GetCustomerInfo().Cust_Ref.length > 0;
  return (
    GetCustomerInfo().Cust_Ref != null && GetCustomerInfo().Cust_Ref.length > 0
  );
}

export function Encode(string) {
  return encodeURIComponent(string);
}

export function GenerateMessage(message) {
  var msg = message;
  if (msg.toLowerCase().indexOf("price has") > 0) {
    msg = "Bet not placed. The price has changed now.";
    this.priceHasChanged = true;
  } else if (msg.toLowerCase().indexOf("event disabled") > 0) {
    msg = "Bet not placed. Event is disabled; no longer available.";
  } else if (msg.toLowerCase().indexOf("event has ended") > 0) {
    msg = "Bet not placed. Event has ended.";
  } else if (msg.toLowerCase().indexOf("liability") > 0) {
    msg = msg.match(new RegExp("(Liability.*)Customer services"))[1].trim();
    this.path = "../assets/icon/exclamation-mark.png";
  }
  // this.path='../../assets/icon/exclamation-mark.png'
  else if (msg.toLowerCase().indexOf("something went") > 0) {
    this.path = "../assets/icon/exclamation-mark.png";
  } else if (msg.toLowerCase().indexOf("connection") > 0) {
    this.path = "../assets/icon/exclamation-mark.png";
  } else if (msg.toLowerCase().indexOf("insufficient Balance") > 0) {
    this.path = "../assets/icon/icon-error.png";
  }
  return msg;
}

export function SetOddFormat(e) {
  localStorage.setItem("OddFormat", e.target.value);
  window.location.reload();
}

export function GetOddFormat() {
  var OddFormatValue = localStorage.getItem("OddFormat");
  if (OddFormatValue != null) {
    return OddFormatValue;
  } else {
    return "fractional";
  }
}

export function ConvertPrice(price) {
  var OddFormatValue = GetOddFormat();
  //console.log("OddFormatValue:", OddFormatValue);
  if (OddFormatValue == "decimal") {
    //console.log("decccccc", OddFormatValue);
    var a = price.split("-");
    if (a[1] != undefined) {
      //var oddval = a[0] +',' +a[1];
      var decimalPrice = odds.fraction.toDecimal(a[0], a[1]);
      return decimalPrice.toFixed(2);
    } else {
      return price;
    }
  } else {
    //console.log("fraccccc:", OddFormatValue);
    return price;
  }
}

export function GetAllBetsFromLocalStorage() {
  var data = localStorage.getItem("BetSlipBox");
  if (typeof data == "undefined" || data == null) return [];
  else return JSON.parse(data);
}

export function GetCustInfoFromLocalStorage() {
  var data = localStorage.getItem("CustInfo");

  //let available_funds = CustInfo.funddetail.AvailableFunds;

  if (typeof data == "undefined" || data == null) return [];
  else return JSON.parse(data);
}

export function GetFavorite(id, callback) {
  var url =
    "https://be.geoff-banks.com/_/items/user_favorites?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*&filter[userid][eq]=" +
    id;
  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {});
}

export function GetPreferences(id, callback) {
  var url =
    "https://be.geoff-banks.com/_/items/user_preferences?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*&filter[userid][eq]=" +
    id;
  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {});
}

export function SetPreferences(id, val, callback) {
  $.post(
    "https://be.geoff-banks.com/_/items/user_favorites?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
    {
      userid: GetCustomerInfo().Cust_Ref,
      odds_format: val,
      status: "published",
    },
    (res) => {
      callback();
    }
  );
}

export function GetPath() {
  var url = window.location.href.replace(GetRoot(), "");
  if (url.length > 1) {
    url = "/" + url;
  }
  return url;
}

export function IsFavorite(callback) {
  //var isFav = false;
  var isFav = GetFavorite(GetCustomerInfo().Cust_Ref, (fav) => {
    if (fav.length > 0) {
      var favLink = _.find(fav, function (o) {
        return o.link == GetPath();
      });
      if (favLink != undefined) {
        callback(true, favLink);
      } else {
        callback(false);
      }
    } else {
      callback(false);
    }
  });
}

export function AddToFavorite(name, callback) {
  IsFavorite((val, data) => {
    if (val) {
      if (data != undefined) {
        let Url =
          "https://be.geoff-banks.com/_/items/user_favorites/" +
          data.id +
          "?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
        fetch(Url, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (res) {
            callback(false);
          })
          .catch(function (err) {
            callback(false);
          });
      }
    } else {
      var data = {
        userid: GetCustomerInfo().Cust_Ref,
        link: GetPath(),
        name: name,
        status: "published",
      };
      $.post(
        "https://be.geoff-banks.com/_/items/user_favorites?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
        {
          userid: GetCustomerInfo().Cust_Ref,
          link: GetPath(),
          name: name,
          status: "published",
        },
        (res) => {
          callback(true);
        }
      );
    }
  });
}

export function SaveBetSlipHistory(betslip, callback) {
  $.post(
    "https://be.geoff-banks.com/_/items/bet_slip_history?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
    {
      customer_id: GetCustomerInfo().Cust_Ref,
      bet_info: JSON.stringify(betslip),
      status: "published",
    },
    (res) => {
      callback(res);
    }
  );
}

export function SaveMyProfileDetail(data, callback) {
  if (GetCustomerInfo().Cust_Ref != "")
    new FormData(data.append("customerid", GetCustomerInfo().Cust_Ref));
  $.ajax({
    url: "https://apiv1.geoffbanks.bet/SaveMyProfile",
    type: "POST",
    data: data,
    contentType: false,
    cache: false,
    processData: false,
    success: function (msg) {
      callback(msg);
    },
  });
}

export function UpdateMyProfileDetail(data, callback) {
  if (GetCustomerInfo().Cust_Ref != "")
    new FormData(data.append("customerid", GetCustomerInfo().Cust_Ref));
  $.ajax({
    url: "https://apiv1.geoffbanks.bet/UpdateMyProfile",
    type: "POST",
    data: data,
    contentType: false,
    cache: false,
    processData: false,
    success: function (msg) {
      callback(msg);
    },
  });
}

export function GetMyProfileDetailsById(id, callback) {
  if (GetCustomerInfo().Cust_Ref != "")
    $.post(
      "https://apiv1.geoffbanks.bet/GetMyProfileDetailsById",
      {
        userid: id,
      },
      (res) => {
        callback(res);
      }
    );
}

export function SendProfileEmail(data, callback) {
  if (GetCustomerInfo().Cust_Ref != "") {
    data.customerid = GetCustomerInfo().Cust_Ref;
  }
  let url = `${base_url_v1}SendProfileEmail`;
  //let url= "http://localhost:4000/SendProfileEmail"

  return new Promise((resolve, reject) => {
    $.post(url, JSON.stringify(data), (res) => {
      resolve(res);
    });
  });
}

//GetMyProfileDetailsById
//  export function GetMyProfileDetailsByUser(callback) {
//   //console.log("GetMyProfileDetailsByIdresponse",id);
//   //if(GetCustomerInfo().Cust_Ref!="")
//   var url = "https://be.geoff-banks.com/_/items/user_profiles?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&filter[customer_id][eq]=" + GetCustomerInfo().Cust_Ref;
//   $.get(url,
//     res => {
//       if (res.data.length > 0) {
//         $.post("https://apiv1.geoffbanks.bet/GetMyProfileDetailsById", {
//           "userid": res.data[0].id,
//         }, res1 => {
//           callback(res1);
//         });
//       }
//       else {
//         callback("");
//       }

//     }
//   );

// }

export function SetSelectedStream(val) {
  localStorage.setItem("SelectedStream", val);
}

export function GetSelectedStream() {
  let val = GetDefaultStream();
  if (typeof val === "undefined" || val === null) val = "SIS";
  return val;
}

export function GetMyProfileDetailsByUser(callback, err) {
  if (!this.IsCustomerLoggedIn()) {
    callback({});
    return;
  }
  var url =
    "https://be.geoff-banks.com/_/items/user_profiles?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&filter[customer_id][eq]=" +
    GetCustomerInfo().Cust_Ref;
  $.ajax({
    url: url,
    success: function (result) {
      callback(result.data);
    },
    error: function (request, msg, error) {},
  });
}

export function GetHeaderMenu(callback) {
  let key = "Utility.GetHeaderMenu";
  if (Cache.Exists(key)) {
    callback(JSON.parse(Cache.Get(key)));
    return;
  } else {
    var url =
      "https://be.geoff-banks.com/_/items/header_menu?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&fields=*.*&filter[status][eq]=published";
    $.get(url, (res) => {
      Cache.SetMins(key, JSON.stringify(res.data), 60); //CACHE FOR 5 MINS
      callback(res.data);
    });
  }
}

export function getLiveEvents(callback) {
  var url =
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";
  //var url = "https://v0.geoff-banks.com/events/inplay-api.asp";

  $.get(url, (res) => {
    callback(res.data);
  });
}

export function SaveInPlayEvents(item, title, dcode, deventid, callback) {
  var eventId1 = item.eventId1;
  var eventId2 = item.eventId2;
  var eventId3 = item.eventId3;
  var eventGroup = item.eventGroup;
  var start_time = item.kickoff;
  var away_team = item.awayTeam;
  var home_team = item.homeTeam;
  var away_odds = item.awayodds;
  var draw_odds = item.drawodds;
  var home_odds = item.homeodds;
  //var available_full_card_options = item.AvailableFullCardOptions;
  //var full_card = item.FullCard;
  //var full_card_types = item.FullCardTypes;
  var available_full_card_options = null;
  var full_card = null;
  var full_card_types = item.FullCardTypes;
  var away_gprice = item.away_gprice;
  var away_price_code = item.awaypricecode;
  var draw_gprice = item.draw_gprice;
  var draw_price_code = item.drawpricecode;
  var home_gprice = item.home_gprice;
  var home_price_code = item.homepricecode;
  var price_code = item.pricecode;
  var second_player = item.secondPlayer;

  $.post(
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
    {
      event_id1: eventId1,
      event_id2: eventId2,
      event_id3: eventId3,
      event_group: eventGroup,
      start_time: start_time,
      away_team: away_team,
      home_team: home_team,
      draw_odds: draw_odds,
      away_odds: away_odds,
      home_odds: home_odds,
      title: title,
      available_full_card_options: available_full_card_options,
      full_card: full_card,
      full_card_types: full_card_types,
      away_gprice: away_gprice,
      away_price_code: away_price_code,
      draw_gprice: draw_gprice,
      draw_price_code: draw_price_code,
      home_gprice: home_gprice,
      home_price_code: home_price_code,
      price_code: price_code,
      second_player: second_player,
      dcode: dcode,
      deventid: deventid.Id,
      status: "published",
    },
    (res) => {
      callback(res);
    }
  );
}

export function RemoveInPlayEvents(id, callback) {
  var urls =
    "https://be.geoff-banks.com/_/items/in_play_events_local/" +
    id +
    "?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M";

  $.ajax({
    url: urls,
    method: "delete",
    contentType: "application/json",
    success: function (result) {
      callback("deleted");
    },
    error: function (request, msg, error) {
      // handle failure
    },
  });
}

export function CheckInPlayEvents_Individuals_Exist(eventId1, callback) {
  var url =
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M&filter[event_id1][eq]=" +
    eventId1;
  $.get(url, (res) => {
    callback(res.data.length);
  });
}

export function SaveInPlayEvents_Individuals(
  item,
  eventgroup,
  awayteam,
  hometeam,
  title,
  dcode,
  deventid,
  callback
) {
  var eventId1 = item;

  $.post(
    "https://be.geoff-banks.com/_/items/in_play_events_local?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
    {
      event_id1: eventId1,
      event_group: eventgroup,
      title: title,
      away_team: awayteam,
      home_team: hometeam,
      dcode: dcode,
      deventid: deventid,
      status: "published",
    },
    (res) => {
      callback(res);
    }
  );
}
export function SaveInPlayEvents_Urls(save_url, callback) {
  var url = "https://api.geoff-banks.com/Events/Save?url=" + save_url;
  $.post(url, (res) => {
    callback(res);
  });
}

export function DeleteInPlayEvents_Urls(delete_url, callback) {
  var url = "https://api.geoff-banks.com/Events/Delete?url=" + delete_url;
  $.post(url, (res) => {
    callback(res);
  });
}

export function checkFullTimeResult(ef, eg, callback) {
  var url =
    "https://api.geoff-banks.com/Geoff/GetEventsFromAPI_Idx/" +
    ef +
    "/" +
    eg +
    "/0";
  //var url = "https://v0.geoff-banks.com/events/inplay-api.asp";
  $.get(url, (res) => {
    callback(res);
  });
}

export function IsEnablePlayEvents() {
  return localStorage.getItem("InPlay");
}
export function IsEnablelive_reload_for_inplay() {
  return localStorage.getItem("live_reload_for_inplay");
}

export function IsEnablelive_transaction_bog() {
  return localStorage.getItem("live_transaction_bog");
}

export function IsEnableAutoBalanceUpdate() {
  return localStorage.getItem("enable_auto_balance_update");
}

export function GetDefaultStream() {
  return localStorage.getItem("default_stream");
}

export function isRefreshOddsEnable() {
  return JSON.parse(localStorage.getItem("enable_refresh_odds"));
}

export function isPaypalDepositEnable() {
  return JSON.parse(localStorage.getItem("enable_paypal_deposit"));
}

export function isCacheApiProxyEnable() {
  return JSON.parse(localStorage.getItem("enable_cache_apiproxy"));
}

export function isOnlineDepositEnable() {
  return JSON.parse(localStorage.getItem("enable_online_deposit"));
}

export function isExternalSignup() {
  return localStorage.getItem("external_signup")==='true';
}

export function isShowLeftBanner() {
  return localStorage.getItem("show_left_banner")==='true';
}

export function isExternalAffiliateForm() {
  return localStorage.getItem("external_affiliate_form")==='true';
}

export function isUseProxyTransactionAPI() {
  return localStorage.getItem("use_proxy_transaction_api")==='true';
}

export function isOnlineCardPayments() {
  return localStorage.getItem("online_card_payments")==='true';
}

export function UpdateIsEnableInPlay() {
  $.get(
    "https://be.geoff-banks.com/_/items/settings?access_token=RRjlZrh9k4rZVnC4ZkzguNFnjRvELg1M",
    (res) => {
      localStorage.setItem("InPlay", JSON.stringify(res.data[0].enable_inplay));
      localStorage.setItem(
        "live_reload_for_inplay",
        JSON.stringify(res.data[0].live_reload_for_inplay)
      );
      localStorage.setItem(
        "live_transaction_bog",
        JSON.stringify(res.data[0].live_transaction_bog)
      );

      localStorage.setItem(
        "enable_auto_balance_update",
        JSON.stringify(res.data[0].enable_auto_balance_update)
      );
      localStorage.setItem("default_stream", res.data[0].default_stream);
      localStorage.setItem(
        "enable_refresh_odds",
        JSON.stringify(res.data[0].enable_refresh_odds)
      );
      localStorage.setItem(
        "enable_paypal_deposit",
        JSON.stringify(res.data[0].enable_paypal_deposit)
      );
      localStorage.setItem(
        "enable_cache_apiproxy",
        JSON.stringify(res.data[0].enable_cache_apiproxy)
      );
      localStorage.setItem(
        "enable_online_deposit",
        JSON.stringify(res.data[0].enable_online_deposit)
      );
      localStorage.setItem(
        "external_signup",
        JSON.stringify(res.data[0].external_signup)
      );
      localStorage.setItem(
        "show_left_banner",
        JSON.stringify(res.data[0].show_left_banner)
      );
      localStorage.setItem(
        "external_affiliate_form",
        JSON.stringify(res.data[0].external_affiliate_form)
      );
      localStorage.setItem(
        "use_proxy_transaction_api",
        JSON.stringify(res.data[0].use_proxy_transaction_api)
      );
      localStorage.setItem(
        "online_card_payments",
        JSON.stringify(res.data[0].online_card_payments)
      );
      localStorage.setItem("deposit_limit", res.data[0].deposit_limit);
      localStorage.setItem(
        "maintanence_mode",
        JSON.stringify(res.data[0].maintanence_mode)
      );
      localStorage.setItem(
        "show_new_payment_gateway",
        JSON.stringify(res.data[0].show_new_payment_gateway)
      );
      localStorage.setItem(
        "show_manual_deposit",
        JSON.stringify(res.data[0].show_manual_deposit)
      );
      //localStorage.setItem("InPlay", "true");
      //localStorage.setItem("live_reload_for_inplay", "true");
    }
  );
}

export function hasFullTimeResult(data) {
  if (
    data[0].ElRespose.toLowerCase() == "full time result" ||
    data[0].ElRespose.toLowerCase() == "to win the match"
  )
    return true;
  else return false;
}

export function liveRaceFormat(data, sportcode) {
  //data.ELResponse and TimeText is undefined here for vh race IN API

  // if (sportcode == "vh") {
  //   data.MeetingName = data.ElRespose + " " + data.TimeText;
  // }

  var index = data.MeetingName.lastIndexOf(" ");
  data.Time = data.MeetingName.substring(index, data.MeetingName.length).trim();

  data.Name = data.MeetingName.substring(0, index).trim();

  data.Info1 = data.EventName1 == undefined ? data.EventName : data.EventName1; // CHECKING FOR UNDEFINED DATA AND REPLACING WITH DATA AVAILABLE IN VIRTUAL RACE DATA
  data.Info2 = data.MeetingName;
  data.TimeLeft = data.TimeText;
  data.PlText = data.Pl;

  //CHECK IF EVENTS ARE AVAILABLE.
  if (data.Events != undefined) {
    data.Events.forEach((v) => {
      if (v.Tooltip.toLowerCase() == "best odds guaranteed") {
        v.ToolTipBoardPrice = v.Tooltip;
      } else if ((v.Tooltip = "")) {
        delete v.ToolTip;
        delete v.Tooltip;
      }
      v.Price1 = v.PriceLink1;
      v.Price2 = v.PriceLink2;
      v.SelName = v.SelName;
      v.JockeyName = "";
      delete v.Image;
    });

    data.Races = data.Events;
  }
  delete data.Events;
  return data;
}

export function greyHoundRaceFormat(data, sportcode) {
  // COMMENTED FOR GREYHOUND DATA, data.ELResponse is undefined IN API
  // if (sportcode == "$") {
  //   data.MeetingName = data.ElRespose + " " + data.TimeText;
  // }
  // return data;
  var index = data.MeetingName.lastIndexOf(" ");
  // data.Time = data.MeetingName.substring(index, data.MeetingName.length).trim();

  data.Name = data.MeetingName.substring(0, index).trim();

  data.Info1 = data.EventName;
  data.Info2 = data.MeetingName;
  data.TimeLeft = data.TimeText;
  data.PlText = data.Pl;

  //CHECK IF EVENTS ARE NOT UNDEFINED
  if (data.Events != undefined) {
    data.Events.forEach((v, i) => {
      v.DogName = v.SelName;

      if (sportcode == "$") {
        v.Image = i + 1 + ".jpg";
        v.number = parseInt(v.Image.split(".")[0].trim());
      } else {
        v.number = parseInt(v.Image.split(".")[0].trim());
      }

      if (v.Tooltip.toLowerCase() == "best odds guaranteed") {
        v.BestOdds = v.Tooltip;

        v.ToolTipBoardPrice = v.Tooltip;
        v.ToolTip = v.ToolTipBoardPrice;
      }
    });
  }

  var events = _.sortBy(data.Events, [
    function (o) {
      return o.number;
    },
  ]);
  data.Events = events;
  return data;
}
export async function isGamstopEnable() {
  var gamstop = localStorage.getItem("gamstop");
  if (gamstop == undefined) {
    await getSetting(cb);
    return localStorage.getItem("gamstop");
  } else {
    return gamstop;
  }
}
export function LoadVirtualPlayer() {
  let random_no = Math.random().toString(36).substring(7);
  var s = document.createElement("script");
  s.setAttribute("src", "/scripts/nanoPlayer.js?c=" + random_no);
  document.body.appendChild(s);
}
export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function emailData(to, subject, body) {
  var form = new FormData();

  form.append("to", to);
  form.append("subject", subject);
  form.append("body", body);

  return form;
}

// export function LoadEmailTemplate(formName, data) {
//   let heading = "",
//     subheading = "",
//     details = "";

//   if (formName == "ManualDeposit") {
//     // subject = "Manual Deposit";
//     heading = "Manual Deposit";
//     subheading =
//       data["username"] + " has requested a manual deposit. Here is a copy:";
//   } else if (formName == "UserDetails") {
//     // subject = "Account Details updated by " + User().username;
//     heading = "Account details Updated.";
//     subheading =
//       data["EmailAddress"] + " has updated details in backend. Here is a copy:";
//   }

//   for (let d in data) {
//     details = details.concat(
//       "<b>" + _.startCase(d) + "</b>: ",
//       data[d],
//       "<br>"
//     );
//   }
//   let template =
//     "<table bgcolor='#FFFFFF' border='0' cellpadding='0' cellspacing='0' align='center' width='100%'><tbody id='1' display=#display1><tr><td><table bgcolor='#FFFFFF' border='0' cellpadding='0' cellspacing='0' align='center' width='520px'><tbody><tr><td style='padding-top:30px;font-family:Helvetica neue,Helvetica,Arial,Verdana,sans-serif;color:#205081;font-size:14px;line-height:32px;text-align:left;font-weight:bold' align='left' align='top'><h3 style='color:#205081'>" +
//     heading +
//     "</h3></td></tr><tr><td style='padding-top:20px;font-family:Helvetica,Helvetica neue,Arial,Verdana,sans-serif;color:#333333;font-size:14px;line-height:20px;text-align:left;font-weight:none' align='left' align='top'>" +
//     subheading +
//     "<p>" +
//     details +
//     "</p></td></tr><tr><td style='padding-top:10px;font-family:Helvetica,Helvetica neue,Arial,Verdana,sans-serif;color:#333333;font-size:14px;line-height:20px;text-align:left;font-weight:none' align='left' align='top'></td></tr></tbody></table></td></tr></tbody></tbody></table>";

//     return template;

//   }

export function LoadEmailTemplate(formName, data, callback) {
  let heading = "",
    subheading = "",
    details = "",
    to = config.email,
    subject = "";

  if (formName == "ManualDeposit") {
    subject = "Manual Deposit";
    heading = "Manual Deposit";
    subheading =
      data["username"] + " has requested a manual deposit. Here is a copy:";
  } else if (formName == "UserDetail") {
    subject = "Account Details updated by " + data.username;
    heading = "Account details Updated.";
    subheading =
      data["EmailAddress"] + " has updated details in backend. Here is a copy:";
  } else if (formName == "PaypalDeposit") {
    subject = "Paypal Deposit details of " + User().username;
    heading = "Paypal Deposit details.";
    subheading =
      data["Email"] + " has updated details in backend. Here is a copy:";
  }

  for (let d in data) {
    details = details.concat(
      "<b>" + _.startCase(d) + "</b>: '",
      data[d],
      "'<br>"
    );
  }

  fetch(`/email/email_data.json`)
    .then((r) => r.json())
    .then((myJson) => {
      to = myJson.to.replace(/#email_to/g, to);
      subject = myJson.subject.replace(/#subject/g, subject);

      let body = myJson.body
        .replace(/#heading/g, heading)
        .replace(/#subheading/g, subheading)
        .replace(/#body/g, details);

      let form = emailData(to, subject, body);

      callback(form);
    });
}

export function LoadWithfdrawalTemplate(data, callback) {
  let heading = "",
    subheading = "",
    det = "",
    details = "",
    to = config.email,
    subject = "";

  for (let d in data) {
    det = det.concat("<b>" + _.startCase(d) + "</b>: ", data[d], "<br>");
  }
  subject = "New Paypal withdrawal requested by " + data.UserId;
  details =
    '<table style="width:550px; margin:0;"><tr><td>Hi Admin,</td></tr><tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td></tr><tr><td>A new request withdrawal request has been made by customer. Details:<br/>' +
    det +
    "</td></tr><tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td></tr><tr><td>You can check the request details at: https://admin.geoffbanks.bet/#/withdrawals/details/" +
    data.RequestID +
    "</td></tr><tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td></tr><tr><td>Thanks,</td></tr><tr><td>Geoff Team</td></tr></table>";

  fetch(`/email/email_data.json`)
    .then((r) => r.json())
    .then((myJson) => {
      to = myJson.to.replace(/#email_to/g, to);
      subject = myJson.subject.replace(/#subject/g, subject);

      let body = myJson.body
        .replace(/#heading/g, heading)
        .replace(/#subheading/g, subheading)
        .replace(/#body/g, details);

      let form = emailData(to, subject, body);

      callback(form);
    });
}

export function checkAuth() {
  var authorization = JSON.parse(localStorage.getItem("authorization"));
  return authorization;
}

export function CheckAuthAndRedirect() {
  if (checkAuth() == null) GotoHomepage();
}

export function getCurrentPage() {
  var href = window.location.href;
  var page = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  if (href.includes("vh")) {
    page = "virtualHorse";
  } else if (href.includes("$")) {
    page = "virtualGreyhound";
  }
  return page;
}

export function currentTimeBasedEvent(startDateTime, endDateTime, callback) {
  var currentTime = new Date().toLocaleTimeString();
  var startTime = new Date(startDateTime).toLocaleTimeString();
  var endTime = new Date(endDateTime).toLocaleTimeString();

  var currentDate = new Date().toLocaleDateString();
  var startDate = new Date(startDateTime).toLocaleDateString();
  var endDate = new Date(endDateTime).toLocaleDateString();

  var current = new Date(currentDate + " " + currentTime).getTime();
  var start = new Date(startDate + " " + startTime).getTime();
  var end = new Date(endDate + " " + endTime).getTime();

  callback(start <= current && current <= end);

  // (startDate <= currentDate && currentDate <= endDate)?callback(startTime <= currentTime && currentTime <= endTime):callback(false)
}

export function displayEventTime(startDateTime) {
  let startTime = new Date(startDateTime).toLocaleTimeString();
  var t = startTime.split(":");
  return t[0] + ":" + t[1];
}

export function Mode() {
  let mode = "dev";
  this.GetRoot().includes("www.geoffbanks.bet")
    ? (mode = "prod")
    : (mode = "dev");
  return mode;
}

export function GetActiveEvent(data) {
  var activeEvent = {};
  let eventArr = [];
  var found = false;
  _.map(data, (d) => {
    if (found) return false;
    _.map(d.Meetings, (meeting) => {
      if (found) return false;
      _.map(meeting.Events, (event) => {
        if (found) return false;
        if (event.Status == "" && !found) {
          activeEvent.Name = d.EventName;
          activeEvent.MeetingName = meeting.MeetinName;
          activeEvent.Event = event;
          // console.log(event);
          found = true;
          return false;
        }
      });
    });
  });
  eventArr.push(activeEvent);
  return eventArr;
}

export function FixVirtualDogRacesData(data) {
  try {
    data = _.orderBy(data, ["selection"], ["asc"]);
    data.forEach((evt, idx) => {
      let imageIdx = evt.selection == undefined ? idx : evt.selection;
      evt.Image = `${parseInt(imageIdx) + 1}.jpg`;
    });
  } catch (ex) {}
  return data;
}

export function CheckActiveEventAndRedirect() {
  let ce = {}; //CURRENT EVENT SHOWING RIGHT NOW
  ce.sportcode = GetQueryStringValue("sportcode");
  ce.eventfile = GetQueryStringValue("eventfile");
  ce.EventGroup = GetQueryStringValue("EventGroup");
  ce.EventIdx = GetQueryStringValue("Events");

  SetSingleEventActivity(
    "<i class='fa fa-bolt'></i>Checking if event still live"
  );
  Api.GetEvents(ce.sportcode, (data) => {
    setTimeout(() => {
      SetSingleEventActivity("");
    }, 2000);

    if (data != undefined && data.length > 0 && data[0].Event != undefined) {
      var evt = data[0].Event;
      if (
        evt.EventFile !== ce.eventfile ||
        evt.EventGroup !== ce.EventGroup ||
        evt.EventIdx !== ce.EventIdx
      ) {
        let url = `/single-event?sportcode=${ce.sportcode}&eventfile=${evt.EventFile}&EventGroup=${evt.EventGroup}&Events=${evt.EventIdx}&EventName=${data[0].MeetingName}`;
        window.location.href = url;
      }
    }
  });
}

export function SetSingleEventActivity(content) {
  $(".single-event-activity-indicator").html(content);
  content == ""
    ? $(".single-event-activity-indicator").hide()
    : $(".single-event-activity-indicator").show();
}

export function CheckForEventExpiry() {
  let sportcode = GetQueryStringValue("sportcode");
  return sportcode == "$" || sportcode == "vh";
}

export function getATRTime(val) {
  let data = val;
  data = data.split("(")[1].split(")")[0];
  data = parseInt(data);

  var startTime = new Date(data);
  startTime = startTime.toISOString();
  startTime = startTime.split("T")[1].split(":");
  startTime = startTime[0] + ":" + startTime[1];
  return startTime;
}

export function _calculateAge(dat) {
  let d = new Date(dat);
  var ageDifMs = Date.now() - d.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  let result = Math.abs(ageDate.getUTCFullYear() - 1970);
  // Check if 18 or just above 18
  if (result == "18") {
    dat = dat.split("-");
    if (
      dat[2] == new Date().getDate() + 1 &&
      dat[1] == new Date().getMonth() + 1
    ) {
      result = "17";
    }
    // console.log("datt", dat,new Date().getDate(),new Date().getMonth(),result)
  }
  return result;
}

export function LogPageView() {
  //IF affiliateToken IS IN LOCALSTORAGE THEN LOGPAGEVIEW
  if (
    getLocalStorage("affiliateToken") != null &&
    getLocalStorage("affiliateToken") != ""
  ) {
    let trackingCode = getLocalStorage("affiliateToken");
    Api.LogPageView(trackingCode, (res) => {
      if (res.status == 200) {
      } else {
      }
    });
  }
}

// export function getFileTypeFromURL(url){
//   let extenstion = get_url_extension(url);
//   console.log("extenstion---",extenstion)
//   let type='';
//   if(extenstion == 'png'){
//     type = 'image/png'
//   }else if(extenstion === 'pdf'){
//     type = 'application/pdf'
//   }else if(extenstion === 'gif'){
//     type = 'image/gif'
//   }else if(extenstion === 'jpeg' || extenstion === 'jpg' || extenstion === 'JPG'){
//     type = 'image/jpeg'
//   }
//   return type;
// }

// function get_url_extension( url ) {
//   return url.split(/[#?]/)[0].split('.').pop().trim();
// }

export const AffiliateContent = [
  {
    img: "/images/q1.png",
    title: "FLEXIBILE COMMISSION",
    description:
      "Tiered revenue share as high as an industry best 40% revenue share.",
  },
  {
    img: "/images/ssd1.png",
    title: "DEDICATED TEAM",
    description:
      "Support for our affiliates, detailed quarterly reporting to help you maximise your earnings.",
  },
  {
    img: "/images/fee1.png",
    title: "A NAME YOU CAN TRUST",
    description:
      "With over 60 years in the business, the John Banks brand, now taken over by his son Geoff remains one of the brightest in online betting.",
  },
  {
    img: "/images/wewe1.png",
    title: "OFFICIALLY LICENSED",
    description: "Licensed in the UK and Irish Republic.",
  },
  {
    img: "/images/rtr1.png",
    title: "TAILORED SUPPORT",
    description:
      "We go the extra mile in offering a personal and professional level of support.",
  },
];

export function ReadLocal(key, parseResult) {
  let data = localStorage.getItem(key);
  console.log("wolf", data);
  if (parseResult && data) {
    return JSON.parse(data);
  }
  return localStorage.getItem(key);
}

export function GetUserName(){
  return localStorage.getItem("username")
}

export const GetCurrentYear = () =>{
  return new Date().getFullYear();
}