import React, { Component } from "react";
import meta from "../../../package.json";

export default class Version extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metaData: {},
    };
  }

  componentDidMount() {
    this.setState({ metaData: meta });
  }

  render() {
    return (
      <div
        className="version-in-footer"
        title={this.state.metaData.version}
      >
        Version: {this.state.metaData.version}
      </div>
    );
  }
}
